import React, { useEffect, useState } from "react";
import Header from "./Header";
import DatePicker from "react-datepicker";
import StarRating from "./StarRating";
import Carousel from "react-elastic-carousel";

const Campaign = () => {
  const [itemsToShow, setItemsToShow] = useState(3);

  useEffect(() => {
    const handleWindowResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth <= 768) {
        setItemsToShow(2);
      } else if (windowWidth <= 820) {
        setItemsToShow(2);
      } else {
        setItemsToShow(3);
      }
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className="d-flex flex-column">
      <Header />
      <div className="custom-home-banner rounded-0 py-5">
        <div className="row align-items-center">
          <div className="col-md-6 p-0 mb-3">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <p className="fs-26p text-white border-bottom mb-2">
                <span className="fw-600">Ogule Airlines</span>&nbsp;Flight
                Booking
              </p>
              <div className="card bg-white">
                <div className="card-body">
                  <div className="form-group mb-0">
                    <label className="text-success fw-700 fs-18p">
                      Subscribe & get up to $10 off. Now!
                    </label>
                    <div class="modified-input-group">
                      <input
                        type="email"
                        class="modified-form-control"
                        placeholder="E-mail Address"
                      />
                      <div class="modified-input-group-append">
                        <span class="modified-input-group-text">Get Promo</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 mb-3 d-none-sm">
            <div className="d-flex align-items-center justify-content-center">
              <div
                className="bg-primary text-white fw-600 overflow-hidden d-flex align-items-center justify-content-center text-center px-3"
                style={{ height: "160px", width: "160px", borderRadius: "50%" }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eveniet, qui.
              </div>
            </div>
          </div>
          <div className="col-12 px-responsive bg-light-blue p-2 rounded px-3">
            <div className="d-flex flex-column">
              <div className="d-flex gap-1rem">
                <div className="form-group mb-0">
                  <input
                    type="radio"
                    id="one_way"
                    name="wayOfFlight"
                    value="one_way"
                    //   checked={this.state.wayOfFlight === "one_way"}
                    //   onChange={(e) => this.search_filters(e.target.value)}
                  />
                  <label
                    className="ml-1 mb-0 fs-14p"
                    htmlFor="one_way"
                    style={{ fontWeight: "600" }}
                  >
                    One Way
                  </label>
                </div>

                <div className="form-group mb-0">
                  <input
                    type="radio"
                    id="round_trip"
                    name="wayOfFlight"
                    value="round_trip"
                    //   checked={this.state.wayOfFlight === "round_trip"}
                    //   onChange={(e) => this.search_filters(e.target.value)}
                  />
                  <label
                    className="ml-1 mb-0 fs-14p"
                    htmlFor="round_trip"
                    style={{ fontWeight: "600" }}
                  >
                    Round Trip
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-4">
                  <div className="form-group mb-0">
                    <label
                      htmlFor="from"
                      className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="#0C8CE9"
                        class="bi bi-geo-alt"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      </svg>
                      From
                    </label>
                    {/* {this.state.city_from === "" ? ( */}
                    <input
                      className="form-control-sm"
                      type="text"
                      autocomplete="off"
                      id="from"
                      list="fromdata"
                      // value={this.state.iatacode_from}
                      // onChange={(e) => this.searchfrom(e.target.value)}
                    />
                    {/* ) : (
                      <span className="form-control-sm">
                        <span className="d-flex align-items-center justify-content-between gap-2rem w-100">
                          <span>
                            {this.state.city_from}&nbsp;(
                            {this.state.iatacode_from})
                          </span>

                          <span
                            className="d-flex align-items-center justify-content-center pointer"
                            onClick={() => {
                              this.setState({
                                iatacode_from: "",
                                city_from: "",
                                airport_name_from: "",
                                country_from: "",
                              });
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                fill="#C9C9C9"
                              />
                            </svg>
                          </span>
                        </span>
                      </span>
                    )} */}
                  </div>
                  <p className="text-secondary mb-0 fs-12p">
                    {/* {this.state.airport_name_from}&nbsp;
                    {this.state.country_from} */}
                    Airport, Country
                  </p>
                  {/* <div>
                    {this.state.fromdata ? (
                      <div>
                        <datalist id="fromdata">
                          {this.state.fromdata.map((item) => (
                            <option value={item.iataCode}>
                              {item.name},{item.address.cityName}&nbsp;
                              {item.address.countryName}
                            </option>
                          ))}
                        </datalist>
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>

                <div className="col-lg-2 col-md-4">
                  <div className="form-group mb-0">
                    <label
                      htmlFor="to"
                      className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="#0C8CE9"
                        class="bi bi-geo-alt"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      </svg>
                      To
                    </label>
                    {/* {this.state.city_to === "" ? ( */}
                    <input
                      className="form-control-sm"
                      type="text"
                      id="to"
                      list="todata"
                      // value={this.state.iatacode_to}
                      // onChange={(e) => this.searchto(e.target.value)}
                    />
                    {/* ) : (
                      <span className="form-control-sm">
                        <span className="d-flex align-items-center justify-content-between gap-2rem w-100">
                          <span>
                            {this.state.city_to}&nbsp;(
                            {this.state.iatacode_to})
                          </span>

                          <span
                            className="d-flex align-items-center justify-content-center pointer"
                            onClick={() => {
                              this.setState({
                                iatacode_to: "",
                                city_to: "",
                                airport_name_to: "",
                                country_to: "",
                              });
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                fill="#C9C9C9"
                              />
                            </svg>
                          </span>
                        </span>
                      </span>
                    )} */}
                  </div>
                  <p className="text-secondary mb-0 fs-12p">
                    {/* {this.state.airport_name_to}&nbsp;
                    {this.state.country_to} */}
                    Airport, Country
                  </p>
                  {/* <div>
                    {this.state.todata ? (
                      <div>
                        <datalist id="todata">
                          {this.state.todata.map((item) => (
                            <option value={item.iataCode}>
                              {item.name},{item.address.cityName}&nbsp;
                              {item.address.countryName}
                            </option>
                          ))}
                        </datalist>
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>

                <div className="col-lg-2 col-md-4">
                  <div className="form-group mb-0">
                    <label
                      htmlFor="from"
                      className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        viewBox="0 0 18 20"
                      >
                        <path
                          d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                          stroke="#0C8CE9"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Departure
                    </label>
                    <DatePicker
                      className="datepicker-control"
                      //   selected={new Date(this.state.departuredate)}
                      dateFormat="d MMM, yy"
                      //   onChange={this.handledeparturedate}
                      //   minDate={new Date()}
                    />
                  </div>
                </div>

                {/* {this.state.wayOfFlight === "round_trip" && ( */}
                <div className="col-lg-2 col-md-4">
                  <div className="form-group mb-0">
                    <label
                      htmlFor="from"
                      className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        viewBox="0 0 18 20"
                      >
                        <path
                          d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                          stroke="#0C8CE9"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Return
                    </label>
                    <DatePicker
                      className="datepicker-control"
                      // selected={
                      //   this.state.returndate
                      //     ? new Date(this.state.returndate)
                      //     : null
                      // }
                      // dateFormat="d MMM, yy"
                      // onChange={this.handlereturndate}
                      // minDate={
                      //   this.state.departuredate
                      //     ? new Date(this.state.departuredate)
                      //     : new Date()
                      // }
                      // maxDate={
                      //   this.state.departuredate
                      //     ? new Date(
                      //         new Date(this.state.departuredate).setDate(
                      //           new Date(this.state.departuredate).getDate() +
                      //             361
                      //         )
                      //       )
                      //     : null
                      // }
                    />
                  </div>
                </div>
                {/* )} */}

                <div className="col-lg-2 col-md-4">
                  <div className="form-group mb-0">
                    <label className="form-label mb-1 fs-12p d-flex align-items-center gap-1">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 25 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.4982 14.0224C11.1666 14.0226 9.8649 13.6279 8.75764 12.8882C7.65038 12.1486 6.78734 11.0972 6.27764 9.86701C5.76794 8.63684 5.63448 7.28316 5.89415 5.97714C6.15382 4.67113 6.79494 3.47145 7.73645 2.52981C8.67795 1.58818 9.87754 0.946893 11.1835 0.687048C12.4895 0.427203 13.8432 0.560474 15.0734 1.07C16.3037 1.57954 17.3552 2.44244 18.095 3.5496C18.8348 4.65675 19.2297 5.95843 19.2297 7.29C19.228 9.07486 18.5182 10.7861 17.2562 12.0483C15.9942 13.3105 14.2831 14.0204 12.4982 14.0224ZM12.4982 2.49912C11.5504 2.49894 10.6238 2.77985 9.83568 3.30631C9.04753 3.83277 8.43321 4.58113 8.07042 5.45676C7.70762 6.33238 7.61265 7.29593 7.79752 8.22553C7.98238 9.15513 8.43877 10.009 9.10897 10.6792C9.77917 11.3494 10.6331 11.8058 11.5627 11.9907C12.4923 12.1756 13.4558 12.0806 14.3314 11.7178C15.2071 11.355 15.9554 10.7407 16.4819 9.95253C17.0084 9.16438 17.2893 8.23781 17.2891 7.29C17.2876 6.01983 16.7824 4.8021 15.8843 3.90395C14.9861 3.0058 13.7684 2.50058 12.4982 2.49912ZM23.2449 28.4409H1.75154L0.78125 27.4706C0.78125 25.9319 1.08432 24.4083 1.67315 22.9867C2.26198 21.5652 3.12505 20.2735 4.21307 19.1855C5.30108 18.0975 6.59275 17.2344 8.01432 16.6456C9.43588 16.0567 10.9595 15.7537 12.4982 15.7537C14.0369 15.7537 15.5605 16.0567 16.9821 16.6456C18.4037 17.2344 19.6953 18.0975 20.7833 19.1855C21.8714 20.2735 22.7344 21.5652 23.3233 22.9867C23.9121 24.4083 24.2152 25.9319 24.2152 27.4706L23.2449 28.4409ZM2.76773 26.5003H22.2287C21.9914 24.0843 20.8638 21.843 19.0652 20.2124C17.2667 18.5819 14.9258 17.6787 12.4982 17.6787C10.0706 17.6787 7.72972 18.5819 5.93118 20.2124C4.13264 21.843 3.00502 24.0843 2.76773 26.5003Z"
                          fill="#0C8CE9"
                        />
                      </svg>
                      Traveler, Class
                    </label>

                    <span
                      className="form-control-sm text-truncate"
                      //   onClick={() => this.setState({ setShow: true })}
                    >
                      <span className="custom-text-primary">
                        {/* {parseInt(this.state.adult) +
                          parseInt(this.state.child) +
                          parseInt(this.state.infants)} */}
                        1 &nbsp;Traveler
                      </span>
                      {/* , &nbsp;{this.state.t_class_selected} */}, Economy
                    </span>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4">
                  <div className="form-group mb-0">
                    <label
                      htmlFor="from"
                      className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                    >
                      &nbsp;
                    </label>
                    <span
                      className="btn btn-sm custom-btn-primary float-right w-100"
                      //   onClick={this.handleUpdate}
                    >
                      Modify Search
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-responsive">
        <div className="d-flex flex-wrap align-items-center gap-1rem justify-content-center">
          <StarRating
            initialRating={3}
            logo="https://cdn.prod.website-files.com/642419dd2be643c50e400f66/6425651c97e0b1158e3920a8_reviewsio-logo.svg"
            Reviews="1,000"
          />
          <StarRating
            initialRating={3}
            logo="https://cdn.trustpilot.net/brand-assets/4.3.0/logo-white.svg"
            Reviews="2,000"
          />
          <StarRating
            initialRating={3}
            logo="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
            Reviews="3,000"
          />
          <StarRating
            initialRating={3}
            logo="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
            Reviews="4,000"
          />
          <StarRating
            initialRating={3}
            logo="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
            Reviews="5,000"
          />
        </div>
      </div>
      <div className="bg-light">
        <div className="py-3 px-responsive fs-32p fw-600">
          <p className="d-flex align-items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-airplane-fill"
              viewBox="0 0 16 16"
              style={{ rotate: "90deg" }}
            >
              <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849" />
            </svg>
            Alaska Airlines Flight Deals
          </p>
          <p>
            Cheapflightsfares brings you some not-to-be missed deals on airfares
            that are bound to enrich your travel experience. These deals are our
            latest and best we've found. Don't keep waiting. Book them now!
          </p>
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="card border-dashed border-secondary">
                <div className="card-body text-secondary">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column">
                      <p className="fw-600 mb-0">07 Sep 2024 - 18 Sep 2024</p>
                      <div className="d-flex align-items-center gap-1rem">
                        <div>
                          <p className="mb-0 fs-20p">LAS</p>
                          <p className="mb-0">Las Vegas</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 7H18C18.5304 7 19.0391 7.21071 19.4142 7.58579C19.7893 7.96086 20 8.46957 20 9C20 9.53043 19.7893 10.0391 19.4142 10.4142C19.0391 10.7893 18.5304 11 18 11H14L10 18H7L9 11H5L3 13H0L2 9L0 5H3L5 7H9L7 0H10L14 7Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                          <svg
                            width="100%"
                            maxwidth="188"
                            height="1"
                            viewBox="0 0 188 1"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line y1="0.5" x2="188" y2="0.5" stroke="#D9D9D9" />
                          </svg>
                          <svg
                            width="13"
                            height="15"
                            viewBox="0 0 13 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.0079 2.2842C12.1783 3.45446 12.8477 5.03403 12.8745 6.68891C12.9013 8.34379 12.2835 9.94423 11.1517 11.1518L11.0079 11.3006L8.00241 14.3053C7.62107 14.6864 7.10912 14.9083 6.57027 14.9261C6.03143 14.9438 5.50598 14.7561 5.10037 14.4009L4.99837 14.3053L1.99221 11.2999C0.796654 10.1043 0.125 8.4828 0.125 6.79203C0.125 5.10127 0.796654 3.47975 1.99221 2.2842C3.18776 1.08865 4.80927 0.416992 6.50004 0.416992C8.1908 0.416992 9.81232 1.08865 11.0079 2.2842ZM6.50004 4.66703C6.22098 4.66703 5.94465 4.722 5.68684 4.82879C5.42902 4.93558 5.19476 5.0921 4.99744 5.28943C4.80011 5.48675 4.64359 5.72101 4.53679 5.97883C4.43 6.23665 4.37504 6.51297 4.37504 6.79203C4.37504 7.07109 4.43 7.34742 4.53679 7.60523C4.64359 7.86305 4.80011 8.09731 4.99744 8.29463C5.19476 8.49196 5.42902 8.64848 5.68684 8.75528C5.94465 8.86207 6.22098 8.91703 6.50004 8.91703C7.06362 8.91703 7.60413 8.69315 8.00264 8.29463C8.40116 7.89612 8.62504 7.35562 8.62504 6.79203C8.62504 6.22845 8.40116 5.68794 8.00264 5.28943C7.60413 4.89091 7.06362 4.66703 6.50004 4.66703Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="mb-0 fs-20p">BOS</p>
                          <p className="mb-0">Boston</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p className="mb-0 fs-12p">Round Trip</p>
                      <p className="mb-0 fs-28p fw-600 text-success">$ 44.00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card border-dashed border-secondary">
                <div className="card-body text-secondary">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column">
                      <p className="fw-600 mb-0">07 Sep 2024 - 18 Sep 2024</p>
                      <div className="d-flex align-items-center gap-1rem">
                        <div>
                          <p className="mb-0 fs-20p">LAS</p>
                          <p className="mb-0">Las Vegas</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 7H18C18.5304 7 19.0391 7.21071 19.4142 7.58579C19.7893 7.96086 20 8.46957 20 9C20 9.53043 19.7893 10.0391 19.4142 10.4142C19.0391 10.7893 18.5304 11 18 11H14L10 18H7L9 11H5L3 13H0L2 9L0 5H3L5 7H9L7 0H10L14 7Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                          <svg
                            width="100%"
                            maxwidth="188"
                            height="1"
                            viewBox="0 0 188 1"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line y1="0.5" x2="188" y2="0.5" stroke="#D9D9D9" />
                          </svg>
                          <svg
                            width="13"
                            height="15"
                            viewBox="0 0 13 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.0079 2.2842C12.1783 3.45446 12.8477 5.03403 12.8745 6.68891C12.9013 8.34379 12.2835 9.94423 11.1517 11.1518L11.0079 11.3006L8.00241 14.3053C7.62107 14.6864 7.10912 14.9083 6.57027 14.9261C6.03143 14.9438 5.50598 14.7561 5.10037 14.4009L4.99837 14.3053L1.99221 11.2999C0.796654 10.1043 0.125 8.4828 0.125 6.79203C0.125 5.10127 0.796654 3.47975 1.99221 2.2842C3.18776 1.08865 4.80927 0.416992 6.50004 0.416992C8.1908 0.416992 9.81232 1.08865 11.0079 2.2842ZM6.50004 4.66703C6.22098 4.66703 5.94465 4.722 5.68684 4.82879C5.42902 4.93558 5.19476 5.0921 4.99744 5.28943C4.80011 5.48675 4.64359 5.72101 4.53679 5.97883C4.43 6.23665 4.37504 6.51297 4.37504 6.79203C4.37504 7.07109 4.43 7.34742 4.53679 7.60523C4.64359 7.86305 4.80011 8.09731 4.99744 8.29463C5.19476 8.49196 5.42902 8.64848 5.68684 8.75528C5.94465 8.86207 6.22098 8.91703 6.50004 8.91703C7.06362 8.91703 7.60413 8.69315 8.00264 8.29463C8.40116 7.89612 8.62504 7.35562 8.62504 6.79203C8.62504 6.22845 8.40116 5.68794 8.00264 5.28943C7.60413 4.89091 7.06362 4.66703 6.50004 4.66703Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="mb-0 fs-20p">BOS</p>
                          <p className="mb-0">Boston</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p className="mb-0 fs-12p">One Way</p>
                      <p className="mb-0 fs-28p fw-600 text-success">$ 44.00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="fw-400">
            <span className="text-danger fw-400">
              *Note: All fares are quoted in USD.
            </span>
            <br />
            Last updated on&nbsp;
            <span className="text-danger">Friday 06/14/2024 at 05:00 AM</span>,
            the fares mentioned above are for flight tickets and inclusive of
            fuel surcharges, service fee and taxes . Based on historical data,
            these fares are subject to change without prior notice and cannot be
            guaranteed at the time of booking. Kindly go through our terms and
            conditions before booking.
          </p>
        </div>
      </div>
      <div className="px-3">
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex flex-column align-items-center p-3">
              <img
                style={{
                  height: "60px",
                  width: "160px",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
                src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
                alt=""
              />
              <p className="fw-600 fs-22p mb-0">Buy now, pay over time</p>
              <p className="mb-0 fs-16p">
                Make monthly payments with no hidden fees
              </p>
              <a
                className="text-link border-bottom"
                href="https://www.google.com/"
                target="_blank"
              >
                Learn More
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-column align-items-center p-3">
              <img
                style={{
                  height: "60px",
                  width: "160px",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
                src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
                alt=""
              />
              <p className="fw-600 fs-22p mb-0">Buy now, pay over time</p>
              <p className="mb-0 fs-16p">
                Make monthly payments with no hidden fees
              </p>
              <a
                className="text-link border-bottom"
                href="https://www.google.com/"
                target="_blank"
              >
                Learn More
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-column align-items-center p-3">
              <img
                style={{
                  height: "60px",
                  width: "160px",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
                src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
                alt=""
              />
              <p className="fw-600 fs-22p mb-0">Buy now, pay over time</p>
              <p className="mb-0 fs-16p">
                Make monthly payments with no hidden fees
              </p>
              <a
                className="text-link border-bottom"
                href="https://www.google.com/"
                target="_blank"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 px-responsive text-secondary">
        <p className="fw-600 fs-28p">About Alaska Airlines (AS) Flights</p>
        <p>
          Alaska Airlines is the seventh-largest U.S. airline in terms of
          passenger traffic and one of the leading carriers of the West Coast.
          The airline is headquartered in Seattle, Alaska. With time, the
          airline has expanded to serve the US East Coast and many southern
          cities, which include Nashville, Charleston and Raleigh/Durham. The
          airline has also spread its wings to Costa Rica. Till today, it flies
          to more than 104 destinations within the States and outside.
        </p>
        <p className="fw-600">Connectivity & Fleet Size</p>
        <p>
          Since its inception, Alaska Airlines have always focused on every side
          of its services - improving both passenger comfort and fuel
          efficiency. The airlines is known for its young operational fleet 153
          Boeing 737 aircraft 52 Bombardier Q400 aircraft.
        </p>
        <p>
          The airlines boasts of covering 92 cities in the United States,
          Canada, Costa Rica and Mexico. Some of the locations covered by this
          low-cost carrier include Anchorage, Adak, Barrow, Cordova, Kodiak,
          Kotzebue, King Salmon, Nome, Prudhoe Bay and Sitka Fairbanks, Juneau,
          Ketchikan several of which are inaccessible by road.
        </p>
        <p className="fw-600">Services and Amenities</p>
        <p className="fw-600">
          The airline offers two great options for entertainment –
        </p>
        <ul className="d-flex flex-column gap-2" style={{ listStyle: "none" }}>
          <li>
            At 10,000 feet in the air, the airlines offers internet through
            gogoinflight network and you can easily enjoy web browsing.{" "}
          </li>
          <li>
            Alaska Beyond – their in-house entertainment streaming channel that
            allows you to watch hundreds of TV shows and pay-per-view movies on
            your device.
          </li>
          <li>
            On the long duration flights, the airlines provide their premium
            in-flight entertainment tablets that can be easily rented.
          </li>
          <li>
            The airline has also partnered with Sub Pop Records, where you will
            be able to enjoy some of the best popular music.
          </li>
          <li>
            For the flights which are longer than 2 hours, meals are available
            for purchase. The flight boasts of local fare of the Pacific
            Northwest and Hawai’I islands. Not only the tasty treats, but the
            airlines offer a large selection of wines and microbrews from
            California, Alaska, and the Pacific Northwest.
          </li>
        </ul>
        <p className="fw-600">Baggage Policy</p>
        <p>
          <span className="fw-600">Carry-on Baggage :&nbsp;</span>When you are
          flying with Alaska Airlines, you are allowed to carry-on baggage of 24
          inches x 17 inches x10 inches. Beyond this, you will have to pay a
          nominal fee.
        </p>
        <p>
          <span className="fw-600">Checked Baggage: :&nbsp;</span>
          For checked in baggage, you will have to pay fee during check-in, at
          one of their kiosks, or the ticket counter with a maximum limit of 50
          lbs and 62" (linear) in dimension.
        </p>
      </div>
      <div className="d-flex flex-column px-responsive py-3">
        <p className="fs-24p">Other Popular Airlines</p>
        <Carousel itemsToShow={itemsToShow}>
          <div className="card">
            <img
              className="card-img-top"
              src="https://images.unsplash.com/photo-1556388158-158ea5ccacbd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
          <div className="card">
            <img
              className="card-img-top"
              src="https://images.unsplash.com/photo-1556388158-158ea5ccacbd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
          <div className="card">
            <img
              className="card-img-top"
              src="https://images.unsplash.com/photo-1556388158-158ea5ccacbd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
          <div className="card">
            <img
              className="card-img-top"
              src="https://images.unsplash.com/photo-1556388158-158ea5ccacbd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
          <div className="card">
            <img
              className="card-img-top"
              src="https://images.unsplash.com/photo-1556388158-158ea5ccacbd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
          <div className="card">
            <img
              className="card-img-top"
              src="https://images.unsplash.com/photo-1556388158-158ea5ccacbd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
          <div className="card">
            <img
              className="card-img-top"
              src="https://images.unsplash.com/photo-1556388158-158ea5ccacbd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
        </Carousel>
      </div>
      <div className="mb-5 pb-5"></div>
    </div>
  );
};

export default Campaign;
