import React, { Component } from "react";
import Header from "./Header";
import Updateprofile from "./Updateprofile";
import Ogulecard from "./Ogulecard";
import Usercards from "./Usercards";
import Bookings from "./Bookings";
import Wishlist from "./Wishlist";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faCreditCard,
  faBell,
  faHeart,
  faClipboard,
  faSignOutAlt,
  faUserEdit,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

class Dashboard extends Component {
  constructor(props) {
    var login_data = JSON.parse(sessionStorage.getItem("login"));
    super(props);

    this.state = {
      name: sessionStorage.getItem("username"),
      password: "",
      dashlink: Ogulecard,
      dashactive: "Ogulecard",
      image: login_data.user.profile_pic,
    };
  }

  nav(item) {
    console.warn(this.props.history.push(item));
  }
  navlink(item, link) {
    this.setState({
      dashlink: item,
      dashactive: link,
    });
  }
  handleClose = () => {
    this.setState({ setShow: false });
  };
  handleShow(id) {
    this.setState({
      setShow: true,
    });
  }

  handleResize = () => {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  componentDidMount() {
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }

  componentWillUnmount() {
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }
  
  render() {
    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Modal
              centered
              size="md"
              show={this.state.setShow}
              onHide={this.handleClose}
              className="logout_conf_popup"
            >
              <Modal.Body className="px-4 py-3 text-center">
                <h4 className="mb-3">Are You Sure to Logout?</h4>
                <Button
                  variant="primary"
                  className="mr-2 px-5 py-2"
                  onClick={() => {
                    this.nav("logout");
                  }}
                >
                  YES
                </Button>
                <Button
                  variant="primary"
                  className="mr-2 px-5 py-2"
                  onClick={() => this.setState({ setShow: false })}
                >
                  NO
                </Button>
              </Modal.Body>
            </Modal>
            <Header />
            <div className="container pb-5">
              <div className="row">
                <div className="col-3">
                  <div className="profile_top_sec py-4 d-flex flex-column align-items-center justify-content-center border-0 shadow-sky mb-3">
                    <div
                      className="profile_img pointer"
                      onClick={() => {
                        this.navlink(Updateprofile, "Updateprofile");
                      }}
                    >
                      <img
                        src={
                          this.state.image
                            ? this.state.image
                            : "https://cdn.pixabay.com/photo/2013/07/13/10/44/man-157699_1280.png"
                        }
                        style={{ borderRadius: "50%" }}
                        alt=""
                      />
                    </div>
                    {/* <div
                      className="profile_details"
                      onClick={() => {
                        this.navlink(Updateprofile, "Updateprofile");
                      }}
                    >
                      <h5 className="text-center">
                        Hello {this.state.name}&nbsp;
                        <FontAwesomeIcon icon={faUserEdit} />
                      </h5>
                    </div> */}
                  </div>
                  <ul className="list-group mt-2" style={{ listStyle: "none" }}>
                    <li
                      className={
                        "rounded-10p mb-2 p-2 shadow-sky pointer" +
                        (this.state.dashactive === "Ogulecard"
                          ? "active border-blue"
                          : "")
                      }
                      onClick={() => {
                        this.navlink(Ogulecard, "Ogulecard");
                      }}
                    >
                      <div className="dashboard_icon float-right">
                        <FontAwesomeIcon icon={faWallet} />
                      </div>
                      Ogule Wallet
                    </li>
                    <li
                      className={
                        "rounded-10p mb-2 p-2 shadow-sky pointer" +
                        (this.state.dashactive === "Usercards"
                          ? "active border-blue"
                          : "")
                      }
                      onClick={() => {
                        this.navlink(Usercards, "Usercards");
                      }}
                    >
                      <div className="dashboard_icon float-right">
                        <FontAwesomeIcon icon={faCreditCard} />
                      </div>
                      Your Card
                    </li>
                    <li
                      className={
                        "rounded-10p mb-2 p-2 shadow-sky pointer" +
                        (this.state.dashactive === "Bookings"
                          ? "active border-blue"
                          : "")
                      }
                      onClick={() => {
                        this.navlink(Bookings, "Bookings");
                      }}
                    >
                      <div className="dashboard_icon float-right">
                        <FontAwesomeIcon icon={faClipboard} />
                      </div>
                      Your Booking
                    </li>
                    {/* <li className="rounded-10p mb-2 p-2 shadow-sky pointer">
                      <div className="dashboard_icon float-right">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                      Notifications
                    </li> */}
                    {/* <li
                      className={
                        "rounded-10p mb-2 p-2 shadow-sky pointer" +
                        (this.state.dashactive === "Wishlist"
                          ? "active border-blue"
                          : "")
                      }
                      onClick={() => {
                        this.navlink(Wishlist, "Wishlist");
                      }}
                    >
                      <div className="dashboard_icon float-right">
                        <FontAwesomeIcon icon={faHeart} />
                      </div>
                      Wishlist
                    </li> */}
                    <li
                      className="rounded-10p mb-2 p-2 shadow-sky pointer"
                      onClick={() => {
                        this.setState({ setShow: true });
                      }}
                    >
                      <div className="dashboard_icon float-right">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                      </div>
                      Logout
                    </li>
                  </ul>
                </div>
                <div className="col-9">
                  <this.state.dashlink />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="profile_top_sec shadow"
              style={{
                borderBottomLeftRadius: "50px",
                borderBottomRightRadius: "50px",
                height: "25vh",
              }}
            >
              <div
                className="d-flex align-items-center"
                style={{ position: "relative" }}
              >
                <span
                  className="btn shadow badge-pill px-3"
                  style={{ position: "absolute", top: "0", right: "0" }}
                  onClick={() => {
                    this.nav("updateprofile");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#0D2A7D"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                  </svg>
                </span>
                <div
                  className="profile_img mx-4 bg-transparent"
                  onClick={() => {
                    this.navlink(Updateprofile, "Updateprofile");
                  }}
                >
                  <img
                    src={
                      this.state.image
                        ? this.state.image
                        : "https://cdn.pixabay.com/photo/2013/07/13/10/44/man-157699_1280.png"
                    }
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                </div>
                <div style={{ color: "#0D2A7D" }}>
                  <p className="fw-600 font-size-1">{this.state.name}</p>
                  <p>{this.state.email}</p>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div
                className="dashboard py-4 bg-transparent shadow-none"
                style={{ top: "25vh" }}
              >
                <div
                  className="dashboard_sec mb-3 border-0 badge-pill px-3 bg-white shadow"
                  onClick={() => {
                    this.nav("ogulecard");
                  }}
                >
                  <div className="dashboard_icon">
                    <FontAwesomeIcon icon={faWallet} />
                  </div>
                  <div className="dashboard_title">
                    <h5 className="mb-0">
                      Ogule Wallet{" "}
                      <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </span>
                    </h5>
                  </div>
                </div>
                <div
                  className="dashboard_sec mb-3 border-0 badge-pill px-3 bg-white shadow"
                  onClick={() => {
                    this.nav("usercards");
                  }}
                >
                  <div className="dashboard_icon">
                    <FontAwesomeIcon icon={faCreditCard} />
                  </div>
                  <div className="dashboard_title">
                    <h5 className="mb-0">
                      Your Card{" "}
                      <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </span>
                    </h5>
                  </div>
                </div>
                <div
                  className="dashboard_sec mb-3 border-0 badge-pill px-3 bg-white shadow"
                  onClick={() => {
                    this.nav("bookings");
                  }}
                >
                  <div className="dashboard_icon">
                    <FontAwesomeIcon icon={faClipboard} />
                  </div>
                  <div className="dashboard_title">
                    <h5 className="mb-0">
                      Your Booking{" "}
                      <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </span>
                    </h5>
                  </div>
                </div>
                {/* <div className="dashboard_sec mb-3 border-0 badge-pill px-3 bg-white shadow">
                  <div className="dashboard_icon">
                    <FontAwesomeIcon icon={faBell} />
                  </div>
                  <div className="dashboard_title">
                    <h5 className="mb-0">
                      Notifications{" "}
                      <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </span>
                    </h5>
                  </div>
                </div> */}
                {/* <div
                  className="dashboard_sec mb-3 border-0 badge-pill px-3 bg-white shadow"
                  onClick={() => {
                    this.nav("wishlist");
                  }}
                >
                  <div className="dashboard_icon">
                    <FontAwesomeIcon icon={faHeart} />
                  </div>
                  <div className="dashboard_title">
                    <h5 className="mb-0">
                      Wishlist{" "}
                      <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </span>
                    </h5>
                  </div>
                </div> */}
                <div
                  className="dashboard_sec mb-3 border-0 badge-pill px-3 bg-white shadow"
                  onClick={() => {
                    this.setState({ setShow: true });
                  }}
                >
                  <div className="dashboard_icon">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </div>
                  <div className="dashboard_title">
                    <h5 className="mb-0">
                      Logout&nbsp;
                      <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <Header />
            <Modal
              centered
              size="md"
              show={this.state.setShow}
              onHide={this.handleClose}
              className="logout_conf_popup"
            >
              <Modal.Body className="px-4 py-3 text-center">
                <h4 className="mb-3 fs-20p">Are You Sure to Logout?</h4>
                <div className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  className="mr-2 px-4 py-2 fs-15p"
                  onClick={() => {
                    this.nav("logout");
                  }}
                >
                  YES
                </Button>
                <Button
                  variant="primary"
                  className="mr-2 px-4 py-2 fs-15p"
                  onClick={() => this.setState({ setShow: false })}
                >
                  NO
                </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}
export default Dashboard;
