import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Form,
  Modal,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap";
import Header from "./Header";
import NewHome from "./NewHome";
import ForgotPassword from "./ForgotPassword/ForgotPassword"

const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Login = () => {
  const history = useHistory();
  const [setShow, setSetShow] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [loginSignup, setLoginSignup] = useState("login");
  const [isLoader, setIsLoader] = useState(false);
  const [googleLoginLoader, setGoogleLoginLoader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [frogotPassword, setForgotPassword] = useState(false);
  const [firstStep, setfirstStep] = useState(true);

  const handleClose = () => {
    history.push("/");
    setSetShow(false);
  };

  const handleShow = () => {
    setSetShow(true);
  };

  const login = () => {
    setIsLoader(true);
    fetch("https://api.ogule.com/api/staff/login-staff", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        isCustomer: true,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success === true) {
          setIsLoader(false);
          setSetShow(false);
          sessionStorage.setItem("login", JSON.stringify(res.result));
          if (sessionStorage.getItem("booking_login") === "yes") {
            history.push("/checkoutnew");
          } else {
            history.push("/");
          }
        } else {
          setIsLoader(false);
          alert(res.message);
        }
      })
      .catch(() => {
        setIsLoader(false);
        alert("Username or password is incorrect");
      });
  };

  const signup = () => {
    setIsLoader(true);
    fetch("https://api.ogule.com/api/staff/add-staff", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        password,
        email,
        mobile_no: phone,
        registered_by: "email",
        role: "customer",
        is_active: true,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success === true) {
          login();
        } else {
          setIsLoader(false);
          alert(res.message);
        }
      })
      .catch(() => {
        setIsLoader(false);
        alert("Fill all details for signup");
      });
  };

  const googleLogin = (response) => {
    setGoogleLoginLoader(true);
    fetch("https://api.ogule.com/api/auth/validate-google-login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        credential: response.credential,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success === true) {
          setGoogleLoginLoader(false);
          sessionStorage.setItem("login", JSON.stringify(res));
          if (sessionStorage.getItem("booking_login") === "yes") {
            history.push("/checkout");
          } else {
            history.push("/dashboard");
          }
        } else {
          setGoogleLoginLoader(false);
          alert("Google Authentication Failed");
        }
      })
      .catch(() => {
        setGoogleLoginLoader(false);
        alert("Google Authentication Failed");
      });
  };

  const guest_user = () => {
    sessionStorage.setItem("guest_user", "guest_access");
    history.push("checkout");
  };

  const handleResize = (e) => {
    setIsMobile(e.matches);
  };

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    setIsMobile(mobileMediaQuery.matches);
    mobileMediaQuery.addEventListener("change", handleResize);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const handleChange = (event) => {
    const formattedValue = event.target.value.replace(/[^\d]/g, "");
    setPhone(formattedValue);
  };

  const handleTabChange = () => {
    setName("");
    setEmail("");
    setPassword("");
    setPhone("");
  };

  return (
    <div>
      {!isMobile ? (
        <div>
          <NewHome />
          {setShow && (
            <Modal
              size="lg"
              backdrop="static"
              show={handleShow}
              onHide={handleClose}
              className="login_popup"
            >
              <Modal.Header closeButton>
                <div className="text-center w-100">
                  <h3>
                    Welcome Back to&nbsp;
                    <span className="text-uppercase text-primary">Ogule</span>
                  </h3>
                  <p style={{ wordSpacing: "4px", letterSpacing: "1.4px" }}>
                    Sign in to your account to continue using Ogule
                  </p>
                </div>
              </Modal.Header>
              <Modal.Body style={{ paddingInline: "2rem" }}>
                <Tabs
                  defaultActiveKey="login"
                  id="uncontrolled-tab-example"
                  onSelect={handleTabChange}
                >
                  <Tab
                    eventKey="login"
                    title={
                      <span
                        className="text-center"
                        style={{ width: "100px", display: "inline-block" }}
                      >
                        LOGIN
                      </span>
                    }
                    className="py-4"
                  >
                    <div className="row d-flex">
                      <div className="col-12 login_form border-0">
                        <Form>
                          <div className="mb-1">
                            <label className="mb-2" htmlFor="email">
                              Email
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Email"
                              pattern={pattern.source}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              style={{ borderRadius: 8, fontSize: "16px" }}
                            />
                            <Form.Text className="text-muted text-right">
                              We'll never share your credentials with anyone
                              else.
                            </Form.Text>
                          </div>

                          <div className="mb-2">
                            <label className="mb-2" htmlFor="password">
                              Password
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              placeholder="Enter Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              style={{ borderRadius: 8, fontSize: "16px" }}
                            />
                          </div>
                          <div className="d-flex justify-content-end mb-2">
                            <span
                              className="text-secondary pointer"
                              onClick={() => {
                                setSetShow(false);
                                setForgotPassword(true);
                                setfirstStep(true);
                              }}
                            >
                              Forgot Password ?
                            </span>
                          </div>
                          <div className="d-flex justify-content-end gap-1rem">
                            <span
                              className="btn btn-lg custom-btn-primary"
                              disabled={isLoader || googleLoginLoader}
                              onClick={() => {
                                if (email.match(pattern)) {
                                  login();
                                } else {
                                  alert("Invalid email format");
                                }
                              }}
                            >
                              {isLoader ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              ) : (
                                "Login"
                              )}
                            </span>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="signup"
                    title={
                      <span
                        className="text-center"
                        style={{ width: "100px", display: "inline-block" }}
                      >
                        SIGN UP
                      </span>
                    }
                    className="py-4"
                  >
                    <div className="row d-flex">
                      <div className="col-12 login_form border-0">
                        <Form>
                          <Form.Group controlId="name">
                            <div className="mb-3">
                              <label className="mb-2" htmlFor="name">
                                Name
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter Name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                style={{ borderRadius: 8, fontSize: "16px" }}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="mb-2" htmlFor="email">
                                Email
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter Email"
                                pattern={pattern.source}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{ borderRadius: 8, fontSize: "16px" }}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="mb-2" htmlFor="phone">
                                Phone no.
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter Phone"
                                value={phone}
                                onChange={handleChange}
                                style={{ borderRadius: 8, fontSize: "16px" }}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="mb-2" htmlFor="password">
                                Password
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ borderRadius: 8, fontSize: "16px" }}
                              />
                            </div>
                          </Form.Group>
                          <div className="d-flex justify-content-end gap-1rem">
                            <span
                              className="btn btn-lg custom-btn-primary"
                              disabled={isLoader || googleLoginLoader}
                              onClick={() => {
                                if (email.match(pattern)) {
                                  signup();
                                } else {
                                  alert("Invalid email format");
                                }
                              }}
                            >
                              {isLoader ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              ) : (
                                "Sign Up"
                              )}
                            </span>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </Modal.Body>
            </Modal>
          )}
          {frogotPassword && (
            <ForgotPassword
              setForgotPassword={setForgotPassword}
              firstStep={firstStep}
              setfirstStep={setfirstStep}
            />
          )}
        </div>
      ) : (
        <div className="login_bg_img">
          <div className="container login_form_section">
            <div className="px-4 mb-4">
              <h5>
                Welcome To&nbsp;<span className="text-uppercase">Ogule</span>
              </h5>
              <p>{loginSignup === "login" ? "Login" : "Signup"} To Continue</p>
            </div>
            <div className="form_box border-0">
              <div className="clearfix mb-2 px-3">
                <span
                  className={
                    "float-left " +
                    (loginSignup === "login"
                      ? "font-weight-bold text-primary"
                      : "font-weight-normal")
                  }
                  onClick={() => setLoginSignup("login")}
                >
                  LOGIN
                </span>
                <span
                  className={
                    "float-right " +
                    (loginSignup === "signup"
                      ? "font-weight-bold text-primary"
                      : "font-weight-normal")
                  }
                  onClick={() => setLoginSignup("signup")}
                >
                  SIGNUP
                </span>
              </div>
              {loginSignup === "login" ? (
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      className="mb-3 px-4"
                      type="email"
                      placeholder="Email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      className="mb-3 px-4"
                      type="password"
                      placeholder="Password"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-end gap-1rem px-2 mb-3">
                    <div
                      className="btn btn-md custom-btn-primary"
                      onClick={login}
                    >
                      {isLoader ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        "Login"
                      )}
                    </div>
                  </div>
                  <p className="text-center mb-0">
                    By Pressing 'Submit' You Agree To Our&nbsp;
                    <Link
                      className="text-dark"
                      to="/terms_conditions"
                      target="_top"
                    >
                      <span className="font-weight-bold">
                        Terms & Conditions
                      </span>
                    </Link>
                  </p>
                </Form>
              ) : (
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Group controlId="name">
                      <Form.Control
                        className="px-4"
                        type="text"
                        placeholder="Enter Name"
                        onChange={(event) => setName(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="email">
                      <Form.Control
                        className="px-4"
                        type="email"
                        placeholder="Enter Email"
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="phone">
                      <Form.Control
                        className="px-4"
                        type="text"
                        placeholder="Enter Phone"
                        onChange={(event) => setPhone(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Control
                        className="px-4 mb-3"
                        type="password"
                        placeholder="Password"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </Form.Group>
                  </Form.Group>
                  <div className="d-flex justify-content-end gap-1rem px-2">
                    <div
                      className="btn btn-lg custom-btn-primary"
                      onClick={signup}
                    >
                      {isLoader ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        "Signup"
                      )}
                    </div>
                  </div>
                  <p className="text-center mb-0">
                    By Pressing 'Submit' You Agree To Our&nbsp;
                    <Link
                      className="text-dark"
                      to="/terms_conditions"
                      target="_top"
                    >
                      <span className="font-weight-bold">
                        Terms & Conditions
                      </span>
                    </Link>
                  </p>
                </Form>
              )}
            </div>
          </div>
          <Header />
        </div>
      )}
    </div>
  );
};

export default Login;
