import React, {Component} from 'react'
import { Form, Button, Container, Row } from 'react-bootstrap';
import Header from './Header'
class Signup extends Component{
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	name:'',
	  	password:'',
	  	email:'',
	  };
	}
	signup(){
		fetch('https://api.ogule.com/api/auth/signup', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "name":this.state.name,
            "password":this.state.password,
            "email":this.state.email,
            "password_confirm":this.state.password,
            "registered_by":'email',            
          })
        }).then((response) => response.json())
        .then((res) => {
      if(res.success === true){
      	sessionStorage.setItem('login',JSON.stringify(res))
       	console.warn(this.props.history.push('dashboard'))
       	alert('Register');
      }
      else{
        alert('Username or password is incorrect');
        }
     }).catch((error) => {
         console.error(error);
        });
     
	}
	render(){
		return(
			<div>
				<Header />
			
			<Container>
  				<Row className="justify-content-md-center">
					<Form>
					  <Form.Group controlId="formBasicEmail">
					    <Form.Label>Email address</Form.Label>
					    <Form.Control type="text" placeholder="Enter Name"
					    	onChange={(event)=>this.setState({name:event.target.value})}
					     />
					     <Form.Control type="email" placeholder="Enter Email"
					    	onChange={(event)=>this.setState({email:event.target.value})}
					     />
					     <Form.Control type="text" placeholder="Enter Phone"
					    	onChange={(event)=>this.setState({phone:event.target.value})}
					     />
					    <Form.Text className="text-muted">
					      We'll never share your email with anyone else.
					    </Form.Text>
					  </Form.Group>

					  <Form.Group controlId="formBasicPassword">
					    <Form.Label>Password</Form.Label>
					    <Form.Control type="password" placeholder="Password"
					    	onChange={(event)=>this.setState({password:event.target.value})}
					     />
					  </Form.Group>
					  <Form.Group controlId="formBasicCheckbox">
					    <Form.Check type="checkbox" label="Check me out" />
					  </Form.Group>
					  <Button variant="primary"
					  	onClick={()=> {this.signup()}}
					  >
					    Submit
					  </Button>
					</Form>
			 	</Row>
			</Container>
			</div>
		)
	}
}
export default Signup;