import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import Header from "./Header";

const Updateprofile = () => {
  const login_data = JSON.parse(sessionStorage.getItem("login"));
  const [userData, setUserData] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);

  const handleGetUserData = async () => {
    setloader(true);
    try {
      const response = await fetch(
        "https://api.ogule.com/api/management/get-customers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            page: 1,
            limit: 5,
            filters: {
              role: "customer",
              isDeleted: false,
              _id: login_data.user._id,
            },
          }),
        }
      );

      const res = await response.json();
      if (res.success) {
        const user = res.result.data[0];
        setUserData(user);
        setName(user?.name || "");
        setEmail(user?.email || "");
        setPhone(user?.mobile_no || "");
        sessionStorage.setItem("username", user?.name)
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    handleGetUserData();
  }, []);

  const editUser = async () => {
    setloader2(true);
    try {
      const response = await fetch(
        "https://api.ogule.com/api/staff/edit-staff",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            staffId: userData?._id,
            name,
            email,
            mobile_no: phone,
            is_active: true,
            role: "customer",
          }),
        }
      );

      const res = await response.json();
      if (res.success) {
        handleGetUserData();
        setloader2(false);
      }
    } catch (error) {
      setloader2(false);
      console.error("Error fetching cards:", error);
    }
  };

  return (
    <div>
      {window.innerWidth > 768 ? (
        <div className="profile_top_sec">
          {!loader && (
            <>
              <div className="mobile_checkout_sec">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    disabled
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>
              </div>
              <button
                className="btn btn-md custom-btn-primary"
                onClick={() => {
                  editUser();
                }}
              >
                {loader2 ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : (
                  "Submit"
                )}
              </button>
            </>
          )}
          {loader && (
            <div className="mobile_checkout_sec">
              <div className="col-md-12">
                <div className="p-2 skeleton_bg">
                  <div className="row">
                    <div className="col-3">
                      <Skeleton variant="circle" width={40} height={40} />
                    </div>
                    <div className="col-9">
                      <h3>
                        <Skeleton variant="text" width="100%" />
                      </h3>
                    </div>
                  </div>
                  <p>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="container-fluid">
          <div className="deals_top_heading_mobile">
            <div className="heading">
              <h5>Your Profile Info</h5>
            </div>
          </div>
          <div className="deals_box_section">
            {!loader && (
              <>
                <div className="mobile_checkout_sec">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      disabled
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </div>
                </div>
                <button
                  className="btn btn-md custom-btn-primary"
                  onClick={() => {
                    editUser();
                  }}
                >
                  {loader2 ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    "Submit"
                  )}
                </button>
              </>
            )}
            {loader && (
              <div className="mobile_checkout_sec">
                <div className="col-md-12">
                  <div className="p-2 skeleton_bg">
                    <div className="row">
                      <div className="col-3">
                        <Skeleton variant="circle" width={40} height={40} />
                      </div>
                      <div className="col-9">
                        <h3>
                          <Skeleton variant="text" width="100%" />
                        </h3>
                      </div>
                    </div>
                    <p>
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                    </p>
                  </div>
                </div>
              </div>
            )}
            <Header />
          </div>
        </div>
      )}
    </div>
  );
};

export default Updateprofile;
