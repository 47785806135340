import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const FooterWrapper = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)");

    const handleResize = () => {
      setIsMobile(mobileMediaQuery.matches);
    };

    handleResize();
    mobileMediaQuery.addEventListener("change", handleResize);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/login" && isMobile) {
      window.location.reload();
    }
  }, []);

  return <div>{location.pathname !== "/login" &&  children}</div>;
};

export default FooterWrapper;
