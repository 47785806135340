import React, { Component } from "react";
import Header from "./Header";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";

class Updatecard extends Component {
  constructor(props) {
    var user_card_data = JSON.parse(sessionStorage.getItem("user_card_data"));
    super(props);
    this.state = {
      name_on_card: user_card_data.name_on_card,
      card_expiry_date: user_card_data.card_expiry_date,
      card_no: user_card_data.card_no,
      card_cvv: user_card_data.card_cvv,
      card_name: user_card_data.card_name,
      user_card_id: user_card_data._id,
    };
  }

  updatecard() {
    var login_data = JSON.parse(sessionStorage.getItem("login"));
    fetch("https://api.ogule.com/api/management/edit-card", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cardId: this.state.user_card_id,
        card_name: this.state.card_name,
        card_no: this.state.card_no,
        card_expiry_date: this.state.card_expiry_date,
        card_cvv: this.state.card_cvv,
        name_on_card: this.state.name_on_card,
        user_id: login_data.user._id,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          console.warn(this.props.history.push("usercards"));
        } else {
          alert("Username or password is incorrect");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    return (
      <div className="px-responsive py-3">
        <Link to="/dashboard" className="btn btn-sm btn-light border mb-2">
          Back
        </Link>
        {window.innerWidth > 768 ? (
          <div className="profile_top_sec mb-3">
            <div className="cr_card">
              <div className="row">
                <div className="col-6 card_type">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="card_type"
                      onChange={(event) =>
                        this.setState({ card_name: event.target.value })
                      }
                    >
                      <option
                        value="Visa"
                        selected={
                          this.state.card_name === "Visa" ? "selected" : ""
                        }
                      >
                        Visa
                      </option>
                      <option
                        value="Master Card"
                        selected={
                          this.state.card_name === "Master Card"
                            ? "selected"
                            : ""
                        }
                      >
                        Master Card
                      </option>
                      <option
                        value="Ruppey"
                        selected={
                          this.state.card_name === "Ruppey" ? "selected" : ""
                        }
                      >
                        Ruppey
                      </option>
                      <option
                        value="Other"
                        selected={
                          this.state.card_name === "Other" ? "selected" : ""
                        }
                      >
                        Other
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-6 card_cvv">
                  <h5>
                    <InputMask
                      class="form-control"
                      mask="999"
                      placeholder="CVV"
                      value={this.state.card_cvv}
                      slotChar="CVV"
                      onChange={(e) => this.setState({ card_cvv: e.value })}
                    ></InputMask>
                  </h5>
                </div>
              </div>
              <div className="card_no">
                <h5>
                  <InputMask
                    class="form-control"
                    mask="9999-9999-9999-9999"
                    placeholder="XXXX-XXXX-XXXX-XXXX"
                    slotChar="XXXX/XXXX/XXXX/XXXX"
                    value={this.state.card_no}
                    onChange={(event) =>
                      this.setState({ card_no: event.target.value })
                    }
                  ></InputMask>
                </h5>
              </div>
              <div className="row">
                <div className="col-6 card_holder">
                  <p>CARD HOLDER</p>
                  <h5>
                    <InputText
                      placeholder="Enter Your Name"
                      value={this.state.name_on_card}
                      onChange={(e) =>
                        this.setState({ name_on_card: e.target.value })
                      }
                    />
                  </h5>
                </div>
                <div className="col-6 card_expiry">
                  <p>Expiry Date</p>
                  <h5>
                    <InputMask
                      class="form-control"
                      mask="99/99"
                      placeholder="MM/YY"
                      value={this.state.card_expiry_date}
                      slotChar="MM/YY"
                      onChange={(e) =>
                        this.setState({ card_expiry_date: e.value })
                      }
                    ></InputMask>
                  </h5>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                class="btn custom-btn-primary"
                onClick={() => {
                  this.updatecard();
                }}
              >
                Update Your Card
              </button>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="cr_card">
              <div className="row">
                <div className="col-6 card_type">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="card_type"
                      onChange={(event) =>
                        this.setState({ card_name: event.target.value })
                      }
                    >
                      <option
                        value="Visa"
                        selected={
                          this.state.card_name === "Visa" ? "selected" : ""
                        }
                      >
                        Visa
                      </option>
                      <option
                        value="Master Card"
                        selected={
                          this.state.card_name === "Master Card"
                            ? "selected"
                            : ""
                        }
                      >
                        Master Card
                      </option>
                      <option
                        value="Ruppey"
                        selected={
                          this.state.card_name === "Ruppey" ? "selected" : ""
                        }
                      >
                        Ruppey
                      </option>
                      <option
                        value="Other"
                        selected={
                          this.state.card_name === "Other" ? "selected" : ""
                        }
                      >
                        Other
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-6 card_cvv">
                  <h5>
                    <InputMask
                      class="form-control"
                      mask="999"
                      placeholder="CVV"
                      value={this.state.card_cvv}
                      slotChar="CVV"
                      onChange={(e) => this.setState({ card_cvv: e.value })}
                    ></InputMask>
                  </h5>
                </div>
              </div>
              <div className="card_no">
                <h5>
                  <InputMask
                    class="form-control"
                    mask="9999-9999-9999-9999"
                    placeholder="XXXX-XXXX-XXXX-XXXX"
                    slotChar="XXXX/XXXX/XXXX/XXXX"
                    value={this.state.card_no}
                    onChange={(event) =>
                      this.setState({ card_no: event.target.value })
                    }
                  ></InputMask>
                </h5>
              </div>
              <div className="row">
                <div className="col-6 card_holder">
                  <p>CARD HOLDER</p>
                  <h5>
                    <InputText
                      placeholder="Enter Your Name"
                      value={this.state.name_on_card}
                      onChange={(e) =>
                        this.setState({ name_on_card: e.target.value })
                      }
                    />
                  </h5>
                </div>
                <div className="col-6 card_expiry">
                  <p>Expiry Date</p>
                  <h5>
                    <InputMask
                      class="form-control"
                      mask="99/99"
                      placeholder="MM/YY"
                      value={this.state.card_expiry_date}
                      slotChar="MM/YY"
                      onChange={(e) =>
                        this.setState({ card_expiry_date: e.value })
                      }
                    ></InputMask>
                  </h5>
                </div>
              </div>
            </div>
            <Header />
            <div className="d-flex justify-content-center">
              <button
                type="button"
                class="btn custom-btn-primary"
                onClick={() => {
                  this.updatecard();
                }}
              >
                Update Your Card
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Updatecard;
