import React, {Component} from 'react'
import Header from './Header'
import queryString from 'query-string';
import payment_failed from '../image/payment_failed.jpg'
class FaildPayment extends Component{
	constructor(props) {
	  super(props);
	
	  this.state = {};
	}
	continue(){
		console.warn(this.props.history.push('/'))
	}
	render(){
		let url = this.props.location.search;
  let params = queryString.parse(url);
  console.log(params);
		return(
			<div>
				<Header />
				<div className="container py-5">
					<div className="faild_payment">
						<img src={payment_failed} className="img-fluid" alt="payment_failed" />
					</div>
				</div>
			</div>
		)
	}
}
export default FaildPayment;