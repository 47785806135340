import React, { Component } from "react";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBell,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

class Guest_Support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotificationModal: false,
    };
  }

  handleNotificationModal = () => {
    this.setState((prevState) => ({
      showNotificationModal: !prevState.showNotificationModal,
    }));
  };

  handleResize = () => {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  componentDidMount() {
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }

  componentWillUnmount() {
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }

  render() {
    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Header />
            <div className="container my-4">
              <div className="row">
                <div className="col-12">
                  <div className="blogs_side">
                    <div className="blogs_sec p-3">
                      <h3>Guest Support</h3>

                      <h6>How do I contact my airline carrier directly?</h6>
                      <p>
                        To contact your airline carrier directly you can call
                        our customer care team and get the phone number of your
                        airline carrier.
                      </p>
                      <br />
                      <h6>
                        How can I modify or cancel my flight within 24 hours of
                        booking?
                      </h6>
                      <p>
                        You need to call our customer support team to modify or
                        cancel your flight within 24 hours. There is no fees for
                        modification or cancellation within 24 hours of booking
                        the flight.
                      </p>
                      <br />
                      <h6>
                        What if I need to modify or cancel my flight outside 24
                        hours of booking?
                      </h6>
                      <p>
                        You need to call our customer support team to modify or
                        cancel your flight outside 24 hours of booking. There
                        will be a charge for modification or cancellation
                        outside 24 hours of booking the flight as per the
                        airline terms.
                      </p>
                      <br />
                      <h6>How do I choose my seats?</h6>
                      <p>
                        Seats can be selected once the reservation is confirmed,
                        you can visit the airlines website and select your
                        seats. You can also call our customer support team if
                        any assistance required.
                      </p>
                      <br />
                      <h6>How can I print my boarding passes?</h6>
                      <p>
                        You can check-in online 24 hours prior to your flight by
                        visiting the airlines website and also print your
                        boarding passes from there. Boarding passes can also be
                        printed through the kiosk machines at the airport.
                      </p>
                      <br />
                      <h6>What about baggage fees?</h6>
                      <p>
                        Baggage fees will be collected by the airlines at the
                        airport. Baggage fees vary depending upon the airlines
                        whether it’s a scheduled airline or a low cost carrier.
                        For exact prices call our customer support team.
                      </p>
                      <br />
                      <h6>What is a code share flight?</h6>
                      <p>
                        A code share flight is a flight that is operated by one
                        and marketed by another carrier. To provide customers
                        with a wider choice of destinations some airlines come
                        together with agreements to sell seats on each others
                        flights.
                      </p>
                      <br />
                      <h6>Who needs a passport?</h6>
                      <p>
                        Anyone taking an international flight i.e flying outside
                        of united states should carry there passport with them.
                        No one is allowed to fly without a passport in an
                        international flight.
                      </p>
                      <br />
                      <h6>
                        How do I make arrangements for special needs assistance?
                      </h6>
                      <p>
                        You can call the airlines directly for any kind of
                        special needs assistance. You can also call our customer
                        support team for the same.
                      </p>
                      <br />
                      <h6>What is a schedule change?</h6>
                      <p>
                        If your flights gets changed prior to the date of
                        departure it is called a schedule change. Every airlines
                        frequently make changes to the flights they operate due
                        to serviceable needs. A schedule change can be a minute
                        delay in your flights or it may also lead to a
                        completely cancelled flight. Some examples of flight
                        changes are a flight number change, routing changes
                        (mostly the stopover airport of a one stop flight
                        changes), time of departure change, date changes and/or
                        cancellations. This is inconvenient, but unfortunately
                        is the right of the airline you’re traveling.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            {/* Bell popup modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showNotificationModal}
              onHide={this.handleNotificationModal}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully added in notification list
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            <div className="deals_top_heading_mobile">
              <Link to="/help">
                <div className="backarrow">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </Link>
              <div className="journy_atacode">
                <h4>Guest Support</h4>
              </div>
              <div
                className="notification"
                onClick={this.handleNotificationModal}
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
            </div>

            <div className="blogs_sec">
              <h3>Guest Support</h3>

              <h6>How do I contact my airline carrier directly?</h6>
              <p>
                To contact your airline carrier directly you can call our
                customer care team and get the phone number of your airline
                carrier.
              </p>
              <br />
              <h6>
                How can I modify or cancel my flight within 24 hours of booking?
              </h6>
              <p>
                You need to call our customer support team to modify or cancel
                your flight within 24 hours. There is no fees for modification
                or cancellation within 24 hours of booking the flight.
              </p>
              <br />
              <h6>
                What if I need to modify or cancel my flight outside 24 hours of
                booking?
              </h6>
              <p>
                You need to call our customer support team to modify or cancel
                your flight outside 24 hours of booking. There will be a charge
                for modification or cancellation outside 24 hours of booking the
                flight as per the airline terms.
              </p>
              <br />
              <h6>How do I choose my seats?</h6>
              <p>
                Seats can be selected once the reservation is confirmed, you can
                visit the airlines website and select your seats. You can also
                call our customer support team if any assistance required.
              </p>
              <br />
              <h6>How can I print my boarding passes?</h6>
              <p>
                You can check-in online 24 hours prior to your flight by
                visiting the airlines website and also print your boarding
                passes from there. Boarding passes can also be printed through
                the kiosk machines at the airport.
              </p>
              <br />
              <h6>What about baggage fees?</h6>
              <p>
                Baggage fees will be collected by the airlines at the airport.
                Baggage fees vary depending upon the airlines whether it’s a
                scheduled airline or a low cost carrier. For exact prices call
                our customer support team.
              </p>
              <br />
              <h6>What is a code share flight?</h6>
              <p>
                A code share flight is a flight that is operated by one and
                marketed by another carrier. To provide customers with a wider
                choice of destinations some airlines come together with
                agreements to sell seats on each others flights.
              </p>
              <br />
              <h6>Who needs a passport?</h6>
              <p>
                Anyone taking an international flight i.e flying outside of
                united states should carry there passport with them. No one is
                allowed to fly without a passport in an international flight.
              </p>
              <br />
              <h6>How do I make arrangements for special needs assistance?</h6>
              <p>
                You can call the airlines directly for any kind of special needs
                assistance. You can also call our customer support team for the
                same.
              </p>
              <br />
              <h6>What is a schedule change?</h6>
              <p>
                If your flights gets changed prior to the date of departure it
                is called a schedule change. Every airlines frequently make
                changes to the flights they operate due to serviceable needs. A
                schedule change can be a minute delay in your flights or it may
                also lead to a completely cancelled flight. Some examples of
                flight changes are a flight number change, routing changes
                (mostly the stopover airport of a one stop flight changes), time
                of departure change, date changes and/or cancellations. This is
                inconvenient, but unfortunately is the right of the airline
                you’re traveling.
              </p>
            </div>
            <Header />
          </div>
        )}
      </div>
    );
  }
}

export default Guest_Support;
