import React, {Component} from 'react'
import Header from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faBell, faExchangeAlt, faPlaneDeparture, faPlaneArrival, faCalendarAlt, faUserAlt, faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import {Button} from 'primereact/button'
import {Link} from 'react-router-dom'
class Filters extends Component{
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	t_class: [
		  		{name:'ECONOMY'},
		  		{name:'PREMIUM_ECONOMY'},
		  		{name:'BUSINESS'},
		  		{name:'FIRST'}
	  		],
	  	t_class_selected:'ECONOMY',
	  	child:0,
	  	adult:1,
	  	infants:0,
		};
	}
	back(){
		console.warn(this.props.history.push(sessionStorage.getItem('search_from')))
	}
	render(){
		return(
			<div className="container-fluid">
				<div className="deals_top_heading_mobile">
					<div className="backarrow" onClick={()=> {this.back()}}>
			     		<FontAwesomeIcon icon={faArrowLeft} />
			     	</div>			     	
			     	<div className="journy_atacode">
			     		<h4>Class & Traveler</h4>
			     	</div>
			     	<div className="notification">
			     		
			     	</div>
			    </div>
			    <div className="deals_box_section">
			    	<div className="travele_class">
				    	{
	        				this.state.t_class.map((item, index)=>
	        					<div key={index}>
	        					{this.state.t_class_selected === item.name ?
	        					<Button label={item.name} className="p-button-raised p-button-rounded mr-2 mb-3" />
	        					:
	        					<Button label={item.name} className="p-button-raised p-button-rounded p-button-secondary mr-2 mb-3" onClick={() => this.setState({t_class_selected:item.name})} />
	        					}
	        					</div>
	        				)
	        			}
        			</div>
        			<div className="traveler_count">
					    <div className="d-flex align-items-center justify-content-between  mb-3">
						    <div className="p-2">Adult</div>
						    <div className="p-2">
						    	<Button label="-" className="p-button-raised" onClick={()=> this.setState({adult: this.state.adult - 1})} disabled={(this.state.adult === '0'? 'true':'')} />
			        			<input type="text" value={this.state.adult} maxlength="2" className="idval" />
			        			<Button label="+" className="p-button-raised" onClick={()=> this.setState({adult: this.state.adult + 1})} />
						    </div>
						</div>
						<div className="d-flex align-items-center justify-content-between  mb-3">
						    <div className="p-2">Child</div>
						    <div className="p-2">
						    	<Button label="-" className="p-button-raised" onClick={()=> this.setState({child: this.state.child - 1})} disabled={(this.state.adult === '0'? 'true':'')} />
			        			<input type="text" value={this.state.child} maxlength="2" className="idval" />
			        			<Button label="+" className="p-button-raised" onClick={()=> this.setState({child: this.state.child + 1})} />
						    </div>
						</div>
						<div className="d-flex align-items-center justify-content-between  mb-3">
						    <div className="p-2">Infants</div>
						    <div className="p-2">
						    	<Button label="-" className="p-button-raised" onClick={()=> this.setState({infants: this.state.infants - 1})} disabled={(this.state.adult === '0'? 'true':'')} />
			        			<input type="text" value={this.state.infants} maxlength="2" className="idval" />
			        			<Button label="+" className="p-button-raised" onClick={()=> this.setState({infants: this.state.infants + 1})} />
						    </div>
						</div>
					</div>
				</div>
				<div className="footer_btn" onClick={()=> {this.back()}}>
					<p>Submit</p>
				</div>
			</div>
		)
	}
}
export default Filters;