import React, { Component } from "react";
import Header from "./Header";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import logo from "../image/NewUI/oguleNewLogo.png";
import { Link } from "react-router-dom";

class Addcard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      card_name: "Visa",
    };
  }
  addcard() {
    var login_data = JSON.parse(sessionStorage.getItem("login"));
    fetch("https://api.ogule.com/api/management/add-card", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: login_data.user._id,
        card_name: this.state.card_name,
        card_no: this.state.card_no,
        card_expiry_date: this.state.card_expiry_date,
        card_cvv: this.state.card_cvv,
        name_on_card: this.state.name_on_card,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          console.warn(this.props.history.push("usercards"));
        } else {
          alert("Username or password is incorrect");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    return (
      <div className="px-responsive py-3">
        {window.innerWidth > 768 ? (
          <div className="profile_top_sec mb-3">
            <Link to="/dashboard" className="btn btn-sm btn-light border mb-2">
              Back
            </Link>
            <div className="cr_card">
              <div className="row">
                <div className="col-6 card_type">
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="card_type"
                      onChange={(event) =>
                        this.setState({ card_name: event.target.value })
                      }
                    >
                      <option value="Visa">Visa</option>
                      <option value="Master Card">Master Card</option>
                      <option value="Ruppey">Ruppey</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-6 card_cvv">
                  <h5>
                    <InputMask
                      class="form-control"
                      mask="999"
                      placeholder="CVV"
                      value={this.state.card_cvv}
                      slotChar="CVV"
                      onChange={(e) => this.setState({ card_cvv: e.value })}
                    ></InputMask>
                  </h5>
                </div>
              </div>
              <div className="card_no">
                <h5>
                  <InputMask
                    class="form-control"
                    mask="9999-9999-9999-9999"
                    placeholder="XXXX-XXXX-XXXX-XXXX"
                    slotChar="XXXX/XXXX/XXXX/XXXX"
                    value={this.state.card_no}
                    onChange={(event) =>
                      this.setState({ card_no: event.target.value })
                    }
                  ></InputMask>
                </h5>
              </div>
              <div className="row">
                <div className="col-6 card_holder">
                  <p>CARD HOLDER</p>
                  <h5>
                    <InputText
                      placeholder="Enter Your Name"
                      value={this.state.name_on_card}
                      onChange={(e) =>
                        this.setState({ name_on_card: e.target.value })
                      }
                    />
                  </h5>
                </div>
                <div className="col-6 card_expiry">
                  <p>Expiry Date</p>
                  <h5>
                    <InputMask
                      class="form-control"
                      mask="99/99"
                      placeholder="MM/YY"
                      value={this.state.card_expiry_date}
                      slotChar="MM/YY"
                      onChange={(e) =>
                        this.setState({ card_expiry_date: e.value })
                      }
                    ></InputMask>
                  </h5>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                class="btn custom-btn-primary"
                onClick={() => {
                  this.addcard();
                }}
              >
                Add Your Card
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="profile_top_sec shadow mt-0"
              style={{
                borderBottomLeftRadius: "50px",
                borderBottomRightRadius: "50px",
                height: "110px",
                background: "linear-gradient(#8CAFF8, #E3EDFF)",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", top: "1rem", left: "1rem" }}>
                <Link to="/dashboard">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="#0D2A7D"
                    class="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                    />
                  </svg>
                </Link>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-0 h-100">
                <img
                  className="mb-0"
                  style={{
                    filter: "drop-shadow(0 0 0.4px #3d3d3d)",
                    width: "140px",
                  }}
                  src={logo}
                />
              </div>
            </div>
            <div className="container-fluid">
              <div
                className="dashboard py-4 bg-transparent shadow-none"
                style={{ top: "110px" }}
              >
                <div className="row">
                  <div className="col-7">
                    <label className="fw-600 text-blue">
                      Account Holder Name
                    </label>

                    <input
                      className="form-control mb-4 rounded-10p"
                      placeholder="Enter Your Name"
                      value={this.state.name_on_card}
                      onChange={(e) =>
                        this.setState({ name_on_card: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-5">
                    <label className="fw-600 text-blue">Card Type</label>
                    <div class="form-group">
                      <select
                        class="form-control rounded-10p"
                        id="card_type"
                        onChange={(event) =>
                          this.setState({ card_name: event.target.value })
                        }
                      >
                        <option value="Visa">Visa</option>
                        <option value="Master Card">Master Card</option>
                        <option value="Ruppey">Ruppey</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="fw-600 text-blue">Card Number</label>
                    <input
                      className="form-control mb-4 rounded-10p"
                      mask="9999-9999-9999-9999"
                      placeholder="XXXX-XXXX-XXXX-XXXX"
                      slotChar="XXXX/XXXX/XXXX/XXXX"
                      value={this.state.card_no}
                      onChange={(event) =>
                        this.setState({ card_no: event.target.value })
                      }
                    />
                  </div>
                  <div className="col-6">
                    <label className="fw-600 text-blue">CVV</label>
                    <input
                      className="form-control mb-4 rounded-10p"
                      mask="999"
                      placeholder="CVV"
                      value={this.state.card_cvv}
                      slotChar="CVV"
                      onChange={(e) => this.setState({ card_cvv: e.value })}
                    />
                  </div>
                  <div className="col-6">
                    <label className="fw-600 text-blue">Expiry Date</label>
                    <input
                      className="form-control mb-4 rounded-10p"
                      mask="99/99"
                      placeholder="MM/YY"
                      value={this.state.card_expiry_date}
                      slotChar="MM/YY"
                      onChange={(e) =>
                        this.setState({ card_expiry_date: e.value })
                      }
                    />
                  </div>
                  <div className="col-12 d-flex justify-content-center py-4">
                    <button
                      type="button"
                      class="btn custom-btn-primary"
                      onClick={() => {
                        this.addcard();
                      }}
                    >
                      Save Card
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Header />
          </div>
        )}
      </div>
    );
  }
}
export default Addcard;
