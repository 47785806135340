import React, { Component } from "react";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBell, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotificationModal: false,
    };
  }

  handleNotificationModal = () => {
    this.setState((prevState) => ({
      showNotificationModal: !prevState.showNotificationModal,
    }));
  };

  handleResize = () => {
    // if (window.innerWidth < 768 || window.innerWidth >= 768) {
    //   window.location.reload();
    // }
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  componentDidMount() {
    // window.addEventListener("resize", this.handleResize);
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }

  componentWillUnmount() {
    // window.removeEventListener("resize", this.handleResize);
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }

  render() {
    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Header />
            <div className="container my-4">
              <div className="row">
                <div className="col-12">
                  <div className="blogs_side">
                    <div className="blogs_sec p-3">
                      <h3>About Us</h3>
                      <h6 className="font-weight-bold">OUR AIM</h6>
                      <p>
                        To give trouvaille travel experience to every customers
                        and target to receive numinous response.{" "}
                      </p>
                      <br />
                      <h6 className="font-weight-bold">OUR VISION</h6>
                      <p>
                        Travelling can be complex. Deciding and budget planning
                        for the next perfect destination can be difficult at
                        times and perhaps for thesame reason OGULE aims at
                        providing holistic guidance to customers travel
                        itineraries.
                      </p>
                      <br />
                      <h6 className="font-weight-bold">OUR STORY</h6>
                      <p>
                        Planning a vacation, going on a holiday is best memories
                        for families together. Starting with where to GO where
                        to stay, what to eat and most important of all how to
                        TRAVEL. This is where OGULE comes as ansolution to all,
                        with one targeted aim to provide holistic travel
                        itinerary.We started with three international travel
                        representatives and now we are a team of 50± employees
                        who are dedicated to work together and towards one goal
                        of providing better customer experience.
                      </p>
                      <p>
                        <b>Company Address-</b> Three Sugar Creek Center Blvd
                        Sugar Land, TX 77479, USA
                      </p>
                      <p>
                        <b>USA Email:-</b> sales@ogule.com
                      </p>
                      <p>
                        <b>Ph:-</b>+1 888-315-1615
                      </p>
                      {/* +1 302-585-3548,  */}
                      <br />
                      <h6 className="font-weigh	t-bold">MAN BEHIND OGULE</h6>
                      <p>
                        During his childhood he was aspired with travelling as
                        everyone us are at that age and for obvious reasons…I
                        mean who won’t like going new places, meeting new people
                        and utilizing time away from school as most of us would
                        like to think& believe back then..butto notice
                        challenges starting from planning where to go, budget
                        planning and its importance, meeting deadlines and
                        having a problem solving outlook for the same and
                        holding a firm belief that it can be changed for better
                        and being INSPIRED towards making air bus travel an
                        option for almost everyone is not very common. With back
                        benching schooling and with pragmatic questioning
                        towards conventional teaching techniques OGULE was
                        making.
                      </p>
                      <br />
                      <p>
                        Our entire operations is handled by BEST IN FLIGHTS
                        which is registered in INDIA. We are happy that we got
                        this opportunity at the time of need. We now manage a
                        50+ team of travel representatives operating from INDIA
                        and also aim at providing every alternate information
                        our customers need. We are happy and more so blessed to
                        have repeated customers like YOU. The trust and
                        confidence we’ve been getting from the verybeginningtill
                        now is motivating enough to keep the work moving
                        forward.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            {/* Bell popup modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showNotificationModal}
              onHide={this.handleNotificationModal}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully added in notification list
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            <div className="deals_top_heading_mobile">
              <Link to="/help">
                <div className="backarrow">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </Link>
              <div className="journy_atacode">
                <h4>About Us</h4>
              </div>
              <div
                className="notification"
                onClick={this.handleNotificationModal}
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
            </div>
            <div className="blogs_sec">
              <h3>About Us</h3>
              <h6 className="font-weight-bold">OUR AIM</h6>
              <p>
                To give trouvaille travel experience to every customers and
                target to receive numinous response.{" "}
              </p>
              <br />
              <h6 className="font-weight-bold">OUR VISION</h6>
              <p>
                Travelling can be complex. Deciding and budget planning for the
                next perfect destination can be difficult at times and perhaps
                for thesame reason OGULE aims at providing holistic guidance to
                customers travel itineraries.
              </p>
              <br />
              <h6 className="font-weight-bold">OUR STORY</h6>
              <p>
                Planning a vacation, going on a holiday is best memories for
                families together. Starting with where to GO where to stay, what
                to eat and most important of all how to TRAVEL. This is where
                OGULE comes as ansolution to all, with one targeted aim to
                provide holistic travel itinerary.We started with three
                international travel representatives and now we are a team of
                50± employees who are dedicated to work together and towards one
                goal of providing better customer experience.
              </p>
              <p>
                <b>Company Address-</b>Three Sugar Creek Center Blvd Sugar Land,
                TX 77479, USA{" "}
              </p>
              <p>
                <b>USA Email:-</b> sales@ogule.com
              </p>
              <p>
                <b>Ph:-</b>+1 888-315-1615.
              </p>
              {/* +1 302-585-3548,  */}
              <br />
              <h6 className="font-weight-bold">MAN BEHIND OGULE</h6>
              <p>
                During his childhood he was aspired with travelling as everyone
                us are at that age and for obvious reasons…I mean who won’t like
                going new places, meeting new people and utilizing time away
                from school as most of us would like to think& believe back
                then..butto notice challenges starting from planning where to
                go, budget planning and its importance, meeting deadlines and
                having a problem solving outlook for the same and holding a firm
                belief that it can be changed for better and being INSPIRED
                towards making air bus travel an option for almost everyone is
                not very common. With back benching schooling and with pragmatic
                questioning towards conventional teaching techniques OGULE was
                making.
              </p>
              <br />
              <p>
                Our entire operations is handled by BEST IN FLIGHTS which is
                registered in INDIA. We are happy that we got this opportunity
                at the time of need. We now manage a 50+ team of travel
                representatives operating from INDIA and also aim at providing
                every alternate information our customers need. We are happy and
                more so blessed to have repeated customers like YOU. The trust
                and confidence we’ve been getting from the verybeginningtill now
                is motivating enough to keep the work moving forward.
              </p>
            </div>

            <Header />
          </div>
        )}
      </div>
    );
  }
}

export default About;
