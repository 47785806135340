import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Accordion, Card, Modal } from "react-bootstrap";
import Header from "./Header";
import logo from "../image/logo.png";
import from_to from "../image/icon/from_to.jpg";
import air_india from "../image/flights_icon/air_india.png";
import Skeleton from "@material-ui/lab/Skeleton";
import { Growl } from "primereact/growl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faBell,
  faExchangeAlt,
  faPlaneDeparture,
  faPlaneArrival,
  faCalendarAlt,
  faUserAlt,
  faSearch,
  faSlidersH,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { SelectButton } from "primereact/selectbutton";
import Logo from "../image/logo.png";
import Blog from "../image/blogs/blogs_1.jpeg";
import { ButtonToolbar, Dropdown } from "react-bootstrap";
import { Button } from "primereact/button";
import Tooltip from "react-tooltip-lite";
import Slider from "@material-ui/core/Slider";

// if (sessionStorage.getItem("wayOfFlight") === "round_trip") {
//   sessionStorage.setItem("returnDate", sessionStorage.getItem("returnDate"));
// } else {
//   sessionStorage.setItem("returnDate", "");
// }
const trip = [
  { label: "Round Trip", value: "round_trip" },
  { label: "One Way", value: "one_way" },
];

class Flights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setShow: false,
      iatacode_from: sessionStorage.getItem("originLocationCode") || "BHM",
      airport_name_from:
        sessionStorage.getItem("airport_name_from") || "SHUTTLESWORTH INTL",
      country_from: sessionStorage.getItem("country_from") || "US",
      iatacode_to: sessionStorage.getItem("destinationLocationCode") || "DEN",
      airport_name_to:
        sessionStorage.getItem("airport_name_to") || "DENVER INTERNATIONAL",
      country_to: sessionStorage.getItem("country_to") || "Us",
      name: "",
      password: "",
      wayOfFlight: sessionStorage.getItem("wayOfFlight"),
      showNotificationModal: false,
      showSubscribeModal: false,
      isResponse: false,
      progress: 10,
      departuredate: sessionStorage.getItem("departureDate"),
      returndate: sessionStorage.getItem("returnDate"),
      updatingFlights: false,
      t_class: [
        { name: "BASIC ECONOMY" },
        { name: "ECONOMY" },
        { name: "PREMIUM_ECONOMY" },
        { name: "BUSINESS" },
        { name: "FIRST" },
      ],

      adult: sessionStorage.getItem("adults")
        ? parseInt(sessionStorage.getItem("adults"))
        : 1,
      child: sessionStorage.getItem("children")
        ? parseInt(sessionStorage.getItem("children"))
        : 0,
      infants: sessionStorage.getItem("infants")
        ? parseInt(sessionStorage.getItem("infants"))
        : 0,
      totalCounts:
        sessionStorage.getItem("adults") &&
        sessionStorage.getItem("children") &&
        sessionStorage.getItem("infants")
          ? parseInt(sessionStorage.getItem("adults")) +
            parseInt(sessionStorage.getItem("children")) +
            parseInt(sessionStorage.getItem("infants"))
          : 0,
      t_class_selected: sessionStorage.getItem("travelClass") || "ECONOMY",
      airlinesArray: JSON.parse(sessionStorage.getItem("airlinesArray")) || "",
      selected_airline: sessionStorage.getItem("selected_airline"),
      directChecked: true,
      stop_1checked: true,
      stop_2checked: true,
      selectAllFlights: true,
      selectedAirlines: [],
      maxPrice: 0,
      selectedMaxPrice: 0,
      deptValue: [0, 0],
      departureData: [],
      arrivValue: [0, 0],
      arrivalData: [],
    };

    this.interval = setInterval(() => {
      this.setState((prevState) => ({
        progress: prevState.progress < 90 ? prevState.progress + 10 : 90,
        updatingFlights: true,
      }));
    }, 1000);

    fetch("https://test.api.amadeus.com/v1/security/oauth2/token", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
      }),
      body: "grant_type=client_credentials&client_id=lFD9B6KFg1j3kMKGLzFgadbAKFEzOwVw&client_secret=XmvZgKrjBJAiAMfE", // <-- Post parameters
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.access_token) {
          this.setState({ apitoken: res.access_token });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    fetch("https://api.ogule.com/api/frontend/findflight", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        originLocationCode: sessionStorage.getItem("originLocationCode"),
        destinationLocationCode: sessionStorage.getItem(
          "destinationLocationCode"
        ),
        departureDate: sessionStorage.getItem("departureDate"),
        adults: sessionStorage.getItem("adults"),
        returnDate: sessionStorage.getItem("returnDate"),
        children: sessionStorage.getItem("children"),
        infants: sessionStorage.getItem("infants"),
        travelclassName:
          sessionStorage.getItem("travelClass") === "BASIC ECONOMY"
            ? "ECONOMY"
            : sessionStorage.getItem("travelClass"),
        currencyCode: sessionStorage.getItem("currencyCode"),
        wayOfFlight: sessionStorage.getItem("wayOfFlight"),
        isDirect: sessionStorage.getItem("isDirect"),
        selected_airline: sessionStorage.getItem("selected_airline"),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          clearInterval(this.interval);
          const flightsWithSelection = res.data.map((flight) => ({
            ...flight,
            selected: true,
          }));
          const departure = res.data
            .map((flight) => flight.itineraries[0].segments[0].departure.at)
            .sort((a, b) => new Date(a) - new Date(b));
          const arrival = res.data
            .map((flight) => flight.itineraries[1].segments[0].departure.at)
            .sort((a, b) => new Date(a) - new Date(b));
          const maxPrice = res?.data[res?.data?.length - 1].price?.total;
          this.setState({
            isResponse: true,
            flightslist: flightsWithSelection,
            dictionaries: res.dictionaries,
            updatingFlights: false,
            filteredArray: res.data,
            customeCarriers: res.customeCarriers,
            selectedAirlines: res.customeCarriers.map((d) => d.iataCode),
            maxPrice: maxPrice,
            departureData: departure,
            arrivalData: arrival,
            selectedMaxPrice: maxPrice,
            deptValue: [
              this.parseTimeValue(departure[0]),
              this.parseTimeValue(departure[departure.length - 1]),
            ],
            arrivValue: [
              this.parseTimeValue(arrival[0]),
              this.parseTimeValue(arrival[arrival.length - 1]),
            ],
          });
        } else {
          alert("Username or password is incorrect");
        }
      })
      .catch((error) => {
        clearInterval(this.interval);
        this.setState({
          isResponse: true,
          updatingFlights: false,
        });
        console.error(error);
      });
  }
  //
  componentDidUpdate() {
    const flightslist = this.state.flightslist;

    if (flightslist) {
      const allAirlines = flightslist.map(
        (flight) => flight.validatingAirlineCodes[0]
      );
      const uniqueAirlines = [...new Set(allAirlines)]; // Get unique airline codes

      // Set default selected airlines
      this.setState(
        {
          selectedAirlines: uniqueAirlines,
          selectAllFlights: true,
        },
        () => {
          this.handleFilterAirlines();
        }
      );
    }
  }

  // STOP FILTER
  handleCheckBoxChange = (name) => {
    this.setState(
      (prevState) => ({
        [name]: !prevState[name],
      }),
      () => {
        this.handleFilterFlights();
      }
    );
  };

  handleFilterFlights = () => {
    const {
      directChecked,
      stop_1checked,
      stop_2checked,
      flightslist,
      selectedAirlines,
      deptValue,
      arrivValue,
      selectedMaxPrice
    } = this.state;

    if (selectedAirlines.length === 0) {
      this.setState({ filteredArray: [] });
      return;
    }
    let filteredArray = flightslist.filter((flight) => {
      if (sessionStorage.getItem("wayOfFlight") === "round_trip") {
        const departureTime = this.parseTimeValue(
          flight.itineraries[0].segments[0].departure.at
        );
        const arrivalTime = this.parseTimeValue(
          flight.itineraries[1].segments[0].departure.at
        );
        const selectedMinTime = deptValue[0];
        const selectedMaxTime = deptValue[1];
        const arrselectedMinTime = arrivValue[0];
        const arrselectedMaxTime = arrivValue[1];
        return (
          departureTime >= selectedMinTime &&
          departureTime <= selectedMaxTime &&
          arrivalTime >= arrselectedMinTime &&
          arrivalTime <= arrselectedMaxTime
        );
      } else {
        const departureTime = this.parseTimeValue(
          flight.itineraries[0].segments[0].departure.at
        );
        const selectedMinTime = deptValue[0];
        const selectedMaxTime = deptValue[1];
        return (
          departureTime >= selectedMinTime && departureTime <= selectedMaxTime
        );
      }
    });
    filteredArray = filteredArray.filter((flight) => {
      return flight.price.total < selectedMaxPrice 
    })
    filteredArray = filteredArray.filter((flight) => {
      if (!selectedAirlines.includes(flight.validatingAirlineCodes[0])) {
        return false;
      } else if (directChecked && stop_1checked && stop_2checked) {
        return true;
      } else if (directChecked && stop_1checked && !stop_2checked) {
        return (
          flight.itineraries[0].segments.length < 2 &&
          flight.itineraries[1].segments.length < 2 
        );
      } else if (directChecked && !stop_1checked && !stop_2checked) {
        return (
          flight.itineraries[0].segments.length === 1 &&
          flight.itineraries[1].segments.length === 1
        );
      } else if (!directChecked && !stop_1checked && !stop_2checked) {
        return false;
      } else if (directChecked && !stop_1checked && stop_2checked) {
        return (
          flight.itineraries[0].segments.length !== 2 &&
          flight.itineraries[1].segments.length !== 2
        );
      } else if (!directChecked && !stop_1checked && stop_2checked) {
        return (
          flight.itineraries[0].segments.length > 2 &&
          flight.itineraries[1].segments.length > 2
        );
      } else if (!directChecked && stop_1checked && stop_2checked) {
        return (
          flight.itineraries[0].segments.length > 1 &&
          flight.itineraries[1].segments.length > 1
        );
      }
      return true;
    });

    this.setState({ filteredArray });
  };

  // AIRLINES FILTER
  handleSelectAll = () => {
    this.setState(
      {
        selectAllFlights: true,
        selectedAirlines: this.state.customeCarriers.map(
          (flight) => flight.iataCode
        ),
      },
      () => {
        this.handleFilterAirlines();
      }
    );
  };

  handleClearAll = () => {
    this.setState(
      {
        selectAllFlights: false,
        selectedAirlines: [],
      },
      () => {
        this.handleFilterFlights();
      }
    );
  };

  handleAirlineCheckboxChange = (airline) => {
    this.setState(
      (prevState) => {
        let updatedAirlines;
        if (prevState.selectedAirlines.includes(airline)) {
          updatedAirlines = prevState.selectedAirlines.filter(
            (selectedAirline) => selectedAirline !== airline
          );
        } else {
          updatedAirlines = [...prevState.selectedAirlines, airline];
        }
        return { selectedAirlines: updatedAirlines };
      },
      () => {
        this.handleFilterFlights();
      }
    );
  };

  // Update Filter Flights Based on Selected Airlines
  handleFilterAirlines = () => {
    const { flightslist, selectedAirlines } = this.state;

    let filteredArray = flightslist.filter((flight) => {
      // Check if the flight's airline is selected
      return selectedAirlines.includes(flight.validatingAirlineCodes[0]);
    });

    this.setState({ filteredArray });
  };

  // PRICE FILTER
  handleChangePrice = (event, newValue) => {
    this.setState({ selectedMaxPrice: newValue }, 
      () => {
      this.handleFilterFlights();
    });
  };

  parseTimeValue = (timeString) => {
    if (typeof timeString === "string" && timeString.includes("T")) {
      const [hours, minutes] = timeString
        .split("T")[1]
        .split(":")
        .slice(0, 2)
        .map(Number);
      return hours * 60 + minutes;
    }
    return 0;
  };

  formatTimeValue = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  handleChangeDeptValue = (event, newValue) => {
    this.setState({ deptValue: newValue }, () => {
      this.handleFilterFlights();
    });
  };

  handleChangeArrivValue = (event, newValue) => {
    this.setState({ arrivValue: newValue }, () => {
      this.handleFilterFlights();
    });
  };

  handleUpdate = () => {
    sessionStorage.setItem("departureDate", this.state.departuredate);
    sessionStorage.setItem("returnDate", this.state.returndate);
    sessionStorage.setItem("originLocationCode", this.state.iatacode_from);
    sessionStorage.setItem("destinationLocationCode", this.state.iatacode_to);
    sessionStorage.setItem("adults", this.state.adult);
    sessionStorage.setItem("children", this.state.child);
    sessionStorage.setItem("infants", this.state.infants);
    sessionStorage.setItem("travelClass", this.state.t_class_selected);
    sessionStorage.setItem("selected_airline", this.state.selected_airline);
    sessionStorage.setItem("wayOfFlight", this.state.wayOfFlight);
    this.setState({ updatingFlights: true });
    fetch("https://api.ogule.com/api/frontend/findflight", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        originLocationCode: this.state.iatacode_from,
        destinationLocationCode: this.state.iatacode_to,
        departureDate: this.state.departuredate,
        adults: this.state.adult,
        returnDate: this.state.returndate,
        children: this.state.child,
        infants: this.state.infants,
        travelclassName:
          this.state.t_class_selected === "BASIC ECONOMY"
            ? "ECONOMY"
            : this.state.t_class_selected,
        currencyCode: sessionStorage.getItem("currencyCode"),
        wayOfFlight: this.state.wayOfFlight,
        selected_airline: this.state.selected_airline,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          clearInterval(this.interval);
          this.setState({
            isResponse: true,
            flightslist: res.data,
            dictionaries: res.dictionaries,
            updatingFlights: false,
          });
        } else {
          alert("Username or password is incorrect");
        }
      })
      .catch((error) => {
        clearInterval(this.interval);
        this.setState({
          isResponse: true,
          updatingFlights: false,
        });
        console.error(error);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.adult !== this.state.adult ||
      prevState.child !== this.state.child ||
      prevState.infants !== this.state.infants
    ) {
      this.setState({
        totalCounts: this.state.adult + this.state.child + this.state.infants,
      });
    }
  }

  searchfrom(val) {
    this.setState({ iatacode_from: val });
    // if (
    //   this.state.fromdata &&
    //   this.state.fromdata.some((f) => f.iataCode === val)
    // ) {
    //   if (this.toInputRef.current) {
    //     this.toInputRef.current.focus();
    //   }
    // }
    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + this.state.apitoken,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          var data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === res.data[i].iataCode) {
              sessionStorage.setItem("iatacode_from", res.data[i].iataCode);
              sessionStorage.setItem("airport_name_from", res.data[i].name);
              sessionStorage.setItem(
                "country_from",
                res.data[i].address.countryCode
              );
              this.setState({
                iatacode_from: res.data[i].iataCode,
                airport_name_from: res.data[i].name,
                country_from: res.data[i].address.countryCode,
              });
            } else {
            }
          }
          console.warn(res);
          this.setState({
            fromdata: res.data,
          });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  searchto(val) {
    this.setState({ iatacode_to: val });
    // if (
    //   this.state.todata &&
    //   this.state.todata.some((f) => f.iataCode === val)
    // ) {
    //   if (this.datePickerRef.current) {
    //     this.datePickerRef.current.setOpen(true);
    //   }
    // }

    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + this.state.apitoken,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          var data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === res.data[i].iataCode) {
              sessionStorage.setItem("iatacode_to", res.data[i].iataCode);
              sessionStorage.setItem("airport_name_to", res.data[i].name);
              sessionStorage.setItem(
                "country_to",
                res.data[i].address.countryCode
              );
              this.setState({
                iatacode_to: res.data[i].iataCode,
                airport_name_to: res.data[i].name,
                country_to: res.data[i].address.countryCode,
              });
            } else {
            }
          }
          console.warn(res);
          this.setState({
            todata: res.data,
          });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handledeparturedate = (date) => {
    if (!date) {
      return null;
    }
    const formattedDate = format(date, "yyyy-MM-dd");
    sessionStorage.setItem("departureDate", format(date, "yyyy-MM-dd"));
    sessionStorage.setItem("mobile_departureDate_oneway", format(date, "MMM dd"));
    sessionStorage.setItem(
      "mobile_departureDate_oneway_day",
      format(date, "iii")
    );
    this.setState({
      departuredate: formattedDate,
    });
    // if (this.departureDatePickerRef.current) {
    //   this.departureDatePickerRef.current.setOpen(true);
    // }
    // this.handlereturndate(date);
    const returnDate = new Date(formattedDate);
    this.handlereturndate(returnDate.setDate(returnDate.getDate() + 1), "true");
  };

  handlereturndate = (date) => {
    if (!date) {
      return null;
    }
    const formattedDate = format(date, "yyyy-MM-dd");
    sessionStorage.setItem("returnDate", format(date, "yyyy-MM-dd"));
    sessionStorage.setItem("mobile_returnDate_oneway", format(date, "MMM dd"));
    sessionStorage.setItem("mobile_returnDate_oneway_day", format(date, "iii"));
    this.setState({
      returndate: formattedDate,
    });
  };

  submit(item) {
    sessionStorage.setItem("booking_details", JSON.stringify(item));
    sessionStorage.setItem("booking_login", "yes");
    this.props.history.push("checkoutdetails");
  }

  calculateDuration(segment, departureTime, arrivalTime, index) {
    const departure = new Date(departureTime);
    const arrival = new Date(arrivalTime);

    let difference = arrival - departure;

    const hours = Math.floor(difference / (1000 * 60 * 60));
    difference -= hours * (1000 * 60 * 60);
    const minutes = Math.floor(difference / (1000 * 60));

    return `${segment.arrival.iataCode} : ${hours} hours ${minutes} minutes`;
  }

  wishlist(item) {
    var login_data = JSON.parse(sessionStorage.getItem("login"));
    fetch("https://api.ogule.com/api/auth/whishlist/add", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + login_data.access_token,
      },
      body: JSON.stringify({
        originLocationCode: sessionStorage.getItem("originLocationCode"),
        destinationLocationCode: sessionStorage.getItem(
          "destinationLocationCode"
        ),
        departureDate: sessionStorage.getItem("departureDate"),
        adults: sessionStorage.getItem("adults"),
        returnDate: sessionStorage.getItem("returnDate"),
        children: sessionStorage.getItem("children"),
        infants: sessionStorage.getItem("infants"),
        travelClass: sessionStorage.getItem("travelClass"),
        currencyCode: sessionStorage.getItem("currencyCode"),
        wayOfFlight: sessionStorage.getItem("wayOfFlight"),
        whishlist_result: item,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          this.growl.show({
            severity: "success",
            summary: "Success",
            detail: "Add To Whishlist",
          });
        } else {
          alert("Username or password is incorrect");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  search_data(val) {
    sessionStorage.setItem("search_from", "flights");
    console.warn(this.props.history.push(val));
  }

  from_and_to_exchange() {
    sessionStorage.setItem("iatacode_from", this.state.iatacode_to);
    sessionStorage.setItem("airport_name_from", this.state.airport_name_to);
    sessionStorage.setItem("country_from", this.state.country_to);
    sessionStorage.setItem("iatacode_to", this.state.iatacode_from);
    sessionStorage.setItem("airport_name_to", this.state.airport_name_from);
    sessionStorage.setItem("country_to", this.state.country_from);
    this.setState({
      iatacode_from: this.state.iatacode_to,
      airport_name_from: this.state.airport_name_to,
      country_from: this.state.country_to,
      iatacode_to: this.state.iatacode_from,
      airport_name_to: this.state.airport_name_from,
      country_to: this.state.country_from,
    });
  }

  search_filters(e) {
    sessionStorage.setItem("wayOfFlight", e);
    this.setState({
      wayOfFlight: e,
    });
  }
  find_flights() {
    window.location.reload(false);
  }

  componentDidMount() {
    fetch("https://api.ogule.com/api/frontend/bloglist").then(
      (response) => {
        response.json().then((result) => {
          this.setState({ bloglist: result.bloglist });
        });
      }
    );
  }
  single_blog(item) {
    sessionStorage.setItem("single_blog", item);
    console.warn(this.props.history.push("single_blog"));
  }

  handleNotificationModal = () => {
    this.setState((prevState) => ({
      showNotificationModal: !prevState.showNotificationModal,
    }));
  };
  handleSubscribeModal = () => {
    this.setState((prevState) => ({
      showSubscribeModal: !prevState.showSubscribeModal,
    }));
  };

  handleClose = () => {
    this.setState({ setShow: false, setShow_from: false, setShow_to: false });
  };

  handleSelectAirline(value) {
    this.setState({ selected_airline: value });
  }

  render() {
    return (
      <div>
        {window.innerWidth > 768 ? (
          <div>
            <Header />

            {/* Subscribe Modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showSubscribeModal}
              onHide={() => this.handleSubscribeModal()}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully subscribed.
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            {/* Travellers Modal */}
            <Modal
              show={this.state.setShow}
              onHide={this.handleClose}
              className="login_popup"
            >
              <Modal.Header closeButton>
                <ButtonToolbar>
                  {this.state.t_class.map((item) => (
                    <div>
                      {this.state.t_class_selected === item.name ? (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded mr-1"
                        />
                      ) : (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded p-button-secondary mr-1"
                          onClick={() =>
                            this.setState({ t_class_selected: item.name })
                          }
                        />
                      )}
                    </div>
                  ))}
                </ButtonToolbar>
              </Modal.Header>
              <Modal.Body>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Adult (Above 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult - 1 });
                        if (this.state.adult - 1 < this.state.infants) {
                          this.setState({ infants: this.state.adult - 1 });
                        }
                      }}
                      disabled={this.state.adult === 0}
                    />
                    <input
                      type="text"
                      value={this.state.adult}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult + 1 });
                      }}
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Child (2 years to 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child - 1 })
                      }
                      disabled={this.state.child === 0}
                    />
                    <input
                      type="text"
                      value={this.state.child}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child + 1 })
                      }
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Infants (Below 2 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants - 1 })
                      }
                      disabled={this.state.infants === 0}
                    />
                    <input
                      type="text"
                      value={this.state.infants}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants + 1 })
                      }
                      disabled={this.state.infants === this.state.adult}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  label="Submit"
                  className="p-button-raised p-button-rounded mr-2"
                  onClick={() => this.setState({ setShow: false })}
                />
              </Modal.Footer>
            </Modal>

            <div
              className="px-5"
              style={{
                position: "-webkit-sticky",
                position: "sticky",
                top: 0,
                zIndex: 1000,
              }}
            >
              <div
                className="row desk_flights_search pb-3 pt-2"
                style={{ position: "relative" }}
              >
                <div
                  className="d-flex gap-1rem px-3 pt-2 pb-1"
                  style={{
                    position: "absolute",
                    top: "-2.75rem",
                    left: "0",
                    background: "#f8f8f8",
                  }}
                >
                  <div className="form-group mb-0">
                    <input
                      type="radio"
                      id="one_way"
                      name="wayOfFlight"
                      value="one_way"
                      checked={this.state.wayOfFlight === "one_way"}
                      onChange={(e) => this.search_filters(e.target.value)}
                    />
                    <label
                      className="ml-1"
                      htmlFor="one_way"
                      style={{ fontWeight: "600" }}
                    >
                      One Way
                    </label>
                  </div>

                  <div className="form-group mb-0">
                    <input
                      type="radio"
                      id="round_trip"
                      name="wayOfFlight"
                      value="round_trip"
                      checked={this.state.wayOfFlight === "round_trip"}
                      onChange={(e) => this.search_filters(e.target.value)}
                    />
                    <label
                      className="ml-1"
                      htmlFor="round_trip"
                      style={{ fontWeight: "600" }}
                    >
                      Round Trip
                    </label>
                  </div>
                </div>

                <div className="col desk_flights_from">
                  <div className="form-group">
                    <label htmlFor="from" className="fw-600">
                      From
                    </label>
                    <input
                      className="form-control text-primary rounded-10p mb-2"
                      type="text"
                      autocomplete="off"
                      value={this.state.iatacode_from}
                      id="from"
                      list="fromdata"
                      onChange={(e) => this.searchfrom(e.target.value)}
                    />
                    <p className="text-secondary mb-0">
                      {this.state.airport_name_from}&nbsp;
                      {this.state.country_from}
                    </p>
                  </div>
                  <div>
                    {this.state.fromdata ? (
                      <div>
                        <datalist id="fromdata">
                          {this.state.fromdata.map((item) => (
                            <option value={item.iataCode}>
                              {item.name},{item.address.cityName}{" "}
                              {item.address.countryName}
                            </option>
                          ))}
                        </datalist>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col desk_flights_to">
                  <div className="form-group">
                    <label htmlFor="to" className="fw-600">
                      To
                    </label>
                    <input
                      className="form-control text-primary rounded-10p mb-2"
                      type="text"
                      id="to"
                      value={this.state.iatacode_to}
                      list="todata"
                      onChange={(e) => this.searchto(e.target.value)}
                      // ref={this.toInputRef}
                    />
                    <p className="text-secondary mb-0">
                      {this.state.airport_name_to}&nbsp;
                      {this.state.country_to}
                    </p>
                  </div>
                  <div>
                    {this.state.todata ? (
                      <div>
                        <datalist id="todata">
                          {this.state.todata.map((item) => (
                            <option value={item.iataCode}>
                              {item.name},{item.address.cityName}{" "}
                              {item.address.countryName}
                            </option>
                          ))}
                        </datalist>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col">
                  <Link onClick={() => this.setState({ setShow: true })}>
                    <div className="form-group">
                      <label className="text-dark">Travellers</label>
                      <p className="text-primary form-control rounded-10p mb-0">
                        {parseInt(this.state.adult) +
                          parseInt(this.state.child) +
                          parseInt(this.state.infants)}
                        &nbsp; Traveller
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col">
                  <Link onClick={() => this.setState({ setShow: true })}>
                    <div className="form-group">
                      <label className="text-dark">Cabin Class</label>
                      <p className="text-primary form-control rounded-10p mb-0">
                        {this.state.t_class_selected}
                      </p>
                    </div>
                  </Link>
                </div>

                <div className="col desk_flights_airline">
                  <label>Airline</label>
                  <select
                    className="form-control text-primary rounded-10p mb-2"
                    value={this.state.selected_airline}
                    onChange={(e) => this.handleSelectAirline(e.target.value)}
                  >
                    <option value="">All</option>
                    {this.state.airlinesArray.map((item) => {
                      return (
                        <option key={item.iataCode} value={item.iataCode}>
                          {item.commonName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="d-flex align-items-center gap-1rem">
                        <div className="form-group mb-0">
                          <label>Departure</label>
                          <div class="input-group">
                            <DatePicker
                              selected={new Date(this.state.departuredate)}
                              dateFormat="d MMM, yy"
                              onChange={this.handledeparturedate}
                              minDate={new Date()}
                            />
                          </div>
                        </div>

                        {this.state.wayOfFlight === "round_trip" && (
                          <div className="form-group mb-0">
                            <label>Return</label>
                            <div class="input-group">
                              <DatePicker
                                selected={
                                  this.state.returndate
                                    ? new Date(this.state.returndate)
                                    : null
                                }
                                dateFormat="d MMM, yy"
                                onChange={this.handlereturndate}
                                minDate={
                                  this.state.departuredate
                                    ? new Date(this.state.departuredate)
                                    : new Date()
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex align-items-end justify-content-end h-100">
                        <button
                          type="button"
                          className="btn btn-primary btn-lg float-right"
                          onClick={this.handleUpdate}
                          style={{ marginBottom: "0.5rem" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-search mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                          </svg>
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-5 my-4">
              <Growl ref={(el) => (this.growl = el)}></Growl>
              <div className="row">
                <div className="col-lg-3 col-md-12">
                  {this.state.filteredArray && (
                    <div className="blogs_side_bar shadow-none d-flex flex-column gap-2rem">
                      <div className="blogs_side_bar_sec border-0 custom-card bg-white">
                        <div className="blogs_side_bar_sec_about_us p-3">
                          <h6 className="text-uppercase text-roboto fw-600">
                            Stop
                          </h6>
                          <div className="d-flex flex-column gap-4p">
                            <div className="d-flex align-items-end justify-content-between">
                              <div className="d-flex align-items-center pl-4">
                                <input
                                  className="form-check-input mb-1"
                                  type="checkbox"
                                  value="direct"
                                  id="direct"
                                  onChange={() => {
                                    this.handleCheckBoxChange("directChecked");
                                  }}
                                  checked={this.state.directChecked === true}
                                  disabled={
                                    !this.state.flightslist?.some(
                                      (d) =>
                                        d.itineraries[0].segments.length ===
                                          1 ||
                                        d.itineraries[1].segments.length === 1
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="direct"
                                >
                                  Direct
                                </label>
                              </div>
                              <label className="mb-0 text-dark">
                                ${" "}
                                {this.state.flightslist?.find(
                                  (item) =>
                                    item.itineraries[0].segments.length === 1 &&
                                    item.itineraries[1].segments.length === 1
                                )?.price?.total || 0}
                              </label>
                            </div>
                            <div className="d-flex align-items-end justify-content-between">
                              <div className="d-flex align-items-center pl-4">
                                <input
                                  className="form-check-input mb-1"
                                  type="checkbox"
                                  value="stop_1"
                                  id="stop_1"
                                  onChange={() =>
                                    this.handleCheckBoxChange("stop_1checked")
                                  }
                                  checked={this.state.stop_1checked === true}
                                  disabled={
                                    !this.state.flightslist?.some(
                                      (d) =>
                                        d.itineraries[0].segments.length ===
                                          2 ||
                                        d.itineraries[1].segments.length === 2
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="stop_1"
                                >
                                  1 Stop
                                </label>
                              </div>
                              <label className="mb-0 text-dark">
                                ${" "}
                                {this.state.flightslist?.find(
                                  (item) =>
                                    item.itineraries[0].segments.length > 1 ||
                                    item.itineraries[1].segments.length > 1
                                )?.price?.total || 0}
                              </label>
                            </div>
                            <div className="d-flex align-items-end justify-content-between">
                              <div className="d-flex align-items-center pl-4">
                                <input
                                  className="form-check-input mb-1"
                                  type="checkbox"
                                  value="stop_2"
                                  id="stop_2"
                                  onChange={() =>
                                    this.handleCheckBoxChange("stop_2checked")
                                  }
                                  checked={this.state.stop_2checked === true}
                                  disabled={
                                    !this.state.flightslist?.some(
                                      (d) =>
                                        d.itineraries[0].segments.length > 2 ||
                                        d.itineraries[1].segments.length > 2
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="stop_2"
                                >
                                  2 + Stop
                                </label>
                              </div>
                              <label className="mb-0 text-dark">
                                ${" "}
                                {this.state.flightslist?.find(
                                  (item) =>
                                    item.itineraries[0].segments.length === 2 ||
                                    item.itineraries[1].segments.length === 2
                                )?.price?.total || 0}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="blogs_side_bar_sec border-0 custom-card bg-white">
                        <div className="blogs_side_bar_sec_about_us p-3">
                          <h6 className="text-uppercase text-roboto fw-600">
                            Airlines
                          </h6>
                          <div className="d-flex flex-column gap-4p">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <span
                                  className={`nav-link px-2 pointer ${
                                    this.state.selectAllFlights ? "active" : ""
                                  }`}
                                  onClick={this.handleSelectAll}
                                >
                                  Select all
                                </span>
                              </li>
                              <li className="nav-item">
                                <span
                                  className={`nav-link px-2 pointer ${
                                    !this.state.selectAllFlights ? "active" : ""
                                  }`}
                                  onClick={this.handleClearAll}
                                >
                                  Clear all
                                </span>
                              </li>
                            </ul>

                            {this.state.flightslist
                              ?.filter((flight, index, self) => {
                                return (
                                  index ===
                                  self.findIndex(
                                    (t) =>
                                      t.validatingAirlineCodes[0] ===
                                      flight.validatingAirlineCodes[0]
                                  )
                                );
                              })
                              .map((flight, index) => {
                                const matchingCarrier =
                                  this.state.customeCarriers?.find(
                                    (carrier) =>
                                      carrier.iataCode ===
                                      flight.validatingAirlineCodes[0]
                                  );

                                return (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <div className="d-flex align-items-center pl-4">
                                      <input
                                        className="form-check-input mb-1"
                                        type="checkbox"
                                        id={flight.id}
                                        value={flight.validatingAirlineCodes[0]}
                                        onChange={() =>
                                          this.handleAirlineCheckboxChange(
                                            flight.validatingAirlineCodes[0]
                                          )
                                        }
                                        checked={this.state.selectedAirlines.includes(
                                          flight.validatingAirlineCodes[0]
                                        )}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`checkbox-${index}`}
                                      >
                                        {matchingCarrier?.businessName}
                                      </label>
                                    </div>
                                    <label className="mb-0 text-dark">
                                      {flight?.price?.currency === "EUR" && "€"}
                                      {flight?.price?.currency === "USD" && "$"}
                                      {flight?.price?.currency === "INR" && "₹"}
                                      &nbsp;
                                      {flight.price.total}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="blogs_side_bar_sec border-0 custom-card bg-white">
                        <div className="blogs_side_bar_sec_about_us p-3">
                          <h6 className="text-uppercase text-roboto fw-600">
                            Price
                          </h6>
                          <div className="d-flex flex-column gap-4p">
                            <div style={{ width: "100%" }}>
                              <div className="d-flex justify-content-between">
                                <span>
                                  {this.state.flightslist?.[0]?.price?.total}{" "}
                                  -&nbsp;
                                  {this.state.maxPrice}
                                </span>
                                <span>{this.state.selectedMaxPrice}</span>
                              </div>
                              <Slider
                                min={0}
                                max={this.state.maxPrice}
                                value={this.state.selectedMaxPrice}
                                onChange={this.handleChangePrice}
                                valueLabelDisplay="auto"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="blogs_side_bar_sec border-0 custom-card bg-white">
                        <div className="blogs_side_bar_sec_about_us p-3">
                          <h6 className="text-uppercase text-roboto fw-600">
                            Times
                          </h6>
                          <div className="d-flex flex-column gap-4p">
                            <div className="w-100">
                              <div className="d-flex flex-column gap-4p">
                                <span>
                                  Take-off from{" "}
                                  {sessionStorage.getItem("originLocationCode")}
                                </span>
                                <span>
                                  {this.state.departureData[0].substring(
                                    11,
                                    16
                                  )}{" "}
                                  -{" "}
                                  {this.state.departureData[
                                    this.state.departureData.length - 1
                                  ].substring(11, 16)}
                                </span>
                              </div>
                              <div>
                                <Slider
                                  value={this.state.deptValue}
                                  onChange={this.handleChangeDeptValue}
                                  valueLabelDisplay="auto"
                                  valueLabelFormat={this.formatTimeValue}
                                  min={this.parseTimeValue(
                                    this.state.departureData[0]
                                  )}
                                  max={this.parseTimeValue(
                                    this.state.departureData[
                                      this.state.departureData.length - 1
                                    ]
                                  )}
                                  disableSwap
                                />
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="d-flex flex-column gap-4p">
                                <span>
                                  Take-off from{" "}
                                  {sessionStorage.getItem(
                                    "destinationLocationCode"
                                  )}
                                </span>
                                <span>
                                  {this.state.arrivalData[0].substring(11, 16)}{" "}
                                  -{" "}
                                  {this.state.arrivalData[
                                    this.state.arrivalData.length - 1
                                  ].substring(11, 16)}
                                </span>
                              </div>
                              <div>
                                <Slider
                                  value={this.state.arrivValue}
                                  onChange={this.handleChangeArrivValue}
                                  valueLabelDisplay="auto"
                                  valueLabelFormat={this.formatTimeValue}
                                  min={this.parseTimeValue(
                                    this.state.arrivalData[0]
                                  )}
                                  max={this.parseTimeValue(
                                    this.state.arrivalData[
                                      this.state.arrivalData.length - 1
                                    ]
                                  )}
                                  disableSwap
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 col-md-8">
                  {this?.state?.updatingFlights ? (
                    <div className="flights_box_section">
                      <div className="p-2 skeleton_bg">
                        <p className="font-size-2 text-center">
                          We are finding the best flights for you.
                        </p>

                        <div className="row">
                          <div className="col-12">
                            <div class="progress pink">
                              <p
                                style={{ fontWeight: "bold", fontSize: "21px" }}
                              >
                                {this.state.isResponse
                                  ? "100%"
                                  : `${this.state.progress}%`}
                              </p>
                              <div
                                class="progress-bar"
                                style={{
                                  width: `${this.state.progress}%`,
                                  backgroundColor: "transparent",
                                }}
                              ></div>
                              ✈️
                            </div>
                          </div>
                        </div>

                        <ul className="px-4">
                          {this.state.progress <= 20 && (
                            <li className="fw-600">
                              Creating a travel masterpiece. Stay with us as the
                              strokes of excitement unfold
                            </li>
                          )}
                          {this.state.progress > 20 &&
                            this.state.progress <= 40 && (
                              <li className="fw-600">
                                Loading your next adventure... Sit tight, we're
                                preparing an unforgettable experience for you.
                              </li>
                            )}
                          {this.state.progress > 40 &&
                            this.state.progress <= 60 && (
                              <li className="fw-600">
                                Buckle up! We're speeding down the virtual
                                runway to unveil your personalized travel
                                escape.
                              </li>
                            )}
                          {this.state.progress > 60 &&
                            this.state.progress <= 80 && (
                              <li className="fw-600">
                                Patience, traveler! We're weaving a tapestry of
                                incredible moments for your upcoming journey.
                              </li>
                            )}
                        </ul>

                        {Array.from({ length: 5 }).map((_, index) => (
                          <>
                            <div className="row">
                              <div className="col-1">
                                <Skeleton
                                  variant="circle"
                                  width={40}
                                  height={40}
                                />
                              </div>
                              <div className="col-11">
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height={40}
                                />
                              </div>
                            </div>
                            <p>
                              <Skeleton variant="text" width="100%" />
                              <Skeleton variant="text" width="100%" />
                              <Skeleton variant="text" width="100%" />
                              <Skeleton variant="text" width="100%" />
                            </p>
                          </>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {this.state.filteredArray ? (
                        <div>
                          {this.state.filteredArray.map((item) => {
                            const matchingCarrier =
                              this.state.customeCarriers?.find(
                                (carrier) =>
                                  carrier.iataCode ===
                                  item.validatingAirlineCodes[0]
                              );
                            return (
                              <div className="flight_box">
                                <div className="flight_box_body">
                                  <div className="row">
                                    <div className="col-9">
                                      {item.itineraries.map(
                                        (childitem, index) => (
                                          <div className="d-flex align-items-center justify-content-between gap-12p mb-3">
                                            <img
                                              style={{
                                                height: "45px",
                                                width: "45px",
                                                objectFit: "contain",
                                                objectPosition: "center",
                                              }}
                                              src={item?.airline_logo}
                                              alt="logo"
                                            />
                                            <div className="d-flex flex-column align-items-start">
                                              <b>
                                                {childitem.segments[0].departure.at.substring(
                                                  11,
                                                  16
                                                )}
                                                &nbsp;-&nbsp;
                                                {childitem.segments[
                                                  childitem.segments.length - 1
                                                ].arrival.at.substring(11, 16)}
                                              </b>
                                              <p
                                                className="mb-0"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {index === 0 ? (
                                                  <>
                                                    {sessionStorage.getItem(
                                                      "originLocationCode"
                                                    )}
                                                    &nbsp;-&nbsp;
                                                    {sessionStorage.getItem(
                                                      "destinationLocationCode"
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {sessionStorage.getItem(
                                                      "destinationLocationCode"
                                                    )}
                                                    &nbsp;-&nbsp;
                                                    {sessionStorage.getItem(
                                                      "originLocationCode"
                                                    )}
                                                  </>
                                                )}
                                              </p>
                                            </div>
                                            <div className="d-flex flex-column align-items-start">
                                              <span>
                                                {childitem.segments.length ===
                                                1 ? (
                                                  <b>Direct</b>
                                                ) : (
                                                  <>
                                                    <b>
                                                      {childitem.segments
                                                        .length -
                                                        1 +
                                                        " Stop"}
                                                    </b>
                                                    <Tooltip
                                                      content={
                                                        childitem.segments &&
                                                        childitem.segments
                                                          .length > 0 &&
                                                        childitem.segments
                                                          .slice(
                                                            0,
                                                            childitem.segments
                                                              .length - 1
                                                          )
                                                          .map(
                                                            (segment, index) =>
                                                              this.calculateDuration(
                                                                segment,
                                                                segment
                                                                  .departure.at,
                                                                segment.arrival
                                                                  .at,
                                                                index
                                                              ) +
                                                              (index <
                                                              childitem.segments
                                                                .length -
                                                                2
                                                                ? ", "
                                                                : "")
                                                          )
                                                      }
                                                    >
                                                      <p
                                                        className="mb-0 pointer"
                                                        style={{
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {
                                                          childitem.segments[0]
                                                            .arrival.iataCode
                                                        }
                                                        {childitem?.segments
                                                          ?.length > 2 && (
                                                          <span>
                                                            +
                                                            {childitem.segments
                                                              .length - 2}{" "}
                                                            Stop
                                                          </span>
                                                        )}
                                                      </p>{" "}
                                                    </Tooltip>
                                                  </>
                                                )}
                                              </span>
                                            </div>
                                            <b>
                                              {childitem.duration
                                                .replace("PT", "")
                                                .replace("H", "H ")}
                                            </b>
                                          </div>
                                        )
                                      )}
                                      <span
                                        className="float-left"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {matchingCarrier.businessName}
                                      </span>
                                    </div>
                                    <div className="col-3 border-left">
                                      <div className="d-flex flex-column align-items-start justify-content-end h-100">
                                        <h5>
                                          {item?.price?.currency === "EUR" &&
                                            "€"}
                                          {item?.price?.currency === "USD" &&
                                            "$"}
                                          {item?.price?.currency === "INR" &&
                                            "₹"}
                                          &nbsp;
                                          {item.price.total}
                                        </h5>

                                        <p
                                          className="text-left"
                                          style={{ fontSize: "12px" }}
                                        >
                                          Ticket Price Per Pessenger
                                        </p>
                                        <button
                                          type="button"
                                          className="btn btn-success btn-block"
                                          onClick={() => {
                                            let newItem = {
                                              logo: item?.airline_logo,
                                              itineraries: item?.itineraries,
                                              price: item?.price,
                                            };
                                            this.submit(newItem);
                                          }}
                                        >
                                          Book Now
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {this?.state?.filteredArray?.length === 0 && (
                            <div
                              className="d-flex flex-column align-items-center my-4"
                              style={{ color: "#a5a5a5" }}
                            >
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{
                                  height: "140px",
                                  width: "140px",
                                  borderRadius: "50%",
                                  background: "#f8f9fa",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="80"
                                  height="80"
                                  fill="currentColor"
                                  class="bi bi-airplane-engines"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0M7 3c0-.432.11-.979.322-1.401C7.542 1.159 7.787 1 8 1s.458.158.678.599C8.889 2.02 9 2.569 9 3v4a.5.5 0 0 0 .276.447l5.448 2.724a.5.5 0 0 1 .276.447v.792l-5.418-.903a.5.5 0 0 0-.575.41l-.5 3a.5.5 0 0 0 .14.437l.646.646H6.707l.647-.646a.5.5 0 0 0 .14-.436l-.5-3a.5.5 0 0 0-.576-.411L1 11.41v-.792a.5.5 0 0 1 .276-.447l5.448-2.724A.5.5 0 0 0 7 7z" />
                                </svg>
                              </div>
                              <p
                                style={{
                                  fontSize: "2.4rem",
                                  fontWeight: "600",
                                }}
                              >
                                No Flights Available
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="flights_box_section">
                          <div className="p-2 skeleton_bg">
                            <p className="font-size-2 text-center">
                              We are finding the best flights for you.
                            </p>

                            <div className="row">
                              <div className="col-12">
                                <div class="progress pink">
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "21px",
                                    }}
                                  >
                                    {this.state.isResponse
                                      ? "100%"
                                      : `${this.state.progress}%`}
                                  </p>
                                  <div
                                    class="progress-bar"
                                    style={{
                                      width: `${this.state.progress}%`,
                                      backgroundColor: "transparent",
                                    }}
                                  ></div>
                                  ✈️
                                </div>
                              </div>
                            </div>

                            {/* <div>
                          <div className="row">
                            <div className="col-1">
                              <Skeleton
                                variant="circle"
                                width={40}
                                height={40}
                              />
                            </div>
                            <div className="col-11">
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                              />
                            </div>
                          </div>
                          <p>
                            <Skeleton variant="text" width="100%" />
                            <Skeleton variant="text" width="100%" />
                            <Skeleton variant="text" width="100%" />
                            <Skeleton variant="text" width="100%" />
                          </p>
                        </div> */}

                            <ul className="px-4">
                              {this.state.progress <= 20 && (
                                <li className="fw-600">
                                  Creating a travel masterpiece. Stay with us as
                                  the strokes of excitement unfold
                                </li>
                              )}
                              {this.state.progress > 20 &&
                                this.state.progress <= 40 && (
                                  <li className="fw-600">
                                    Loading your next adventure... Sit tight,
                                    we're preparing an unforgettable experience
                                    for you.
                                  </li>
                                )}
                              {this.state.progress > 40 &&
                                this.state.progress <= 60 && (
                                  <li className="fw-600">
                                    Buckle up! We're speeding down the virtual
                                    runway to unveil your personalized travel
                                    escape.
                                  </li>
                                )}
                              {this.state.progress > 60 &&
                                this.state.progress <= 80 && (
                                  <li className="fw-600">
                                    Patience, traveler! We're weaving a tapestry
                                    of incredible moments for your upcoming
                                    journey.
                                  </li>
                                )}
                            </ul>

                            {Array.from({ length: 5 }).map((_, index) => (
                              <>
                                <div className="row">
                                  <div className="col-1">
                                    <Skeleton
                                      variant="circle"
                                      width={40}
                                      height={40}
                                    />
                                  </div>
                                  <div className="col-11">
                                    <Skeleton
                                      variant="text"
                                      width="100%"
                                      height={40}
                                    />
                                  </div>
                                </div>
                                <p>
                                  <Skeleton variant="text" width="100%" />
                                  <Skeleton variant="text" width="100%" />
                                  <Skeleton variant="text" width="100%" />
                                  <Skeleton variant="text" width="100%" />
                                </p>
                              </>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="blogs_side_bar shadow-none d-flex flex-column gap-2rem">
                    <div className="blogs_side_bar_sec border-0">
                      <a
                        href="tel:+18883151615"
                        className="btn rounded-1rem text-white btn-lg pulse w-100"
                        role="button"
                        style={{
                          background:
                            "linear-gradient(45deg, #FF6200, #ffa500, #FF6200)",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            class="bi bi-telephone-fill call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                            />
                          </svg>
                          <div className="">
                            <p className="fw-600 float-right mb-0 font-size-2 w-100">
                              +1 888-315-1615 (US)
                            </p>
                            <p className="mb-0 font-size-1">
                              Call us now to get best offers!
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="blogs_side_bar_sec border-0 custom-card">
                      <div className="blogs_side_bar_sec_about_us p-3 text-center">
                        <h4 className="text-uppercase text-roboto fw-600">
                          About Us
                        </h4>
                        <div className="img_bx d-flex justify-content-center align-items-center border-0 bg-white">
                          <img
                            src={Logo}
                            className="img-fluid object-fit-contain"
                            alt="logo"
                          />
                        </div>
                        <p>
                          Welcome to Ogule, your ultimate destination for
                          seamless flight bookings across the United States. At
                          Ogule, we're passionate about transforming your travel
                          dreams into reality. Your hassle-free travel
                          experience begins here, at Ogule...
                          <Link
                            to="/about"
                            className="text-primary pointer ml-1"
                          >
                            Read more
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="blogs_side_bar_sec border-0 custom-card">
                      <div className="blogs_side_bar_sec_about_us p-3 text-center">
                        <h4 className="text-uppercase mb-3 text-roboto fw-600">
                          Social Me
                        </h4>

                        <div className="mt-3">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control form-control-lg"
                              id="email"
                              placeholder="Email Address"
                              name="email"
                            />
                          </div>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-facebook-f fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-google-plus-g fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-twitter fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-instagram fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-linkedin-in fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <div className="d-flex justify-content-center btn-block">
                            <button
                              className="btn btn-primary btn-lg"
                              style={{ fontFamily: "Roboto, sans-serif" }}
                              onClick={this.handleSubscribeModal}
                            >
                              Subscribe
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blogs_side_bar_sec border-0">
                      {/* <div className="blogs_side_bar_sec_about_us p-3">
                        <h4 className="text-uppercase mb-3 text-roboto fw-600">
                          Categories
                        </h4>
                        <ul className="list-group list-group-flush gap-1rem">
                          <li className="d-flex justify-content-between align-items-center border-0 list-shadow">
                            Fashion
                            <span className="badge badge-primary badge-pill">
                              16
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center border-0 list-shadow">
                            Travel
                            <span className="badge badge-primary badge-pill">
                              11
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center border-0 list-shadow">
                            Lifestyle
                            <span className="badge badge-primary badge-pill">
                              16
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center border-0 list-shadow">
                            Photography
                            <span className="badge badge-primary badge-pill">
                              10
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="blogs_side_bar_sec border-0">
                        <div className="blogs_side_bar_sec_about_us p-3">
                          <h4 className="text-uppercase mb-3 text-roboto fw-600">
                            Tags
                          </h4>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                        </div>
                      </div> */}
                      <div className="blogs_side_bar_sec border-0">
                        <div className="blogs_side_bar_sec_about_us p-3">
                          <h4 className="text-uppercase mb-3 text-roboto fw-600">
                            Recent Post
                          </h4>
                          {this.state.bloglist ? (
                            <>
                              {this.state.bloglist?.slice(0, 4)?.map((item) => {
                                return (
                                  <div
                                    className="row sidebar_recent_post list-shadow border-0 mb-3 pointer"
                                    key={item?.id}
                                    onClick={() => {
                                      this.single_blog(item.slug);
                                    }}
                                  >
                                    <div
                                      className="col-5"
                                      style={{
                                        borderRight: "1px dashed #343a40",
                                      }}
                                    >
                                      <img
                                        src={
                                          "https://api.ogule.com/public/blog/" +
                                          item?.image
                                        }
                                        className="h-100 w-100 rounded-10p"
                                        style={{
                                          backgroundPosition: "center",
                                          backgroundSize: "cover",
                                        }}
                                        alt="blogs_1"
                                      />
                                    </div>
                                    <div className="col-7">
                                      <h6 className="text-truncate">
                                        {item?.name}
                                      </h6>
                                      <div className="d-flex gap-12p justify-content-between"></div>
                                      <p className="text-truncate">
                                        {item.short_description}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <div className="row">
                                <div className="col-12">
                                  <p className="font-size-2 text-center py-4">
                                    We are finding the best flights for you.
                                  </p>
                                </div>
                              </div>
                              {Array.from({ length: 4 }).map((_, index) => (
                                <div className="card rounded-10p mb-3 border-0 shadow">
                                  <div className="card-body py-1">
                                    <div className="d-flex gap-1rem">
                                      <Skeleton
                                        variant="text"
                                        width="40%"
                                        height={100}
                                      />
                                      <div className="w-100 d-flex flex-column gap-1rem justify-content-center">
                                        <Skeleton
                                          variant="text"
                                          width="100%"
                                          height={40}
                                        />
                                        <Skeleton
                                          variant="text"
                                          width="100%"
                                          height={20}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            {/* Bell popup modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showNotificationModal}
              onHide={this.handleNotificationModal}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully added in notification list
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            <div className="deals_top_heading_mobile">
              <Link to="/">
                <div className="backarrow">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </Link>
              <div className="journy_atacode">
                <h4>
                  {sessionStorage.getItem("iatacode_from")} -{" "}
                  {sessionStorage.getItem("iatacode_to")}
                </h4>
              </div>
              <div
                className="notification"
                onClick={this.handleNotificationModal}
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
            </div>
            <div className="m_flights_filters">
              <SelectButton
                value={this.state.trip}
                options={trip}
                onChange={(e) => this.search_filters(e.value)}
              ></SelectButton>
              <FontAwesomeIcon
                icon={faSlidersH}
                onClick={() => {
                  this.search_data("filters");
                }}
              />
            </div>
            <div className="m_flights_seacrh_form">
              <div
                className="m_from"
                onClick={() => {
                  this.search_data("fromflights");
                }}
              >
                <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
                {sessionStorage.getItem("iatacode_from")}
              </div>
              <div
                className="m_exchange"
                onClick={() => {
                  this.from_and_to_exchange();
                }}
              >
                <FontAwesomeIcon icon={faExchangeAlt} />
              </div>
              <div
                className="m_to"
                onClick={() => {
                  this.search_data("toflights");
                }}
              >
                <FontAwesomeIcon icon={faPlaneArrival} />{" "}
                {sessionStorage.getItem("iatacode_to")}
              </div>
              {sessionStorage.getItem("trip") === "round_trip" ? (
                <div
                  className="m_departure_date"
                  onClick={() => {
                    this.search_data("roundTripDate");
                  }}
                >
                  <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                  {sessionStorage.getItem("mobile_departureDate_oneway")} /{" "}
                  {sessionStorage.getItem("mobile_returnDate_oneway")}
                </div>
              ) : (
                <div
                  className="m_departure_date"
                  onClick={() => {
                    this.search_data("onwayDate");
                  }}
                >
                  <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                  {sessionStorage.getItem("mobile_departureDate_oneway")}
                </div>
              )}
              <div
                className="m_submit"
                onClick={() => {
                  this.find_flights();
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
            <Growl ref={(el) => (this.growl = el)}></Growl>
            {this.state.flightslist ? (
              <div className="flights_box_section">
                <a
                  href="tel:+18883151615"
                  className="btn rounded-1rem card pulse w-100 mb-3"
                  role="button"
                >
                  <div className="d-flex align-items-center gap-12p w-100 ">
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src="https://tse3.explicit.bing.net/th?id=OIP.Aqn3nZQJW8VFj_uvJkWANgHaFz&pid=Api&P=0&h=180"
                      alt=""
                    />
                    <div className="w-100">
                      <div className="d-flex justify-content-between w-100">
                        <p className="mb-0 text-left">
                          Get upto&nbsp;
                          <span className="text-danger fw-600">30% off</span>
                          &nbsp;on call
                        </p>

                        <p className="mb-0 d-flex align-items-center justify-content-end">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            class="bi bi-telephone call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                          </svg>
                          &nbsp;Call Us Now
                        </p>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <p className="text-danger fw-600 mb-0">
                          Last hour DEALS!
                        </p>
                        <p className="fw-600 mb-0 font-size-1 blinking">
                          +1 888-315-1615 (US)
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                {this.state.filteredArray.map((item) => {
                  return (
                    <div className="mobile_flight_box p-2 " key={item.id}>
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="top_sec p-2"
                            style={{
                              borderTopRightRadius: "10px",
                              borderTopLeftRadius: "10px",
                              background: "#4587ed",
                            }}
                          >
                            <div className="airline_flights_details text-white">
                              <p className="font-size-1 fw-600 mb-0">
                                {
                                  this.state.dictionaries.carriers[
                                    item.validatingAirlineCodes[0]
                                  ]
                                }
                              </p>
                              <p className="font-size-1 fw-600 mb-0">
                                $&nbsp;{item.price.total}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-3 flights_box_left d-flex justify-content-center">
                          <img
                            src={item?.airline_logo}
                            className="img-fluid"
                            alt="logo"
                          />
                        </div>
                        <div className="col-9 flights_box_right border-0">
                          {item.itineraries.map((childitem) => (
                            <div className="p-2 depart">
                              {/* <div className="container-fluid"> */}
                              <div className="flights_details">
                                <div>
                                  <p className="ariv_time">
                                    {childitem.segments[0].departure.at.substring(
                                      11,
                                      16
                                    )}
                                  </p>
                                  <p className="ariv_from">
                                    {childitem.segments[0].departure.iataCode}
                                  </p>
                                </div>
                                <div>
                                  <p className="ariv_t_time">
                                    {childitem.duration
                                      .replace("PT", "")
                                      .replace("H", "H ")}
                                  </p>
                                  <hr />
                                  <p className="ariv_stop">
                                    {childitem.segments.length === 1
                                      ? "(Direct)"
                                      : "(" +
                                        (childitem.segments.length - 1) +
                                        " Stop)"}
                                  </p>
                                </div>
                                <div>
                                  <p className="ariv_time">
                                    {childitem.segments[
                                      childitem.segments.length - 1
                                    ].arrival.at.substring(11, 16)}
                                  </p>
                                  <p className="ariv_from">
                                    {childitem.segments[0].arrival.iataCode}
                                  </p>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          ))}
                        </div>

                        <div className="col-12">
                          <div className="d-flex justify-content-end mt-2">
                            {/* <div
                              className="wishlist"
                              onClick={() => {
                                this.wishlist(item);
                              }}
                            >
                              <button
                                type="button"
                                className="btn badge-pill btn-outline-primary btn-sm"
                              >
                                Add To Wishlist
                              </button>
                            </div> */}
                            <div
                              className="book_now"
                              onClick={() => {
                                let newItem = {
                                  logo: item?.airline_logo,
                                  itineraries: item?.itineraries,
                                  price: item?.price,
                                };
                                this.submit(newItem);
                              }}
                            >
                              <button
                                type="button"
                                className="btn badge-pill btn-outline-primary btn-sm"
                              >
                                Book Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <a
                  href="tel:+18883151615"
                  className="btn rounded-1rem text-white btn-lg pulse w-100"
                  role="button"
                  style={{
                    background:
                      "linear-gradient(45deg, #FF6200, #ffa500, #FF6200)",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-around">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="bi bi-telephone-fill call_vibration"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                      />
                    </svg>
                    <div className="">
                      <p className="fw-600 float-right mb-0 font-size-2">
                        +1 888-315-1615 (US)
                      </p>
                      <p className="mb-0 font-size-1 blinking">
                        Call now for exciting offers!
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            ) : (
              <div className="flights_box_section">
                <div className="p-2 skeleton_bg mb-2">
                  <div className="text-center">
                    <h5>We're searching the best flights for you.</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div class="progress pink">
                      <p style={{ fontWeight: "bold", fontSize: "21px" }}>
                        {this.state.isResponse
                          ? "100%"
                          : `${this.state.progress}%`}
                      </p>
                      <div
                        class="progress-bar"
                        style={{
                          width: `${this.state.progress}%`,
                          backgroundColor: "transparent",
                        }}
                      ></div>
                      ✈️
                    </div>
                  </div>
                </div>

                <ul className="px-3">
                  {this.state.progress <= 20 && (
                    <li className="fw-600">
                      Creating a travel masterpiece. Stay with us as the strokes
                      of excitement unfold
                    </li>
                  )}
                  {this.state.progress > 20 && this.state.progress <= 40 && (
                    <li className="fw-600">
                      Loading your next adventure... Sit tight, we're preparing
                      an unforgettable experience for you.
                    </li>
                  )}
                  {this.state.progress > 40 && this.state.progress <= 60 && (
                    <li className="fw-600">
                      Buckle up! We're speeding down the virtual runway to
                      unveil your personalized travel escape.
                    </li>
                  )}
                  {this.state.progress > 60 && this.state.progress <= 80 && (
                    <li className="fw-600">
                      Patience, traveler! We're weaving a tapestry of incredible
                      moments for your upcoming journey.
                    </li>
                  )}
                </ul>

                {Array.from({ length: 5 }).map((_, index) => (
                  <div className="p-2 skeleton_bg">
                    <div className="row">
                      <div className="col-2">
                        <Skeleton variant="circle" width={40} height={40} />
                      </div>
                      <div className="col-10">
                        <Skeleton variant="text" width="100%" height={40} />
                      </div>
                    </div>
                    <p>
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                    </p>
                  </div>
                ))}
              </div>
            )}
            <Header />
          </div>
        )}
      </div>
    );
  }
}
export default Flights;
