import React, { Component } from "react";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBell,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

class Our_Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotificationModal: false,
    };
  }

  handleNotificationModal = () => {
    this.setState((prevState) => ({
      showNotificationModal: !prevState.showNotificationModal,
    }));
  };

  handleResize = () => {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  componentDidMount() {
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }

  componentWillUnmount() {
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }

  render() {
    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Header />
            <div className="container my-4">
              <div className="row">
                <div className="col-12">
                  <div className="blogs_side">
                    <div className="blogs_sec p-3">
                      <h3>OUR TEAM</h3>
                      <p>
                        Our service and our prestigious customer’s experience is
                        decisively bonded by a robust thread and that is none
                        other but our very own customer service team. Customer
                        service team is the core of the organization by
                        exceptionally providing a courteous service that helps
                        in adding up more customers to the organizational family
                        as it is already quoted that the customer doesn’t
                        remember what you talk to them, they remember what you
                        made them feel. This team unanimously helps us to
                        provide our customers the good and also helps in
                        entangling the aberration that we provide involuntarily
                        and which also brings to us a feeling of culpability.
                      </p>
                      <br />
                      <p>
                        It is a much known fact that notable things in business
                        are never done by one person, they are always done by a
                        team of people. Here, when you are consolidated as a new
                        member of the family “OGULE” we expect you to serve with
                        wholehearted passion and hard work to take our company
                        to the whole new level with the work motto of building a
                        platform where we intend to yield only satisfactory
                        customer experiences. It is always better to have a
                        great team than a team of greats; so here we entrust
                        that each and every member of the team possess their
                        very own prominence as we believe in the unique eminence
                        that every individual possesses. We work with the spirit
                        of believing in unity of our smart working passionate
                        employees bringing prosperity to the company.
                      </p>
                      <br />
                      <p>
                        Customers can never love a company until employees love
                        it first because what’s happening inside of a company is
                        being felt on the outside by the customer. We believe in
                        making every interaction count, even the small ones as
                        they are all relevant. Customer service is the overall
                        activity of identifying and satisfying the customers. We
                        entrust in accomplishing and making even the unhappy
                        customers to them giving satisfactory outcome. We value
                        and appreciate our team members’ sincerity, integrity,
                        patience, individual commitments, empathy, self-control,
                        flexibility, courteous behavior and every quality the
                        members possess to be an ideal, presentable
                        personification able to deal with any kind of customers
                        with different behavioural varieties.
                      </p>
                      <br />
                      <p>
                        We look forward to the spark of creativity of each and
                        every individual of the team of OGULE to enlighten the
                        workspace and bring out the most illuminating service
                        for our utmost important customers. So here we can
                        conclude; the customer service is an asset for the
                        corporation as high quality of customer service helps to
                        create loyal customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            {/* Bell popup modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showNotificationModal}
              onHide={this.handleNotificationModal}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully added in notification list
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            <div className="deals_top_heading_mobile">
              <Link to="/help">
                <div className="backarrow">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </Link>
              <div className="journy_atacode">
                <h4>OUR TEAM</h4>
              </div>
              <div
                className="notification"
                onClick={this.handleNotificationModal}
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
            </div>
            <div className="blogs_sec">
              <h3>OUR TEAM</h3>
              <p>
                Our service and our prestigious customer’s experience is
                decisively bonded by a robust thread and that is none other but
                our very own customer service team. Customer service team is the
                core of the organization by exceptionally providing a courteous
                service that helps in adding up more customers to the
                organizational family as it is already quoted that the customer
                doesn’t remember what you talk to them, they remember what you
                made them feel. This team unanimously helps us to provide our
                customers the good and also helps in entangling the aberration
                that we provide involuntarily and which also brings to us a
                feeling of culpability.
              </p>
              <br />
              <p>
                It is a much known fact that notable things in business are
                never done by one person, they are always done by a team of
                people. Here, when you are consolidated as a new member of the
                family “OGULE” we expect you to serve with wholehearted passion
                and hard work to take our company to the whole new level with
                the work motto of building a platform where we intend to yield
                only satisfactory customer experiences. It is always better to
                have a great team than a team of greats; so here we entrust that
                each and every member of the team possess their very own
                prominence as we believe in the unique eminence that every
                individual possesses. We work with the spirit of believing in
                unity of our smart working passionate employees bringing
                prosperity to the company.
              </p>
              <br />
              <p>
                Customers can never love a company until employees love it first
                because what’s happening inside of a company is being felt on
                the outside by the customer. We believe in making every
                interaction count, even the small ones as they are all relevant.
                Customer service is the overall activity of identifying and
                satisfying the customers. We entrust in accomplishing and making
                even the unhappy customers to them giving satisfactory outcome.
                We value and appreciate our team members’ sincerity, integrity,
                patience, individual commitments, empathy, self-control,
                flexibility, courteous behavior and every quality the members
                possess to be an ideal, presentable personification able to deal
                with any kind of customers with different behavioural varieties.
              </p>
              <br />
              <p>
                We look forward to the spark of creativity of each and every
                individual of the team of OGULE to enlighten the workspace and
                bring out the most illuminating service for our utmost important
                customers. So here we can conclude; the customer service is an
                asset for the corporation as high quality of customer service
                helps to create loyal customers.
              </p>
            </div>
            <Header />
          </div>
        )}
      </div>
    );
  }
}

export default Our_Team;
