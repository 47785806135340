import moment from "moment";

export const convertTimeFormat = (timeString) => {
  if (timeString) {
    const hours = timeString?.substring(0, 2);
    const minutes = timeString?.substring(2);
    return `${hours}:${minutes}`;
  }
  return 0;
};

// calculate total duration between 2 location
export const calculateTotalDuration = (flightDetails) => {
  if (!Array.isArray(flightDetails) || flightDetails.length < 1) {
    return null;
  }
  const firstFlight = flightDetails?.[0].flightInformation?.[0];
  const lastFlight =
    flightDetails[flightDetails.length - 1].flightInformation?.[0];

  // Format dates and times
  const departureDateTime = moment(
    `${firstFlight?.productDateTime?.[0].dateOfDeparture?.[0]} ${firstFlight.productDateTime?.[0].timeOfDeparture?.[0]}`,
    "DDMMYY HHmm"
  );
  const arrivalDateTime = moment(
    `${lastFlight?.productDateTime?.[0].dateOfArrival?.[0]} ${lastFlight.productDateTime?.[0].timeOfArrival?.[0]}`,
    "DDMMYY HHmm"
  );
  const durationMinutes = arrivalDateTime.diff(departureDateTime, "minutes");

  const durationHours = Math.floor(durationMinutes / 60);
  const durationRemainingMinutes = durationMinutes % 60;

  const formattedDuration = `${durationHours}h ${durationRemainingMinutes}m`;

  return formattedDuration;
};

// calculate total duration if any stop between 2 location
export const calculateDuration = (
  item,
  departureTime,
  dateOfDeparture,
  arrivalTime,
  dateOfArrival,
  index
) => {
  const dateObjDeparture = new Date(
    `20${dateOfDeparture?.slice(4, 6)}-${dateOfDeparture?.slice(
      2,
      4
    )}-${dateOfDeparture?.slice(0, 2)}`
  );

  const departure = new Date(
    dateObjDeparture.getFullYear(),
    dateObjDeparture.getMonth(),
    dateObjDeparture.getDate(),
    parseInt(departureTime?.slice(0, 2)),
    parseInt(departureTime?.slice(2)),
    0,
    0
  );

  const dateObjArrival = new Date(
    `20${dateOfArrival?.slice(4, 6)}-${dateOfArrival?.slice(
      2,
      4
    )}-${dateOfArrival?.slice(0, 2)}`
  );

  const arrival = new Date(
    dateObjArrival.getFullYear(),
    dateObjArrival.getMonth(),
    dateObjArrival.getDate(),
    parseInt(arrivalTime?.slice(0, 2)),
    parseInt(arrivalTime?.slice(2)),
    0,
    0
  );

  const difference = arrival - departure;
  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  return `${item?.flightInformation?.[0]?.location?.[1]?.locationId?.[0]} : ${hours} hours ${minutes} minutes`;
};

// duration in minutes
export const flightTotalDuration = (
  departureTime,
  dateOfDeparture,
  arrivalTime,
  dateOfArrival
) => {
  const dateObjDeparture = new Date(
    `20${dateOfDeparture?.slice(4, 6)}-${dateOfDeparture?.slice(
      2,
      4
    )}-${dateOfDeparture?.slice(0, 2)}`
  );

  const departure = new Date(
    dateObjDeparture.getFullYear(),
    dateObjDeparture.getMonth(),
    dateObjDeparture.getDate(),
    parseInt(departureTime?.slice(0, 2)),
    parseInt(departureTime?.slice(2)),
    0,
    0
  );

  const dateObjArrival = new Date(
    `20${dateOfArrival?.slice(4, 6)}-${dateOfArrival?.slice(
      2,
      4
    )}-${dateOfArrival?.slice(0, 2)}`
  );

  const arrival = new Date(
    dateObjArrival.getFullYear(),
    dateObjArrival.getMonth(),
    dateObjArrival.getDate(),
    parseInt(arrivalTime?.slice(0, 2)),
    parseInt(arrivalTime?.slice(2)),
    0,
    0
  );

  // const difference = arrival - departure;
  // const hours = Math.floor(difference / (1000 * 60 * 60));
  // const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  // return `${hours} hours ${minutes} minutes`;
  const differenceInMinutes = Math.floor((arrival - departure) / (1000 * 60));
  return differenceInMinutes;
};

export const parseTimeValue = (timeString) => {
  if (typeof timeString === "string") {
    const hours = parseInt(timeString.substring(0, 2), 10);
    const minutes = parseInt(timeString.substring(2), 10);
    return hours * 60 + minutes;
  }
  return 0;
};

export const updateArray = (arr, newObj) => {
  const index = arr.findIndex(
    (item) =>
      item.airlineName === newObj.airlineName &&
      item.layovers === newObj.layovers
  );
  if (index !== -1) {
    if (parseFloat(newObj.price) < parseFloat(arr[index].price)) {
      arr[index] = newObj;
    }
  } else {
    arr.push(newObj);
  }
  return arr;
};
