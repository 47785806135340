import React, { useEffect, useState } from "react";
import ogule from "../image/NewUI/oguleNewLogo.png";
import { Modal, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faSquareXTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import { Link } from "react-router-dom";
import CallFooter from "./CallFooter";

const Header = () => {
  const [selectedCurrency, setSelectedCurrency] = useState("usd");
  const [setShow, setSetShow] = useState(false);
  const currentPath = window.location.pathname;
  const [isRender, setIsRender] = useState(sessionStorage.getItem("call"));
  const [loader, setLoader] = useState(false);

  // const handleCurrencyChange = (eventKey) => {
  //   setSelectedCurrency(eventKey);
  //   handleGetCurrency(eventKey);
  // };

  const handleClose = () => {
    setSetShow(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sessionStorage.setItem("call", !isRender);
      setIsRender(!isRender);
    }, 120000); // 2 min in milliseconds
    return () => clearTimeout(timeoutId);
  }, [isRender]);

  const closeFooterCall = () => {
    sessionStorage.setItem("call", false);
    setIsRender(false);
  };

  // const handleGetCurrency = async (currency) => {
  //   setLoader(true);
  //   try {
  //     const response = await fetch(
  //       `https://latest.currency-api.pages.dev/v1/currencies/${currency}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (res.success) {
  //       setLoader(false);
  //       sessionStorage.setItem("latest_currency", currency)
  //     } else {
  //       setLoader(false);
  //       console.error("Error fetching currency:", res.message);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     console.error("Error fetching currency:", error);
  //   }
  // };
  // useEffect(() => {
  //   handleGetCurrency(selectedCurrency);
  // }, [selectedCurrency]);
  // const convertCurrency = () => {
  //   if (selectedCurrency === "usd") {
  //     return 1;
  //   } else if (selectedCurrency === "eur") {
  //     return 1 * 0.93188186;
  //   } else if (selectedCurrency === "inr") {
  //     return 1 * 83.52969742;
  //   } else {
  //     return 1;
  //   }
  // };

  return (
    <div>
      {window.innerWidth > 768 ? (
        <div>
          <Modal
            centered
            size="lg"
            show={setShow}
            onHide={handleClose}
            className="logout_conf_popup"
          >
            <Modal.Header closeButton>
              <h5>Choose Language</h5>
            </Modal.Header>
            <Modal.Body className="px-4 py-3">
              <div className="row">
                <div className="col">
                  <select name="cars" className="custom-select mb-3">
                    <option selected>Default Custom Select Menu</option>
                    <option value="volvo">English</option>
                    <option value="fiat">Arabic</option>
                    <option value="audi">Thai</option>
                  </select>
                </div>
                {/* <div className="col">
                <select name="cars" className="custom-select mb-3">
                  <option selected>Default Custom Select Menu</option>
                  <option value="volvo">Volvo</option>
                  <option value="fiat">Fiat</option>
                  <option value="audi">Audi</option>
                </select>
              </div> */}
                <div className="col-2">
                  <button
                    type="button"
                    class="btn btn-primary btn-block"
                    onClick={() => {
                      setSetShow(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <div className="top_bar_bg py-1" id="top">
            <div className="container">
              <div className="row">
                <div className="col-6 d-flex">
                  <div className="top_social">
                    <FontAwesomeIcon icon={faFacebook} />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      class="bi bi-twitter-x"
                      viewBox="0 0 16 16"
                      style={{marginBottom:"3px"}}
                    >
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                    </svg>
                    <FontAwesomeIcon icon={faInstagram} />
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </div>
                  <div className="top_mail">
                    <span>sales@ogule.com</span>
                  </div>
                </div>
                {/* <div className="col-6 d-flex justify-content-end">
                <div className="top_contact">
                    <span>+1 302-585-3548</span>
                  </div>
                <div className="top_login">
                  <span>LOGIN</span>
                </div>
                <div className="top_signup">
                  <span>SIGN UP</span>
                </div>
              </div> */}
              </div>
            </div>
          </div>

          <div className="desk_header shadow-none">
            <div className="px-sm-responsive py-2">
              <nav className="navbar d-flex align-items-center justify-content-between">
                <Link to="/">
                  <img
                    src={ogule}
                    className="img-fluid mx-auto d-block pointer"
                    style={{ height: "48px" }}
                    alt="logo"
                  />
                </Link>

                {/* <div
                  className="collapse navbar-collapse"
                  id="collapsibleNavbar"
                > */}
                <ul
                  className="d-flex align-items-center mb-0"
                  style={{ listStyle: "none" }}
                >
                  <li
                    className={`nav-item ${
                      currentPath === "/" ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      currentPath?.includes("deals") ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to="/deals">
                      Deals
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      currentPath?.includes("blogs") ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to="/blogs">
                      Blogs
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      currentPath === "/about" ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to="/about">
                      About
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    <Dropdown onSelect={handleCurrencyChange}>
                      <Dropdown.Toggle
                        className="nav-link btn-light bg-white fw-600 border-0 text-uppercase"
                        id="currency-dropdown"
                      >
                        {selectedCurrency}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="usd">USD</Dropdown.Item>
                        <Dropdown.Item eventKey="eur">EUR</Dropdown.Item>
                        <Dropdown.Item eventKey="inr">INR</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li> */}

                  {/* <li
                      className={`nav-item ${
                        currentPath === "/" ? "active" : ""
                      }`}
                    >
                      <Link className="nav-link" to="/help">
                        +1 302-585-3548
                      </Link>
                    </li> */}

                  <li
                    className={`nav-item ${
                      currentPath === "/dashboard" ? "active" : ""
                    }`}
                  >
                    {sessionStorage.getItem("login") ? (
                      <Link className="nav-link" to="/dashboard">
                        My Account
                      </Link>
                    ) : (
                      <Link className="nav-link" to="/login">
                        Sign in
                      </Link>
                    )}
                  </li>
                </ul>
                {/* </div> */}

                <div className="d-flex align-items-center gap-2">
                  {/* <button
                        type="button"
                        class="btn btn-primary btn-lg"
                        onClick={() => {
                          this.setState({ setShow: true });
                        }}
                      >
                        Lang/Curn
                      </button> */}

                  <a
                    href="tel:+18883151615"
                    className="btn rounded-1rem pulse"
                    role="button"
                  >
                    <div className="d-flex align-items-center gap-1">
                      <span className="custom-text-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-telephone"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                        </svg>
                      </span>
                      <div className="d-flex flex-column font-size-1 text-nowrap">
                        <p className="mb-0 fw-600 float-right fs-13p">
                          +1 888-315-1615 (US)
                        </p>
                        <p className="mb-0" style={{ fontSize: "11px" }}>
                          Get upto&nbsp;
                          <span className="text-danger fw-600">30% off</span>
                          &nbsp; on call
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="mobile-footer">
            {/* {sessionStorage.getItem("call") === "true" && (
              <CallFooter closeFooterCall={closeFooterCall} />
            )} */}
            <div className="row">
              <div className="col">
                <Link to="/deals">
                  <div
                    className={`mobile-footer-part ${
                      currentPath?.includes("deals") ? "nav_active" : ""
                    } `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-tags"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 2v4.586l7 7L14.586 9l-7-7zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586z" />
                      <path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1z" />
                    </svg>
                    DEALS
                    {currentPath?.includes("deals") && (
                      <span className="nav_active_pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          fill="currentColor"
                          class="bi bi-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                      </span>
                    )}
                  </div>
                </Link>
              </div>

              <div className="col">
                <Link to="/blogs">
                  <div
                    className={`mobile-footer-part ${
                      currentPath?.includes("blogs") ? "nav_active" : ""
                    } `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-subtract"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                    </svg>
                    BLOGS
                    {currentPath?.includes("blogs") && (
                      <span className="nav_active_pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          fill="currentColor"
                          class="bi bi-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                      </span>
                    )}
                  </div>
                </Link>
              </div>

              <div className="col home_link">
                <Link to="/">
                  <div
                    className={`mobile-footer-part ${
                      currentPath === "/" ? "nav_active" : ""
                    } `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-airplane-engines"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0M7 3c0-.432.11-.979.322-1.401C7.542 1.159 7.787 1 8 1s.458.158.678.599C8.889 2.02 9 2.569 9 3v4a.5.5 0 0 0 .276.447l5.448 2.724a.5.5 0 0 1 .276.447v.792l-5.418-.903a.5.5 0 0 0-.575.41l-.5 3a.5.5 0 0 0 .14.437l.646.646H6.707l.647-.646a.5.5 0 0 0 .14-.436l-.5-3a.5.5 0 0 0-.576-.411L1 11.41v-.792a.5.5 0 0 1 .276-.447l5.448-2.724A.5.5 0 0 0 7 7z" />
                    </svg>
                    HOME
                    {currentPath === "/" && (
                      <span className="nav_active_pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          fill="currentColor"
                          class="bi bi-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                      </span>
                    )}
                  </div>
                </Link>
              </div>

              <div className="col">
                <Link to="/help">
                  <div
                    className={`mobile-footer-part ${
                      currentPath?.includes("help") ? "nav_active" : ""
                    } `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                    HELP
                    {currentPath?.includes("help") && (
                      <span className="nav_active_pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          fill="currentColor"
                          class="bi bi-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                      </span>
                    )}
                  </div>
                </Link>
              </div>

              <div className="col">
                {sessionStorage.getItem("login") ? (
                  <Link to="/dashboard">
                    <div className="mobile-footer-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-person-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                        <path
                          fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                        />
                      </svg>
                      USER
                    </div>
                  </Link>
                ) : (
                  <Link to="/login">
                    <div className="mobile-footer-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-person-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                        <path
                          fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                        />
                      </svg>
                      USER
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
