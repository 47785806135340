import React, { Component } from "react";
import { format } from "date-fns";
import Header from "../Header";
import DatePicker from "react-datepicker";
import Slider from "@material-ui/core/Slider";
import moment from "moment";
import { Button } from "primereact/button";
import { Modal, ButtonToolbar } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import RoundTrip from "./RoundTrip";
import Oneway from "./Oneway";
import {
  convertTimeFormat,
  flightTotalDuration,
  parseTimeValue,
  updateArray,
} from "../GlobalFunction";
import FilterSidebar from "./FilterSidebar";
import RoundTripMobileView from "./RoundTripMobileView";
import OnewayMobileView from "./OnewayMobileView";

class FlightsUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      updatingFlights: false,
      progress: 0,
      isResponse: false,
      airport_name_from:
        sessionStorage.getItem("airport_name_from") || "SHUTTLESWORTH INTL",
      iatacode_from: sessionStorage.getItem("originLocationCode") || "BHM",
      country_from: sessionStorage.getItem("country_from") || "US",
      city_from: sessionStorage.getItem("city_from") || "BIRMINGHAM",
      airport_name_to:
        sessionStorage.getItem("airport_name_to") || "DENVER INTERNATIONAL",
      iatacode_to: sessionStorage.getItem("destinationLocationCode") || "DEN",
      country_to: sessionStorage.getItem("country_to") || "US",
      city_to: sessionStorage.getItem("city_to") || "DENVER",
      wayOfFlight: sessionStorage.getItem("wayOfFlight"),
      flightClass: sessionStorage.getItem("travelClass"),
      departuredate: sessionStorage.getItem("departureDate") ? sessionStorage.getItem("departureDate") : format(new Date(), "yyyy-MM-dd"),
      returndate: sessionStorage.getItem("returnDate") ?  sessionStorage.getItem("returnDate") : format(
        new Date().setDate(new Date().getDate() + 1),
        "yyyy-MM-dd"
      ),
      t_class_selected: sessionStorage.getItem("travelClass") || "ECONOMY",
      t_class: [
        // { name: "BASIC ECONOMY" },
        { name: "Economy", value: "ECONOMY" },
        { name: "Premium Economy", value: "PREMIUM_ECONOMY" },
        { name: "Business", value: "BUSINESS" },
        { name: "First", value: "FIRST" },
      ],
      adult: sessionStorage.getItem("adults")
        ? parseInt(sessionStorage.getItem("adults"))
        : 1,
      child: sessionStorage.getItem("children")
        ? parseInt(sessionStorage.getItem("children"))
        : 0,
      infants: sessionStorage.getItem("infants")
        ? parseInt(sessionStorage.getItem("infants"))
        : 0,
      totalCounts:
        sessionStorage.getItem("adults") && sessionStorage.getItem("children")
          ? parseInt(sessionStorage.getItem("adults")) +
            parseInt(sessionStorage.getItem("children"))
          : 0,
      selectAllFlights: true,
      selectedAirlines: [],
      priceWithAirlines: [],
      //Stops
      stops: ["0", "1", "2+"],
      totalStopCount: [],
      updateStopCount: [],
      // Price
      minPrice: 0,
      maxPrice: 0,
      selectedMaxPrice: 0,
      deptValue: [0, 0],
      departureData: [],
      arrivValue: [0, 0],
      arrivalData: [],
      isDeptvalue: false,
      loading: true,
      updateModal: false,
      stopModal: false,
      timesModal: false,
      airlinesModal: false,
      priceModal: false,
      directStopWithAirlinePrice: [],
      onePlusWithAirlinePrice: [],
      flightAirlineCodes: [],
      bestFlight: [],
      cheapestFlight: [],
      fastestFlight: [],
      showTimeOutModal: false,
      timer: null,
      displayedFlights: 15,
      isActive: "cheapest",
      timerCount: 0,
      filterType: "",
      flightWisePrice: [],
    };

    this.inactivityTime = 300000;
    this.handleUserActivity = this.handleUserActivity.bind(this);
    this.closeTimeOutModal = this.closeTimeOutModal.bind(this);

    this.interval = setInterval(() => {
      this.setState((prevState) => ({
        progress: prevState.progress < 90 ? prevState.progress + 10 : 90,
        updatingFlights: true,
      }));
    }, 1000);

    fetch("https://test.api.amadeus.com/v1/security/oauth2/token", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
      }),
      body: "grant_type=client_credentials&client_id=lFD9B6KFg1j3kMKGLzFgadbAKFEzOwVw&client_secret=XmvZgKrjBJAiAMfE", // <-- Post parameters
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.access_token) {
          this.setState({ apitoken: res.access_token });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    var login_data = JSON.parse(sessionStorage.getItem('login'));
    fetch("https://api.ogule.com/api/frontend/MasterPricer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: login_data ? login_data.user._id : "",
        ip_address: sessionStorage.getItem("ipAddress"),
        origin: sessionStorage.getItem("originLocationCode"),
        destination: sessionStorage.getItem("destinationLocationCode"),
        startingDate: sessionStorage.getItem("departureDate") ? moment(sessionStorage.getItem("departureDate")).format(
          "DDMMYY"
        ) : moment(new Date()).format("DDMMYY"),
        returnDate: sessionStorage.getItem("returnDate") ? moment(sessionStorage.getItem("returnDate")).format(
          "DDMMYY"
        ) : moment(new Date().setDate(new Date().getDate() + 1)).format("DDMMYY"),
        adult: parseInt(sessionStorage.getItem("adults")),
        child: parseInt(sessionStorage.getItem("children")),
        infants: parseInt(sessionStorage.getItem("infants")),
        flightClass:
          sessionStorage.getItem("travelClass") === "BASIC ECONOMY"
            ? "ECONOMY"
            : sessionStorage.getItem("travelClass"),
        wayOfflight:
          sessionStorage.getItem("wayOfFlight") === "round_trip"
            ? "return"
            : "one",
        totalPassengers:
          sessionStorage.getItem("adults") && sessionStorage.getItem("children")
            ? parseInt(sessionStorage.getItem("adults")) +
              parseInt(sessionStorage.getItem("children"))
            : 0,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          clearInterval(this.interval);
          if (sessionStorage.getItem("wayOfFlight") === "round_trip") {
            const departureFromDest = res.flightIndex?.[0].groupOfFlights
              .map(
                (flight) =>
                  flight?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0]
              )
              .sort((a, b) => new Date(a) - new Date(b));
            const departureFromOrigiin = res.flightIndex?.[1].groupOfFlights
              .map(
                (flight) =>
                  flight?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0]
              )
              .sort((a, b) => new Date(a) - new Date(b));
            const stopWisePrice = [];
            const flightWisePrice = res.combinationsArray
              ?.map((data) => {
                const flight1 =
                  res.flightIndex?.[0]?.groupOfFlights[data?.outboundFlight];
                const flight2 =
                  res.flightIndex?.[1]?.groupOfFlights[data?.inboundFlight];
                const f1Airline =
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.companyId?.[0]?.customCarriers?.airline_name;
                const f2Airline =
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.companyId?.[0]?.customCarriers?.airline_name;

                const stopsMinPrice = {};
                stopsMinPrice.airlineName =
                  f1Airline === f2Airline ? f1Airline : "Mixed Airline";
                stopsMinPrice.layovers = data.layovers;
                stopsMinPrice.price = data.customPrice / this.state.totalCounts;
                stopsMinPrice.airlineCode =
                  f1Airline === f2Airline
                    ? data?.inboundFlightAirline
                    : "MixedAirline123";
                updateArray(stopWisePrice, stopsMinPrice);
                const totalPrice = parseFloat(data?.customPrice) / this.state.totalCounts;
                const finalPrice = parseFloat(data?.customPrice);
                const adultPrice = parseFloat(data?.ADT);
                const childPrice = data?.CNN ? parseFloat(data?.CNN) : 0;
                const flight1Duration = flightTotalDuration(
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const flight2Duration = flightTotalDuration(
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const totalDuration = flight1Duration + flight2Duration;
                return {
                  flight1,
                  flight2,
                  totalPrice,
                  finalPrice,
                  adultPrice,
                  childPrice,
                  markUp: data?.markUp,
                  totalDuration,
                };
              })
              .sort((a, b) => a.totalPrice - b.totalPrice);
            const allAirlines = flightWisePrice?.map((data) => {
              const flight1 =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_name;
              const flight2 =
                data?.flight2?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_name;
              const totalPrice = parseFloat(data?.totalPrice);
              const airlineCode =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_code;
              const image =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.image;
              return {
                airline: flight1 === flight2 ? flight1 : "Mixed Airline",
                totalPrice,
                airline_code:
                  flight1 === flight2 ? airlineCode : "MixedAirline123",
                image: flight1 === flight2 ? image : "",
              };
            });
            const airlineMinPrices = allAirlines?.reduce((acc, curr) => {
              const { airline, totalPrice, airline_code, image } = curr;
              if (!acc[airline] || totalPrice < acc[airline]) {
                acc[airline] = {
                  totalPrice: totalPrice.toFixed(2),
                  airline_code,
                  image,
                };
              }
              return acc;
            }, {});
            const uniqueAirlines = Object.keys(airlineMinPrices).map(
              (airline) => ({
                airline_name: airline,
                totalPrice: airlineMinPrices[airline].totalPrice,
                airline_code: airlineMinPrices[airline].airline_code,
                image: airlineMinPrices[airline].image,
              })
            );
            const cheapBestFastFlight = flightWisePrice?.map(
              ({ flight1, flight2, totalPrice }) => {
                const flight1Duration = flightTotalDuration(
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const flight2Duration = flightTotalDuration(
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const totalDuration = flight1Duration + flight2Duration;
                const priceWithduration = totalPrice * totalDuration;
                return {
                  duration: totalDuration,
                  price: totalPrice,
                  priceWithduration,
                };
              }
            );
            const checkCheapestFlight = cheapBestFastFlight.reduce(
              (min, pair) => {
                return pair.price < min.price ? pair : min;
              },
              cheapBestFastFlight[0]
            );

            const checkFastestFlight = cheapBestFastFlight.reduce(
              (min, pair) => {
                return pair.duration < min.duration ? pair : min;
              },
              cheapBestFastFlight[0]
            );

            const checkBestFlight = cheapBestFastFlight.reduce((min, pair) => {
              return pair.priceWithduration < min.priceWithduration
                ? pair
                : min;
            }, cheapBestFastFlight[0]);
            this.setState({
              isResponse: true,
              flightslist: res.flightIndex,
              filteredArray: flightWisePrice,
              priceWithAirlines: uniqueAirlines,
              selectedAirlines: res.selectedAirline,
              totalStopCount: res.totalStopCount,
              updateStopCount: res.totalStopCount,
              flightWisePrice: res.combinationsArray,
              flightsData: flightWisePrice,
              minPrice: flightWisePrice?.[0]?.totalPrice,
              maxPrice:
                flightWisePrice[flightWisePrice?.length - 1]?.totalPrice,
              selectedMaxPrice:
                flightWisePrice[flightWisePrice?.length - 1]?.totalPrice,
              updatingFlights: false,
              departureData: departureFromDest,
              arrivalData: departureFromOrigiin,
              deptValue: [
                parseTimeValue(departureFromDest[0]),
                parseTimeValue(departureFromDest[departureFromDest.length - 1]),
              ],
              arrivValue: [
                parseTimeValue(departureFromOrigiin?.[0]),
                parseTimeValue(
                  departureFromOrigiin[departureFromOrigiin?.length - 1]
                ),
              ],
              flightAirlineCodes: uniqueAirlines?.map(
                (data) => data.airline_code
              ),
              directStopWithAirlinePrice: stopWisePrice.filter(
                (data) => data.layovers === 0
              ),
              onePlusWithAirlinePrice: stopWisePrice.filter(
                (data) => data.layovers === 1
              ),
              bestFlight: checkBestFlight,
              cheapestFlight: checkCheapestFlight,
              fastestFlight: checkFastestFlight,
              loading: false,
            });
          } else {
            const departureFromDest = res.flightIndex?.[0].groupOfFlights
              .map(
                (flight) =>
                  flight?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0]
              )
              .sort((a, b) => new Date(a) - new Date(b));
            const stopWisePrice = [];
            const flightWisePrice = res.combinationsArray
              ?.map((data) => {
                const flight1 =
                  res.flightIndex?.[0]?.groupOfFlights[data?.outboundFlight];
                const f1Airline =
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.companyId?.[0]?.customCarriers?.airline_name;
                const stopsMinPrice = {};
                stopsMinPrice.airlineName = f1Airline;
                stopsMinPrice.layovers = data.layovers;
                stopsMinPrice.price = data.customPrice / this.state.totalCounts;
                stopsMinPrice.airlineCode = data?.outboundFlightAirline;
                updateArray(stopWisePrice, stopsMinPrice);
                const totalPrice = parseFloat(data?.customPrice) / this.state.totalCounts;
                const finalPrice = parseFloat(data?.customPrice);
                const adultPrice = parseFloat(data?.ADT);
                const childPrice = data?.CNN ? parseFloat(data?.CNN) : 0;
                const flight1Duration = flightTotalDuration(
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const totalDuration = flight1Duration;
                return {
                  flight1,
                  totalPrice,
                  finalPrice,
                  adultPrice,
                  childPrice,
                  markUp: data?.markUp,
                  totalDuration
                };
              })
              .sort((a, b) => a.totalPrice - b.totalPrice);
            const allAirlines = flightWisePrice?.map((data) => {
              const flight1 =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_name;
              const totalPrice = parseFloat(data?.totalPrice);
              const airlineCode =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_code;
              const image =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.image;

              return {
                airline: flight1,
                totalPrice,
                airline_code: airlineCode,
                image,
              };
            });
            const airlineMinPrices = allAirlines?.reduce((acc, curr) => {
              const { airline, totalPrice, airline_code, image } = curr;
              if (!acc[airline] || totalPrice < acc[airline]) {
                acc[airline] = {
                  totalPrice: totalPrice.toFixed(2),
                  airline_code,
                  image,
                };
              }
              return acc;
            }, {});
            const uniqueAirlines = Object.keys(airlineMinPrices).map(
              (airline) => ({
                airline_name: airline,
                totalPrice: airlineMinPrices[airline].totalPrice,
                airline_code: airlineMinPrices[airline].airline_code,
                image: airlineMinPrices[airline].image,
              })
            );
            const cheapBestFastFlight = flightWisePrice?.map(
              ({ flight1, totalPrice }) => {
                const flight1Duration = flightTotalDuration(
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const totalDuration = flight1Duration;
                const priceWithduration = totalPrice * totalDuration;
                return {
                  duration: totalDuration,
                  price: totalPrice,
                  priceWithduration,
                };
              }
            );
            const checkCheapestFlight = cheapBestFastFlight.reduce(
              (min, pair) => {
                return pair.price < min.price ? pair : min;
              },
              cheapBestFastFlight[0]
            );
            const checkFastestFlight = cheapBestFastFlight.reduce(
              (min, pair) => {
                return pair.duration < min.duration ? pair : min;
              },
              cheapBestFastFlight[0]
            );
            const checkBestFlight = cheapBestFastFlight.reduce((min, pair) => {
              return pair.priceWithduration < min.priceWithduration
                ? pair
                : min;
            }, cheapBestFastFlight[0]);
            this.setState({
              isResponse: true,
              flightslist: res.flightIndex,
              filteredArray: flightWisePrice,
              priceWithAirlines: uniqueAirlines,
              selectedAirlines: res.selectedAirline,
              totalStopCount: res.totalStopCount,
              updateStopCount: res.totalStopCount,
              flightsData: flightWisePrice,
              flightWisePrice: res.combinationsArray,
              minPrice: flightWisePrice?.[0]?.totalPrice,
              maxPrice:
                flightWisePrice[flightWisePrice?.length - 1]?.totalPrice,
              selectedMaxPrice:
                flightWisePrice[flightWisePrice?.length - 1]?.totalPrice,
              updatingFlights: false,
              departureData: departureFromDest,
              deptValue: [
                parseTimeValue(departureFromDest[0]),
                parseTimeValue(departureFromDest[departureFromDest.length - 1]),
              ],
              flightAirlineCodes: uniqueAirlines?.map(
                (data) => data.airline_code
              ),
              directStopWithAirlinePrice: stopWisePrice.filter(
                (data) => data.layovers === 0
              ),
              onePlusWithAirlinePrice: stopWisePrice.filter(
                (data) => data.layovers === 1
              ),
              cheapestFlight: checkCheapestFlight,
              fastestFlight: checkFastestFlight,
              bestFlight: checkBestFlight,
              loading: false,
            });
          }
        } else {
          alert("Username or password is incorrect");
          this.setState({
            flightslist: [],
            filteredArray: [],
          });
        }
      })
      .catch((error) => {
        clearInterval(this.interval);
        this.setState({
          isResponse: true,
          updatingFlights: false,
          loading: false,
        });
        console.error(error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.adult !== this.state.adult ||
      prevState.child !== this.state.child ||
      prevState.infants !== this.state.infants
    ) {
      this.setState({
        totalCounts: this.state.adult + this.state.child + this.state.infants,
      });
    }
  }

  searchfrom(val) {
    if(val === this.state.iatacode_to){
      alert('origin and destination locations cannot be the same.');
      this.setState({
        iatacode_from: "",
        airport_name_from: "",
        country_from: "",
        city_from: ""
      })
      return;
    }
    this.setState({ iatacode_from: val });
    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + this.state.apitoken,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          var data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === res.data[i].iataCode) {
              sessionStorage.setItem("iatacode_from", res.data[i].iataCode);
              sessionStorage.setItem("airport_name_from", res.data[i].name);
              sessionStorage.setItem(
                "country_from",
                res.data[i].address.countryCode
              );
              sessionStorage.setItem("city_from", res.data[i].address.cityName);
              this.setState({
                iatacode_from: res.data[i].iataCode,
                airport_name_from: res.data[i].name,
                country_from: res.data[i].address.countryCode,
                city_from: res.data[i].address.cityName,
              });
            } else {
            }
          }
          console.warn(res);
          this.setState({
            fromdata: res.data,
          });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  swapValues = () => {
    this.setState((prevState) => ({
      city_from: prevState.city_to,
      iatacode_from: prevState.iatacode_to,
      airport_name_from: prevState.airport_name_to,
      country_from: prevState.country_to,
      city_to: prevState.city_from,
      iatacode_to: prevState.iatacode_from,
      airport_name_to: prevState.airport_name_from,
      country_to: prevState.country_from,
    }));
  };

  searchto(val) {
    if(val === this.state.iatacode_from){
      alert('origin and destination locations cannot be the same.');
      this.setState({
        iatacode_to: "",
        airport_name_to: "",
        country_to: "",
        city_to: ""
      })
      return;
    }
    this.setState({ iatacode_to: val });
    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + this.state.apitoken,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          var data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === res.data[i].iataCode) {
              sessionStorage.setItem("iatacode_to", res.data[i].iataCode);
              sessionStorage.setItem("airport_name_to", res.data[i].name);
              sessionStorage.setItem(
                "country_to",
                res.data[i].address.countryCode
              );
              sessionStorage.setItem("city_to", res.data[i].address.cityName);
              this.setState({
                iatacode_to: res.data[i].iataCode,
                airport_name_to: res.data[i].name,
                country_to: res.data[i].address.countryCode,
                city_to: res.data[i].address.cityName,
              });
            } else {
            }
          }
          console.warn(res);
          this.setState({
            todata: res.data,
          });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handledeparturedate = (date) => {
    if (!date) {
      return null;
    }
    const formattedDate = format(date, "yyyy-MM-dd");
    sessionStorage.setItem("departureDate", format(date, "yyyy-MM-dd"));
    sessionStorage.setItem(
      "mobile_departureDate_oneway",
      format(date, "MMM dd")
    );
    sessionStorage.setItem(
      "mobile_departureDate_oneway_day",
      format(date, "iii")
    );
    this.setState({
      departuredate: formattedDate,
    });
    const returnDate = new Date(formattedDate);
    this.handlereturndate(returnDate.setDate(returnDate.getDate() + 1), "true");
  };

  handlereturndate = (date) => {
    if (!date) {
      return null;
    }
    const formattedDate = format(date, "yyyy-MM-dd");
    sessionStorage.setItem("returnDate", format(date, "yyyy-MM-dd"));
    sessionStorage.setItem("mobile_returnDate_oneway", format(date, "MMM dd"));
    sessionStorage.setItem("mobile_returnDate_oneway_day", format(date, "iii"));
    this.setState({
      returndate: formattedDate,
    });
  };

  // Filter Search button call
  handleUpdate = () => {
    sessionStorage.setItem("departureDate", this.state.departuredate);
    sessionStorage.setItem("returnDate", this.state.returndate);
    sessionStorage.setItem("originLocationCode", this.state.iatacode_from);
    sessionStorage.setItem("iatacode_from", this.state.iatacode_from);
    sessionStorage.setItem("airport_name_from", this.state.airport_name_from);
    sessionStorage.setItem("country_from", this.state.country_from);
    sessionStorage.setItem("city_from", this.state.city_from);
    sessionStorage.setItem("destinationLocationCode", this.state.iatacode_to);
    sessionStorage.setItem("iatacode_to", this.state.iatacode_to);
    sessionStorage.setItem("airport_name_to", this.state.airport_name_to);
    sessionStorage.setItem("country_to", this.state.country_to);
    sessionStorage.setItem("city_to", this.state.city_to);
    sessionStorage.setItem("adults", this.state.adult);
    sessionStorage.setItem("children", this.state.child);
    sessionStorage.setItem("infants", this.state.infants);
    sessionStorage.setItem("travelClass", this.state.t_class_selected);
    sessionStorage.setItem("wayOfFlight", this.state.wayOfFlight);
    sessionStorage.setItem("totalCounts", this.state.totalCounts);
    this.setState({ updatingFlights: true });
    var login_data = JSON.parse(sessionStorage.getItem('login'));
    fetch("https://api.ogule.com/api/frontend/MasterPricer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: login_data ? login_data.user._id : "",
        ip_address: sessionStorage.getItem("ipAddress"),
        origin: this.state.iatacode_from,
        destination: this.state.iatacode_to,
        startingDate: moment(this.state.departuredate).format("DDMMYY"),
        returnDate: moment(this.state.returndate).format("DDMMYY"),
        adult: this.state.adult,
        child: this.state.child,
        infants: this.state.infants,
        flightClass:
          this.state.t_class_selected === "BASIC ECONOMY"
            ? "ECONOMY"
            : this.state.t_class_selected,
        wayOfflight: this.state.wayOfFlight === "round_trip" ? "return" : "one",
        totalPassengers: this.state.adult + this.state.child,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          clearInterval(this.interval);
          if (sessionStorage.getItem("wayOfFlight") === "round_trip") {
            const departureFromDest = res.flightIndex?.[0].groupOfFlights
              .map(
                (flight) =>
                  flight?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0]
              )
              .sort((a, b) => new Date(a) - new Date(b));
            const departureFromOrigiin = res.flightIndex?.[1].groupOfFlights
              .map(
                (flight) =>
                  flight?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0]
              )
              .sort((a, b) => new Date(a) - new Date(b));
            const stopWisePrice = [];
            const flightWisePrice = res.combinationsArray
              ?.map((data) => {
                const flight1 =
                  res.flightIndex?.[0]?.groupOfFlights[data?.outboundFlight];
                const flight2 =
                  res.flightIndex?.[1]?.groupOfFlights[data?.inboundFlight];
                const f1Airline =
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.companyId?.[0]?.customCarriers?.airline_name;
                const f2Airline =
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.companyId?.[0]?.customCarriers?.airline_name;
                const stopsMinPrice = {};
                stopsMinPrice.airlineName =
                  f1Airline === f2Airline ? f1Airline : "Mixed Airline";
                stopsMinPrice.layovers = data.layovers;
                stopsMinPrice.price = data.customPrice / this.state.totalCounts;
                stopsMinPrice.airlineCode =
                  f1Airline === f2Airline
                    ? data?.inboundFlightAirline
                    : "MixedAirline123";
                updateArray(stopWisePrice, stopsMinPrice);
                const totalPrice = parseFloat(data?.customPrice) / this.state.totalCounts;
                const finalPrice = parseFloat(data?.customPrice);
                const adultPrice = parseFloat(data?.ADT);
                const childPrice = data?.CNN ? parseFloat(data?.CNN) : 0;
                const flight1Duration = flightTotalDuration(
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const flight2Duration = flightTotalDuration(
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const totalDuration = flight1Duration + flight2Duration;
                return {
                  flight1,
                  flight2,
                  totalPrice,
                  finalPrice,
                  adultPrice,
                  childPrice,
                  markUp: data?.markUp,
                  totalDuration
                };
              })
              .sort((a, b) => a.totalPrice - b.totalPrice);

            const allAirlines = flightWisePrice?.map((data) => {
              const flight1 =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_name;
              const flight2 =
                data?.flight2?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_name;
              const totalPrice = parseFloat(data?.totalPrice);
              const airlineCode =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_code;
              const image =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.image;
              return {
                airline: flight1 === flight2 ? flight1 : "Mixed Airline",
                totalPrice,
                airline_code:
                  flight1 === flight2 ? airlineCode : "MixedAirline123",
                image: flight1 === flight2 ? image : "",
              };
            });
            const airlineMinPrices = allAirlines?.reduce((acc, curr) => {
              const { airline, totalPrice, airline_code, image } = curr;
              if (!acc[airline] || totalPrice < acc[airline]) {
                acc[airline] = {
                  totalPrice: totalPrice.toFixed(2),
                  airline_code,
                  image,
                };
              }
              return acc;
            }, {});
            const uniqueAirlines = Object.keys(airlineMinPrices).map(
              (airline) => ({
                airline_name: airline,
                totalPrice: airlineMinPrices[airline].totalPrice,
                airline_code: airlineMinPrices[airline].airline_code,
                image: airlineMinPrices[airline].image,
              })
            );

            const cheapBestFastFlight = flightWisePrice?.map(
              ({ flight1, flight2, totalPrice }) => {
                const flight1Duration = flightTotalDuration(
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const flight2Duration = flightTotalDuration(
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight2?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const totalDuration = flight1Duration + flight2Duration;
                const priceWithduration = totalPrice * totalDuration;
                return {
                  duration: totalDuration,
                  price: totalPrice,
                  priceWithduration,
                };
              }
            );
            const checkCheapestFlight = cheapBestFastFlight.reduce(
              (min, pair) => {
                return pair.price < min.price ? pair : min;
              },
              cheapBestFastFlight[0]
            );

            const checkFastestFlight = cheapBestFastFlight.reduce(
              (min, pair) => {
                return pair.duration < min.duration ? pair : min;
              },
              cheapBestFastFlight[0]
            );

            const checkBestFlight = cheapBestFastFlight.reduce((min, pair) => {
              return pair.priceWithduration < min.priceWithduration
                ? pair
                : min;
            }, cheapBestFastFlight[0]);
            this.setState({
              isResponse: true,
              updatingFlights: false,
              flightslist: res.flightIndex,
              filteredArray: flightWisePrice,
              priceWithAirlines: uniqueAirlines,
              selectedAirlines: res.selectedAirline,
              totalStopCount: res.totalStopCount,
              updateStopCount: res.totalStopCount,
              flightWisePrice: res.combinationsArray,
              flightsData: flightWisePrice,
              minPrice: flightWisePrice?.[0]?.totalPrice,
              maxPrice:
                flightWisePrice[flightWisePrice?.length - 1]?.totalPrice,
              selectedMaxPrice:
                flightWisePrice[flightWisePrice?.length - 1]?.totalPrice,
              departureData: departureFromDest,
              arrivalData: departureFromOrigiin,
              deptValue: [
                parseTimeValue(departureFromDest[0]),
                parseTimeValue(departureFromDest[departureFromDest.length - 1]),
              ],
              arrivValue: [
                parseTimeValue(departureFromOrigiin?.[0]),
                parseTimeValue(
                  departureFromOrigiin[departureFromOrigiin?.length - 1]
                ),
              ],
              flightAirlineCodes: uniqueAirlines?.map(
                (data) => data.airline_code
              ),
              directStopWithAirlinePrice: stopWisePrice.filter(
                (data) => data.layovers === 0
              ),
              onePlusWithAirlinePrice: stopWisePrice.filter(
                (data) => data.layovers === 1
              ),
              cheapestFlight: checkCheapestFlight,
              fastestFlight: checkFastestFlight,
              bestFlight: checkBestFlight,
            });
          } else {
            const departureFromDest = res.flightIndex?.[0].groupOfFlights
              .map(
                (flight) =>
                  flight?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0]
              )
              .sort((a, b) => new Date(a) - new Date(b));
            const stopWisePrice = [];
            const flightWisePrice = res.combinationsArray
              ?.map((data) => {
                const flight1 =
                  res.flightIndex?.[0]?.groupOfFlights[data?.outboundFlight];
                const f1Airline =
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.companyId?.[0]?.customCarriers?.airline_name;
                const stopsMinPrice = {};
                stopsMinPrice.airlineName = f1Airline;
                stopsMinPrice.layovers = data.layovers;
                stopsMinPrice.price = data.customPrice / this.state.totalCounts;
                stopsMinPrice.airlineCode = data?.outboundFlightAirline;
                updateArray(stopWisePrice, stopsMinPrice);
                const totalPrice = parseFloat(data?.customPrice) / this.state.totalCounts;
                const finalPrice = parseFloat(data?.customPrice);
                const adultPrice = parseFloat(data?.ADT);
                const childPrice = data?.CNN ? parseFloat(data?.CNN) : 0;
                const flight1Duration = flightTotalDuration(
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const totalDuration = flight1Duration;
                return {
                  flight1,
                  totalPrice,
                  finalPrice,
                  adultPrice,
                  childPrice,
                  markUp: data?.markUp,
                  totalDuration
                };
              })
              .sort((a, b) => a.totalPrice - b.totalPrice);
            const allAirlines = flightWisePrice?.map((data) => {
              const flight1 =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_name;
              const totalPrice = parseFloat(data?.totalPrice);
              const airlineCode =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.airline_code;
              const image =
                data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.companyId?.[0]?.customCarriers?.image;

              return {
                airline: flight1,
                totalPrice,
                airline_code: airlineCode,
                image,
              };
            });
            const airlineMinPrices = allAirlines?.reduce((acc, curr) => {
              const { airline, totalPrice, airline_code, image } = curr;
              if (!acc[airline] || totalPrice < acc[airline]) {
                acc[airline] = {
                  totalPrice: totalPrice.toFixed(2),
                  airline_code,
                  image,
                };
              }
              return acc;
            }, {});
            const uniqueAirlines = Object.keys(airlineMinPrices).map(
              (airline) => ({
                airline_name: airline,
                totalPrice: airlineMinPrices[airline].totalPrice,
                airline_code: airlineMinPrices[airline].airline_code,
                image: airlineMinPrices[airline].image,
              })
            );
            const cheapBestFastFlight = flightWisePrice?.map(
              ({ flight1, totalPrice }) => {
                const flight1Duration = flightTotalDuration(
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                  flight1?.flightDetails?.[0]?.flightInformation?.[0]
                    ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.timeOfArrival?.[0],
                  flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                    ?.flightInformation?.[0]?.productDateTime?.[0]
                    ?.dateOfArrival?.[0]
                );
                const totalDuration = flight1Duration;
                const priceWithduration = totalPrice * totalDuration;
                return {
                  duration: totalDuration,
                  price: totalPrice,
                  priceWithduration,
                };
              }
            );
            const checkCheapestFlight = cheapBestFastFlight.reduce(
              (min, pair) => {
                return pair.price < min.price ? pair : min;
              },
              cheapBestFastFlight[0]
            );
            const checkFastestFlight = cheapBestFastFlight.reduce(
              (min, pair) => {
                return pair.duration < min.duration ? pair : min;
              },
              cheapBestFastFlight[0]
            );
            const checkBestFlight = cheapBestFastFlight.reduce((min, pair) => {
              return pair.priceWithduration < min.priceWithduration
                ? pair
                : min;
            }, cheapBestFastFlight[0]);
            this.setState({
              isResponse: true,
              updatingFlights: false,
              flightslist: res.flightIndex,
              filteredArray: flightWisePrice,
              priceWithAirlines: uniqueAirlines,
              selectedAirlines: res.selectedAirline,
              totalStopCount: res.totalStopCount,
              updateStopCount: res.totalStopCount,
              flightsData: flightWisePrice,
              flightWisePrice: res.combinationsArray,
              minPrice: flightWisePrice?.[0]?.totalPrice,
              maxPrice:
                flightWisePrice[flightWisePrice?.length - 1]?.totalPrice,
              selectedMaxPrice:
                flightWisePrice[flightWisePrice?.length - 1]?.totalPrice,
              departureData: departureFromDest,
              deptValue: [
                parseTimeValue(departureFromDest[0]),
                parseTimeValue(departureFromDest[departureFromDest.length - 1]),
              ],
              flightAirlineCodes: uniqueAirlines?.map(
                (data) => data.airline_code
              ),
              directStopWithAirlinePrice: stopWisePrice.filter(
                (data) => data.layovers === 0
              ),
              onePlusWithAirlinePrice: stopWisePrice.filter(
                (data) => data.layovers === 1
              ),
              cheapestFlight: checkCheapestFlight,
              fastestFlight: checkFastestFlight,
              bestFlight: checkBestFlight,
            });
          }
        } else {
          alert("Username or password is incorrect");
        }
      })
      .catch((error) => {
        clearInterval(this.interval);
        this.setState({
          isResponse: true,
          updatingFlights: false,
        });
        console.error(error);
      });
  };

  formatTimeValue = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  handleFilterFlights = () => {
    let {
      deptValue,
      arrivValue,
      selectedAirlines,
      updateStopCount,
      selectedMaxPrice,
      flightsData,
    } = this.state;

    if (selectedAirlines.length === 0) {
      this.setState({ filteredArray: [] });
      return;
    }

    if (sessionStorage.getItem("wayOfFlight") === "round_trip") {
      let filteredArray = flightsData?.filter((flight) => {
        const departureTime = parseTimeValue(
          flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.timeOfDeparture?.[0]
        );
        const arrivalTime = parseTimeValue(
          flight?.flight2?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.timeOfDeparture?.[0]
        );
        const selectedMinTime = deptValue[0];
        const selectedMaxTime = deptValue[1];
        const selectedMinArrivalTime = arrivValue[0];
        const selectedMaxArrivalTime = arrivValue[1];
        return (
          departureTime >= selectedMinTime &&
          departureTime <= selectedMaxTime &&
          arrivalTime >= selectedMinArrivalTime &&
          arrivalTime <= selectedMaxArrivalTime
        );
      });
      filteredArray = filteredArray?.filter((flight) => {
        return this.state.filterType === undefined
          ? flight?.totalPrice >= selectedMaxPrice
          : flight?.totalPrice <= selectedMaxPrice;
      });
      filteredArray = filteredArray.filter((flight) => {
        return (
          flight?.flight1?.flightDetails.some((detail) =>
            detail.flightInformation.some((info) =>
              info.companyId.some((company) =>
                selectedAirlines.includes(company.marketingCarrier?.[0])
              )
            )
          ) &&
          flight?.flight2?.flightDetails.some((detail) =>
            detail.flightInformation.some((info) =>
              info.companyId.some((company) =>
                selectedAirlines.includes(company.marketingCarrier?.[0])
              )
            )
          )
        );
      });

      filteredArray = filteredArray.filter((flight) => {
        if (updateStopCount.includes("2+")) {
          return (
            ((updateStopCount.includes(
              (flight?.flight1?.flightDetails?.length - 1).toString()
            ) ||
              flight?.flight1?.flightDetails?.length - 1 > 1) &&
              updateStopCount.includes(
                (flight?.flight2?.flightDetails?.length - 1).toString()
              )) ||
            flight?.flight2?.flightDetails?.length - 1 > 1
          );
        } else {
          return (
            updateStopCount.includes(
              (flight?.flight1?.flightDetails?.length - 1).toString()
            ) &&
            updateStopCount.includes(
              (flight?.flight2?.flightDetails?.length - 1).toString()
            )
          );
        }
      });
      this.setState({ filteredArray });
    } else {
      let filteredArray = flightsData?.filter((flight) => {
        const departureTime = parseTimeValue(
          flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.timeOfDeparture?.[0]
        );
        const selectedMinTime = deptValue[0];
        const selectedMaxTime = deptValue[1];
        return (
          departureTime >= selectedMinTime && departureTime <= selectedMaxTime
        );
      });

      filteredArray = filteredArray?.filter((flightGroup) => {
        return flightGroup?.flight1?.flightDetails?.some((detail) =>
          detail.flightInformation.some((info) =>
            info.companyId.some((company) =>
              selectedAirlines.includes(company.marketingCarrier?.[0])
            )
          )
        );
      });

      filteredArray = filteredArray?.filter((flight) => {
        if (updateStopCount.includes("2+")) {
          return (
            updateStopCount.includes(
              (flight?.flight1?.flightDetails?.length - 1).toString()
            ) && flight?.flight1?.flightDetails?.length - 1 > 1
          );
        } else {
          return updateStopCount.includes(
            (flight.flight1?.flightDetails?.length - 1).toString()
          );
        }
      });

      filteredArray = filteredArray?.filter((flight) => {
        return this.state.filterType === undefined
          ? selectedMaxPrice <= flight?.totalPrice
          : selectedMaxPrice >= flight?.totalPrice;
      });

      this.setState({
        filteredArray,
      });
    }
  };

  handleChangeDeptValue = (event, newValue) => {
    this.setState({ deptValue: newValue }, () => {
      this.handleFilterFlights();
    });
  };

  handleChangeArrivValue = (event, newValue) => {
    this.setState({ arrivValue: newValue }, () => {
      this.handleFilterFlights();
    });
  };

  handleCheckBoxChange = (name) => {
    if (this.state.updateStopCount?.includes(name)) {
      this.setState(
        {
          updateStopCount: this.state.updateStopCount.filter(
            (dt) => dt !== name
          ),
        },
        () => {
          this.handleFilterFlights();
        }
      );
    } else {
      const updateCount = this.state.updateStopCount;
      updateCount.push(name);
      this.setState({ updateStopCount: updateCount }, () => {
        this.handleFilterFlights();
      });
    }
  };

  handleChangePrice = (event, newValue, filterType) => {
    if (newValue !== undefined) {
      this.setState({ selectedMaxPrice: newValue, filterType }, () => {
        this.handleFilterFlights();
      });
    } else {
      const clickedValue = event.target.getAttribute("data-value");
      if (clickedValue) {
        const parsedValue = parseFloat(clickedValue);
        this.setState(
          { selectedMaxPrice: parsedValue, filterApplied: true, filterType },
          () => {
            this.handleFilterFlights();
          }
        );
      }
    }
  };

  handleCleaPriceFilter = () => {
    this.setState({ filterApplied: false, filterType: "" }, () => {
      this.handleUpdate();
    });
  };

  handleSelectAll = () => {
    this.setState(
      {
        selectAllFlights: true,
        selectedAirlines: this.state.priceWithAirlines.map(
          (d) => d.airline_code
        ),
      },
      () => {
        this.handleFilterFlights();
      }
    );
  };

  handleClearAll = () => {
    this.setState(
      {
        selectAllFlights: false,
        selectedAirlines: [],
      },
      () => {
        this.handleFilterFlights();
      }
    );
  };

  handleAirlineCheckboxChange = (airline) => {
    this.setState(
      (prevState) => {
        let updatedAirlines;
        if (prevState.selectedAirlines?.includes(airline)) {
          updatedAirlines = prevState.selectedAirlines.filter(
            (selectedAirline) => selectedAirline !== airline
          );
        } else {
          updatedAirlines = [...prevState.selectedAirlines, airline];
        }
        if (
          updatedAirlines?.includes("MixedAirline123") &&
          updatedAirlines?.length === 1
        ) {
          updatedAirlines = [];
        }
        return { selectedAirlines: updatedAirlines };
      },
      () => {
        this.handleFilterFlights();
      }
    );
  };

  find_flights() {
    window.location.reload(false);
  }

  handleClose = () => {
    this.setState({ setShow: false, setShow_from: false, setShow_to: false });
  };

  submit(item) {
    sessionStorage.setItem("booking_details", JSON.stringify(item));
    sessionStorage.setItem("booking_login", "yes");
    this.props.history.push("checkoutnew");
  }

  search_filters(e) {
    // sessionStorage.setItem("wayOfFlight", e);
    this.setState({
      wayOfFlight: e,
    });
  }

  backToHome() {
    this.props.history.push("/");
  }

  toggleModal = () => {
    this.setState({ updateModal: !this.state.updateModal });
  };

  toggleTimesModal = () => {
    this.setState({ timesModal: !this.state.timesModal });
  };
  togglePriceModal = () => {
    this.setState({ priceModal: !this.state.priceModal });
  };
  toggleStopModal = () => {
    this.setState({ stopModal: !this.state.stopModal });
  };
  toggleAirlinesModal = () => {
    this.setState({ airlinesModal: !this.state.airlinesModal });
  };

  convertFlightDuration(duration) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${hours}h ${minutes}m`;
  }

  handleResize = () => {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  // Inactivity Modal
  componentDidMount() {
    const events = ["mousemove", "mousedown", "keydown", "touchstart"];
    events.forEach((event) =>
      window.addEventListener(event, this.handleUserActivity)
    );

    this.handleUserActivity();
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }

  componentWillUnmount() {
    const events = ["mousemove", "mousedown", "keydown", "touchstart"];
    events.forEach((event) =>
      window.removeEventListener(event, this.handleUserActivity)
    );
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }

  handleUserActivity() {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }

    if (this.state.timerCount < 2) {
      const timerDuration = this.state.timerCount === 0 ? 15000 : 30000; // 15 seconds for the first time, 30 seconds for the second time
      this.setState({
        timer: setTimeout(() => {
          this.setState({ showTimeOutModal: true });
        }, timerDuration),
      });
    } else if (!this.state.showTimeOutModal) {
      this.setState({
        timer: setTimeout(() => {
          this.setState({ showTimeOutModal: true });
        }, this.inactivityTime),
      });
    }
  }

  closeTimeOutModal() {
    this.setState(
      (prevState) => ({
        showTimeOutModal: false,
        timerCount: prevState.timerCount + 1,
      }),
      this.handleUserActivity
    );
  }

  handleShowMore = () => {
    this.setState((prevState) => ({
      displayedFlights: prevState.displayedFlights + 20,
    }));
  };

  setIsActive = (option) => {
    this.setState({ isActive: option });
  };

  render() {
    const {
      filteredArray,
      cheapestFlight,
      bestFlight,
      fastestFlight,
      displayedFlights,
      isActive,
      flightsData,
    } = this.state;
    let checkCheapestFlight = {};
    let checkFastestFlight = {};
    let checkBestFlight = {};
    let checkCheapestFlightOneWay = {};
    let checkFastestFlightOneWay = {};
    let checkBestFlightOneWay = {};

    const flightPairs = filteredArray
      ?.map((data) => {
        const flight1 = data?.flight1;
        const flight2 = data?.flight2;
        const totalPrice = parseFloat(data?.totalPrice);
        const finalPrice = parseFloat(data?.finalPrice);
        const adultPrice = parseFloat(data?.adultPrice);
        const childPrice = data?.childPrice ? parseFloat(data?.childPrice) : 0;
        const flight1Duration = flightTotalDuration(
          flight1?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.timeOfDeparture?.[0],
          flight1?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.dateOfDeparture?.[0],
          flight1?.flightDetails[flight1?.flightDetails?.length - 1]
            ?.flightInformation?.[0]?.productDateTime?.[0]?.timeOfArrival?.[0],
          flight1?.flightDetails[flight1?.flightDetails?.length - 1]
            ?.flightInformation?.[0]?.productDateTime?.[0]?.dateOfArrival?.[0]
        );
        const flight2Duration = flightTotalDuration(
          flight2?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.timeOfDeparture?.[0],
          flight2?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.dateOfDeparture?.[0],
          flight2?.flightDetails[flight2?.flightDetails?.length - 1]
            ?.flightInformation?.[0]?.productDateTime?.[0]?.timeOfArrival?.[0],
          flight2?.flightDetails[flight2?.flightDetails?.length - 1]
            ?.flightInformation?.[0]?.productDateTime?.[0]?.dateOfArrival?.[0]
        );
        const totalDuration = flight1Duration + flight2Duration;
        const priceWithduration = totalPrice * totalDuration;
        if (!this.state.selectedAirlines?.includes("MixedAirline123")) {
          if (
            flight1?.flightDetails?.[0]?.flightInformation?.[0]?.companyId?.[0]
              ?.customCarriers?.airline_code ===
            flight2?.flightDetails?.[0]?.flightInformation?.[0]?.companyId?.[0]
              ?.customCarriers?.airline_code
          ) {
            return {
              flight1,
              flight2,
              totalPrice,
              finalPrice,
              totalDuration,
              priceWithduration,
              adultPrice,
              childPrice,
              markUp: data?.markUp,
              totalDuration: data?.totalDuration,
            };
          }
        } else {
          return {
            flight1,
            flight2,
            totalPrice,
            finalPrice,
            totalDuration,
            priceWithduration,
            adultPrice,
            childPrice,
            markUp: data?.markUp,
            totalDuration: data?.totalDuration,
          };
        }
      })
      ?.sort((a, b) => isActive=== "cheapest" ? a?.totalPrice - b?.totalPrice : isActive ==="fastest" ?  a.totalDuration - b.totalDuration : a.totalDuration * a.totalPrice - b.totalDuration * b.totalPrice);

    if (
      flightPairs &&
      flightPairs.length > 0 &&
      sessionStorage.getItem("wayOfFlight") === "round_trip"
    ) {
      checkCheapestFlight = flightPairs.reduce((min, pair) => {
        return pair?.totalPrice < min?.totalPrice ? pair : min;
      }, flightPairs[0]);

      checkFastestFlight = flightPairs.reduce((min, pair) => {
        return pair?.totalDuration < min?.totalDuration ? pair : min;
      }, flightPairs[0]);

      checkBestFlight = flightPairs.reduce((min, pair) => {
        return pair?.priceWithduration < min?.priceWithduration ? pair : min;
      }, flightPairs[0]);
    }

    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Header />

            <Modal
              show={this.state.setShow}
              onHide={this.handleClose}
              className="login_popup"
            >
              <Modal.Header closeButton>
                <ButtonToolbar>
                  {this.state.t_class.map((item) => (
                    <div>
                      {this.state.t_class_selected === item.value ? (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded mr-1 custom-btn-primary"
                        />
                      ) : (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded p-button-secondary mr-1 custom-btn-primary"
                          onClick={() =>
                            this.setState({ t_class_selected: item.value })
                          }
                        />
                      )}
                    </div>
                  ))}
                </ButtonToolbar>
              </Modal.Header>
              <Modal.Body>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Adult (Above 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult - 1 });
                        if (this.state.adult - 1 < this.state.infants) {
                          this.setState({ infants: this.state.adult - 1 });
                        }
                      }}
                      disabled={this.state.adult === 1}
                    />
                    <input
                      type="text"
                      value={this.state.adult}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult + 1 });
                      }}
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Child (2 years to 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child - 1 })
                      }
                      disabled={this.state.child === 0}
                    />
                    <input
                      type="text"
                      value={this.state.child}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child + 1 })
                      }
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Infants (Below 2 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants - 1 })
                      }
                      disabled={this.state.infants === 0}
                    />
                    <input
                      type="text"
                      value={this.state.infants}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants + 1 })
                      }
                      disabled={this.state.infants === this.state.adult}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  label="Submit"
                  className="p-button-raised p-button-rounded mr-2 custom-btn-primary"
                  onClick={() => this.setState({ setShow: false })}
                />
              </Modal.Footer>
            </Modal>

            {/* Inactivity Modal */}
            {this.state.showTimeOutModal && <div className="overlay"></div>}
            {this.state.showTimeOutModal && (
              <div className="sticky-modal">
                <div className="inactive-modal">
                  <div className="modal-header">
                    <div className="d-flex gap-1rem flex-wrap">
                      <div className="d-flex align-items-center flex-column gap-1rem">
                        <img
                          className="pulse"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                          }}
                          src="https://img.freepik.com/free-vector/customer-support-illustration_23-2148903318.jpg?t=st=1718351497~exp=1718355097~hmac=2765533fd293e493ca68e1670cfd2b4ecb57d3d6fa4c8c460cc5266fcfcb15e7&w=740"
                          alt=""
                        />
                        <span className="badge badge-pill badge-light text-success border">
                          Available Now
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <p className="text-capitalize fw-700 fs-22p mb-0">
                            Speak to a travel expert now
                          </p>
                          <p className="mb-0">
                            Call us now to get up to USD 15 OFF our fees
                          </p>
                          <p className="mb-0">
                            Use&nbsp;
                            <span className="border-success border-dashed text-success py-1 px-2">
                              promo code <span className="fw-600">JUN24</span>
                            </span>
                          </p>
                        </div>
                        <div className="d-flex flex-column">
                          <p className="text-primary fw-700 fs-22p mb-0 d-flex align-items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-telephone-fill call_vibration"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                              />
                            </svg>
                            +1 888-315-1615 (US)
                          </p>
                          <p className="mb-0 fw-600 fs-12p">Available 24/7</p>
                        </div>
                      </div>
                    </div>
                    <span className="close" onClick={this.closeTimeOutModal}>
                      &times;
                    </span>
                  </div>
                  <div className="modal-body">
                    <div className="d-flex flex-column">
                      <p className="fw-600 fs-20p mb-0">Get fares alerts!</p>
                      <p>
                        Be the first to know when fares drop for the route&nbsp;
                        <span className="fw-600">BOS - Boston Logan</span>
                        &nbsp;to&nbsp;
                        <span className="fw-600">MIA - Miami</span>
                      </p>
                      <div className="form-group">
                        <lable className="form-label">
                          Enter Email Address
                        </lable>
                        <div className="row">
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Enter your email address"
                            />
                          </div>
                          <div className="col-sm-4">
                            <span className="btn custom-btn-primary w-100">
                              Get Fare Alerts
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="fs-12p text-center mt-3">
                        By entering your email address you agree to
                        Ogule's&nbsp;
                        <span className="text-primary text-link">
                          Terms and conditions, Privacy Policy
                        </span>
                        &nbsp;and to receive email marketing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                position: "-webkit-sticky",
                position: "sticky",
                top: 0,
                zIndex: 1000,
              }}
            >
              <div className="d-flex flex-column bg-light-blue px-4 py-2">
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-1rem">
                    <div className="form-group mb-0">
                      <input
                        type="radio"
                        id="one_way"
                        name="wayOfFlight"
                        value="one_way"
                        checked={this.state.wayOfFlight === "one_way"}
                        onChange={(e) => this.search_filters(e.target.value)}
                      />
                      <label
                        className="ml-1 mb-0 fs-14p"
                        htmlFor="one_way"
                        style={{ fontWeight: "600" }}
                      >
                        One Way
                      </label>
                    </div>

                    <div className="form-group mb-0">
                      <input
                        type="radio"
                        id="round_trip"
                        name="wayOfFlight"
                        value="round_trip"
                        checked={this.state.wayOfFlight === "round_trip"}
                        onChange={(e) => this.search_filters(e.target.value)}
                      />
                      <label
                        className="ml-1 mb-0 fs-14p"
                        htmlFor="round_trip"
                        style={{ fontWeight: "600" }}
                      >
                        Round Trip
                      </label>
                    </div>
                  </div>
                  <span
                    className="btn btn-sm btn-light px-3"
                    onClick={() => {
                      this.backToHome();
                    }}
                  >
                    <span className="d-flex align-items-center gap-2">
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 16 14"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 7H15M1 7L7 13M1 7L7 1"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      &nbsp; Back to home
                    </span>
                  </span>
                </div>
                <div className="d-flex gap-1rem">
                  <div
                    className="d-flex align-items-center"
                    style={{ width: "40%" }}
                  >
                    <div className="w-100">
                      <div className="form-group mb-0">
                        <label
                          htmlFor="from"
                          className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="#0C8CE9"
                            class="bi bi-geo-alt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          From
                        </label>
                        {this.state.city_from === "" ? (
                          <input
                            className="form-control-sm"
                            type="text"
                            autocomplete="off"
                            id="from"
                            list="fromdata"
                            value={this.state.iatacode_from}
                            onChange={(e) => this.searchfrom(e.target.value)}
                          />
                        ) : (
                          <span
                            className="form-control-sm"
                            contentEditable
                            suppressContentEditableWarning
                            tabIndex="0"
                            onKeyDown={(e) => {
                              if (e.key === "Backspace" || e.key === "Delete") {
                                this.setState({
                                  iatacode_from: "",
                                  city_from: "",
                                  airport_name_from: "",
                                  country_from: "",
                                });
                              }
                            }}
                          >
                            <span className="d-flex align-items-center justify-content-between gap-1rem w-100">
                              <span>
                                {this.state.city_from}&nbsp;(
                                {this.state.iatacode_from})
                              </span>

                              <span
                                className="d-flex align-items-center justify-content-center pointer"
                                onClick={() => {
                                  this.setState({
                                    iatacode_from: "",
                                    city_from: "",
                                    airport_name_from: "",
                                    country_from: "",
                                  });
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                    fill="#C9C9C9"
                                  />
                                </svg>
                              </span>
                            </span>
                          </span>
                        )}
                      </div>
                      <p className="text-secondary mb-0 fs-12p">
                        {this.state.airport_name_from}&nbsp;
                        {this.state.country_from}
                      </p>
                      <div>
                        {this.state.fromdata ? (
                          <div>
                            <datalist id="fromdata">
                              {this.state.fromdata.map((item) => (
                                <option value={item.iataCode}>
                                  {item.name},{item.address.cityName}&nbsp;
                                  {item.address.countryName}
                                </option>
                              ))}
                            </datalist>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <span
                      className="btn btn-sm btn-primary d-flex align-items-center justify-content-center mx-1"
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                      }}
                      onClick={this.swapValues}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ rotate: "90deg" }}
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-down-up"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"
                        />
                      </svg>
                    </span>

                    <div className="w-100">
                      <div className="form-group mb-0">
                        <label
                          htmlFor="to"
                          className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="#0C8CE9"
                            class="bi bi-geo-alt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          To
                        </label>
                        {this.state.city_to === "" ? (
                          <input
                            className="form-control-sm"
                            type="text"
                            id="to"
                            list="todata"
                            value={this.state.iatacode_to}
                            onChange={(e) => this.searchto(e.target.value)}
                          />
                        ) : (
                          <span
                            className="form-control-sm"
                            contentEditable
                            suppressContentEditableWarning
                            tabIndex="0"
                            onKeyDown={(e) => {
                              if (e.key === "Backspace" || e.key === "Delete") {
                                this.setState({
                                  iatacode_to: "",
                                  city_to: "",
                                  airport_name_to: "",
                                  country_to: "",
                                });
                              }
                            }}
                          >
                            <span className="d-flex align-items-center justify-content-between gap-1rem w-100">
                              <span>
                                {this.state.city_to}&nbsp;(
                                {this.state.iatacode_to})
                              </span>

                              <span
                                className="d-flex align-items-center justify-content-center pointer"
                                onClick={() => {
                                  this.setState({
                                    iatacode_to: "",
                                    city_to: "",
                                    airport_name_to: "",
                                    country_to: "",
                                  });
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                    fill="#C9C9C9"
                                  />
                                </svg>
                              </span>
                            </span>
                          </span>
                        )}
                      </div>
                      <p className="text-secondary mb-0 fs-12p">
                        {this.state.airport_name_to}&nbsp;
                        {this.state.country_to}
                      </p>
                      <div>
                        {this.state.todata ? (
                          <div>
                            <datalist id="todata">
                              {this.state.todata.map((item) => (
                                <option value={item.iataCode}>
                                  {item.name},{item.address.cityName}&nbsp;
                                  {item.address.countryName}
                                </option>
                              ))}
                            </datalist>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "20%" }}>
                    <div className="form-group mb-0">
                      <label
                        htmlFor="from"
                        className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="none"
                          viewBox="0 0 18 20"
                        >
                          <path
                            d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                            stroke="#0C8CE9"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Departure
                      </label>
                      <DatePicker
                        className="datepicker-control"
                        selected={new Date(this.state.departuredate)}
                        dateFormat="d MMM, yy"
                        onChange={this.handledeparturedate}
                        minDate={new Date()}
                      />
                    </div>
                  </div>

                  {this.state.wayOfFlight === "round_trip" && (
                    <div style={{ width: "20%" }}>
                      <div className="form-group mb-0">
                        <label
                          htmlFor="from"
                          className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="none"
                            viewBox="0 0 18 20"
                          >
                            <path
                              d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                              stroke="#0C8CE9"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Return
                        </label>
                        <DatePicker
                          className="datepicker-control"
                          selected={
                            this.state.returndate
                              ? new Date(this.state.returndate)
                              : null
                          }
                          dateFormat="d MMM, yy"
                          onChange={this.handlereturndate}
                          minDate={
                            this.state.departuredate
                              ? new Date(this.state.departuredate)
                              : new Date()
                          }
                          maxDate={
                            this.state.departuredate
                              ? new Date(
                                  new Date(this.state.departuredate).setDate(
                                    new Date(
                                      this.state.departuredate
                                    ).getDate() + 361
                                  )
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div style={{ width: "20%" }}>
                    <div className="form-group mb-0">
                      <label className="form-label mb-1 fs-12p d-flex align-items-center gap-1">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 25 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.4982 14.0224C11.1666 14.0226 9.8649 13.6279 8.75764 12.8882C7.65038 12.1486 6.78734 11.0972 6.27764 9.86701C5.76794 8.63684 5.63448 7.28316 5.89415 5.97714C6.15382 4.67113 6.79494 3.47145 7.73645 2.52981C8.67795 1.58818 9.87754 0.946893 11.1835 0.687048C12.4895 0.427203 13.8432 0.560474 15.0734 1.07C16.3037 1.57954 17.3552 2.44244 18.095 3.5496C18.8348 4.65675 19.2297 5.95843 19.2297 7.29C19.228 9.07486 18.5182 10.7861 17.2562 12.0483C15.9942 13.3105 14.2831 14.0204 12.4982 14.0224ZM12.4982 2.49912C11.5504 2.49894 10.6238 2.77985 9.83568 3.30631C9.04753 3.83277 8.43321 4.58113 8.07042 5.45676C7.70762 6.33238 7.61265 7.29593 7.79752 8.22553C7.98238 9.15513 8.43877 10.009 9.10897 10.6792C9.77917 11.3494 10.6331 11.8058 11.5627 11.9907C12.4923 12.1756 13.4558 12.0806 14.3314 11.7178C15.2071 11.355 15.9554 10.7407 16.4819 9.95253C17.0084 9.16438 17.2893 8.23781 17.2891 7.29C17.2876 6.01983 16.7824 4.8021 15.8843 3.90395C14.9861 3.0058 13.7684 2.50058 12.4982 2.49912ZM23.2449 28.4409H1.75154L0.78125 27.4706C0.78125 25.9319 1.08432 24.4083 1.67315 22.9867C2.26198 21.5652 3.12505 20.2735 4.21307 19.1855C5.30108 18.0975 6.59275 17.2344 8.01432 16.6456C9.43588 16.0567 10.9595 15.7537 12.4982 15.7537C14.0369 15.7537 15.5605 16.0567 16.9821 16.6456C18.4037 17.2344 19.6953 18.0975 20.7833 19.1855C21.8714 20.2735 22.7344 21.5652 23.3233 22.9867C23.9121 24.4083 24.2152 25.9319 24.2152 27.4706L23.2449 28.4409ZM2.76773 26.5003H22.2287C21.9914 24.0843 20.8638 21.843 19.0652 20.2124C17.2667 18.5819 14.9258 17.6787 12.4982 17.6787C10.0706 17.6787 7.72972 18.5819 5.93118 20.2124C4.13264 21.843 3.00502 24.0843 2.76773 26.5003Z"
                            fill="#0C8CE9"
                          />
                        </svg>
                        Traveler, Class
                      </label>

                      <span
                        className="form-control-sm text-truncate"
                        onClick={() => this.setState({ setShow: true })}
                      >
                        <span className="custom-text-primary">
                          {parseInt(this.state.adult) +
                            parseInt(this.state.child) +
                            parseInt(this.state.infants)}
                          &nbsp;Traveler
                        </span>
                        , &nbsp;{this.state.t_class_selected}
                      </span>
                    </div>
                  </div>

                  <div style={{ width: "20%" }}>
                    <div className="form-group mb-0">
                      <label
                        htmlFor="from"
                        className="form-label mb-1 fs-12p d-flex align-items-center gap-1"
                      >
                        &nbsp;
                      </label>
                      <span
                        className="btn btn-sm custom-btn-primary float-right w-100"
                        onClick={this.handleUpdate}
                      >
                        Modify Search
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-3">
              <div className="row">
                <div className="col-lg-3 col-md-4 d-flex flex-column gap-2">
                  {this.state.flightsData?.length > 0 && (
                    <div className="shadow-none d-flex flex-column gap-1rem">
                      <FilterSidebar
                        stops={this.state.stops}
                        updateStopCount={this.state.updateStopCount}
                        totalStopCount={this.state.totalStopCount}
                        selectAllFlights={this.state.selectAllFlights}
                        priceWithAirlines={this.state.priceWithAirlines}
                        selectedAirlines={this.state.selectedAirlines}
                        minPrice={this.state.minPrice}
                        maxPrice={this.state.maxPrice}
                        selectedMaxPrice={this.state.selectedMaxPrice}
                        departureData={this.state.departureData}
                        arrivalData={this.state.arrivalData}
                        deptValue={this.state.deptValue}
                        arrivValue={this.state.arrivValue}
                        handleCheckBoxChange={this.handleCheckBoxChange}
                        handleSelectAll={this.handleSelectAll}
                        handleClearAll={this.handleClearAll}
                        handleAirlineCheckboxChange={
                          this.handleAirlineCheckboxChange
                        }
                        handleChangePrice={this.handleChangePrice}
                        handleChangeDeptValue={this.handleChangeDeptValue}
                        handleChangeArrivValue={this.handleChangeArrivValue}
                        formatTimeValue={this.formatTimeValue}
                      />
                      <div className="d-flex justify-content-center mt-2">
                        <button
                          className="btn text-primary border rounded-5"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          Scroll To Top
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-9 col-md-8">
                  {this?.state?.updatingFlights ? (
                    <div className="flights_box_section">
                      <div className="p-2 skeleton_bg">
                        <p className="font-size-2 text-center">
                          We are finding the best flights for you.
                        </p>

                        <div className="row">
                          <div className="col-12">
                            <div class="progress pink">
                              <p
                                style={{ fontWeight: "bold", fontSize: "21px" }}
                              >
                                {this.state.isResponse
                                  ? "100%"
                                  : `${this.state.progress}%`}
                              </p>
                              <div
                                class="progress-bar"
                                style={{
                                  width: `${this.state.progress}%`,
                                  backgroundColor: "transparent",
                                }}
                              ></div>
                              ✈️
                            </div>
                          </div>
                        </div>

                        <ul className="px-4">
                          {this.state.progress <= 20 && (
                            <li className="fw-600">
                              Creating a travel masterpiece. Stay with us as the
                              strokes of excitement unfold
                            </li>
                          )}
                          {this.state.progress > 20 &&
                            this.state.progress <= 40 && (
                              <li className="fw-600">
                                Loading your next adventure... Sit tight, we're
                                preparing an unforgettable experience for you.
                              </li>
                            )}
                          {this.state.progress > 40 &&
                            this.state.progress <= 60 && (
                              <li className="fw-600">
                                Buckle up! We're speeding down the virtual
                                runway to unveil your personalized travel
                                escape.
                              </li>
                            )}
                          {this.state.progress > 60 &&
                            this.state.progress <= 80 && (
                              <li className="fw-600">
                                Patience, traveler! We're weaving a tapestry of
                                incredible moments for your upcoming journey.
                              </li>
                            )}
                        </ul>

                        {Array.from({ length: 5 }).map((_, index) => (
                          <>
                            <div className="row">
                              <div className="col-1">
                                <Skeleton
                                  variant="circle"
                                  width={40}
                                  height={40}
                                />
                              </div>
                              <div className="col-11">
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height={40}
                                />
                              </div>
                            </div>
                            <p>
                              <Skeleton variant="text" width="100%" />
                              <Skeleton variant="text" width="100%" />
                              <Skeleton variant="text" width="100%" />
                              <Skeleton variant="text" width="100%" />
                            </p>
                          </>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column gap-2">
                      {this.state.flightsData?.length > 0 && (
                        <div className="d-flex">
                          <div
                            style={{ overflowX: "scroll", minWidth: "150px" }}
                          >
                            <table
                              className="table table-bordered fs-14p text-center mb-0"
                              style={{ width: "150px" }}
                            >
                              <thead>
                                <tr className="text-nowrap">
                                  <th style={{ paddingBlock: "8.5px" }}>
                                    Show All Fares
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="text-center">
                                  <td
                                    className="py-1 fw-600 custom-text-primary pointer"
                                    onClick={() =>
                                      this.handleCheckBoxChange("0")
                                    }
                                  >
                                    Direct
                                  </td>
                                </tr>
                                <tr className="text-center">
                                  <td
                                    className="py-1 fw-600 custom-text-primary pointer"
                                    onClick={() =>
                                      this.handleCheckBoxChange("1")
                                    }
                                  >
                                    1 Stop
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            className="w-100"
                            style={{ overflowX: "scroll" }}
                          >
                            <table className="table table-bordered fs-14p text-center mb-0">
                              <thead>
                                <tr>
                                  {this.state.priceWithAirlines?.map(
                                    (flight, index) => {
                                      return (
                                        <th
                                          className="text-nowrap py-1"
                                          key={index}
                                        >
                                          <div
                                            className="d-flex gap-1 align-items-center justify-content-center"
                                            style={{
                                              maxWidth: "265px",
                                              minWidth: "160px",
                                            }}
                                          >
                                            {flight?.image && (
                                              <img
                                                style={{
                                                  width: "40px",
                                                  height: "30px",
                                                  objectFit: "contain",
                                                  objectPosition: "center",
                                                }}
                                                src={flight?.image}
                                                alt=""
                                              />
                                            )}
                                            <span
                                              style={{ fontWeight: "400" }}
                                              className="ml-1"
                                            >
                                              {flight?.airline_name}
                                            </span>
                                          </div>
                                        </th>
                                      );
                                    }
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="text-center">
                                  {this.state.flightAirlineCodes.map(
                                    (airlineCode, index) => {
                                      const flight =
                                        this.state.directStopWithAirlinePrice?.find(
                                          (flight) =>
                                            flight.airlineCode === airlineCode
                                        );
                                      return (
                                        <td className="py-1 fw-600" key={index}>
                                          <div
                                            className={
                                              this.state.selectedMaxPrice ===
                                              flight?.price
                                                ? "active_table_td"
                                                : ""
                                            }
                                            style={{
                                              maxWidth: "265px",
                                              minWidth: "150px",
                                              cursor: "pointer",
                                            }}
                                            data-value={
                                              flight ? flight.price : 0
                                            }
                                            // onClick={this.handleChangePrice}
                                            onClick={
                                              flight
                                                ? this.handleChangePrice
                                                : null
                                            }
                                          >
                                            {flight
                                              ? `$ ${flight.price?.toFixed(2)}`
                                              : "-----"}
                                          </div>
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                                <tr className="text-center">
                                  {this.state.flightAirlineCodes.map(
                                    (airlineCode, index) => {
                                      const flight =
                                        this.state.onePlusWithAirlinePrice?.find(
                                          (flight) =>
                                            flight.airlineCode === airlineCode
                                        );
                                      return (
                                        <td className="py-1 fw-600" key={index}>
                                          <div
                                            className={
                                              this.state.selectedMaxPrice ===
                                              flight?.price
                                                ? "active_table_td"
                                                : ""
                                            }
                                            style={{
                                              maxWidth: "265px",
                                              minWidth: "150px",
                                              cursor: "pointer",
                                            }}
                                            data-value={
                                              flight ? flight.price : 0
                                            }
                                            onClick={
                                              flight
                                                ? this.handleChangePrice
                                                : null
                                            }
                                          >
                                            {flight
                                              ? `$ ${flight.price?.toFixed(2)}`
                                              : "-----"}
                                          </div>
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}

                      {this.state.flightsData?.length > 0 && (
                        <div className="px-3 py-2 border rounded-10p">
                          <div className="row">
                            <div
                              className="col-4 d-flex flex-column align-items-center pointer"
                              data-value={
                                cheapestFlight ? cheapestFlight?.price : ""
                              }
                              onClick={(event) => {
                                // this.handleChangePrice(
                                //   event,
                                //   cheapestFlight.price
                                // );
                                this.setIsActive("cheapest");
                              }}
                            >
                              <div>
                                <div className="text-secondary">
                                  <p className="d-flex justify-content-center mb-0 fs-18p fw-600">
                                    Cheapest
                                  </p>
                                  <p className="mb-0 d-flex align-items-center">
                                    $ {cheapestFlight?.price?.toFixed(2)}
                                    &nbsp;&nbsp;&nbsp;
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="currentColor"
                                      class="bi bi-clock"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                    </svg>
                                    &nbsp;
                                    {this.convertFlightDuration(
                                      cheapestFlight?.duration
                                    )}
                                  </p>
                                </div>
                              </div>
                              {isActive === "cheapest" && (
                                <div
                                  className="border-bottom border-primary w-100"
                                  style={{ borderStyle: "double" }}
                                ></div>
                              )}
                            </div>
                            <div
                              className="col-4 d-flex flex-column align-items-center border-left border-right pointer"
                              data-value={bestFlight ? bestFlight.price : ""}
                              onClick={(event) => {
                                // this.handleChangePrice(event, bestFlight.price);
                                this.setIsActive("best");
                              }}
                            >
                              <div>
                                <p className="d-flex justify-content-center mb-0 fs-18p fw-600">
                                  Best
                                </p>
                                <p className="mb-0 d-flex align-items-center">
                                  $ {bestFlight?.price?.toFixed(2)}
                                  &nbsp;&nbsp;&nbsp;
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="currentColor"
                                    class="bi bi-clock"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                  </svg>
                                  &nbsp;
                                  {this.convertFlightDuration(
                                    bestFlight?.duration
                                  )}
                                </p>
                              </div>
                              {isActive === "best" && (
                                <div
                                  className="border-bottom border-primary w-100"
                                  style={{ borderStyle: "double" }}
                                ></div>
                              )}
                            </div>
                            <div
                              className="col-4 d-flex flex-column align-items-center pointer"
                              data-value={
                                fastestFlight ? fastestFlight?.price : ""
                              }
                              onClick={(event) => {
                                // this.handleChangePrice(
                                //   event,
                                //   fastestFlight.price
                                // );
                                this.setIsActive("fastest");
                              }}
                            >
                              <div>
                                <div className="text-secondary">
                                  <p className="d-flex justify-content-center mb-0 fs-18p fw-600">
                                    Fastest
                                  </p>
                                  <p className="mb-0 d-flex align-items-center">
                                    $ {fastestFlight?.price?.toFixed(2)}
                                    &nbsp;&nbsp;&nbsp;
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="currentColor"
                                      class="bi bi-clock"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                    </svg>
                                    &nbsp;
                                    {this.convertFlightDuration(
                                      fastestFlight?.duration
                                    )}
                                  </p>
                                </div>
                              </div>
                              {isActive === "fastest" && (
                                <div
                                  className="border-bottom border-primary w-100"
                                  style={{ borderStyle: "double" }}
                                ></div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.filterApplied && (
                        <div className="d-flex gap-2 overflow-auto p-1">
                          <span className="active_price_chip">
                            <span className="pr-2 text-nowrap">
                              $ {this.state.selectedMaxPrice?.toFixed(2)}
                            </span>
                            <span
                              className="clear_active_price_chip"
                              onClick={this.handleCleaPriceFilter}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-x-lg"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                              </svg>
                            </span>
                          </span>
                        </div>
                      )}
                      <div className="ticket-card-container pb-3 pt-2">
                        {sessionStorage.getItem("wayOfFlight") ===
                        "round_trip" ? (
                          <>
                            <RoundTrip
                              flightPairs={flightPairs}
                              displayedFlights={displayedFlights}
                              handleShowMore={this.handleShowMore}
                              checkCheapestFlight={checkCheapestFlight}
                              checkBestFlight={checkBestFlight}
                              checkFastestFlight={checkFastestFlight}
                            />
                          </>
                        ) : (
                          <>
                            <Oneway
                              filteredArray={this.state.filteredArray}
                              displayedFlights={displayedFlights}
                              handleShowMore={this.handleShowMore}
                              checkCheapestFlightOneWay={
                                checkCheapestFlightOneWay
                              }
                              checkFastestFlightOneWay={
                                checkFastestFlightOneWay
                              }
                              checkBestFlightOneWay={checkBestFlightOneWay}
                            />
                          </>
                        )}
                      </div>
                      <div className="col-6">
                        {(this.state.filteredArray?.length === 0 ||
                          this.state.updateStopCount?.length === 0) &&
                          !this.state.loading && (
                            <div
                              className="text-end fw-600 text-secondary my-5"
                              style={{ fontSize: "2rem" }}
                            >
                              No Flights Available
                            </div>
                          )}
                      </div>
                      {this.state.updatingFlights && (
                        <div className="loader">
                          <div className="w-100 h-100 mt-4">
                            <div class="progress pink">
                              <p
                                style={{ fontWeight: "bold", fontSize: "21px" }}
                              >
                                {this.state.isResponse
                                  ? "100%"
                                  : `${this.state.progress}%`}
                              </p>
                              <div
                                class="progress-bar"
                                style={{
                                  width: `${this.state.progress}%`,
                                  backgroundColor: "transparent",
                                }}
                              ></div>
                              ✈️
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="w-100 bg-white position-relative"
            style={{ minHeight: "100vh", paddingBottom: "4.2rem" }}
          >
            <Header />

            <Modal show={this.state.updateModal} onHide={this.toggleModal}>
              <Modal.Body>
                <div className="d-flex flex-column p-3">
                  <div className="d-flex gap-1rem px-3 pt-2 pb-1">
                    <div className="form-group mb-0">
                      <input
                        type="radio"
                        id="one_way"
                        name="wayOfFlight"
                        value="one_way"
                        checked={this.state.wayOfFlight === "one_way"}
                        onChange={(e) => this.search_filters(e.target.value)}
                      />
                      <label
                        className="ml-1"
                        htmlFor="one_way"
                        style={{ fontWeight: "600" }}
                      >
                        One Way
                      </label>
                    </div>

                    <div className="form-group mb-0">
                      <input
                        type="radio"
                        id="round_trip"
                        name="wayOfFlight"
                        value="round_trip"
                        checked={this.state.wayOfFlight === "round_trip"}
                        onChange={(e) => this.search_filters(e.target.value)}
                      />
                      <label
                        className="ml-1"
                        htmlFor="round_trip"
                        style={{ fontWeight: "600" }}
                      >
                        Round Trip
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="border p-2 d-flex bg-white gap-2 mb-2"
                        style={{ borderRadius: "16px" }}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_972_9032)">
                            <path
                              d="M17.361 13.5892L22.1906 12.2951C22.7029 12.1578 23.2489 12.2297 23.7082 12.4949C24.1676 12.7601 24.5028 13.197 24.6401 13.7093C24.7774 14.2217 24.7055 14.7676 24.4403 15.227C24.1751 15.6863 23.7382 16.0215 23.2259 16.1588L8.73698 20.0411L4.28628 15.022L7.18406 14.2456L9.63355 15.6598L12.5313 14.8833L8.78774 8.63947L11.6855 7.86301L17.361 13.5892Z"
                              stroke="#1391A7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.39844 22.9951H25.5984"
                              stroke="#1391A7"
                              stroke-linecap="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_972_9032">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0 6.76465) rotate(-15)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                        <div className="d-flex flex-column w-100">
                          {this.state.city_from === "" ? (
                            <input
                              className="border-0"
                              style={{ outline: "none" }}
                              type="text"
                              autocomplete="off"
                              id="from"
                              list="fromdata"
                              value={this.state.iatacode_from}
                              onChange={(e) => this.searchfrom(e.target.value)}
                            />
                          ) : (
                            <span className="rounded-10p mt-1 d-flex p-1">
                              <span className="d-flex align-items-center justify-content-between rounded gap-2rem w-100">
                                <span>
                                  {this.state.city_from}&nbsp;(
                                  {this.state.iatacode_from})
                                </span>

                                <span
                                  className="d-flex align-items-center justify-content-center pointer"
                                  onClick={() => {
                                    this.setState({
                                      iatacode_from: "",
                                      city_from: "",
                                      airport_name_from: "",
                                      country_from: "",
                                    });
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                      fill="#C9C9C9"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </span>
                          )}
                          <p className="text-secondary mb-0 fs-10p">
                            {this.state.airport_name_from}&nbsp;
                            {this.state.country_from}
                          </p>
                          <div>
                            {this.state.fromdata ? (
                              <div>
                                <datalist id="fromdata">
                                  {this.state.fromdata.map((item) => (
                                    <option value={item.iataCode}>
                                      {item.name},{item.address.cityName}&nbsp;
                                      {item.address.countryName}
                                    </option>
                                  ))}
                                </datalist>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        className="border p-2 d-flex bg-white gap-2 mb-2"
                        style={{ borderRadius: "16px" }}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_972_9047)">
                            <path
                              d="M16.5812 15.1418L21.4109 16.4359C21.9232 16.5731 22.3601 16.9083 22.6253 17.3677C22.8905 17.8271 22.9624 18.373 22.8251 18.8853C22.6878 19.3977 22.3526 19.8345 21.8932 20.0998C21.4339 20.365 20.888 20.4368 20.3756 20.2996L5.8867 16.4173L4.54184 9.84526L7.43962 10.6217L8.85383 13.0712L11.7516 13.8477L11.6315 6.56854L14.5293 7.345L16.5812 15.1418Z"
                              stroke="#0C8CE9"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.39844 22.9951H25.5984"
                              stroke="#0C8CE9"
                              stroke-linecap="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_972_9047">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0 6.76465) rotate(-15)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <div className="d-flex flex-column w-100">
                          {/* <input
                            className="w-100 border-0"
                            style={{ outline: "none" }}
                            type="text"
                            id="to"
                            list="todata"
                            value={this.state.iatacode_to}
                            onChange={(e) => this.searchto(e.target.value)}
                          /> */}
                          {this.state.city_to === "" ? (
                            <input
                              className="border-0"
                              style={{ outline: "none" }}
                              type="text"
                              id="to"
                              list="todata"
                              value={this.state.iatacode_to}
                              onChange={(e) => this.searchto(e.target.value)}
                            />
                          ) : (
                            <span className="rounded-10p mt-1 d-flex p-1">
                              <span className="d-flex align-items-center justify-content-between rounded gap-2rem w-100">
                                <span>
                                  {this.state.city_to}&nbsp;(
                                  {this.state.iatacode_to})
                                </span>

                                <span
                                  className="d-flex align-items-center justify-content-center pointer"
                                  onClick={() => {
                                    this.setState({
                                      iatacode_to: "",
                                      city_to: "",
                                      airport_name_to: "",
                                      country_to: "",
                                    });
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                      fill="#C9C9C9"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </span>
                          )}
                          <p className="text-secondary mb-0 fs-10p">
                            {this.state.airport_name_to}&nbsp;
                            {this.state.country_to}
                          </p>
                          <div>
                            {this.state.todata ? (
                              <div>
                                <datalist id="todata">
                                  {this.state.todata.map((item) => (
                                    <option value={item.iataCode}>
                                      {item.name},{item.address.cityName}&nbsp;
                                      {item.address.countryName}
                                    </option>
                                  ))}
                                </datalist>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        className="border p-2 d-flex bg-white gap-2 mb-2"
                        style={{ borderRadius: "16px" }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.6641 2V4.66667M5.33073 2V4.66667M2.66406 7.33333H13.3307M4.66406 9.33333H4.67273M6.66927 9.33333H6.6726M8.66927 9.33333H8.6726M10.6745 9.33333H10.6778M8.67448 11.3333H8.67781M4.66927 11.3333H4.6726M6.66927 11.3333H6.6726M2.66406 4.66667C2.66406 4.31304 2.80454 3.97391 3.05459 3.72386C3.30464 3.47381 3.64377 3.33333 3.9974 3.33333H11.9974C12.351 3.33333 12.6902 3.47381 12.9402 3.72386C13.1903 3.97391 13.3307 4.31304 13.3307 4.66667V12.6667C13.3307 13.0203 13.1903 13.3594 12.9402 13.6095C12.6902 13.8595 12.351 14 11.9974 14H3.9974C3.64377 14 3.30464 13.8595 3.05459 13.6095C2.80454 13.3594 2.66406 13.0203 2.66406 12.6667V4.66667Z"
                            stroke="#0C8CE9"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <div className="d-flex flex-column">
                          <label className="mb-0 fs-12p">Departure</label>
                          <DatePicker
                            className="custom-datepicker p-0"
                            selected={new Date(this.state.departuredate)}
                            dateFormat="d MMM, yy"
                            onChange={this.handledeparturedate}
                            minDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.wayOfFlight === "round_trip" && (
                      <div className="col-12">
                        <div
                          className="border p-2 d-flex bg-white gap-2 mb-2"
                          style={{ borderRadius: "16px" }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.6641 2V4.66667M5.33073 2V4.66667M2.66406 7.33333H13.3307M4.66406 9.33333H4.67273M6.66927 9.33333H6.6726M8.66927 9.33333H8.6726M10.6745 9.33333H10.6778M8.67448 11.3333H8.67781M4.66927 11.3333H4.6726M6.66927 11.3333H6.6726M2.66406 4.66667C2.66406 4.31304 2.80454 3.97391 3.05459 3.72386C3.30464 3.47381 3.64377 3.33333 3.9974 3.33333H11.9974C12.351 3.33333 12.6902 3.47381 12.9402 3.72386C13.1903 3.97391 13.3307 4.31304 13.3307 4.66667V12.6667C13.3307 13.0203 13.1903 13.3594 12.9402 13.6095C12.6902 13.8595 12.351 14 11.9974 14H3.9974C3.64377 14 3.30464 13.8595 3.05459 13.6095C2.80454 13.3594 2.66406 13.0203 2.66406 12.6667V4.66667Z"
                              stroke="#0C8CE9"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <div className="d-flex flex-column">
                            <label className="mb-0 fs-12p">Return</label>
                            <DatePicker
                              className="custom-datepicker p-0"
                              selected={
                                this.state.returndate
                                  ? new Date(this.state.returndate)
                                  : null
                              }
                              dateFormat="d MMM, yy"
                              onChange={this.handlereturndate}
                              minDate={
                                this.state.departuredate
                                  ? new Date(this.state.departuredate)
                                  : new Date()
                              }
                              maxDate={
                                this.state.departuredate
                                  ? new Date(
                                      new Date(
                                        this.state.departuredate
                                      ).setDate(
                                        new Date(
                                          this.state.departuredate
                                        ).getDate() + 361
                                      )
                                    )
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-12">
                      <div
                        className="border p-2 d-flex align-items-end bg-white gap-2 mb-2"
                        style={{ borderRadius: "16px" }}
                        onClick={() => this.setState({ setShow: true })}
                      >
                        <svg
                          style={{ marginBottom: "4px" }}
                          width="16"
                          height="20"
                          viewBox="0 0 16 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00094 9.67531C7.09433 9.67544 6.20805 9.40671 5.45417 8.90312C4.7003 8.39952 4.11269 7.68368 3.76566 6.84612C3.41863 6.00856 3.32777 5.0869 3.50456 4.1977C3.68136 3.3085 4.11787 2.4917 4.75889 1.85058C5.39991 1.20947 6.21666 0.772851 7.10584 0.595936C7.99501 0.41902 8.91668 0.509757 9.75429 0.856672C10.5919 1.20359 11.3078 1.7911 11.8115 2.5449C12.3152 3.29871 12.5841 4.18496 12.5841 5.09157C12.5829 6.30679 12.0997 7.47191 11.2405 8.33127C10.3812 9.19062 9.21616 9.67399 8.00094 9.67531ZM8.00094 1.82969C7.35562 1.82957 6.72476 2.02082 6.18815 2.37926C5.65154 2.7377 5.23328 3.24723 4.98627 3.8434C4.73927 4.43957 4.67461 5.0956 4.80047 5.72852C4.92633 6.36144 5.23707 6.94282 5.69338 7.39913C6.14968 7.85543 6.73106 8.16617 7.36398 8.29203C7.9969 8.4179 8.65294 8.35324 9.24911 8.10623C9.84527 7.85922 10.3548 7.44096 10.7132 6.90435C11.0717 6.36774 11.2629 5.73688 11.2628 5.09157C11.2618 4.22677 10.9178 3.39767 10.3063 2.78617C9.69483 2.17466 8.86574 1.83068 8.00094 1.82969ZM15.3178 19.4922H0.684062L0.0234375 18.8316C0.0234375 17.7839 0.229782 16.7466 0.630689 15.7787C1.0316 14.8108 1.61921 13.9314 2.35999 13.1906C3.10077 12.4498 3.9802 11.8622 4.94808 11.4613C5.91595 11.0604 6.95332 10.8541 8.00094 10.8541C9.04856 10.8541 10.0859 11.0604 11.0538 11.4613C12.0217 11.8622 12.9011 12.4498 13.6419 13.1906C14.3827 13.9314 14.9703 14.8108 15.3712 15.7787C15.7721 16.7466 15.9784 17.7839 15.9784 18.8316L15.3178 19.4922ZM1.37594 18.1709H14.6259C14.4644 16.526 13.6966 15 12.4721 13.8898C11.2476 12.7796 9.6538 12.1647 8.00094 12.1647C6.34807 12.1647 4.75431 12.7796 3.52977 13.8898C2.30523 15 1.53749 16.526 1.37594 18.1709Z"
                            fill="#979797"
                          />
                        </svg>
                        <div className="d-flex flex-column">
                          <label className="text-secondary mb-0 fs-12p">
                            Travelers & Class
                          </label>
                          <span className="custom-text-primary font-size-1 mb-0">
                            {parseInt(this.state.adult) +
                              parseInt(this.state.child) +
                              parseInt(this.state.infants)}
                            &nbsp;Traveler ,{" "}
                            <span className="text-dark">
                              {this.state.t_class_selected}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <span
                        className="btn btn-primary w-100"
                        style={{ borderRadius: "16px" }}
                        onClick={() => {
                          this.handleUpdate();
                          this.toggleModal();
                        }}
                      >
                        Modify Search
                      </span>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={this.state.setShow}
              onHide={this.handleClose}
              className="login_popup-sm border"
            >
              <Modal.Header closeButton className="py-1">
                <h6 className="text-uppercase text-roboto fw-600 mb-0">
                  Class and passengers
                </h6>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex flex-column">
                  <div
                    className="d-flex gap-2 pb-1"
                    style={{ overflowX: "scroll" }}
                  >
                    {this.state.t_class.map((item) => (
                      <div>
                        {this.state.t_class_selected === item.value ? (
                          <Button
                            label={item.name}
                            className="p-button-raised p-button-rounded text-nowrap custom-btn-primary"
                          />
                        ) : (
                          <Button
                            label={item.name}
                            className="p-button-raised p-button-rounded p-button-secondary mr-1 text-nowrap custom-btn-primary"
                            onClick={() =>
                              this.setState({ t_class_selected: item.value })
                            }
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  <div class="d-flex align-items-center justify-content-between  mb-3">
                    <div class="p-2">Adult (Above 12 years)</div>
                    <div class="p-2">
                      <Button
                        label="-"
                        className="p-button-raised"
                        onClick={() => {
                          this.setState({ adult: this.state.adult - 1 });
                          if (this.state.adult - 1 < this.state.infants) {
                            this.setState({ infants: this.state.adult - 1 });
                          }
                        }}
                        disabled={this.state.adult === 0}
                      />
                      <input
                        type="text"
                        value={this.state.adult}
                        maxlength="2"
                        className="idval"
                      />
                      <Button
                        label="+"
                        className="p-button-raised"
                        onClick={() => {
                          this.setState({ adult: this.state.adult + 1 });
                        }}
                        disabled={
                          parseInt(this.state.adult) +
                            parseInt(this.state.child) ===
                          9
                        }
                      />
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between  mb-3">
                    <div class="p-2">Child (2 years to 12 years)</div>
                    <div class="p-2">
                      <Button
                        label="-"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ child: this.state.child - 1 })
                        }
                        disabled={this.state.child === 0}
                      />
                      <input
                        type="text"
                        value={this.state.child}
                        maxlength="2"
                        className="idval"
                      />
                      <Button
                        label="+"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ child: this.state.child + 1 })
                        }
                        disabled={
                          parseInt(this.state.adult) +
                            parseInt(this.state.child) ===
                          9
                        }
                      />
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between  mb-3">
                    <div class="p-2">Infants (Below 2 years)</div>
                    <div class="p-2">
                      <Button
                        label="-"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ infants: this.state.infants - 1 })
                        }
                        disabled={this.state.infants === 0}
                      />
                      <input
                        type="text"
                        value={this.state.infants}
                        maxlength="2"
                        className="idval"
                      />
                      <Button
                        label="+"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ infants: this.state.infants + 1 })
                        }
                        disabled={this.state.infants === this.state.adult}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="pb-2">
                <Button
                  label="Submit"
                  className="p-button-raised p-button-rounded mr-2 custom-btn-primary"
                  onClick={() => this.setState({ setShow: false })}
                />
              </Modal.Footer>
            </Modal>

            <Modal
              centered
              show={this.state.timesModal}
              onHide={this.toggleTimesModal}
              className="login_popup-sm"
            >
              <Modal.Header closeButton className="py-1">
                <h6 className="text-uppercase text-roboto fw-600 mb-0">
                  Times
                </h6>
              </Modal.Header>
              <Modal.Body className="p-0">
                <div className="blogs_side_bar_sec_about_us p-3">
                  <div className="d-flex flex-column gap-4p">
                    <div className="w-100">
                      <div className="d-flex flex-column gap-4p">
                        <span>
                          Take-off from&nbsp;
                          {sessionStorage.getItem("destinationLocationCode")}
                        </span>
                        <span>
                          {convertTimeFormat(this.state.departureData?.[0])}
                          -&nbsp;
                          {convertTimeFormat(
                            this.state.departureData[
                              this.state.departureData?.length - 1
                            ]
                          )}
                        </span>
                      </div>
                      <div>
                        <Slider
                          value={this.state.deptValue}
                          onChange={this.handleChangeDeptValue}
                          valueLabelDisplay="auto"
                          valueLabelFormat={this.formatTimeValue}
                          min={parseTimeValue(this.state.departureData[0])}
                          max={parseTimeValue(
                            this.state.departureData[
                              this.state.departureData.length - 1
                            ]
                          )}
                          disableSwap
                        />
                      </div>
                    </div>

                    {sessionStorage.getItem("wayOfFlight") === "round_trip" && (
                      <div className="w-100">
                        <div className="d-flex flex-column gap-4p">
                          <span>
                            Take-off from&nbsp;
                            {sessionStorage.getItem("originLocationCode")}
                          </span>
                          <span>
                            {convertTimeFormat(this.state.arrivalData?.[0])}
                            -&nbsp;
                            {convertTimeFormat(
                              this.state.arrivalData[
                                this.state.arrivalData?.length - 1
                              ]
                            )}
                          </span>
                        </div>
                        <div>
                          <Slider
                            value={this.state.arrivValue}
                            onChange={this.handleChangeArrivValue}
                            valueLabelDisplay="auto"
                            valueLabelFormat={this.formatTimeValue}
                            min={parseTimeValue(this.state.arrivalData?.[0])}
                            max={parseTimeValue(
                              this.state.arrivalData[
                                this.state.arrivalData?.length - 1
                              ]
                            )}
                            disableSwap
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              centered
              show={this.state.priceModal}
              onHide={this.togglePriceModal}
              className="login_popup-sm"
            >
              <Modal.Header closeButton className="py-1">
                <h6 className="text-uppercase text-roboto fw-600 mb-0">
                  Price
                </h6>
              </Modal.Header>
              <Modal.Body className="p-0">
                <div className="blogs_side_bar_sec_about_us p-3">
                  <div className="d-flex flex-column gap-4p">
                    <div style={{ width: "100%" }}>
                      <div className="d-flex justify-content-between">
                        <span className="price-box-success">
                          Min : $ {this.state.minPrice?.toFixed(2)}
                        </span>
                        <span className="price-box-danger">
                          Max : $ {this.state.maxPrice?.toFixed(2)}
                        </span>
                      </div>
                      <Slider
                        min={this.state.minPrice}
                        max={this.state.maxPrice}
                        value={this.state.selectedMaxPrice}
                        // onChange={this.handleChangePrice}
                        onChange={(event, newValue) =>
                          this.handleChangePrice(event, newValue, "slider")
                        }
                        valueLabelDisplay="auto"
                      />
                      <div className="d-flex justify-content-end">
                        <span className="price-box-primary">
                          $ {this.state.selectedMaxPrice?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              centered
              show={this.state.stopModal}
              onHide={this.toggleStopModal}
              className="login_popup-sm"
            >
              <Modal.Header closeButton className="py-1">
                <h6 className="text-uppercase text-roboto fw-600 mb-0">Stop</h6>
              </Modal.Header>
              <Modal.Body className="p-0">
                <div className="blogs_side_bar_sec_about_us p-3">
                  <div className="d-flex flex-column gap-4p">
                    {this.state.stops.map((data) => {
                      return (
                        <div className="d-flex align-items-end justify-content-between">
                          <div className="d-flex align-items-center gap-1">
                            <input
                              className="update-filter-checkbox"
                              type="checkbox"
                              value={data}
                              id="direct"
                              onChange={() => {
                                this.handleCheckBoxChange(data);
                              }}
                              checked={this.state.updateStopCount?.includes(
                                data
                              )}
                              disabled={
                                !this.state.totalStopCount?.includes(data)
                              }
                            />
                            <label className="mb-0" htmlFor="direct">
                              {data === "0"
                                ? "Direct"
                                : data === "1"
                                ? "1 Stop"
                                : "2+ Stop"}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              centered
              show={this.state.airlinesModal}
              onHide={this.toggleAirlinesModal}
              className="login_popup-sm"
            >
              <Modal.Header closeButton className="py-1">
                <h6 className="text-uppercase text-roboto fw-600 mb-0">
                  Airlines
                </h6>
              </Modal.Header>
              <Modal.Body className="p-0">
                <div className="blogs_side_bar_sec_about_us p-3">
                  <div className="d-flex flex-column gap-4p">
                    <div className="d-flex align-items-center justify-content-between">
                      <span
                        onClick={this.handleSelectAll}
                        className={`btn btn-sm ${
                          this.state.selectAllFlights ? "btn-shadow" : ""
                        }`}
                      >
                        Select all
                      </span>
                      <span
                        onClick={this.handleClearAll}
                        className={`btn btn-sm ${
                          !this.state.selectAllFlights ? "btn-shadow" : ""
                        }`}
                      >
                        Clear all
                      </span>
                    </div>
                    {this.state.priceWithAirlines?.map((flight, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center justify-content-between"
                        >
                          <div className="d-flex align-items-center gap-1">
                            <input
                              className="update-filter-checkbox"
                              type="checkbox"
                              // id={flight.id}
                              value={flight.airline_code}
                              onChange={() =>
                                this.handleAirlineCheckboxChange(
                                  flight.airline_code
                                )
                              }
                              checked={this.state.selectedAirlines?.includes(
                                flight.airline_code
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`checkbox-${index}`}
                            >
                              {flight?.airline_name}
                            </label>
                          </div>
                          <label className="mb-0 text-dark">
                            &nbsp; $ {parseFloat(flight.totalPrice)?.toFixed(2)}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            {/* Inactivity Modal */}
            {this.state.showTimeOutModal && <div className="overlay"></div>}
            {this.state.showTimeOutModal && (
              <div className="sticky-modal">
                <div className="inactive-modal">
                  <div className="modal-header">
                    <div className="d-flex gap-1rem flex-wrap">
                      <div className="d-flex align-items-center flex-column gap-1rem">
                        <img
                          className="pulse"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                          }}
                          src="https://img.freepik.com/free-vector/customer-support-illustration_23-2148903318.jpg?t=st=1718351497~exp=1718355097~hmac=2765533fd293e493ca68e1670cfd2b4ecb57d3d6fa4c8c460cc5266fcfcb15e7&w=740"
                          alt=""
                        />
                        <span className="badge badge-pill badge-light text-success border">
                          Available Now
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <p className="text-capitalize fw-700 fs-22p mb-0">
                            Speak to a travel expert now
                          </p>
                          <p className="mb-0">
                            Call us now to get up to USD 15 OFF our fees
                          </p>
                          <p className="mb-0">
                            Use&nbsp;
                            <span className="border-success border-dashed text-success py-1 px-2">
                              promo code <span className="fw-600">JUN24</span>
                            </span>
                          </p>
                        </div>
                        <div className="d-flex flex-column">
                          <p className="text-primary fw-700 fs-22p mb-0 d-flex align-items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-telephone-fill call_vibration"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                              />
                            </svg>
                            +1 888-315-1615 (US)
                          </p>
                          <p className="mb-0 fw-600 fs-12p">Available 24/7</p>
                        </div>
                      </div>
                    </div>
                    <span className="close" onClick={this.closeTimeOutModal}>
                      &times;
                    </span>
                  </div>
                  <div className="modal-body">
                    <div className="d-flex flex-column">
                      <p className="fw-600 fs-20p mb-0">Get fares alerts!</p>
                      <p>
                        Be the first to know when fares drop for the route&nbsp;
                        <span className="fw-600">BOS - Boston Logan</span>
                        &nbsp;to&nbsp;
                        <span className="fw-600">MIA - Miami</span>
                      </p>
                      <div className="form-group">
                        <label className="form-label">
                          Enter Email Address
                        </label>
                        <div className="row">
                          <div className="col-sm-8">
                            <input
                              className="form-control mb-1"
                              type="email"
                              placeholder="Enter your email address"
                            />
                          </div>
                          <div className="col-sm-4">
                            <span className="btn custom-btn-primary w-100 mb-1">
                              Get Fare Alerts
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="fs-12p text-center mt-3">
                        By entering your email address you agree to
                        Ogule's&nbsp;
                        <span className="text-primary text-link">
                          Terms and conditions, Privacy Policy
                        </span>
                        &nbsp;and to receive email marketing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="p-2 d-flex flex-column gap-2">
              <div
                className="d-flex align-items-top justify-content-between gap-2 p-2 border"
                style={{ borderRadius: "12px" }}
              >
                <div className="d-flex align-items-top gap-2">
                  <span
                    className="btn btn-sm btn-icon"
                    onClick={() => {
                      this.backToHome();
                    }}
                  >
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7H15M1 7L7 13M1 7L7 1"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>

                  <div className="d-flex flex-column">
                    <div className="d-flex gap-2 align-items-center">
                      <p className="mb-0 fw-600">{this.state.iatacode_from}</p>
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.6667 18H5L11.4167 25M5 10.6667H23.6667L17.25 3.66667"
                          stroke="#979797"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p className="mb-0 fw-600">{this.state.iatacode_to}</p>
                    </div>
                    <div className="d-flex align-items-end">
                      <span
                        className="text-secondary fs-12p pr-1 border-right"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {this.state.departuredate}&nbsp;-&nbsp;
                        {this.state.wayOfFlight === "round_trip" && (
                          <>{this.state.returndate}</>
                        )}
                      </span>
                      <span className="text-secondary fs-12p px-1 border-right">
                        {parseInt(this.state.adult) +
                          parseInt(this.state.child) +
                          parseInt(this.state.infants)}
                        &nbsp;Traveler
                      </span>
                      <span className="text-secondary fs-12p pl-1">
                        {this.state.t_class_selected}
                      </span>
                    </div>
                  </div>
                </div>

                <span
                  className="btn btn-sm btn-icon"
                  onClick={this.toggleModal}
                >
                  <svg
                    width="20"
                    height="37"
                    viewBox="0 0 20 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.83594 5.83333H5.0026C4.56058 5.83333 4.13665 6.00893 3.82409 6.32149C3.51153 6.63405 3.33594 7.05797 3.33594 7.5V15C3.33594 15.442 3.51153 15.866 3.82409 16.1785C4.13665 16.4911 4.56058 16.6667 5.0026 16.6667H12.5026C12.9446 16.6667 13.3686 16.4911 13.6811 16.1785C13.9937 15.866 14.1693 15.442 14.1693 15V14.1667M13.3359 4.16667L15.8359 6.66667M16.9901 5.48759C17.3183 5.15938 17.5027 4.71424 17.5027 4.25009C17.5027 3.78594 17.3183 3.34079 16.9901 3.01259C16.6619 2.68438 16.2168 2.5 15.7526 2.5C15.2885 2.5 14.8433 2.68438 14.5151 3.01259L7.5026 10.0001V12.5001H10.0026L16.9901 5.48759Z"
                      stroke="#0C8CE9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.89 26.97V29.01H5.29V29.94H2.89V32.07H5.59V33H1.75V26.04H5.59V26.97H2.89ZM6.61414 30.22C6.61414 29.6667 6.72747 29.1767 6.95414 28.75C7.18747 28.3233 7.50081 27.9933 7.89414 27.76C8.29414 27.52 8.73747 27.4 9.22414 27.4C9.58414 27.4 9.93747 27.48 10.2841 27.64C10.6375 27.7933 10.9175 28 11.1241 28.26V25.6H12.2741V33H11.1241V32.17C10.9375 32.4367 10.6775 32.6567 10.3441 32.83C10.0175 33.0033 9.64081 33.09 9.21414 33.09C8.73414 33.09 8.29414 32.97 7.89414 32.73C7.50081 32.4833 7.18747 32.1433 6.95414 31.71C6.72747 31.27 6.61414 30.7733 6.61414 30.22ZM11.1241 30.24C11.1241 29.86 11.0441 29.53 10.8841 29.25C10.7308 28.97 10.5275 28.7567 10.2741 28.61C10.0208 28.4633 9.74747 28.39 9.45414 28.39C9.16081 28.39 8.88747 28.4633 8.63414 28.61C8.38081 28.75 8.17414 28.96 8.01414 29.24C7.86081 29.5133 7.78414 29.84 7.78414 30.22C7.78414 30.6 7.86081 30.9333 8.01414 31.22C8.17414 31.5067 8.38081 31.7267 8.63414 31.88C8.89414 32.0267 9.16747 32.1 9.45414 32.1C9.74747 32.1 10.0208 32.0267 10.2741 31.88C10.5275 31.7333 10.7308 31.52 10.8841 31.24C11.0441 30.9533 11.1241 30.62 11.1241 30.24ZM14.3515 26.76C14.1448 26.76 13.9715 26.69 13.8315 26.55C13.6915 26.41 13.6215 26.2367 13.6215 26.03C13.6215 25.8233 13.6915 25.65 13.8315 25.51C13.9715 25.37 14.1448 25.3 14.3515 25.3C14.5515 25.3 14.7215 25.37 14.8615 25.51C15.0015 25.65 15.0715 25.8233 15.0715 26.03C15.0715 26.2367 15.0015 26.41 14.8615 26.55C14.7215 26.69 14.5515 26.76 14.3515 26.76ZM14.9115 27.49V33H13.7715V27.49H14.9115ZM17.7382 28.42V31.47C17.7382 31.6767 17.7849 31.8267 17.8782 31.92C17.9782 32.0067 18.1449 32.05 18.3782 32.05H19.0782V33H18.1782C17.6649 33 17.2715 32.88 16.9982 32.64C16.7249 32.4 16.5882 32.01 16.5882 31.47V28.42H15.9382V27.49H16.5882V26.12H17.7382V27.49H19.0782V28.42H17.7382Z"
                      fill="#0C8CE9"
                    />
                  </svg>
                </span>
              </div>

              {this.state.filteredArray?.length > 0 && (
                <div
                  className="d-flex gap-3 mt-1"
                  style={{ overflowX: "scroll" }}
                >
                  <span
                    className="btn btn-sm btn-outline-secondary btn-pill"
                    style={{ borderRadius: "10px" }}
                    onClick={this.toggleStopModal}
                  >
                    Stop
                  </span>
                  <span
                    className="btn btn-sm btn-outline-secondary btn-pill"
                    style={{ borderRadius: "10px" }}
                    onClick={this.toggleAirlinesModal}
                  >
                    Airlines
                  </span>
                  <span
                    className="btn btn-sm btn-outline-secondary btn-pill"
                    style={{ borderRadius: "10px" }}
                    onClick={this.togglePriceModal}
                  >
                    Price
                  </span>
                  <span
                    className="btn btn-sm btn-outline-secondary btn-pill"
                    style={{ borderRadius: "10px" }}
                    onClick={this.toggleTimesModal}
                  >
                    Times
                  </span>
                </div>
              )}

              <div>
                {this?.state?.updatingFlights ? (
                  <div className="flights_box_section px-3">
                    <div className="p-2 skeleton_bg">
                      <p className="font-size-2 text-center">
                        We are finding the best flights for you.
                      </p>

                      <div className="row">
                        <div className="col-12">
                          <div class="progress pink">
                            <p style={{ fontWeight: "bold", fontSize: "21px" }}>
                              {this.state.isResponse
                                ? "100%"
                                : `${this.state.progress}%`}
                            </p>
                            <div
                              class="progress-bar"
                              style={{
                                width: `${this.state.progress}%`,
                                backgroundColor: "transparent",
                              }}
                            ></div>
                            ✈️
                          </div>
                        </div>
                      </div>

                      <ul className="px-4">
                        {this.state.progress <= 20 && (
                          <li className="fw-600">
                            Creating a travel masterpiece. Stay with us as the
                            strokes of excitement unfold
                          </li>
                        )}
                        {this.state.progress > 20 &&
                          this.state.progress <= 40 && (
                            <li className="fw-600">
                              Loading your next adventure... Sit tight, we're
                              preparing an unforgettable experience for you.
                            </li>
                          )}
                        {this.state.progress > 40 &&
                          this.state.progress <= 60 && (
                            <li className="fw-600">
                              Buckle up! We're speeding down the virtual runway
                              to unveil your personalized travel escape.
                            </li>
                          )}
                        {this.state.progress > 60 &&
                          this.state.progress <= 80 && (
                            <li className="fw-600">
                              Patience, traveler! We're weaving a tapestry of
                              incredible moments for your upcoming journey.
                            </li>
                          )}
                      </ul>

                      {Array.from({ length: 5 }).map((_, index) => (
                        <>
                          <div className="row">
                            <div className="col-1">
                              <Skeleton
                                variant="circle"
                                width={40}
                                height={40}
                              />
                            </div>
                            <div className="col-11">
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                              />
                            </div>
                          </div>
                          <p>
                            <Skeleton variant="text" width="100%" />
                            <Skeleton variant="text" width="100%" />
                            <Skeleton variant="text" width="100%" />
                            <Skeleton variant="text" width="100%" />
                          </p>
                        </>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column gap-2">
                    <div
                      className="ticket-card-container"
                      style={{ maxHeight: "83vh" }}
                    >
                      {sessionStorage.getItem("wayOfFlight") ===
                      "round_trip" ? (
                        <RoundTripMobileView
                          flightPairs={flightPairs}
                          displayedFlights={displayedFlights}
                          handleShowMore={this.handleShowMore}
                        />
                      ) : (
                        <OnewayMobileView
                          filteredArray={this.state.filteredArray}
                          displayedFlights={displayedFlights}
                          handleShowMore={this.handleShowMore}
                        />
                      )}
                    </div>
                    {(this.state.filteredArray?.length === 0 ||
                      this.state.updateStopCount?.length === 0) &&
                      !this.state.loading && (
                        <div
                          className="text-center fw-600 text-secondary my-5"
                          style={{ fontSize: "2rem" }}
                        >
                          No Flights Available
                        </div>
                      )}
                    {this.state.updatingFlights && (
                      <div className="loader px-3">
                        <div className="w-100 h-100 mt-4">
                          <div class="progress pink">
                            <p style={{ fontWeight: "bold", fontSize: "21px" }}>
                              {this.state.isResponse
                                ? "100%"
                                : `${this.state.progress}%`}
                            </p>
                            <div
                              class="progress-bar"
                              style={{
                                width: `${this.state.progress}%`,
                                backgroundColor: "transparent",
                              }}
                            ></div>
                            ✈️
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FlightsUpdate;
