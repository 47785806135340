import React, {Component} from 'react'
import Header from './Header'
import air_india from '../image/flights_icon/air_india.png';
import Skeleton from '@material-ui/lab/Skeleton';
import { Modal, Button } from 'react-bootstrap';
import {Growl} from 'primereact/growl';
class Wishlist extends Component{
	constructor(props) {
	  super(props);
	
	  this.state = {};
	}
	componentDidMount()
	{
		var login_data = JSON.parse(sessionStorage.getItem('login'));
		fetch('https://api.ogule.com/api/auth/whishlist',
			{
				headers: {
		            "Authorization": "Bearer " + login_data.access_token,
		          },
			}).then((response) => {
			response.json().then((result) => {		
				this.setState({ whishlist: result })
			})
		})
	}
	submit(item){
		sessionStorage.setItem('wayOfFlight', item.wayOfFlight)	
	  	sessionStorage.setItem('originLocationCode', item.originLocationCode)
	  	sessionStorage.setItem('destinationLocationCode', item.destinationLocationCode)
	  	sessionStorage.setItem('adults', item.adults)
	  	sessionStorage.setItem('departureDate', item.departureDate)
	  	sessionStorage.setItem('returnDate', item.returnDate)
	  	sessionStorage.setItem('children', item.children)
	  	sessionStorage.setItem('infants', item.infants)
	  	sessionStorage.setItem('travelClass', item.travelClass)
	  	sessionStorage.setItem('currencyCode', item.currencyCode)
	  	sessionStorage.setItem('booking_details', item.whishlist_result)
		console.warn(this.props.history.push('checkout'))
	}
	delete(item){
		this.setState({setShow:false})
		var login_data = JSON.parse(sessionStorage.getItem('login'));
	    fetch('https://api.ogule.com/api/auth/whishlist/destroy', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + login_data.access_token,
          },
          body: JSON.stringify({
           "whishlist_id": item     
          })
        }).then((response) => response.json())
        .then((res) => {
	      if(res.success){
	      	this.setState({ whishlist: res.whishlistdata })
	      	this.growl.show({severity: 'success', summary: 'Success', detail: 'Remove Whishlist'});
	      }
	      else{
	        alert(res.errors.mobile_no[0]);
	        }
	     }).catch((error) => {
	         console.error(error);
	        });
	}
	render(){
		return(
			<div>
				{
					(window.innerWidth > 768)?						
						<div className="profile_top_sec">
						<Growl ref={(el) => this.growl = el}></Growl>
						<div className="container-fluid">
							{
							  	this.state.whishlist?
							  	<div className="row p-2">
							  		{this.state.whishlist.map((item, index) =>
							  			<div className="col-6 p-2" key={item.id}>
							  			<div className="mobile_flight_box">
						      				<span className="badge badge-pill badge-primary">Value For Money</span>
						      				<div className="top_sec p-2">
							      				<div className="row">
							      					<div className="col-2">
							      						<img src={air_india} className="img-fluid" alt="air_india" />
							      					</div>
							      					<div className="col-7">						      						
							      						<h6>Air India</h6>
							      					</div>
							      					<div className="col-3">
							      						<h6>740</h6>
							      					</div>
							      				</div>
						      				</div>
						      				
						      				<div className="p-2 depart">										      					
						      					{
						      						(item.wayOfFlight === "round_trip")?
						      							<div>
						      							<div className="my_booking_round_trip">
						      							<div class="d-flex justify-content-start">
								      						<div className="flex-fill">
								      							<h6>Depart - {item.originLocationCode}</h6>
								      						</div>
								      						<div className="flex-fill">
								      							<h6>Destination - {item.destinationLocationCode}</h6>
								      						</div>
								      					</div>
								      					<h6>Date - {item.departureDate}</h6>
								      					</div>
								      					<div className="my_booking_round_trip">
								      					<div class="d-flex justify-content-start">
								      						<div className="flex-fill">
								      							<h6>Depart - {item.destinationLocationCode}</h6>
								      						</div>
								      						<div className="flex-fill">
								      							<h6>Destination - {item.originLocationCode}</h6>
								      						</div>
								      					</div>
								      					<h6>Date - {item.returnDate}</h6>
								      					</div>
								      					</div>
								      					:
								      					<div className="my_booking_round_trip">
								      					<div className="d-flex justify-content-start">
								      						<div className="flex-fill">
								      							<h6>Depart - {item.originLocationCode}</h6>
								      						</div>
								      						<div className="flex-fill">
								      							<h6>Destination - {item.destinationLocationCode}</h6>
								      						</div>
								      					</div>
								      					<h6>Date - {item.departureDate}</h6>
								      					</div>
						      					}
						      					<div className="container-fluid">
						      						<div className="row">
						      							<div className="col-9">
						      								<div className="row">
															    <div className="col-4 p-1">
															    	<p className="ariv_time">Adults</p>
															    	<p className="ariv_from">{item.adults}</p> 
															    </div>
															    <div className="col-4 p-1">
															    	<p className="ariv_time">Childs</p>
															    	<p className="ariv_from">{item.children}</p> 
															    </div>
															    <div className="col-4 p-1">
															    	<p className="ariv_time">Infants</p>
															    	<p className="ariv_from">{item.infants}</p> 
															    </div>
															</div>															
						      							</div>
						      						</div>
						      						<div className="d-flex justify-content-between mt-2">
													    <div className="book_now" onClick={() => {this.submit(item)}}>
													    	<button type="button" className="btn badge-pill btn-outline-primary btn-sm">Book Now</button>
													    </div>
													    <div className="wishlist" onClick={() => {this.setState({setShow:true})}}>
													    	<button type="button" className="btn badge-pill btn-outline-primary btn-sm">Remove</button>
													    </div>															    
													  </div>
													  <Modal centered size="md" show={this.state.setShow} onHide={this.handleClose} className="logout_conf_popup">				        
												        <Modal.Body className="px-4 py-3 text-center">
												        	<h4 className="mb-3">Are You Sure Delete?</h4>
												        	<Button variant="primary" className="mr-2 px-5 py-2" onClick={()=>{this.delete(item.id)}}>YES</Button>
												        	<Button variant="primary" className="mr-2 px-5 py-2" onClick={() => this.setState({setShow:false})}>NO</Button>
												        </Modal.Body>
												    </Modal>					      						
						      					</div>
						      				</div>
						      			</div>
						      			</div>
							  		)}
							  	</div>
							  	:
							  	<div className="flights_box_section">
					      			<div className="p-2 skeleton_bg" >
					      				<div className="row">
					      					<div className="col-3">
					      						<Skeleton variant="circle" width={40} height={40} />
					      					</div>
					      					<div className="col-9">
					      						<h3><Skeleton variant="text" width="100%" /></h3>
					      					</div>
					      				</div>				      					
				      					<p>
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      					</p>				      				
					      			</div>
					      			<div className="p-2 skeleton_bg mt-2">
					      				<div className="row">
					      					<div className="col-3">
					      						<Skeleton variant="circle" width={40} height={40} />
					      					</div>
					      					<div className="col-9">
					      						<h3><Skeleton variant="text" width="100%" /></h3>
					      					</div>
					      				</div>				      					
				      					<p>
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      					</p>				      				
					      			</div>
					      			<div className="p-2 skeleton_bg mt-2">
					      				<div className="row">
					      					<div className="col-3">
					      						<Skeleton variant="circle" width={40} height={40} />
					      					</div>
					      					<div className="col-9">
					      						<h3><Skeleton variant="text" width="100%" /></h3>
					      					</div>
					      				</div>				      					
				      					<p>
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      					</p>				      				
					      			</div>
					      			<div className="p-2 skeleton_bg mt-2">
					      				<div className="row">
					      					<div className="col-3">
					      						<Skeleton variant="circle" width={40} height={40} />
					      					</div>
					      					<div className="col-9">
					      						<h3><Skeleton variant="text" width="100%" /></h3>
					      					</div>
					      				</div>				      					
				      					<p>
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      						<Skeleton variant="text" width="100%" />
				      					</p>				      				
					      			</div>
							  	</div>
							  }
						</div>
					</div>								
					:
					<div className="container-fluid">
						<div className="deals_top_heading_mobile">
					      <h5>Wishlist</h5>
					    </div>
					    <Growl ref={(el) => this.growl = el}></Growl>
						{
						  	this.state.whishlist?
						  	<div className="flights_box_section">
						  		{this.state.whishlist.map((item, index) =>
						  			<div className="mobile_flight_box p-2" key={item.id}>
					      				<div className="top_sec p-2">
						      				<div className="row">
						      					<div className="col-2">
						      						<img src={air_india} className="img-fluid" alt="air_india" />
						      					</div>
						      					<div className="col-7">						      						
						      						<h6>Air India</h6>
						      					</div>
						      					<div className="col-3">
						      						<h6>740</h6>
						      					</div>
						      				</div>
					      				</div>
					      				
					      				<div className="p-2 depart">
					      					
					      					{
					      						(item.wayOfFlight === "round_trip")?
					      							<div>
					      							<div className="my_booking_round_trip">
					      							<div class="d-flex justify-content-start">
							      						<div className="flex-fill">
							      							<h6>Depart - {item.originLocationCode}</h6>
							      						</div>
							      						<div className="flex-fill">
							      							<h6>Destination - {item.destinationLocationCode}</h6>
							      						</div>
							      					</div>
							      					<h6>Date - {item.departureDate}</h6>
							      					</div>
							      					<div className="my_booking_round_trip">
							      					<div class="d-flex justify-content-start">
							      						<div className="flex-fill">
							      							<h6>Depart - {item.destinationLocationCode}</h6>
							      						</div>
							      						<div className="flex-fill">
							      							<h6>Destination - {item.originLocationCode}</h6>
							      						</div>
							      					</div>
							      					<h6>Date - {item.returnDate}</h6>
							      					</div>
							      					</div>
							      					:
							      					<div className="my_booking_round_trip">
							      					<div className="d-flex justify-content-start">
							      						<div className="flex-fill">
							      							<h6>Depart - {item.originLocationCode}</h6>
							      						</div>
							      						<div className="flex-fill">
							      							<h6>Destination - {item.destinationLocationCode}</h6>
							      						</div>
							      					</div>
							      					<h6>Date - {item.departureDate}</h6>
							      					</div>
					      					}
					      					<div className="container-fluid">
					      						<div className="row">
					      							<div className="col-9">
					      								<div className="row">
														    <div className="col-4 p-1">
														    	<p className="ariv_time">Adults</p>
														    	<p className="ariv_from">{item.adults}</p> 
														    </div>
														    <div className="col-4 p-1">
														    	<p className="ariv_time">Childs</p>
														    	<p className="ariv_from">{item.children}</p> 
														    </div>
														    <div className="col-4 p-1">
														    	<p className="ariv_time">Infants</p>
														    	<p className="ariv_from">{item.infants}</p> 
														    </div>
														</div>
					      							</div>
					      						</div>					      						
					      					</div>
					      					<div className="d-flex justify-content-between mt-2">
											    <div className="book_now" onClick={() => {this.submit(item)}}>
											    	<button type="button" className="btn badge-pill btn-outline-primary btn-sm">Book Now</button>
											    </div>
											    <div className="wishlist" onClick={() => {this.setState({setShow:true})}}>
											    	<button type="button" className="btn badge-pill btn-outline-primary btn-sm">Remove</button>
											    </div>															    
											  </div>
					      				</div>
					      				<Modal centered size="sm" show={this.state.setShow} onHide={this.handleClose} className="logout_conf_popup">				        
									        <Modal.Body className="px-4 py-3 text-center">
									        	<h4 className="mb-3">Are You Sure Delete?</h4>
									        	<Button variant="primary" className="mr-2 px-5 py-2" onClick={()=>{this.delete(item.id)}}>YES</Button>
									        	<Button variant="primary" className="mr-2 px-5 py-2" onClick={() => this.setState({setShow:false})}>NO</Button>
									        </Modal.Body>
									    </Modal>

					      			</div>
						  		)}
						  	</div>
						  	:
						  	<div className="flights_box_section">
				      			<div className="p-2 skeleton_bg" >
				      				<div className="row">
				      					<div className="col-3">
				      						<Skeleton variant="circle" width={40} height={40} />
				      					</div>
				      					<div className="col-9">
				      						<h3><Skeleton variant="text" width="100%" /></h3>
				      					</div>
				      				</div>				      					
			      					<p>
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      					</p>				      				
				      			</div>
				      			<div className="p-2 skeleton_bg mt-2">
				      				<div className="row">
				      					<div className="col-3">
				      						<Skeleton variant="circle" width={40} height={40} />
				      					</div>
				      					<div className="col-9">
				      						<h3><Skeleton variant="text" width="100%" /></h3>
				      					</div>
				      				</div>				      					
			      					<p>
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      					</p>				      				
				      			</div>
				      			<div className="p-2 skeleton_bg mt-2">
				      				<div className="row">
				      					<div className="col-3">
				      						<Skeleton variant="circle" width={40} height={40} />
				      					</div>
				      					<div className="col-9">
				      						<h3><Skeleton variant="text" width="100%" /></h3>
				      					</div>
				      				</div>				      					
			      					<p>
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      					</p>				      				
				      			</div>
				      			<div className="p-2 skeleton_bg mt-2">
				      				<div className="row">
				      					<div className="col-3">
				      						<Skeleton variant="circle" width={40} height={40} />
				      					</div>
				      					<div className="col-9">
				      						<h3><Skeleton variant="text" width="100%" /></h3>
				      					</div>
				      				</div>				      					
			      					<p>
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      						<Skeleton variant="text" width="100%" />
			      					</p>				      				
				      			</div>
						  	</div>
						  }
						<Header />
					</div>
				}
			</div>
		)
	}
}
export default Wishlist;