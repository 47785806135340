import React, { useEffect, useState } from "react";
import Header from "./Header";
import logo from "../image/NewUI/oguleNewLogo.png";
import { Link } from "react-router-dom";
import moment from "moment";

const Ogulecard = () => {
  const login_data = JSON.parse(sessionStorage.getItem("login"));

  const [cardDetails, setCardDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const [visibleTransactions, setVisibleTransactions] = useState(10);

  const handleGetCardDetails = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        "https://api.ogule.com/api/staff/get-credit-card",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: login_data.user._id,
          }),
        }
      );

      const res = await response.json();
      if (res.success) {
        setCardDetails(res.result);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    handleGetCardDetails();
  }, []);

  const handleLoadMore = () => {
    setVisibleTransactions((prevVisible) => prevVisible + 10);
  };

  return (
    <div>
      {window.innerWidth > 768 ? (
        <div className="d-flex flex-column gap-1rem">
          <div className="profile_top_sec border-0 shadow-sky p-4">
            <div className="d-flex flex-column">
              <h4 className="mb-3">
                <span className="text-primary">OGULE</span>&nbsp;WALLET
              </h4>
              <div className="d-flex gap-1rem justify-content-between">
                <p className="mb-0 fs-18p">
                  Account Holder :&nbsp;
                  <span className="fw-600">{login_data?.user?.name}</span>
                </p>
                <p className="mb-0 d-flex align-items-center fs-18p">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#FFC207"
                    className="bi bi-star-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  &nbsp;Points :&nbsp;
                  {!loader && (
                    <span className="fw-600">
                      {cardDetails?.card?.points ? cardDetails?.card?.points : "0"}
                    </span>
                  )}
                  {loader && <span className="fw-600">...</span>}
                </p>
              </div>
            </div>
          </div>

          <div
            className="overflow-auto"
            style={{ maxHeight: "calc(100vh - 250px)" }}
          >
            <div className="rounded-10p border overflow-hidden">
              <table className="table mb-0 text-center">
                <thead className="vertical-linear-gradient-primary text-white">
                  <tr>
                    <th className="text-nowrap" colSpan={1}>
                      Sl No.
                    </th>
                    <th className="text-nowrap" colSpan={1}>
                      Created at
                    </th>
                    <th className="text-nowrap" colSpan={1}>
                      Transaction type
                    </th>
                    <th className="text-nowrap" colSpan={1}>
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loader ? (
                    cardDetails?.transactions
                      ?.slice(0, visibleTransactions)
                      ?.map((item, index) => (
                        <tr key={item?._id}>
                          <td>{index + 1}.</td>
                          <td className="text-nowrap">
                            {moment(item?.createdAt).format(
                              "DD/MM/YYYY - h:mm A"
                            )}
                          </td>
                          <td>
                            {item?.transactionType === "in"
                              ? "Credit"
                              : item?.transactionType === "out"
                              ? "Debit"
                              : ""}
                          </td>
                          <td>{item?.amount}</td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td
                        colSpan="4"
                        className="text-center py-5 text-secondary"
                      >
                        <span className="typewriter-loader"></span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {!loader &&
              cardDetails?.transactions?.length > visibleTransactions && (
                <div className="text-center pb-3 mt-2">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={handleLoadMore}
                  >
                    Load More
                  </button>
                </div>
              )}
          </div>
        </div>
      ) : (
        <div>
          <div
            className="profile_top_sec shadow"
            style={{
              borderBottomLeftRadius: "50px",
              borderBottomRightRadius: "50px",
              height: "270px",
              background: "linear-gradient(#8CAFF8, #E3EDFF)",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", top: "1rem", left: "1rem" }}>
              <Link to="/dashboard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="#0D2A7D"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </Link>
            </div>
            <div className="d-flex justify-content-center mb-2">
              <img
                style={{
                  filter: "drop-shadow(0 0 0.4px #3d3d3d)",
                  width: "140px",
                }}
                src={logo}
                alt=""
              />
            </div>
            <div className="d-flex flex-column bg-white p-3 rounded-10p shadow-sm">
              <h5>
                <span className="text-primary">OGULE</span>&nbsp;CARD
              </h5>
              <div className="d-flex gap-1rem justify-content-between">
                <p className="mb-0 fs-18p">
                  Account Holder :&nbsp;
                  <span className="fw-600">{login_data?.user?.name}</span>
                </p>
                <p className="mb-0 d-flex align-items-center fs-18p">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#FFC207"
                    className="bi bi-star-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  &nbsp;Points :&nbsp;
                  {!loader && (
                    <span className="fw-600">
                      {cardDetails?.points ? cardDetails?.points : "0"}
                    </span>
                  )}
                  {loader && <span className="fw-600">...</span>}
                </p>
              </div>
            </div>
          </div>

          <Header />
        </div>
      )}
    </div>
  );
};

export default Ogulecard;