import React from "react";
import {
  calculateDuration,
  calculateTotalDuration,
  convertTimeFormat,
} from "../GlobalFunction";
import { useHistory } from "react-router-dom";
import Tooltip from "react-tooltip-lite";

const RoundTripMobileView = ({
  flightPairs,
  displayedFlights,
  handleShowMore,
}) => {
  const history = useHistory();

  const submit = (item) => {
    sessionStorage.setItem("booking_details", JSON.stringify(item));
    sessionStorage.setItem("booking_login", "yes");
    history.push("checkoutnew");
  };

  return (
    <>
      <div>
        {flightPairs
          ?.slice(0, displayedFlights)
          ?.map(
            ({
              flight1,
              flight2,
              totalPrice,
              finalPrice,
              adultPrice,
              childPrice,
              markUp,
            }) => {
              return (
                <div className="d-flex flex-column p-2 card gap-1 mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex gap-2 align-items-center">
                        <img
                          style={{ width: "40px" }}
                          src={
                            flight1?.flightDetails?.[0]?.flightInformation?.[0]
                              ?.companyId?.[0]?.customCarriers?.image
                          }
                          alt=""
                        />
                        <span className="d-flex align-items-center gap-1">
                          <span className="mb-0 fs-14p text-truncate w-75 text-capitalize pointer">
                            {
                              flight1?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.companyId?.[0]
                                ?.customCarriers?.airline_name
                            }
                          </span>
                          <span className="text-secondary rounded-circle line-h-7p">
                            <Tooltip
                              content={
                                flight1?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.companyId?.[0]
                                  ?.customCarriers?.airline_name
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                class="bi bi-info-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                              </svg>
                            </Tooltip>
                          </span>
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <span className="fs-14p fw-600">
                          {
                            flight1?.flightDetails?.[0]?.flightInformation?.[0]
                              ?.location?.[0]?.locationId
                          }
                        </span>
                        -
                        <span className="fs-14p fw-600">
                          {
                            flight1?.flightDetails[
                              flight1?.flightDetails?.length - 1
                            ]?.flightInformation?.[0]?.location?.[1]?.locationId
                          }
                        </span>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      <div className="d-flex flex-column">
                        <div>
                          {convertTimeFormat(
                            flight1?.flightDetails?.[0]?.flightInformation?.[0]
                              ?.productDateTime?.[0]?.timeOfDeparture?.[0]
                          )}
                        </div>
                        <p className="mb-0 text-secondary fs-12p">
                          {flight1?.flightDetails?.length === 1 ? (
                            "Direct"
                          ) : (
                            <span className="d-flex align-items-center gap-1">
                              <span className="pointer">
                                {flight1?.flightDetails?.length - 1}
                                &nbsp;Stop
                              </span>
                              <span className="text-secondary rounded-circle line-h-7p">
                                <Tooltip
                                  content={
                                    flight1?.flightDetails &&
                                    flight1?.flightDetails?.length > 0 &&
                                    flight1?.flightDetails
                                      .slice(
                                        0,
                                        flight1?.flightDetails.length - 1
                                      )
                                      .map(
                                        (item, index) =>
                                          calculateDuration(
                                            item,
                                            item?.flightInformation?.[0]
                                              ?.productDateTime?.[0]
                                              ?.timeOfDeparture?.[0],
                                            item?.flightInformation?.[0]
                                              ?.productDateTime?.[0]
                                              ?.dateOfDeparture?.[0],
                                            item?.flightInformation?.[0]
                                              ?.productDateTime?.[0]
                                              ?.timeOfArrival?.[0],
                                            item?.flightInformation?.[0]
                                              ?.productDateTime?.[0]
                                              ?.dateOfArrival?.[0],
                                            index
                                          ) +
                                          (index <
                                          item?.flightDetails?.length - 2
                                            ? ", "
                                            : "")
                                      )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    fill="currentColor"
                                    class="bi bi-info-circle"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                  </svg>
                                </Tooltip>
                              </span>
                            </span>
                          )}
                        </p>
                      </div>
                      <svg
                        style={{ marginTop: "14px" }}
                        width="49"
                        height="1"
                        viewBox="0 0 49 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line y1="0.5" x2="49" y2="0.5" stroke="black" />
                      </svg>
                      <div className="d-flex flex-column">
                        <div>
                          {convertTimeFormat(
                            flight1?.flightDetails[
                              flight1?.flightDetails?.length - 1
                            ]?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.timeOfArrival?.[0]
                          )}
                        </div>
                        <p className="mb-0 text-secondary fs-12p">
                          {calculateTotalDuration(flight1?.flightDetails)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="border-dashed w-75 mx-auto"></div>

                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex gap-2 align-items-center">
                        <img
                          style={{ width: "40px" }}
                          src={
                            flight2?.flightDetails?.[0]?.flightInformation?.[0]
                              ?.companyId?.[0]?.customCarriers?.image
                          }
                          alt=""
                        />
                        <span className="d-flex align-items-center gap-1">
                          <span className="mb-0 fs-14p text-truncate w-75 text-capitalize pointer">
                            {
                              flight2?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.companyId?.[0]
                                ?.customCarriers?.airline_name
                            }
                          </span>
                          <span className="text-secondary rounded-circle line-h-7p">
                            <Tooltip
                              content={
                                flight2?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.companyId?.[0]
                                  ?.customCarriers?.airline_name
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                class="bi bi-info-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                              </svg>
                            </Tooltip>
                          </span>
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <span className="fs-14p fw-600">
                          {
                            flight2?.flightDetails?.[0]?.flightInformation?.[0]
                              ?.location?.[0]?.locationId
                          }
                        </span>
                        -
                        <span className="fs-14p fw-600">
                          {
                            flight2?.flightDetails[
                              flight2?.flightDetails?.length - 1
                            ]?.flightInformation?.[0]?.location?.[1]?.locationId
                          }
                        </span>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      <div className="d-flex flex-column">
                        <div>
                          {convertTimeFormat(
                            flight2?.flightDetails?.[0]?.flightInformation?.[0]
                              ?.productDateTime?.[0]?.timeOfDeparture?.[0]
                          )}
                        </div>
                        <p className="mb-0 text-secondary fs-12p">
                          {flight2?.flightDetails?.length === 1 ? (
                            "Direct"
                          ) : (
                            <span className="d-flex align-items-center gap-1">
                              <span className="pointer">
                                {flight2?.flightDetails?.length - 1}
                                &nbsp;Stop
                              </span>
                              <span className="text-secondary rounded-circle line-h-7p">
                                <Tooltip
                                  content={
                                    flight2?.flightDetails &&
                                    flight2?.flightDetails?.length > 0 &&
                                    flight2?.flightDetails
                                      .slice(
                                        0,
                                        flight2?.flightDetails.length - 1
                                      )
                                      .map(
                                        (item, index) =>
                                          calculateDuration(
                                            item,
                                            item?.flightInformation?.[0]
                                              ?.productDateTime?.[0]
                                              ?.timeOfDeparture?.[0],
                                            item?.flightInformation?.[0]
                                              ?.productDateTime?.[0]
                                              ?.dateOfDeparture?.[0],
                                            item?.flightInformation?.[0]
                                              ?.productDateTime?.[0]
                                              ?.timeOfArrival?.[0],
                                            item?.flightInformation?.[0]
                                              ?.productDateTime?.[0]
                                              ?.dateOfArrival?.[0],
                                            index
                                          ) +
                                          (index <
                                          item?.flightDetails?.length - 2
                                            ? ", "
                                            : "")
                                      )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    fill="currentColor"
                                    class="bi bi-info-circle"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                  </svg>
                                </Tooltip>
                              </span>
                            </span>
                          )}
                        </p>
                      </div>
                      <svg
                        style={{ marginTop: "14px" }}
                        width="49"
                        height="1"
                        viewBox="0 0 49 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line y1="0.5" x2="49" y2="0.5" stroke="black" />
                      </svg>
                      <div className="d-flex flex-column">
                        <div>
                          {convertTimeFormat(
                            flight2?.flightDetails[
                              flight2?.flightDetails?.length - 1
                            ]?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.timeOfArrival?.[0]
                          )}
                        </div>
                        <p className="mb-0 text-secondary fs-12p">
                          {calculateTotalDuration(flight2?.flightDetails)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="border-dashed w-75 mx-auto"></div>

                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column gap-2 align-items-top">
                      <p className="d-flex mb-0 fw-600 text-success fs-18p">
                        ${totalPrice?.toFixed(2)}
                        <p className="text-dark fs-12p mt-1 ml-1">/ person</p>
                      </p>
                      <div>
                        <p className="mb-0 fs-14p">
                          ${finalPrice?.toFixed(2)} total
                        </p>
                      </div>
                    </div>
                    <span
                      className="btn btn-sm btn-outline-primary"
                      onClick={() => {
                        let newItem = {
                          flights: [flight1, flight2],
                          price: finalPrice?.toFixed(2),
                          adult: adultPrice?.toFixed(2),
                          child: childPrice?.toFixed(2),
                          markUp: markUp,
                        };
                        submit(newItem);
                      }}
                    >
                      Book
                    </span>
                  </div>
                </div>
              );
            }
          )}
        {displayedFlights < flightPairs?.length && (
          <div>
            <button
              className="btn custom-btn-primary mt-3 w-100 mb-3"
              onClick={handleShowMore}
            >
              Show More Results
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default RoundTripMobileView;
