import React from 'react'
import {Redirect, Route} from 'react-router-dom'

const Protected = ({component:Cmp,...rest}) => (
	<Route
		{...rest}
		render={(props)=>
			sessionStorage.getItem('login') || sessionStorage.getItem('guest_user') ? (
				<Cmp {...props} />
			):
			<Redirect to='/login' />
		}
	/>
)

export default Protected;