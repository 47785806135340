import React, { Component } from "react";
import Header from "./Header";
import { RadioButton } from "primereact/radiobutton";
import DatePicker from "react-datepicker";
import creditCard from "../image/NewUI/credit card.png";
import ogule from "../image/NewUI/oguleNewLogo.png";
import Carousel from "react-elastic-carousel";
import { format } from "date-fns";
import { Button } from "primereact/button";
import { Modal, ButtonToolbar, Dropdown } from "react-bootstrap";
import noImg from "../image/NewUI/no-image.png";
import hour24 from "../image/NewUI/24Hours.png";
import callCenter from "../image/NewUI/callCenter.png";
import flexiblePayment from "../image/NewUI/flexiblePayment.png";
import confirmationBooking from "../image/NewUI/confirmationBooking.png";

class NewHome extends Component {
  constructor(props) {
    super(props);
    this.departureDatePickerRef = React.createRef();
    this.datePickerRef = React.createRef();
    this.travellerRef = React.createRef();
    this.toInputRef = React.createRef();
    this.state = {
      setShow: false,
      iatacode_from: sessionStorage.getItem("iatacode_from") || "BHM",
      airport_name_from:
        sessionStorage.getItem("airport_name_from") || "SHUTTLESWORTH INTL",
      country_from: sessionStorage.getItem("country_from") || "US",
      city_from: sessionStorage.getItem("city_from") || "BIRMINGHAM",
      iatacode_to: sessionStorage.getItem("iatacode_to") || "DEN",
      airport_name_to:
        sessionStorage.getItem("airport_name_to") || "DENVER INTERNATIONAL",
      country_to: sessionStorage.getItem("country_to") || "US",
      city_to: sessionStorage.getItem("city_to") || "DENVER",

      departuredate: format(new Date(), "yyyy-MM-dd"),
      returndate: format(
        new Date().setDate(new Date().getDate() + 1),
        "yyyy-MM-dd"
      ),

      child: 0,
      adult: 1,
      infants: 0,
      totalCounts: 1,
      t_class: [
        // { name: "BASIC ECONOMY" },
        { name: "Economy", value: "ECONOMY" },
        { name: "Premium Economy", value: "PREMIUM_ECONOMY" },
        { name: "Business", value: "BUSINESS" },
        { name: "First", value: "FIRST" },
      ],
      t_class_checked: 0,
      t_class_selected: "ECONOMY",
      trip: sessionStorage.getItem("wayOfFlight") || "round_trip",
      currencyCode: "USD",
      selectedCurrency: "USD",
      selectedAirline: "",
      isDirect: false,
      showFormModal: false,
      showTimeOutModal: false,
      timer: null,
      timerCount: 0,
    };
    this.inactivityTime = 300000;
    this.handleUserActivity = this.handleUserActivity.bind(this);
    this.closeTimeOutModal = this.closeTimeOutModal.bind(this);

    fetch("https://test.api.amadeus.com/v1/security/oauth2/token", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
      }),
      body: "grant_type=client_credentials&client_id=lFD9B6KFg1j3kMKGLzFgadbAKFEzOwVw&client_secret=XmvZgKrjBJAiAMfE", // <-- Post parameters
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.access_token) {
          this.setState({ apitoken: res.access_token });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    fetch("https://api.ogule.com/api/frontend/bloglist").then(
      (response) => {
        response.json().then((result) => {
          this.setState({ bloglist: result.bloglist });
        });
      }
    );
    fetch("https://api.ogule.com/api/frontend/offerpagelist").then(
      (response) => {
        response.json().then((result) => {
          this.setState({ dealslist: result.offerpagelsit });
        });
      }
    );
  }

  searchfrom(val) {
    if(val === this.state.iatacode_to){
      alert('origin and destination locations cannot be the same.');
      this.setState({
        iatacode_from: "",
        airport_name_from: "",
        country_from: "",
        city_from: ""
      })
      return;
    }
    console.warn(this.state.apitoken);
    this.setState({ iatacode_from: val });
    if (
      this.state.fromdata &&
      this.state.fromdata.some((f) => f.iataCode === val)
    ) {
      if (this.toInputRef.current) {
        this.toInputRef.current.focus();
      }
    }
    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + this.state.apitoken,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          var data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === res.data[i].iataCode) {
              sessionStorage.setItem("iatacode_from", res.data[i].iataCode);
              sessionStorage.setItem("airport_name_from", res.data[i].name);
              sessionStorage.setItem(
                "country_from",
                res.data[i].address.countryCode
              );
              sessionStorage.setItem("city_from", res.data[i].address.cityName);
              this.setState({
                iatacode_from: res.data[i].iataCode,
                airport_name_from: res.data[i].name,
                country_from: res.data[i].address.countryCode,
                city_from: res.data[i].address.cityName,
              });
            } else {
            }
          }
          console.warn(res);
          this.setState({
            fromdata: res.data,
          });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  searchto(val) {
    //console.warn(val)
    if(val === this.state.iatacode_from){
      alert('origin and destination locations cannot be the same.');
      this.setState({
        iatacode_to: "",
        airport_name_to: "",
        country_to: "",
        city_to: ""
      })
      return;
    }
    this.setState({ iatacode_to: val });
    if (
      this.state.todata &&
      this.state.todata.some((f) => f.iataCode === val)
    ) {
      if (this.datePickerRef.current) {
        this.datePickerRef.current.setOpen(true);
      }
    }

    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + this.state.apitoken,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          var data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === res.data[i].iataCode) {
              sessionStorage.setItem("iatacode_to", res.data[i].iataCode);
              sessionStorage.setItem("airport_name_to", res.data[i].name);
              sessionStorage.setItem(
                "country_to",
                res.data[i].address.countryCode
              );
              sessionStorage.setItem("city_to", res.data[i].address.cityName);
              this.setState({
                iatacode_to: res.data[i].iataCode,
                airport_name_to: res.data[i].name,
                country_to: res.data[i].address.countryCode,
                city_to: res.data[i].address.cityName,
              });
            } else {
            }
          }
          console.warn(res);
          this.setState({
            todata: res.data,
          });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handledeparturedate = (date) => {
    if (!date) {
      return null;
    }
    sessionStorage.setItem("departureDate", format(date, "yyyy-MM-dd"));
    sessionStorage.setItem("mobile_departureDate_oneway", format(date, "MMM dd"));
    sessionStorage.setItem(
      "mobile_departureDate_oneway_day",
      format(date, "iii")
    );
    this.setState({
      departuredate: date,
    });
    if (this.state.trip === "one_way") {
      this.setState({ setShow: true });
    }
    if (this.departureDatePickerRef.current) {
      this.departureDatePickerRef.current.setOpen(true);
    }
    const returnDate = new Date(date);
    this.handlereturndate(returnDate.setDate(returnDate.getDate() + 1), "true");
  };

  handlereturndate = (date, autoUpdate) => {
    if (!date) {
      return null;
    }
    sessionStorage.setItem("returnDate", format(date, "yyyy-MM-dd"));
    sessionStorage.setItem("mobile_returnDate_oneway", format(date, "MMM dd"));
    sessionStorage.setItem("mobile_returnDate_oneway_day", format(date, "iii"));
    this.setState({
      returndate: date,
    });
    if (autoUpdate !== "true" && typeof autoUpdate !== "string") {
      this.setState({
        setShow: true,
      });
    }
  };

  handleClose = () => {
    this.setState({ setShow: false, setShow_from: false, setShow_to: false });
  };
  handleShow = () => {
    this.setState({
      setShow: true,
    });
  };

  searchflights() {
    sessionStorage.setItem("originLocationCode", this.state.iatacode_from);
    sessionStorage.setItem("destinationLocationCode", this.state.iatacode_to);
    sessionStorage.setItem("adults", this.state.adult);
    sessionStorage.setItem("children", this.state.child);
    sessionStorage.setItem("infants", this.state.infants);
    sessionStorage.setItem("travelClass", this.state.t_class_selected);
    sessionStorage.setItem("currencyCode", this.state.currencyCode);
    sessionStorage.setItem("wayOfFlight", this.state.trip);
    sessionStorage.setItem("selected_airline", this.state.selectedAirline);
    this.props.history.push("flights");
  }

  handleSelectAirline(value) {
    this.setState({ selectedAirline: value });
  }

  Fromflights() {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push("fromflights"));
  }
  Toflights() {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push("toflights"));
  }
  Onewaydate() {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push("onwayDate"));
  }
  RoundTripdate() {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push("roundTripDate"));
  }

  Singleblog(item) {
    sessionStorage.setItem("single_blog", item);
    console.warn(this.props.history.push("single_blog"));
  }
  from_and_to_exchange() {
    sessionStorage.setItem("iatacode_from", this.state.iatacode_to);
    sessionStorage.setItem("airport_name_from", this.state.airport_name_to);
    sessionStorage.setItem("country_from", this.state.country_to);
    sessionStorage.setItem("iatacode_to", this.state.iatacode_from);
    sessionStorage.setItem("airport_name_to", this.state.airport_name_from);
    sessionStorage.setItem("country_to", this.state.country_from);
    this.setState({
      iatacode_from: this.state.iatacode_to,
      airport_name_from: this.state.airport_name_to,
      country_from: this.state.country_to,
      iatacode_to: this.state.iatacode_from,
      airport_name_to: this.state.airport_name_from,
      country_to: this.state.country_from,
    });
  }
  trip(val) {
    sessionStorage.setItem("trip", val);
    this.setState({ trip: val });
  }
  search_data(val) {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push(val));
  }
  handleCurrencyChange = (eventKey) => {
    this.setState({ selectedCurrency: eventKey });
  };

  returnDay = (day) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = daysOfWeek[day];
    return dayName;
  };

  handleResize = () => {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  componentDidMount() {
    sessionStorage.setItem("departureDate", format(new Date(), "yyyy-MM-dd"));
    sessionStorage.setItem(
      "returnDate",
      format(new Date().setDate(new Date().getDate() + 1), "yyyy-MM-dd")
    );
    sessionStorage.setItem(
      "mobile_departureDate_oneway",
      format(new Date(), "MMM dd")
    );
    sessionStorage.setItem(
      "mobile_departureDate_oneway_day",
      format(new Date(), "iii")
    );
    this.setState({
      departuredate: new Date(),
    });

    const events = ["mousemove", "mousedown", "keydown", "touchstart"];
    events.forEach((event) =>
      window.addEventListener(event, this.handleUserActivity)
    );

    this.handleUserActivity();
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }

  componentWillUnmount() {
    const events = ["mousemove", "mousedown", "keydown", "touchstart"];
    events.forEach((event) =>
      window.removeEventListener(event, this.handleUserActivity)
    );
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }

  handleFormModal = () => {
    this.setState((prevState) => ({
      showFormModal: !prevState.showFormModal,
    }));
  };

  // handleUserActivity() {
  //   if (this.state.timer) {
  //     clearTimeout(this.state.timer);
  //   }
  //   if (!this.state.showTimeOutModal) {
  //     this.setState({
  //       timer: setTimeout(() => {
  //         this.setState({ showTimeOutModal: true });
  //       }, this.inactivityTime),
  //     });
  //   }
  // }
  handleUserActivity() {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }

    if (this.state.timerCount < 2) {
      const timerDuration = this.state.timerCount === 0 ? 15000 : 30000; // 15 seconds for the first time, 30 seconds for the second time
      this.setState({
        timer: setTimeout(() => {
          this.setState({ showTimeOutModal: true });
        }, timerDuration),
      });
    } else if (!this.state.showTimeOutModal) {
      this.setState({
        timer: setTimeout(() => {
          this.setState({ showTimeOutModal: true });
        }, this.inactivityTime),
      });
    }
  }

  closeTimeOutModal() {
    this.setState(
      (prevState) => ({
        showTimeOutModal: false,
        timerCount: prevState.timerCount + 1,
      }),
      this.handleUserActivity
    );
  }

  render() {
    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Header />

            <Modal
              show={this.state.setShow}
              onHide={this.handleClose}
              className="login_popup"
            >
              <Modal.Header closeButton>
                <ButtonToolbar>
                  {this.state.t_class.map((item) => (
                    <div>
                      {this.state.t_class_selected === item.value ? (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded mr-1 custom-btn-primary"
                        />
                      ) : (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded p-button-secondary mr-1 custom-btn-primary"
                          onClick={() =>
                            this.setState({ t_class_selected: item.value })
                          }
                        />
                      )}
                    </div>
                  ))}
                </ButtonToolbar>
              </Modal.Header>
              <Modal.Body>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Adult (Above 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult - 1 });
                        if (this.state.adult - 1 < this.state.infants) {
                          this.setState({ infants: this.state.adult - 1 });
                        }
                      }}
                      disabled={this.state.adult === 1}
                    />
                    <input
                      type="text"
                      value={this.state.adult}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult + 1 });
                      }}
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Child (2 years to 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child - 1 })
                      }
                      disabled={this.state.child === 0}
                    />
                    <input
                      type="text"
                      value={this.state.child}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child + 1 })
                      }
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Infants (Below 2 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants - 1 })
                      }
                      disabled={this.state.infants === 0}
                    />
                    <input
                      type="text"
                      value={this.state.infants}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants + 1 })
                      }
                      disabled={this.state.infants === this.state.adult}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  label="Submit"
                  className="p-button-raised p-button-rounded mr-2 custom-btn-primary"
                  onClick={() => this.setState({ setShow: false })}
                />
              </Modal.Footer>
            </Modal>

            {/* Inactivity Modal */}
            {this.state.showTimeOutModal && <div className="overlay"></div>}
            {this.state.showTimeOutModal && (
              <div className="sticky-modal">
                <div className="inactive-modal">
                  <div className="modal-header">
                    <div className="d-flex justify-content-between gap-1rem flex-wrap">
                      <div className="d-flex align-items-center flex-column gap-2">
                        <img
                          className="pulse"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                          }}
                          src="https://img.freepik.com/free-vector/customer-support-illustration_23-2148903318.jpg?t=st=1718351497~exp=1718355097~hmac=2765533fd293e493ca68e1670cfd2b4ecb57d3d6fa4c8c460cc5266fcfcb15e7&w=740"
                          alt=""
                        />
                        <span className="badge badge-pill badge-light text-success border">
                          Available Now
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <p className="text-capitalize fw-700 fs-22p mb-0">
                            Let us help you book!
                          </p>
                          <p className="mb-0">
                            Speak with a travel expert and get assistance 24/7
                          </p>
                        </div>
                        <p className="text-primary fw-700 fs-22p mb-0 d-flex align-items-center gap-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-telephone-fill call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                            />
                          </svg>
                          +1 888-315-1615 (US)
                        </p>
                      </div>
                    </div>
                    <span className="close" onClick={this.closeTimeOutModal}>
                      &times;
                    </span>
                  </div>
                  <div className="modal-body bg-light">
                    <div className="d-flex flex-column align-items-center px-2">
                      <p className="fw-600 fs-20p">
                        Why to Book with Call Center
                      </p>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <img
                              style={{
                                height: "40px",
                                filter: "hue-rotate(204deg)",
                              }}
                              src={callCenter}
                              alt=""
                            />
                            <div className="d-flex flex-column">
                              <p className="fs-16p">
                                Expert guidance by our&nbsp;
                                <span className="fw-600 text-primary">
                                  Travel experts
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <img
                              style={{
                                height: "40px",
                                filter: "hue-rotate(204deg)",
                              }}
                              src={confirmationBooking}
                              alt=""
                            />
                            <div className="d-flex flex-column">
                              <p className="fs-16p">
                                Immediate&nbsp;
                                <span className="fw-600 text-primary">
                                  Booking confirmation
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <img
                              style={{
                                height: "40px",
                                filter: "hue-rotate(204deg)",
                              }}
                              src={hour24}
                              alt=""
                            />
                            <div className="d-flex flex-column">
                              <p className="fs-16p">
                                24 hour&nbsp;
                                <span className="fw-600 text-primary">
                                  Cancellation
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <img
                              style={{
                                height: "40px",
                                filter: "hue-rotate(204deg)",
                              }}
                              src={flexiblePayment}
                              alt=""
                            />
                            <div className="d-flex flex-column">
                              <p className="fs-16p">
                                <span className="fw-600 text-primary">
                                  Flexible payment
                                </span>
                                &nbsp;plans
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="px-5">
              <div className="custom-home-banner">
                <div className="row p-4">
                  <div className="col-6 p-0">
                    <div className="custom-home-banner-form">
                      <div className="d-flex gap-1rem justify-content-between">
                        <div className="d-flex gap-1rem">
                          <div className="d-flex align-items-center gap-12p">
                            <RadioButton
                              value="one_way"
                              name="One Way"
                              onChange={(e) => {
                                this.trip(e.value);
                              }}
                              checked={this.state.trip === "one_way"}
                            />
                            <b>One Way</b>
                          </div>
                          <div className="d-flex align-items-center gap-12p">
                            <RadioButton
                              className="ml-3"
                              value="round_trip"
                              name="Round Trip"
                              onChange={(e) => {
                                this.trip(e.value);
                              }}
                              checked={this.state.trip === "round_trip"}
                            />
                            <b>Round Trip</b>
                          </div>
                        </div>
                      </div>
                      <form>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label
                                htmlFor="from"
                                className="fw-600 custom-text-primary d-flex align-items-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-geo-alt"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                </svg>
                                &nbsp;From
                              </label>
                              {this.state.city_from === "" ? (
                                <input
                                  className="form-control custom-text-primary rounded-10p datepicker-fs mb-2"
                                  type="text"
                                  autocomplete="off"
                                  id="from"
                                  list="fromdata"
                                  value={this.state.iatacode_from}
                                  onChange={(e) =>
                                    this.searchfrom(e.target.value)
                                  }
                                />
                              ) : (
                                <span className="border custom-text-primary rounded-10p mb-2 d-flex p-2">
                                  <span className="d-flex align-items-center justify-content-between rounded gap-2 w-100">
                                    <span>
                                      {this.state.city_from}&nbsp;(
                                      {this.state.iatacode_from})
                                    </span>

                                    <span
                                      className="d-flex align-items-center justify-content-center pointer"
                                      onClick={() => {
                                        this.setState({
                                          iatacode_from: "",
                                          city_from: "",
                                          airport_name_from: "",
                                          country_from: "",
                                        });
                                      }}
                                    >
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                          fill="#C9C9C9"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                </span>
                              )}
                              <p className="text-secondary" style={{marginLeft: 5}}>
                                {this.state.airport_name_from}&nbsp;
                                {this.state.country_from}
                              </p>
                            </div>

                            <div>
                              {this.state.fromdata ? (
                                <div>
                                  <datalist id="fromdata">
                                    {this.state.fromdata.map((item) => (
                                      <option value={item.iataCode}>
                                        {item.name},{item.address.cityName}{" "}
                                        {item.address.countryName}
                                      </option>
                                    ))}
                                  </datalist>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form-group">
                              <label
                                htmlFor="to"
                                className="fw-600 custom-text-primary d-flex align-items-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-geo-alt"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                </svg>
                                &nbsp;To
                              </label>
                              {this.state.city_to === "" ? (
                                <input
                                  className="form-control custom-text-primary rounded-10p datepicker-fs mb-2"
                                  type="text"
                                  id="to"
                                  list="todata"
                                  value={this.state.iatacode_to}
                                  onChange={(e) =>
                                    this.searchto(e.target.value)
                                  }
                                  ref={this.toInputRef}
                                />
                              ) : (
                                <span className="border custom-text-primary rounded-10p mb-2 d-flex p-2">
                                  <span className="d-flex align-items-center justify-content-between rounded gap-2 w-100">
                                    <span>
                                      {this.state.city_to}&nbsp;(
                                      {this.state.iatacode_to})
                                    </span>

                                    <span
                                      className="d-flex align-items-center justify-content-center pointer"
                                      onClick={() => {
                                        this.setState({
                                          iatacode_to: "",
                                          city_to: "",
                                          airport_name_to: "",
                                          country_to: "",
                                        });
                                      }}
                                    >
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                          fill="#C9C9C9"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                </span>
                              )}
                              <p className="text-secondary" style={{marginLeft: 5}}>
                                {this.state.airport_name_to}&nbsp;
                                {this.state.country_to}
                              </p>
                            </div>
                            <div>
                              {this.state.todata ? (
                                <div>
                                  <datalist id="todata">
                                    {this.state.todata.map((item) => (
                                      <option value={item.iataCode}>
                                        {item.name},{item.address.cityName}{" "}
                                        {item.address.countryName}
                                      </option>
                                    ))}
                                  </datalist>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          {this.state.trip === "round_trip" ? (
                            <div className="col-12">
                              <div className="row">
                                <div className="col-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor="departure"
                                      className="fw-600 custom-text-primary d-flex align-items-center"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="20"
                                        fill="none"
                                        viewBox="0 0 18 20"
                                      >
                                        <path
                                          d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                                          stroke="currentColor"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      &nbsp;Departure
                                    </label>
                                    <DatePicker
                                      className="datepicker-fs"
                                      selected={
                                        new Date(this.state.departuredate)
                                      }
                                      onChange={this.handledeparturedate}
                                      minDate={new Date()}
                                      dateFormat="d MMM, yy"
                                      ref={this.datePickerRef}
                                    />
                                    <p className="text-secondary" style={{marginLeft: 5}}>
                                      {this.returnDay(
                                        new Date(
                                          this.state.departuredate
                                        ).getDay()
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor="return"
                                      className="fw-600 custom-text-primary d-flex align-items-center"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="20"
                                        fill="none"
                                        viewBox="0 0 18 20"
                                      >
                                        <path
                                          d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                                          stroke="currentColor"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      &nbsp;Return
                                    </label>
                                    <DatePicker
                                      className="datepicker-fs"
                                      selected={new Date(this.state.returndate)}
                                      onChange={this.handlereturndate}
                                      minDate={
                                        new Date(this.state.departuredate)
                                      }
                                      dateFormat="d MMM, yy"
                                      maxDate={
                                        this.state.departuredate
                                          ? new Date(
                                              new Date(
                                                this.state.departuredate
                                              ).setDate(
                                                new Date(
                                                  this.state.departuredate
                                                ).getDate() + 361
                                              )
                                            )
                                          : null
                                      }
                                      ref={this.departureDatePickerRef}
                                    />
                                    <p className="text-secondary" style={{marginLeft: 5}}>
                                      {this.returnDay(
                                        new Date(this.state.returndate).getDay()
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-6">
                              <div className="form-group">
                                <label
                                  htmlFor="departure"
                                  className="fw-600 custom-text-primary d-flex align-items-center"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="20"
                                    fill="none"
                                    viewBox="0 0 18 20"
                                  >
                                    <path
                                      d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  &nbsp;Departure
                                </label>
                                <DatePicker
                                  className="datepicker-fs"
                                  selected={new Date(this.state.departuredate)}
                                  onChange={this.handledeparturedate}
                                  minDate={new Date()}
                                  dateFormat="d MMM, yy"
                                />
                                <p className="text-secondary">
                                  {this.returnDay(
                                    new Date(this.state.departuredate).getDay()
                                  )}
                                </p>
                              </div>
                            </div>
                          )}

                          <div className="col-12">
                            <div className="form-group mb-4">
                              <label
                                htmlFor="departure"
                                className="fw-600 custom-text-primary"
                              >
                                Class
                              </label>
                              <ul
                                className="d-flex justify-content-between gap-2"
                                style={{ overflowX: "scroll" }}
                              >
                                {this.state.t_class.map((item) => (
                                  <li
                                    className={`custom-tab-list text-capitalize text-nowrap ${
                                      this.state.t_class_selected === item.value
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      this.setState({
                                        t_class_selected: item.value,
                                      })
                                    }
                                  >
                                    {item?.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="form-group traveller_class">
                              <label className="fw-600 custom-text-primary d-flex align-items-center">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 21 25"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.5024 12.0838C9.34083 12.0839 8.20528 11.7396 7.23938 11.0944C6.27347 10.4491 5.5206 9.53197 5.07597 8.45885C4.63134 7.38573 4.51492 6.20485 4.74144 5.06556C4.96796 3.92627 5.52724 2.87974 6.34855 2.05832C7.16986 1.23689 8.21631 0.677472 9.35557 0.450798C10.4948 0.224125 11.6757 0.340382 12.7489 0.784867C13.8221 1.22935 14.7394 1.9821 15.3847 2.94791C16.0301 3.91373 16.3745 5.04923 16.3745 6.21082C16.3731 7.76783 15.7539 9.26065 14.6531 10.3617C13.5522 11.4627 12.0594 12.0821 10.5024 12.0838ZM10.5024 2.03155C9.67561 2.03139 8.86733 2.27643 8.17979 2.73568C7.49226 3.19493 6.95636 3.84776 6.63988 4.61161C6.32341 5.37545 6.24056 6.21599 6.40182 7.02692C6.56309 7.83785 6.96122 8.58274 7.54586 9.16739C8.1305 9.75203 8.87539 10.1502 9.68632 10.3114C10.4973 10.4727 11.3378 10.3898 12.1016 10.0734C12.8655 9.75688 13.5183 9.22098 13.9776 8.53345C14.4368 7.84592 14.6819 7.03763 14.6817 6.21082C14.6804 5.1028 14.2397 4.04052 13.4562 3.25703C12.6727 2.47354 11.6104 2.03282 10.5024 2.03155ZM19.8772 24.6616H1.12768L0.28125 23.8152C0.28125 22.4729 0.545629 21.1438 1.05929 19.9037C1.57295 18.6636 2.32584 17.5369 3.27496 16.5877C4.22408 15.6386 5.35086 14.8857 6.59095 14.3721C7.83104 13.8584 9.16016 13.594 10.5024 13.594C11.8447 13.594 13.1738 13.8584 14.4139 14.3721C15.654 14.8857 16.7808 15.6386 17.7299 16.5877C18.679 17.5369 19.4319 18.6636 19.9456 19.9037C20.4592 21.1438 20.7236 22.4729 20.7236 23.8152L19.8772 24.6616ZM2.01414 22.9688H18.9907C18.7837 20.8612 17.8 18.906 16.2311 17.4836C14.6622 16.0612 12.6202 15.2733 10.5024 15.2733C8.38469 15.2733 6.34269 16.0612 4.77374 17.4836C3.2048 18.906 2.22113 20.8612 2.01414 22.9688Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                &nbsp;Travelers
                              </label>
                              <span
                                onClick={() => this.setState({ setShow: true })}
                                ref={this.handlereturndate()}
                              >
                                <div className="custom-text-primary form-control rounded-10p datepicker-fs overflow-auto">
                                  {this.state.adult +
                                    this.state.child +
                                    this.state.infants}
                                  &nbsp;Traveler
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="col-2"></div>

                          <div className="col-6 d-flex align-items-end">
                            <button
                              type="button"
                              className="banner-form-main-btn btn-lg mb-3"
                              style={{ paddingBlock: "0.75rem" }}
                              onClick={() => {
                                this.searchflights();
                              }}
                            >
                              Search flight
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="banner-text-container">
                      <p className="banner-text">
                        Find And Book A Great&nbsp;
                        <span className="custom-text-primary-dark">
                          Experience
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="works-section">
                <p style={{ fontSize: "30px", marginLeft: "20px" }}>
                  How It Works
                </p>
                <div className="row" style={{ paddingRight: "20px" }}>
                  <div className="col-4">
                    <div className="works-card">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          fill="currentColor"
                          class="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                        <span
                          className="ml-4"
                          style={{ fontSize: "22px", fontWeight: 600 }}
                        >
                          Search
                        </span>
                      </div>
                      <div className="mt-3">
                        Enter your departure and destination cities, travel
                        dates, and the number of passengers.
                      </div>
                      <div className="mt-3">
                        Ogule scans multiple airlines and travel websites to
                        compile a comprehensive list of available flights.
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="works-card">
                      <div className="d-flex">
                        <svg
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          height="36"
                          width="36"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M 5 4 L 5 6.34375 L 5.21875 6.625 L 13 16.34375 L 13 28.03125 L 14.59375 26.78125 L 16.15625 25.59375 C 16.90525 29.23375 20.145 32 24 32 C 28.406 32 32 28.406 32 24 C 32 19.594 28.406 16 24 16 C 22.113 16 20.371 16.68025 19 17.78125 L 19 16.34375 L 26.78125 6.6230469 L 27 6.34375 L 27 4 L 5 4 z M 7.28125 6 L 24.71875 6 L 17.53125 15 L 14.46875 15 L 7.28125 6 z M 15 17 L 17 17 L 17 20.125 C 16.488 21.047 16.14525 22.0895 16.03125 23.1875 L 15 24 L 15 17 z M 24 18 C 27.324 18 30 20.676 30 24 C 30 27.324 27.324 30 24 30 C 20.676 30 18 27.324 18 24 C 18 20.676 20.676 18 24 18 z M 23 19 L 23 20.0625 C 21.875 20.2505 21 21.204953 21 22.376953 C 21 23.130953 21.41775 23.82025 22.09375 24.15625 L 25 25.625 C 25 25.848 24.848 26 24.625 26 L 23.375 26 C 23.152 26 23 25.848 23 25.625 L 23 25 L 21 25 L 21 25.625 C 21 26.797 21.875 27.7495 23 27.9375 L 23 29 L 25 29 L 25 27.9375 C 26.125 27.7495 27 26.795047 27 25.623047 C 27 24.869047 26.58225 24.18375 25.90625 23.84375 L 23 22.375 C 23 22.152 23.152 22 23.375 22 L 24.625 22 C 24.848 22 25 22.152 25 22.375 L 25 23 L 27 23 L 27 22.375 C 27 21.203 26.125 20.2505 25 20.0625 L 25 19 L 23 19 z"></path>
                        </svg>
                        <span
                          className="ml-4"
                          style={{ fontSize: "22px", fontWeight: 600 }}
                        >
                          Compare
                        </span>
                      </div>
                      <div className="mt-3">
                        Adjust filters such as preferred airlines, flight
                        duration, layovers, and budget to tailor the search to
                        your needs.
                      </div>
                      <div className="mt-3">
                        View flight deals side-by-side, comparing prices, travel
                        times, and other key details to find the best deal.
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="works-card">
                      <div className="d-flex">
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="36"
                          width="36"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M4 4.85v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9z"></path>
                          <path d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3zM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9z"></path>
                        </svg>
                        <span
                          className="ml-4"
                          style={{ fontSize: "22px", fontWeight: 600 }}
                        >
                          Book
                        </span>
                      </div>
                      <div className="mt-3">
                        Choose the flight that best suits your preferences and
                        budget.
                      </div>
                      <div className="mt-3">
                        Ogule redirects you to the airline or travel agency's
                        website to complete your booking seamlessly, ensuring
                        you get the best price without hidden fees.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="choose-ogule-section">
                <p style={{ fontSize: "30px" }}>
                  Here's why travelers choose Ogule
                </p>
                <div className="row">
                  <div className="col-3">
                    <div className="choose-ogule-card">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-check-lg text-success mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                        <span
                          className="ml-2"
                          style={{ fontSize: "18px", fontWeight: 600 }}
                        >
                          Get Great Deals!
                        </span>
                      </div>
                      <div className="mt-2" style={{ marginLeft: "30px" }}>
                        Choose from 500+ airlines for low airfares!
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="choose-ogule-card">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-check-lg text-success mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                        <span
                          className="ml-2"
                          style={{ fontSize: "18px", fontWeight: 600 }}
                        >
                          Price Match Promise
                        </span>
                      </div>
                      <div className="mt-2" style={{ marginLeft: "30px" }}>
                        Find low prices to destinations worldwide.
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="choose-ogule-card">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-check-lg text-success mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                        <span
                          className="ml-2"
                          style={{ fontSize: "18px", fontWeight: 600 }}
                        >
                          Easy Cancellations with Ogule
                        </span>
                      </div>
                      <div className="mt-2" style={{ marginLeft: "30px" }}>
                        Convenient self-service options available online.
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="choose-ogule-card">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-check-lg text-success mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                        <span
                          className="ml-2"
                          style={{ fontSize: "18px", fontWeight: 600 }}
                        >
                          Expert Guidance
                        </span>
                      </div>
                      <div className="mt-2" style={{ marginLeft: "30px" }}>
                        Get personalized assistance from our travel experts.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="best-travelers-section">
                <p style={{ fontSize: "30px" }}>Best travelers of the month</p>
                <div className="d-flex gap-1rem justify-content-evenly mb-5">
                  <div className="best-travelers-card">
                    <img
                      className="w-100"
                      src="https://images.unsplash.com/photo-1565881606991-789a8dff9dbb?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt=""
                    />
                    <span className="best-travelers-dp">
                      <img
                        src="https://images.unsplash.com/photo-1499887142886-791eca5918cd?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </span>
                    <p className="fw-600 mb-0 fs-16p">William mark</p>
                    <p className="mb-4 fs-13p" style={{ fontWeight: "300" }}>
                      @william_mark
                    </p>
                  </div>
                  <div className="best-travelers-card">
                    <img
                      className="w-100"
                      src="https://images.unsplash.com/photo-1565881606991-789a8dff9dbb?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt=""
                    />
                    <span className="best-travelers-dp">
                      <img
                        src="https://images.unsplash.com/photo-1499887142886-791eca5918cd?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </span>
                    <p className="fw-600 mb-0 fs-16p">William mark</p>
                    <p className="mb-4 fs-13p" style={{ fontWeight: "300" }}>
                      @william_mark
                    </p>
                  </div>
                  <div className="best-travelers-card">
                    <img
                      className="w-100"
                      src="https://images.unsplash.com/photo-1565881606991-789a8dff9dbb?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt=""
                    />
                    <span className="best-travelers-dp">
                      <img
                        src="https://images.unsplash.com/photo-1499887142886-791eca5918cd?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </span>
                    <p className="fw-600 mb-0 fs-16p">William mark</p>
                    <p className="mb-4 fs-13p" style={{ fontWeight: "300" }}>
                      @william_mark
                    </p>
                  </div>
                  <div className="best-travelers-card">
                    <img
                      className="w-100"
                      src="https://images.unsplash.com/photo-1565881606991-789a8dff9dbb?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt=""
                    />
                    <span className="best-travelers-dp">
                      <img
                        src="https://images.unsplash.com/photo-1499887142886-791eca5918cd?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </span>
                    <p className="fw-600 mb-0 fs-16p">William mark</p>
                    <p className="mb-4 fs-13p" style={{ fontWeight: "300" }}>
                      @william_mark
                    </p>
                  </div>
                </div>
              </div> */}

              <div className="py-5 px-4 row card-section my-5">
                <div className="col-7 d-flex align-items-center">
                  <div className="d-flex flex-column justify-content-center">
                    <p className="gradient-text mb-5">
                      Travel with Ogule credit cards
                    </p>
                    <p style={{ fontSize: "27px", color: "#03045E" }}>
                      Journey 1000 miles, score a free Ogule Credits Card!
                      <br />
                      Unlock rewards as you travel. Sign up now – no cost!"
                    </p>
                  </div>
                </div>
                <div className="col-5 card2-container">
                  <img className="credit-card" src={creditCard} alt="" />
                  <p className="text-white" style={{ fontSize: "36px" }}>
                    Where Every Purchase Paves the Way to Rewards!
                  </p>
                  <div className="d-flex justify-content-end">
                    <span className="credit-card-btn mt-4 btn btn-lg">
                      Know more
                    </span>
                  </div>
                </div>
              </div>

              <div className="my-5">
                <p style={{ fontSize: "30px" }}>Travel blogs</p>
                {this.state.bloglist ? (
                  <div className="mb-5">
                    <Carousel itemsToShow={3}>
                      {this.state.bloglist?.map((item) => {
                        return (
                          <div className="blog-card">
                            <img
                              src={item?.image ? item?.image : noImg}
                              alt=""
                            />
                            <div className="d-flex flex-column gap-2 p-3">
                              <p
                                className="fw-600 mb-0"
                                style={{ fontSize: "21px" }}
                              >
                                {item?.name.substring(0, 25)}
                              </p>
                              <p className="text-secondary mb-0">
                                {item.short_description.substring(0, 30) +
                                  "..."}
                              </p>
                              <div className="d-flex justify-content-end">
                                <span
                                  className="blog-read-btn"
                                  onClick={() => {
                                    this.Singleblog(item?.slug);
                                  }}
                                >
                                  Read more
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                ) : (
                  <p>Please Wait....</p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="w-100 bg-white"
            style={{ minHeight: "100vh", paddingBottom: "1.2rem" }}
          >
            <Header />

            <Modal
              show={this.state.setShow}
              onHide={this.handleClose}
              className="login_popup-sm"
            >
              <Modal.Header closeButton>
                <ButtonToolbar
                  style={{
                    overflowX: "scroll",
                    flexWrap: "nowrap",
                    gap: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  {this.state.t_class.map((item) => (
                    <div>
                      {this.state.t_class_selected === item.value ? (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded mr-1 text-nowrap custom-btn-primary"
                        />
                      ) : (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded p-button-secondary mr-1 text-nowrap custom-btn-primary"
                          onClick={() =>
                            this.setState({ t_class_selected: item.value })
                          }
                        />
                      )}
                    </div>
                  ))}
                </ButtonToolbar>
              </Modal.Header>
              <Modal.Body>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Adult (Above 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult - 1 });
                        if (this.state.adult - 1 < this.state.infants) {
                          this.setState({ infants: this.state.adult - 1 });
                        }
                      }}
                      disabled={this.state.adult === 0}
                    />
                    <input
                      type="text"
                      value={this.state.adult}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult + 1 });
                      }}
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Child (2 years to 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child - 1 })
                      }
                      disabled={this.state.child === 0}
                    />
                    <input
                      type="text"
                      value={this.state.child}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child + 1 })
                      }
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Infants (Below 2 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants - 1 })
                      }
                      disabled={this.state.infants === 0}
                    />
                    <input
                      type="text"
                      value={this.state.infants}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants + 1 })
                      }
                      disabled={this.state.infants === this.state.adult}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  label="Submit"
                  className="p-button-raised p-button-rounded mr-2 custom-btn-primary"
                  onClick={() => this.setState({ setShow: false })}
                />
              </Modal.Footer>
            </Modal>

            {/* Inactivity Modal */}
            {this.state.showTimeOutModal && <div className="overlay"></div>}
            {this.state.showTimeOutModal && (
              <div className="sticky-modal">
                <div className="inactive-modal">
                  <div className="modal-header">
                    <div className="d-flex justify-content-between gap-1rem flex-wrap">
                      <div className="d-flex align-items-center flex-column gap-2">
                        <img
                          className="pulse"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                          }}
                          src="https://img.freepik.com/free-vector/customer-support-illustration_23-2148903318.jpg?t=st=1718351497~exp=1718355097~hmac=2765533fd293e493ca68e1670cfd2b4ecb57d3d6fa4c8c460cc5266fcfcb15e7&w=740"
                          alt=""
                        />
                        <span className="badge badge-pill badge-light text-success border">
                          Available Now
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <p className="text-capitalize fw-700 fs-22p mb-0">
                            Let us help you book!
                          </p>
                          <p className="mb-0">
                            Speak with a travel expert and get assistance 24/7
                          </p>
                        </div>
                        <p className="text-primary fw-700 fs-22p mb-0 d-flex align-items-center gap-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-telephone-fill call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                            />
                          </svg>
                          +1 888-315-1615 (US)
                        </p>
                      </div>
                    </div>
                    <span className="close" onClick={this.closeTimeOutModal}>
                      &times;
                    </span>
                  </div>
                  <div className="modal-body bg-light">
                    <div className="d-flex flex-column align-items-center px-2">
                      <p className="fw-600 fs-20p">
                        Why to Book with Call Center
                      </p>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <img
                              style={{
                                height: "40px",
                                filter: "hue-rotate(204deg)",
                              }}
                              src={callCenter}
                              alt=""
                            />
                            <div className="d-flex flex-column">
                              <p className="fs-16p">
                                Expert guidance by our&nbsp;
                                <span className="fw-600 text-primary">
                                  Travel experts
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <img
                              style={{
                                height: "40px",
                                filter: "hue-rotate(204deg)",
                              }}
                              src={confirmationBooking}
                              alt=""
                            />
                            <div className="d-flex flex-column">
                              <p className="fs-16p">
                                Immediate&nbsp;
                                <span className="fw-600 text-primary">
                                  Booking confirmation
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <img
                              style={{
                                height: "40px",
                                filter: "hue-rotate(204deg)",
                              }}
                              src={hour24}
                              alt=""
                            />
                            <div className="d-flex flex-column">
                              <p className="fs-16p">
                                24 hour&nbsp;
                                <span className="fw-600 text-primary">
                                  Cancellation
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <img
                              style={{
                                height: "40px",
                                filter: "hue-rotate(204deg)",
                              }}
                              src={flexiblePayment}
                              alt=""
                            />
                            <div className="d-flex flex-column">
                              <p className="fs-16p">
                                <span className="fw-600 text-primary">
                                  Flexible payment
                                </span>
                                &nbsp;plans
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="container py-1 w-100 d-flex align-items-center justify-content-between">
              <img style={{ height: "40px" }} src={ogule} alt="Ogule" />
              {/* <a
                href="tel:+18883151615"
                className="btn btn-sm custom-btn-primary btn-pill pulse"
                role="button"
              >
                Contact
              </a> */}
              <a
                href="tel:+18883151615"
                className="btn rounded-1rem pulse mt-2 mb-2"
                role="button"
              >
                <div className="d-flex align-items-center gap-1">
                  <span className="custom-text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-telephone"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                    </svg>
                  </span>
                  <div className="d-flex flex-column font-size-1 text-nowrap">
                    <p className="mb-0 fw-600 float-right fs-10p">
                      +1 888-315-1615 (US)
                    </p>
                    <p className="mb-0" style={{ fontSize: "10px" }}>
                      Get upto&nbsp;
                      <span className="text-danger fw-600">30% off</span>
                      &nbsp; on call
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className="custom-home-banner rounded-0">
              <p className="bg-glass" style={{ fontSize: "18px" }}>
                Find And Book A Great&nbsp;
                <div className="custom-text-primary">Experience</div>
              </p>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "12px",
                  borderRadius: "10px",
                  paddingTop: "14px",
                }}
              >
                <div className="d-flex gap-1rem justify-content-between mb-2">
                  <div className="d-flex gap-1rem">
                    <div className="d-flex align-items-center gap-12p">
                      <RadioButton
                        value="one_way"
                        name="One Way"
                        onChange={(e) => {
                          this.trip(e.value);
                        }}
                        checked={this.state.trip === "one_way"}
                      />
                      <b>One Way</b>
                    </div>
                    <div className="d-flex align-items-center gap-12p">
                      <RadioButton
                        className="ml-3"
                        value="round_trip"
                        name="Round Trip"
                        onChange={(e) => {
                          this.trip(e.value);
                        }}
                        checked={this.state.trip === "round_trip"}
                      />
                      <b>Round Trip</b>
                    </div>
                  </div>
                </div>
                <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="from"
                          className="fw-600 custom-text-primary d-flex align-items-center fs-14p"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            class="bi bi-geo-alt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          &nbsp;From
                        </label>

                        {this.state.city_from === "" ? (
                          <input
                            className="form-control custom-text-primary rounded-10p datepicker-fs mb-2"
                            type="text"
                            autocomplete="off"
                            id="from"
                            list="fromdata"
                            value={this.state.iatacode_from}
                            onChange={(e) => this.searchfrom(e.target.value)}
                          />
                        ) : (
                          <span className="border custom-text-primary rounded-10p fs-12p mb-2 d-flex p-2">
                            <span className="d-flex align-items-center justify-content-between rounded gap-1 w-100">
                              <span>
                                {this.state.city_from}&nbsp;(
                                {this.state.iatacode_from})
                              </span>

                              <span
                                className="d-flex align-items-center justify-content-center pointer"
                                onClick={() => {
                                  this.setState({
                                    iatacode_from: "",
                                    city_from: "",
                                    airport_name_from: "",
                                    country_from: "",
                                  });
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                    fill="#C9C9C9"
                                  />
                                </svg>
                              </span>
                            </span>
                          </span>
                        )}
                        <p
                          className="text-secondary fs-14p"
                          style={{ marginLeft: 5, marginTop: -5 }}
                        >
                          {this.state.airport_name_from}&nbsp;
                          {this.state.country_from}
                        </p>
                      </div>

                      <div>
                        {this.state.fromdata ? (
                          <div>
                            <datalist id="fromdata">
                              {this.state.fromdata.map((item) => (
                                <option value={item.iataCode}>
                                  {item.name},{item.address.cityName}{" "}
                                  {item.address.countryName}
                                </option>
                              ))}
                            </datalist>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-12" style={{ marginTop: -5 }}>
                      <div className="form-group">
                        <label
                          htmlFor="to"
                          className="fw-600 custom-text-primary d-flex align-items-center fs-14p"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            class="bi bi-geo-alt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          &nbsp;To
                        </label>
                        {this.state.city_to === "" ? (
                          <input
                            className="form-control custom-text-primary rounded-10p datepicker-fs mb-2"
                            type="text"
                            id="to"
                            list="todata"
                            value={this.state.iatacode_to}
                            onChange={(e) => this.searchto(e.target.value)}
                            ref={this.toInputRef}
                          />
                        ) : (
                          <span className="border custom-text-primary rounded-10p fs-12p mb-2 d-flex p-2">
                            <span className="d-flex align-items-center justify-content-between rounded gap-1 w-100">
                              <span>
                                {this.state.city_to}&nbsp;(
                                {this.state.iatacode_to})
                              </span>

                              <span
                                className="d-flex align-items-center justify-content-center pointer"
                                onClick={() => {
                                  this.setState({
                                    iatacode_to: "",
                                    city_to: "",
                                    airport_name_to: "",
                                    country_to: "",
                                  });
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.9974 0.666016C11.3603 0.666016 8.78245 1.448 6.5898 2.91309C4.39714 4.37817 2.68818 6.46055 1.67901 8.8969C0.66984 11.3333 0.405795 14.0141 0.920265 16.6006C1.43473 19.187 2.70461 21.5627 4.56931 23.4274C6.43401 25.2921 8.80978 26.562 11.3962 27.0765C13.9826 27.591 16.6635 27.3269 19.0998 26.3177C21.5362 25.3086 23.6186 23.5996 25.0837 21.4069C26.5487 19.2143 27.3307 16.6364 27.3307 13.9993C27.3307 12.2484 26.9859 10.5146 26.3158 8.8969C25.6457 7.27923 24.6636 5.80937 23.4255 4.57126C22.1874 3.33314 20.7175 2.35102 19.0998 1.68096C17.4822 1.01089 15.7484 0.666016 13.9974 0.666016ZM18.2774 16.386C18.4024 16.51 18.5016 16.6574 18.5693 16.8199C18.6369 16.9824 18.6718 17.1567 18.6718 17.3327C18.6718 17.5087 18.6369 17.683 18.5693 17.8454C18.5016 18.0079 18.4024 18.1554 18.2774 18.2793C18.1535 18.4043 18.006 18.5035 17.8435 18.5712C17.681 18.6389 17.5068 18.6737 17.3307 18.6737C17.1547 18.6737 16.9804 18.6389 16.818 18.5712C16.6555 18.5035 16.508 18.4043 16.3841 18.2793L13.9974 15.8793L11.6107 18.2793C11.4868 18.4043 11.3393 18.5035 11.1768 18.5712C11.0144 18.6389 10.8401 18.6737 10.6641 18.6737C10.4881 18.6737 10.3138 18.6389 10.1513 18.5712C9.98882 18.5035 9.84135 18.4043 9.7174 18.2793C9.59243 18.1554 9.49324 18.0079 9.42555 17.8454C9.35786 17.683 9.323 17.5087 9.323 17.3327C9.323 17.1567 9.35786 16.9824 9.42555 16.8199C9.49324 16.6574 9.59243 16.51 9.7174 16.386L12.1174 13.9993L9.7174 11.6127C9.46633 11.3616 9.32528 11.0211 9.32528 10.666C9.32528 10.3109 9.46633 9.97042 9.7174 9.71935C9.96847 9.46828 10.309 9.32723 10.6641 9.32723C11.0191 9.32723 11.3597 9.46828 11.6107 9.71935L13.9974 12.1193L16.3841 9.71935C16.6351 9.46828 16.9757 9.32723 17.3307 9.32723C17.6858 9.32723 18.0263 9.46828 18.2774 9.71935C18.5285 9.97042 18.6695 10.3109 18.6695 10.666C18.6695 11.0211 18.5285 11.3616 18.2774 11.6127L15.8774 13.9993L18.2774 16.386Z"
                                    fill="#C9C9C9"
                                  />
                                </svg>
                              </span>
                            </span>
                          </span>
                        )}
                        <p
                          className="text-secondary"
                          style={{ marginLeft: 5, marginTop: -5 }}
                        >
                          {this.state.airport_name_to}&nbsp;
                          {this.state.country_to}
                        </p>
                      </div>
                      <div>
                        {this.state.todata ? (
                          <div>
                            <datalist id="todata">
                              {this.state.todata.map((item) => (
                                <option value={item.iataCode}>
                                  {item.name},{item.address.cityName}{" "}
                                  {item.address.countryName}
                                </option>
                              ))}
                            </datalist>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {this.state.trip === "round_trip" ? (
                      <div className="col-12" style={{ marginTop: -5 }}>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label
                                htmlFor="departure"
                                className="fw-600 custom-text-primary d-flex align-items-center fs-14p"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="18"
                                  fill="none"
                                  viewBox="0 0 18 20"
                                >
                                  <path
                                    d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                &nbsp;Departure
                              </label>
                              <DatePicker
                                className="datepicker-fs-16p"
                                selected={new Date(this.state.departuredate)}
                                onChange={this.handledeparturedate}
                                minDate={new Date()}
                                dateFormat="d MMM, yy"
                                ref={this.datePickerRef}
                                style={{
                                  fontSize: "16px",
                                }}
                              />
                              <p
                                className="text-secondary"
                                style={{ marginLeft: 5 }}
                              >
                                {this.returnDay(
                                  new Date(this.state.departuredate).getDay()
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label
                                htmlFor="return"
                                className="fw-600 custom-text-primary d-flex align-items-center fs-14p"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="18"
                                  fill="none"
                                  viewBox="0 0 18 20"
                                >
                                  <path
                                    d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                                    stroke="#23779A"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                &nbsp;Return
                              </label>
                              <DatePicker
                                className="datepicker-fs-16p"
                                selected={new Date(this.state.returndate)}
                                onChange={this.handlereturndate}
                                minDate={new Date(this.state.departuredate)}
                                maxDate={
                                  this.state.departuredate
                                    ? new Date(
                                        new Date(
                                          this.state.departuredate
                                        ).setDate(
                                          new Date(
                                            this.state.departuredate
                                          ).getDate() + 361
                                        )
                                      )
                                    : null
                                }
                                dateFormat="d MMM, yy"
                                ref={this.departureDatePickerRef}
                                style={{
                                  fontSize: "16px",
                                }}
                              />
                              <p
                                className="text-secondary"
                                style={{ marginLeft: 5 }}
                              >
                                {this.returnDay(
                                  new Date(this.state.returndate).getDay()
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12" style={{ marginTop: -5 }}>
                        <div className="form-group">
                          <label
                            htmlFor="departure"
                            className="fw-600 custom-text-primary d-flex align-items-center fs-14p"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="18"
                              fill="none"
                              viewBox="0 0 18 20"
                            >
                              <path
                                d="M13 1V5M5 1V5M1 9H17M4 12H4.013M7.00781 12H7.01281M10.0078 12H10.0128M13.0156 12H13.0206M10.0156 15H10.0206M4.00781 15H4.01281M7.00781 15H7.01281M1 5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5Z"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            &nbsp;Departure
                          </label>
                          <DatePicker
                            className="datepicker-fs-16p"
                            selected={new Date(this.state.departuredate)}
                            onChange={this.handledeparturedate}
                            minDate={new Date()}
                            dateFormat="d MMM, yy"
                            style={{
                              fontSize: "16px",
                            }}
                          />
                          <p
                            className="text-secondary"
                            style={{ marginLeft: 5 }}
                          >
                            {this.returnDay(
                              new Date(this.state.departuredate).getDay()
                            )}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="col-12">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="departure"
                          className="fw-600 custom-text-primary fs-14p"
                        >
                          Class
                        </label>
                        <ul
                          className="d-flex justify-content-between gap-2 pb-2"
                          style={{ overflowX: "scroll" }}
                        >
                          {this.state.t_class.map((item) => (
                            <li
                              className={`custom-tab-list text-nowrap ${
                                this.state.t_class_selected === item.name
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                this.setState({
                                  t_class_selected: item.name,
                                })
                              }
                              style={{ paddingBlock: "4px" }}
                            >
                              {item?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group traveller_class">
                        <label className="fw-600 custom-text-primary d-flex align-items-center fs-14p">
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 21 25"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.5024 12.0838C9.34083 12.0839 8.20528 11.7396 7.23938 11.0944C6.27347 10.4491 5.5206 9.53197 5.07597 8.45885C4.63134 7.38573 4.51492 6.20485 4.74144 5.06556C4.96796 3.92627 5.52724 2.87974 6.34855 2.05832C7.16986 1.23689 8.21631 0.677472 9.35557 0.450798C10.4948 0.224125 11.6757 0.340382 12.7489 0.784867C13.8221 1.22935 14.7394 1.9821 15.3847 2.94791C16.0301 3.91373 16.3745 5.04923 16.3745 6.21082C16.3731 7.76783 15.7539 9.26065 14.6531 10.3617C13.5522 11.4627 12.0594 12.0821 10.5024 12.0838ZM10.5024 2.03155C9.67561 2.03139 8.86733 2.27643 8.17979 2.73568C7.49226 3.19493 6.95636 3.84776 6.63988 4.61161C6.32341 5.37545 6.24056 6.21599 6.40182 7.02692C6.56309 7.83785 6.96122 8.58274 7.54586 9.16739C8.1305 9.75203 8.87539 10.1502 9.68632 10.3114C10.4973 10.4727 11.3378 10.3898 12.1016 10.0734C12.8655 9.75688 13.5183 9.22098 13.9776 8.53345C14.4368 7.84592 14.6819 7.03763 14.6817 6.21082C14.6804 5.1028 14.2397 4.04052 13.4562 3.25703C12.6727 2.47354 11.6104 2.03282 10.5024 2.03155ZM19.8772 24.6616H1.12768L0.28125 23.8152C0.28125 22.4729 0.545629 21.1438 1.05929 19.9037C1.57295 18.6636 2.32584 17.5369 3.27496 16.5877C4.22408 15.6386 5.35086 14.8857 6.59095 14.3721C7.83104 13.8584 9.16016 13.594 10.5024 13.594C11.8447 13.594 13.1738 13.8584 14.4139 14.3721C15.654 14.8857 16.7808 15.6386 17.7299 16.5877C18.679 17.5369 19.4319 18.6636 19.9456 19.9037C20.4592 21.1438 20.7236 22.4729 20.7236 23.8152L19.8772 24.6616ZM2.01414 22.9688H18.9907C18.7837 20.8612 17.8 18.906 16.2311 17.4836C14.6622 16.0612 12.6202 15.2733 10.5024 15.2733C8.38469 15.2733 6.34269 16.0612 4.77374 17.4836C3.2048 18.906 2.22113 20.8612 2.01414 22.9688Z"
                              fill="currentColor"
                            />
                          </svg>
                          &nbsp;Travelers
                        </label>
                        <span
                          onClick={() => this.setState({ setShow: true })}
                          ref={this.handlereturndate()}
                        >
                          <div className="custom-text-primary form-control rounded-10p fs-16p overflow-auto">
                            {this.state.adult +
                              this.state.child +
                              this.state.infants}
                            &nbsp;Traveler
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="col-6 d-flex align-items-end">
                      <button
                        type="button"
                        className="banner-form-main-btn-sm w-100 mb-3"
                        onClick={() => {
                          this.searchflights();
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="px-3">
              <div className="works-section">
                <p
                  className="fw-600"
                  style={{ fontSize: "20px", marginLeft: "20px" }}
                >
                  How It Works
                </p>
                <div className="row">
                  <div className="col-12">
                    <div className="works-card-sm">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          fill="currentColor"
                          class="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                        <span
                          className="ml-4"
                          style={{ fontSize: "22px", fontWeight: 600 }}
                        >
                          Search
                        </span>
                      </div>
                      <div className="mt-3 mr-4">
                        Enter your departure and destination cities, travel
                        dates, and the number of passengers.
                      </div>
                      <div className="mt-3 mr-4">
                        Ogule scans multiple airlines and travel websites to
                        compile a comprehensive list of available flights.
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="works-card-sm">
                      <div className="d-flex">
                        <svg
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          height="36"
                          width="36"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M 5 4 L 5 6.34375 L 5.21875 6.625 L 13 16.34375 L 13 28.03125 L 14.59375 26.78125 L 16.15625 25.59375 C 16.90525 29.23375 20.145 32 24 32 C 28.406 32 32 28.406 32 24 C 32 19.594 28.406 16 24 16 C 22.113 16 20.371 16.68025 19 17.78125 L 19 16.34375 L 26.78125 6.6230469 L 27 6.34375 L 27 4 L 5 4 z M 7.28125 6 L 24.71875 6 L 17.53125 15 L 14.46875 15 L 7.28125 6 z M 15 17 L 17 17 L 17 20.125 C 16.488 21.047 16.14525 22.0895 16.03125 23.1875 L 15 24 L 15 17 z M 24 18 C 27.324 18 30 20.676 30 24 C 30 27.324 27.324 30 24 30 C 20.676 30 18 27.324 18 24 C 18 20.676 20.676 18 24 18 z M 23 19 L 23 20.0625 C 21.875 20.2505 21 21.204953 21 22.376953 C 21 23.130953 21.41775 23.82025 22.09375 24.15625 L 25 25.625 C 25 25.848 24.848 26 24.625 26 L 23.375 26 C 23.152 26 23 25.848 23 25.625 L 23 25 L 21 25 L 21 25.625 C 21 26.797 21.875 27.7495 23 27.9375 L 23 29 L 25 29 L 25 27.9375 C 26.125 27.7495 27 26.795047 27 25.623047 C 27 24.869047 26.58225 24.18375 25.90625 23.84375 L 23 22.375 C 23 22.152 23.152 22 23.375 22 L 24.625 22 C 24.848 22 25 22.152 25 22.375 L 25 23 L 27 23 L 27 22.375 C 27 21.203 26.125 20.2505 25 20.0625 L 25 19 L 23 19 z"></path>
                        </svg>
                        <span
                          className="ml-4"
                          style={{ fontSize: "22px", fontWeight: 600 }}
                        >
                          Compare
                        </span>
                      </div>
                      <div className="mt-3 mr-4">
                        Adjust filters such as preferred airlines, flight
                        duration, layovers, and budget to tailor the search to
                        your needs.
                      </div>
                      <div className="mt-3 mr-4">
                        View flight deals side-by-side, comparing prices, travel
                        times, and other key details to find the best deal.
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="works-card-sm">
                      <div className="d-flex">
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="36"
                          width="36"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M4 4.85v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9z"></path>
                          <path d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3zM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9z"></path>
                        </svg>
                        <span
                          className="ml-4"
                          style={{ fontSize: "22px", fontWeight: 600 }}
                        >
                          Book
                        </span>
                      </div>
                      <div className="mt-3 mr-4">
                        Choose the flight that best suits your preferences and
                        budget.
                      </div>
                      <div className="mt-3 mr-4">
                        Ogule redirects you to the airline or travel agency's
                        website to complete your booking seamlessly, ensuring
                        you get the best price without hidden fees.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="choose-ogule-section-sm">
                <p className="fw-600" style={{ fontSize: "20px" }}>
                  Here's why travelers choose Ogule
                </p>
                <div className="row gap-1rem">
                  <div className="col-12">
                    <div className="choose-ogule-card">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-check-lg text-success mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                        <span
                          className="ml-2"
                          style={{ fontSize: "18px", fontWeight: 600 }}
                        >
                          Get Great Deals!
                        </span>
                      </div>
                      <div className="mt-1" style={{ marginLeft: "30px" }}>
                        Choose from 500+ airlines for low airfares!
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="choose-ogule-card">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-check-lg text-success mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                        <span
                          className="ml-2"
                          style={{ fontSize: "18px", fontWeight: 600 }}
                        >
                          Price Match Promise
                        </span>
                      </div>
                      <div className="mt-1" style={{ marginLeft: "30px" }}>
                        Find low prices to destinations worldwide.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="choose-ogule-card">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-check-lg text-success mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                        <span
                          className="ml-2"
                          style={{ fontSize: "18px", fontWeight: 600 }}
                        >
                          Easy Cancellations with Ogule
                        </span>
                      </div>
                      <div className="mt-1" style={{ marginLeft: "30px" }}>
                        Convenient self-service options available online.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="choose-ogule-card">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-check-lg text-success mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                        <span
                          className="ml-2"
                          style={{ fontSize: "18px", fontWeight: 600 }}
                        >
                          Expert Guidance
                        </span>
                      </div>
                      <div className="mt-1" style={{ marginLeft: "30px" }}>
                        Get personalized assistance from our travel experts.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="p-4 d-flex flex-column card-section"
                style={{ height: "610px" }}
              >
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-column justify-content-center">
                    <p
                      className="gradient-text mb-0"
                      style={{ fontSize: "20px" }}
                    >
                      Travel with Ogule credit cards
                    </p>
                    <p style={{ color: "#03045E" }}>
                      Journey 1000 miles, score a free Ogule Credits Card!
                      <br />
                      Unlock rewards as you travel. Sign up now – no cost!"
                    </p>
                  </div>
                </div>
                <div
                  className="card2-container p-3"
                  style={{ gap: "1rem", backgroundPosition: "top" }}
                >
                  <img
                    className="credit-card w-100"
                    style={{ maxWidth: "400px" }}
                    src={creditCard}
                    alt=""
                  />
                  <p className="text-white">
                    Where Every Purchase Paves the Way to Rewards!"
                  </p>
                  <div className="d-flex justify-content-end">
                    <span className="credit-card-btn-sm">Know more</span>
                  </div>
                </div>
              </div>

              <div className="">
                <p className="fw-600" style={{ fontSize: "20px" }}>
                  Travel blogs
                </p>
                {this.state.bloglist ? (
                  <Carousel
                    itemsToShow={1}
                    pagination={true}
                    renderPagination={({ pages, activePage, onClick }) => {
                      const maxVisibleDots = 6;
                      const totalDots = pages.length;
                      let startPage = Math.max(
                        0,
                        activePage - Math.floor(maxVisibleDots / 2)
                      );
                      let endPage = startPage + maxVisibleDots;

                      if (endPage > totalDots) {
                        endPage = totalDots;
                        startPage = Math.max(0, endPage - maxVisibleDots);
                      }

                      return (
                        <div
                          className="rec.rec-pagination"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                          }}
                        >
                          {pages
                            .slice(startPage, endPage)
                            .map((page, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`rec.rec-pagination-item ${
                                    activePage === page
                                      ? "rec.rec-pagination-item--active"
                                      : ""
                                  }`}
                                  onClick={() => onClick(page)}
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    background: activePage === page ? "#000" : "#ccc",
                                    margin: "0 5px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                />
                              );
                            })}
                        </div>
                      );
                    }}
                  >
                    {this.state.bloglist?.map((item) => (
                      <div className="blog-card">
                        <img
                          style={{ height: "200px" }}
                          src={item?.image ? item?.image : noImg}
                          alt=""
                        />
                        <div className="d-flex flex-column p-3">
                          <p
                            className="fw-600 mb-0"
                            style={{ fontSize: "21px" }}
                          >
                            {item?.name.substring(0, 25)}
                          </p>
                          <p className="text-secondary mb-0">
                            {item.short_description.substring(0, 30) + "..."}
                          </p>
                          <div className="d-flex justify-content-end">
                            <span
                              className="blog-read-btn"
                              onClick={() => {
                                this.Singleblog(item?.slug);
                              }}
                            >
                              Read more
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <p>Please Wait....</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NewHome;
