import React, { Component } from "react";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneSquareAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import facebook from "../image/social/facebook.png";
import instagram from "../image/social/instagram.png";
import linkedin from "../image/social/linkedin.png";
import twitter from "../image/social/twitter.png";
import trustpilot from "../image/social/trustpilot.png";
import whatsapp from "../image/social/whatsapp.png";
class Help extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  link(val) {
    console.warn(this.props.history.push(val));
  }
  render() {
    return (
      <div>
        {window.innerWidth > 768 ? (
          <div>
            <Header />
            <div className="container py-5">
              <div className="help_section_desktop"></div>
            </div>
          </div>
        ) : (
          <div className="help_bg_img">
            <div className="help_section">
              <div className="container" style={{ paddingBottom: "3rem" }}>
                <div className="d-flex flex-column justify-content-between h-100">
                  <div>
                    <a
                      href="tel:+18883151615"
                      className="btn rounded-1rem text-white btn-lg pulse w-100 mb-4 custom-primary-linear-gradient"
                      role="button"
                    >
                      <div className="d-flex align-items-center justify-content-around">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="currentColor"
                          class="bi bi-telephone-fill call_vibration"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                          />
                        </svg>
                        <div className="">
                          <p className="fw-600 float-right mb-0 font-size-2">
                            +1 888-315-1615 (US)
                          </p>
                          <p className="mb-0 font-size-1 blinking">
                            Call now for exciting offers!
                          </p>
                        </div>
                      </div>
                    </a>

                    {/* <a href="tel:+18883151615">
                      <div className="help_sec_box pulse">
                        <p className="help_sec_box_icon">
                          <FontAwesomeIcon
                            icon={faPhoneSquareAlt}
                            className="blinking"
                          />
                        </p>
                        <p className="help_sec_box_text blinking">
                          +1 888-315-1615 (US)
                        </p>
                      </div>
                    </a> */}
                    <div className="help_sec_box shadow-sm">
                      <p className="help_sec_box_icon">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="blinking"
                        />
                      </p>
                      <p className="help_sec_box_text">sales@ogule.com</p>
                    </div>
                    <div className="help_sec_box shadow-sm">
                      <p className="help_sec_box_icon">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="blinking"
                        />
                      </p>
                      <p className="help_sec_box_text_address">
                        Three Sugar Creek Center Blvd Sugar Land, TX 77479, USA
                        <br />
                        {/* <hr /> */}
                        {/* H No 114-B, Radhika Nagar, Kharangajhar
										Telco Colony, Jamshedpur - 831004, Jharkhand INDIA */}
                      </p>
                    </div>
                    <div className="help_sec_box bg-transparent px-0">
                      <div
                        className="d-flex gap-1rem px-0 w-100"
                        style={{ overflowX: "scroll" }}
                      >
                        <div
                          className="footer_page_link text-nowrap"
                          onClick={() => {
                            this.link("about");
                          }}
                        >
                          About Us
                        </div>
                        <div
                          className="footer_page_link text-nowrap"
                          onClick={() => {
                            this.link("our_team");
                          }}
                        >
                          Our Team
                        </div>
                        <div
                          className="footer_page_link text-nowrap"
                          onClick={() => {
                            this.link("guest_support");
                          }}
                        >
                          Guest Support
                        </div>
                        <div
                          className="footer_page_link text-nowrap"
                          onClick={() => {
                            this.link("privacy_policy");
                          }}
                        >
                          Privacy and Policy
                        </div>
                        <div
                          className="footer_page_link text-nowrap"
                          onClick={() => {
                            this.link("terms_conditions");
                          }}
                        >
                          Terms and Conditions
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="help_sec_box mx-0 shadow-sm">
                    <p className="help_sec_box_text">
                      <div className="row footer_social">
                        <div
                          className="col"
                          style={{ borderRight: "1px dashed #33333340" }}
                        >
                          <a
                            href="https://www.facebook.com/ogulellc/?modal=admin_todo_tour"
                            target="_blank"
                          >
                            <img
                              src={facebook}
                              className="img-fluid"
                              alt="facebook"
                            />
                          </a>
                        </div>
                        {/* <div
                          className="col"
                          style={{ borderRight: "1px dashed #33333340" }}
                        >
                          <a
                            href="https://www.facebook.com/ogulellc/?modal=admin_todo_tour"
                            target="_blank"
                          >
                            <img
                              src={whatsapp}
                              className="img-fluid"
                              alt="whatsapp"
                            />
                          </a>
                        </div> */}
                        <div
                          className="col"
                          style={{ borderRight: "1px dashed #33333340" }}
                        >
                          <a
                            href="https://www.instagram.com/ogulellc/"
                            target="_blank"
                          >
                            <img
                              src={instagram}
                              className="img-fluid"
                              alt="instagram"
                            />
                          </a>
                        </div>
                        <div
                          className="col"
                          style={{ borderRight: "1px dashed #33333340" }}
                        >
                          <a
                            href="https://www.linkedin.com/in/ogule-llc-890a541a4/"
                            target="_blank"
                          >
                            <img
                              src={linkedin}
                              className="img-fluid"
                              alt="linkedin"
                            />
                          </a>
                        </div>
                        <div
                          className="col"
                          style={{ borderRight: "1px dashed #33333340" }}
                        >
                          <a href="https://twitter.com/Ogule4" target="_blank">
                            <img
                              src={twitter}
                              className="img-fluid"
                              alt="twitter"
                            />
                          </a>
                        </div>
                        <div className="col">
                          <a
                            href="https://www.trustpilot.com/review/ogule.com"
                            target="_blank"
                          >
                            <img
                              src={trustpilot}
                              className="img-fluid"
                              alt="trustpilot"
                            />
                          </a>
                        </div>
                      </div>
                    </p>
                  </div>
                  <a
                    href="tel:+18883151615"
                    className="btn rounded-1rem card pulse w-100 mb-4"
                    role="button"
                  >
                    <div className="d-flex align-items-center gap-12p w-100 ">
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src="https://tse3.explicit.bing.net/th?id=OIP.Aqn3nZQJW8VFj_uvJkWANgHaFz&pid=Api&P=0&h=180"
                        alt=""
                      />
                      <div className="w-100">
                        <div className="d-flex justify-content-between w-100">
                          <p className="mb-0 text-left">
                            Get upto&nbsp;
                            <span className="text-danger fw-600">30% off</span>
                            &nbsp;on call
                          </p>

                          <p className="mb-0 d-flex align-items-center justify-content-end">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="currentColor"
                              class="bi bi-telephone call_vibration"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                            </svg>
                            &nbsp;Call Us Now
                          </p>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <p className="text-danger fw-600 mb-0">
                            Last hour DEALS!
                          </p>
                          <p className="fw-600 mb-0 font-size-1 blinking">
                            +1 888-315-1615 (US)
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <Header />
          </div>
        )}
      </div>
    );
  }
}
export default Help;
