import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import popupfooterimg from "../image/offer_popup_fotter.png";
import Header from "./Header";
import ReactHtmlParser from "react-html-parser";
import Skeleton from "@material-ui/lab/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBell,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import noImg from "../image/NewUI/no-image.png";

class Deals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [
        {
          id: 1,
          slug: "first-offer-page",
          name: "first offer page",
          short_description: "here is my short description",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.",
          image: "1576329707.jpeg",
        },
        {
          id: 2,
          slug: "second-offer-page",
          name: "second offer page",
          short_description: "test SHORT DESCRIPTION",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.",
          image: "1581608799.jpeg",
        },
      ],
      setShow: false,
      showNotificationModal: false,

      offer_id: null,
      name: null,
      email: null,
      phone: null,
      p_list: null,
      date: null,
      itemCode: "",
      showSecondModal: false,
    };
  }
  componentDidMount() {
    fetch("https://api.ogule.com/api/frontend/offerpagelist").then(
      (response) => {
        response.json().then((result) => {
          this.setState({ dealslist: result.offerpagelsit });
        });
      }
    );
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }

  handleClose = () => {
    this.setState({ setShow: false });
  };
  handleShow(id) {
    this.setState({
      offer_id: id,
      setShow: true,
    });
  }

  handleCloseSecondModal = () => {
    this.setState({ showSecondModal: false });
  };

  create() {
    this.setState({ setShow: false });
    fetch("https://api.ogule.com/api/frontend/sumit_offer_inquiry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        offerpage_id: this.state.offer_id,
        name: this.state.name,
        phone_no: this.state.phone,
        email: this.state.email,
        no_of_passengers: this.state.p_list,
        date_of_travel: this.state.date,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          this.setState({ showSecondModal: true });
        } else {
          alert("Not Submit");
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  handleNotificationModal = () => {
    this.setState((prevState) => ({
      showNotificationModal: !prevState.showNotificationModal,
    }));
  };

  handleResize = () => {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  componentWillUnmount() {
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }

  render() {
    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Header />
            <div className="container my-4">
              <div className="row align-items-center">
                <div className="col">
                  <h2>Super Offers</h2>
                </div>
              </div>

              <Modal
                show={this.state.setShow}
                onHide={this.handleClose}
                className="login_popup"
                centered
              >
                <Modal.Header closeButton>
                  <div className="d-flex align-items-top justify-content-between gap-1rem w-100">
                    <div className="d-flex align-items-center justify-content-center gap-2 font-size-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#0c8ce9"
                        class="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                      CONTACT US
                    </div>
                    {/* </div> */}

                    <a
                      href="tel:+18883151615"
                      className="btn badge-pill btn-sm pulse text-white custom-primary-linear-gradient"
                      role="button"
                    >
                      <p className="mb-0 px-3">
                        Get upto&nbsp;
                        <span className="text-danger fw-600">30% off</span> on
                        call
                      </p>
                      <p className="mb-0 px-3 fw-600">+1 888-315-1615 (US)</p>
                    </a>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-0">
                        <label htmlFor="name">Enter Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter Your Name"
                          onChange={(event) => {
                            this.setState({ name: event.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-0">
                        <label htmlFor="p_list">Number of Passenger:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="p_list"
                          placeholder="Number of Passenger"
                          onChange={(event) => {
                            this.setState({ p_list: event.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-0">
                        <label htmlFor="phone">Enter Phone Number:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          placeholder="Enter Your Contact Number"
                          onChange={(event) => {
                            this.setState({ phone: event.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-0">
                        <label htmlFor="date">Journey Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          style={{ maxWidth: "100%" }}
                          id="date"
                          data-date-format="DD MMMM YYYY"
                          onChange={(event) => {
                            this.setState({ date: event.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="email">Enter Email ID:</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter Your Email"
                          onChange={(event) => {
                            this.setState({ email: event.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <p className="fw-600 mb-0">CODE : {this.state.itemCode}</p>
                    <button
                      className="btn btn-lg custom-btn-primary badge-pill px-4"
                      onClick={() => {
                        this.create();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>

              <Modal
                centered
                show={this.state.showSecondModal}
                onHide={this.handleCloseSecondModal}
              >
                <Modal.Header
                  closeButton
                  className="pb-0 border-0"
                ></Modal.Header>
                <Modal.Body className="pt-0">
                  <div className="px-3 text-center">
                    <span className="text-success" style={{ fontSize: "4rem" }}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <h6 className="mb-5">
                      Thank you for your interest in our deal. We will contact
                      you soon.
                    </h6>
                  </div>
                </Modal.Body>
              </Modal>

              <div className="modal fade offer_popup">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header text-center">
                      <div className="offer-popup_header">
                        <span className="offer_popup_pill">
                          <i className="fas fa-check-circle"></i> AVAILABLE NOW
                        </span>
                        <p>Call Now to Save Big</p>
                        <h3 className="modal-title">+1 888-315-1615 (US)</h3>
                      </div>
                      <button
                        type="button"
                        className="close offer_popup_cls_btn"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.dealslist ? (
                <div>
                  {this.state.dealslist.map((item, index) => {
                    // console.log("item : ", item);
                    return (
                      <div className="offers_box p-3 mb-3" id="myModal">
                        <div className="row">
                          <div className="col-sm-8">
                            <div className="d-flex flex-column gap-1rem justify-content-between h-100">
                              <div>
                                <h2>{item.name}</h2>
                                <p>{ReactHtmlParser(item.description)}</p>
                                <p style={{ fontWeight: "bold" }}>
                                  CODE : {item.short_description}
                                </p>
                              </div>
                              <div className="d-flex align-items-end justify-content-between gap-1rem">
                                <a
                                  href="tel:+18883151615"
                                  className="btn badge-pill btn-lg pulse text-white custom-primary-linear-gradient"
                                  role="button"
                                >
                                  <p className="mb-0 px-3">
                                    Get upto{" "}
                                    <span className="text-danger fw-600">
                                      30% off
                                    </span>{" "}
                                    on call
                                  </p>
                                  <p className="blinking mb-0 px-3 fw-600">
                                    +1 888-315-1615 (US)
                                  </p>
                                </a>
                                <Button
                                  className="btn custom-btn-primary badge-pill btn-lg"
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  onClick={() => {
                                    this.handleShow(item._id);
                                    this.setState({
                                      itemCode: item.short_description,
                                    });
                                  }}
                                >
                                  Get Offer
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div
                              className="rounded-10p float-right"
                              style={{
                                width: "320px",
                                height: "320px",
                                objectFit: "cover",
                              }}
                            >
                              <img
                                src={item?.image ? item?.image : noImg}
                                alt="John Doe"
                                className="w-100 h-100 rounded-10p"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center mb-3">
                        <h5>We're finding the best deals for you.</h5>
                      </div>
                    </div>
                  </div>
                  <div className="offers_box" id="myModal">
                    {Array.from({ length: 4 }).map((_, index) => (
                      <div className="row">
                        <div className="col-sm-8">
                          <Skeleton variant="text" width="100%" height={60} />
                          <p>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                          </p>
                        </div>
                        <div className="col-sm-4">
                          <Skeleton variant="rect" width="100%" height={200} />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            {/* Bell popup modal */}
            <Modal
              centered
              show={this.state.showNotificationModal}
              onHide={this.handleNotificationModal}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully added in notification list
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            {/* Call Now modal */}
            <Modal
              show={this.state.setShow}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <div className="d-flex align-items-top justify-content-between gap-1rem w-100">
                  <div className="d-flex align-items-center justify-content-center gap-2 text-nowrap">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#0c8ce9"
                      class="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    CONTACT US
                  </div>
                  {/* </div> */}

                  <a
                    href="tel:+18883151615"
                    className="btn badge-pill btn-sm pulse text-white custom-primary-linear-gradient"
                    role="button"
                  >
                    <p className="mb-0 fw-600 text-truncate">+1 888-315-1615 (US)</p>
                  </a>
                </div>
              </Modal.Header>
              <Modal.Body className="px-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                      <label htmlFor="name">Enter Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your Name"
                        onChange={(event) => {
                          this.setState({ name: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                      <label htmlFor="p_list">Number of Passenger:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="p_list"
                        placeholder="Number of Passenger"
                        onChange={(event) => {
                          this.setState({ p_list: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                      <label htmlFor="phone">Enter Phone Number:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Enter Your Contact Number"
                        onChange={(event) => {
                          this.setState({ phone: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                      <label htmlFor="date">Journey Date:</label>
                      <input
                        type="date"
                        className="form-control"
                        style={{ maxWidth: "100%" }}
                        id="date"
                        data-date-format="DD MMMM YYYY"
                        onChange={(event) => {
                          this.setState({ date: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="email">Enter Email ID:</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Your Email"
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="px-3">
                <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                  <p className="fw-600 mb-0">CODE : {this.state.itemCode}</p>
                  <button
                    className="btn btn-sm custom-btn-primary badge-pill px-4"
                    onClick={() => {
                      this.create();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </Modal.Footer>
            </Modal>

            <div className="deals_top_heading_mobile">
              <Link to="/">
                <div className="backarrow">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </Link>
              <div className="journy_atacode">
                <h4>Super Offers</h4>
              </div>
              <div
                className="notification"
                onClick={this.handleNotificationModal}
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
            </div>

            {this.state.dealslist ? (
              <div className="deals_box_section">
                <a
                  href="tel:+18883151615"
                  className="btn rounded-1rem card pulse w-100 mb-3"
                  role="button"
                >
                  <div className="d-flex align-items-center gap-12p w-100 ">
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src="https://tse3.explicit.bing.net/th?id=OIP.Aqn3nZQJW8VFj_uvJkWANgHaFz&pid=Api&P=0&h=180"
                      alt=""
                    />
                    <div className="w-100">
                      <div className="d-flex justify-content-between w-100">
                        <p className="mb-0 text-left">
                          Get upto&nbsp;
                          <span className="text-danger fw-600">30% off</span>
                          &nbsp;on call
                        </p>

                        <p className="mb-0 d-flex align-items-center justify-content-end">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            class="bi bi-telephone call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                          </svg>
                          &nbsp;Call Us Now
                        </p>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <p className="text-danger fw-600 mb-0">
                          Last hour DEALS!
                        </p>
                        <p className="fw-600 mb-0 font-size-1 blinking">
                          +1 888-315-1615 (US)
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                {this.state.dealslist.map((item, index) => (
                  <div
                    className="offers_box_mobile p-2 border-0"
                    key={index}
                    style={{ boxShadow: "0 2px 4px #64bed580" }}
                  >
                    <div
                      className="deals_box_left"
                      style={{ borderRight: "1px dashed rgb(52, 58, 64)" }}
                    >
                      <img
                        src={item?.image ? item?.image : noImg}
                        alt="John Doe"
                        className="img-fluid rounded-10p"
                      />
                    </div>
                    <div className="deals_box_right d-flex flex-column justify-content-between">
                      <div>
                        <h5>{item.name}</h5>
                        <p style={{ marginBottom: "12px", fontWeight: "bold" }}>
                          CODE : {item.short_description}
                        </p>
                      </div>
                      <Button
                        className="btn custom-btn-primary rounded-10p"
                        data-toggle="modal"
                        data-target="#myModal"
                        onClick={() => {
                          this.handleShow(item.id);
                          this.setState({ itemCode: item.short_description });
                        }}
                      >
                        Get up to 30% off
                      </Button>
                    </div>
                  </div>
                ))}
                <a
                  href="tel:+18883151615"
                  className="btn rounded-1rem text-white btn-lg pulse w-100 custom-primary-linear-gradient"
                  role="button"
                >
                  <div className="d-flex align-items-center justify-content-around">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="bi bi-telephone-fill call_vibration"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                      />
                    </svg>
                    <div className="">
                      <p className="fw-600 float-right mb-0 font-size-2">
                        +1 888-315-1615 (US)
                      </p>
                      <p className="mb-0 font-size-1 blinking">
                        Call now for exciting offers!
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            ) : (
              <div className="deals_box_section">
                <div
                  className="offers_box_mobile border-0 p-3"
                  style={{ boxShadow: "0 2px 4px #64bed580" }}
                >
                  <div className="text-center">
                    <h5>We're finding the best deals for you.</h5>
                  </div>
                </div>
                {Array.from({ length: 4 }).map((_, index) => (
                  <div
                    className="offers_box_mobile border-0 p-2"
                    style={{ boxShadow: "0 2px 4px #64bed580" }}
                    key={index}
                  >
                    <div className="deals_box_left">
                      <Skeleton
                        className="w-100 rounded-10p"
                        variant="rect"
                        height={140}
                      />
                    </div>
                    <div className="deals_box_right d-flex flex-column justify-content-between">
                      <div>
                        <h6>
                          <Skeleton variant="text" width="100%" height={50} />
                        </h6>
                        <p className="mb-0">
                          <Skeleton variant="text" />
                          <Skeleton variant="text" />
                        </p>
                      </div>
                      <p>
                        <Skeleton variant="text" height={50} />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <Header />
          </div>
        )}
      </div>
    );
  }
}

export default Deals;
