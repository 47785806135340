import React from "react";
import Slider from "@material-ui/core/Slider";
import { convertTimeFormat, parseTimeValue } from "../GlobalFunction";

const FilterSidebar = ({
  stops,
  updateStopCount,
  totalStopCount,
  selectAllFlights,
  priceWithAirlines,
  selectedAirlines,
  minPrice,
  maxPrice,
  selectedMaxPrice,
  departureData,
  arrivalData,
  deptValue,
  arrivValue,
  handleCheckBoxChange,
  handleSelectAll,
  handleClearAll,
  handleAirlineCheckboxChange,
  handleChangePrice,
  handleChangeDeptValue,
  handleChangeArrivValue,
  formatTimeValue,
}) => {
  return (
    <>
      <div className="blogs_side_bar_sec border-0 custom-card bg-white">
        <div className="blogs_side_bar_sec_about_us p-3">
          <h6 className="text-uppercase text-roboto fw-600">Stop</h6>
          <div className="d-flex flex-column gap-4p">
            {stops.map((data) => {
              return (
                <div className="d-flex align-items-end justify-content-between">
                  <div className="d-flex align-items-center gap-1">
                    <input
                      className="update-filter-checkbox"
                      type="checkbox"
                      value={data}
                      id="direct"
                      onChange={() => {
                        handleCheckBoxChange(data);
                      }}
                      checked={updateStopCount?.includes(data)}
                      disabled={!totalStopCount?.includes(data)}
                    />
                    <label className="mb-0" htmlFor="direct">
                      {data === "0"
                        ? "Direct"
                        : data === "1"
                        ? "1 Stop"
                        : "2+ Stop"}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="blogs_side_bar_sec border-0 custom-card bg-white">
        <div className="blogs_side_bar_sec_about_us p-3">
          <h6 className="text-uppercase text-roboto fw-600">Airlines</h6>
          <div className="d-flex flex-column gap-4p">
            <div className="d-flex align-items-center justify-content-between">
              <span
                onClick={handleSelectAll}
                className={`btn btn-sm ${selectAllFlights ? "btn-shadow" : ""}`}
              >
                Select all
              </span>
              <span
                onClick={handleClearAll}
                className={`btn btn-sm ${!selectAllFlights ? "btn-shadow" : ""}`}
              >
                Clear all
              </span>
            </div>
            {priceWithAirlines?.map((flight, index) => {
              return (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center gap-1">
                    <input
                      className="update-filter-checkbox"
                      type="checkbox"
                      // id={flight.id}
                      value={flight.airline_code}
                      onChange={() =>
                        handleAirlineCheckboxChange(flight.airline_code)
                      }
                      checked={selectedAirlines?.includes(flight.airline_code)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-${index}`}
                    >
                      {flight?.airline_name}
                    </label>
                  </div>
                  <label className="mb-0 text-dark">
                    &nbsp; $ {parseFloat(flight.totalPrice)?.toFixed(2)}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="blogs_side_bar_sec border-0 custom-card bg-white">
        <div className="blogs_side_bar_sec_about_us p-3">
          <h6 className="text-uppercase text-roboto fw-600">Price</h6>
          <div className="d-flex flex-column gap-4p">
            <div style={{ width: "100%" }}>
              <div className="d-flex justify-content-between mb-2">
                <span className="price-box-success">
                  Min : $ {minPrice?.toFixed(2)}
                </span>
                <span className="price-box-danger">
                  Max : $ {maxPrice?.toFixed(2)}
                </span>
              </div>
              <Slider
                min={minPrice}
                max={maxPrice}
                defaultValue={selectedMaxPrice}
                // onChange={this.handleChangePrice}
                onChange={(event, newValue) =>
                  handleChangePrice(event, newValue, "slider")
                }
                valueLabelDisplay="auto"
              />
              <div className="d-flex justify-content-end">
                <span className="price-box-primary">
                  $ {selectedMaxPrice?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blogs_side_bar_sec border-0 custom-card bg-white">
        <div className="blogs_side_bar_sec_about_us p-3">
          <h6 className="text-uppercase text-roboto fw-600">Times</h6>
          <div className="d-flex flex-column gap-4p">
            <div className="w-100">
              <div className="d-flex flex-column gap-4p">
                <span>
                  Take-off from&nbsp;
                  {sessionStorage.getItem("originLocationCode")}
                </span>
                <span>
                  {convertTimeFormat(departureData?.[0])}
                  -&nbsp;
                  {convertTimeFormat(departureData[departureData?.length - 1])}
                </span>
              </div>
              <div>
                <Slider
                  value={deptValue}
                  onChange={handleChangeDeptValue}
                  valueLabelDisplay="auto"
                  valueLabelFormat={formatTimeValue}
                  min={parseTimeValue(departureData[0])}
                  max={parseTimeValue(departureData[departureData.length - 1])}
                  disableSwap
                />
              </div>
            </div>

            {sessionStorage.getItem("wayOfFlight") === "round_trip" && (
              <div className="w-100">
                <div className="d-flex flex-column gap-4p">
                  <span>
                    Take-off from&nbsp;
                    {sessionStorage.getItem("destinationLocationCode")}
                  </span>
                  <span>
                    {convertTimeFormat(arrivalData?.[0])}
                    -&nbsp;
                    {convertTimeFormat(arrivalData[arrivalData?.length - 1])}
                  </span>
                </div>
                <div>
                  <Slider
                    value={arrivValue}
                    onChange={handleChangeArrivValue}
                    valueLabelDisplay="auto"
                    valueLabelFormat={formatTimeValue}
                    min={parseTimeValue(arrivalData?.[0])}
                    max={parseTimeValue(arrivalData[arrivalData?.length - 1])}
                    disableSwap
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSidebar;
