import React, { Component } from "react";
import Header from "./Header";
import ReactHtmlParser from "react-html-parser";
import Skeleton from "@material-ui/lab/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBell,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Blog from "../image/blogs/blogs_1.jpeg";
import { Modal } from "react-bootstrap";
import noImg from "../image/NewUI/no-image.png";
import Logo from "../image/NewUI/oguleNewLogo.png";

class Blogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayedBlogs: 5,
      showNotificationModal: false,
      showSubscribeModal: false,
    };
  }
  componentDidMount() {
    fetch("https://api.ogule.com/api/frontend/bloglist").then(
      (response) => {
        response.json().then((result) => {
          this.setState({ bloglist: result.bloglist });
        });
      }
    );
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }
  single_blog(item) {
    sessionStorage.setItem("single_blog", item);
    console.warn(this.props.history.push("single_blog"));
  }

  handleNotificationModal = () => {
    this.setState((prevState) => ({
      showNotificationModal: !prevState.showNotificationModal,
    }));
  };
  handleSubscribeModal = () => {
    this.setState((prevState) => ({
      showSubscribeModal: !prevState.showSubscribeModal,
    }));
  };

  handleResize = () => {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  componentWillUnmount() {
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }

  handleShowMore = () => {
    this.setState((prevState) => ({
      displayedBlogs: prevState.displayedBlogs + 5,
    }));
  };

  render() {
    const { displayedBlogs } = this.state;
    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Header />

            {/* Subscribe Modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showSubscribeModal}
              onHide={() => this.handleSubscribeModal()}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully subscribed.
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            <div className="container my-4">
              <div className="row">
                <div className="col-8">
                  <div className="blogs_side shadow-none border-0">
                    {/* <div className="blogs_sec p-3">
					          <div className="row">
					            <div className="col-12 mb-3">
					                <img src="image/blogs/blogs_0.jpeg" className="img-fluid" alt="blogs_0" />
					            </div>
					            <div className="col-12">
					              <h3>Two Beautifull Girls</h3>
					              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
					              <p className="float-right">
					                <i className="far fa-comment mr-3"> 3</i>
					                <i className="fas fa-share-alt"></i>
					              </p>              
					            </div>
					          </div>  
					        </div> */}

                    {this.state.bloglist ? (
                      <div className="d-flex flex-column gap-2rem">
                        {this.state.bloglist
                          .slice(0, displayedBlogs)
                          .map((item) => {
                            return (
                              <div className="blogs_sec border-0">
                                <div className="row">
                                  <div className="col-12 mb-4">
                                    <img
                                      className="img-fluid w-100 h-100"
                                      style={{
                                        borderRadius: "3rem",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                      }}
                                      src={item.image ? item.image : noImg}
                                      alt="blog_img"
                                    />
                                  </div>
                                  <div className="col-12">
                                    <div className="d-flex justify-content-between mb-2">
                                      <h3
                                        className="fw-bold text-primary-emphasis border-primary"
                                        style={{ borderWidth: "4px" }}
                                      >
                                        {item.name}
                                      </h3>
                                      <span
                                        className="btn px-3 rounded-10p"
                                        style={{
                                          background:
                                            "linear-gradient(0deg, #ADF6E6, #D5E4FD)",
                                          color: "navy",
                                        }}
                                        onClick={() => {
                                          this.single_blog(item.slug);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="22"
                                          fill="currentColor"
                                          class="bi bi-chevron-double-up"
                                          viewBox="0 0 16 16"
                                          style={{rotate:"90deg"}}
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708z"
                                          />
                                          <path
                                            fill-rule="evenodd"
                                            d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p>
                                      {ReactHtmlParser(
                                        item.short_description.substring(
                                          0,
                                          460
                                        ) + "..."
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        {displayedBlogs < this.state.bloglist.length && (
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn custom-btn-primary mt-3"
                              onClick={this.handleShowMore}
                            >
                              Show More
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-12 mobile_screen_blogs">
                            <div className="card border-0 p-2 mb-3">
                              <div className="text-center">
                                <h5>We're finding the best blogs for you.</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        {Array.from({ length: 4 }).map((_, index) => (
                          <div className="blogs_sec border-0 mb-4" key={index}>
                            <div className="row">
                              <div className="col-12">
                                <Skeleton
                                  variant="rect"
                                  width="100%"
                                  height={340}
                                />
                              </div>
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <h3
                                    className="border-primary"
                                    style={{ borderWidth: "4px" }}
                                  >
                                    <Skeleton
                                      variant="text"
                                      width="200px"
                                      height={80}
                                    />
                                  </h3>
                                  <h3 className="border-0">
                                    <Skeleton
                                      variant="text"
                                      width="80px"
                                      height={80}
                                    />
                                  </h3>
                                </div>
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height={40}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    {/* <div className="col blogs_pagination">
                      <ul className="pagination justify-content-center mt-3">
                        <li className="page-item">
                          <a className="page-link" href="ogule.com">
                            0
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="ogule.com">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="ogule.com">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="ogule.com"></a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="col-4">
                  <div className="blogs_side_bar shadow-none d-flex flex-column gap-2rem">
                    <div className="blogs_side_bar_sec border-0">
                      <a
                        href="tel:+18883151615"
                        className="btn rounded-1rem text-white btn-lg pulse w-100 custom-primary-linear-gradient"
                        role="button"
                      >
                        <div className="d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            class="bi bi-telephone-fill call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                            />
                          </svg>
                          <div className="">
                            <p className="fw-600 float-right mb-0 font-size-2 w-100">
                              +1 888-315-1615 (US)
                            </p>
                            <p className="mb-0 font-size-1">
                              Call us now to get best offers!
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="blogs_side_bar_sec border-0 custom-card">
                      <div className="blogs_side_bar_sec_about_us p-3 text-center">
                        <h4 className="text-uppercase text-roboto fw-600">
                          About Us
                        </h4>
                        <div className="img_bx d-flex justify-content-center align-items-center border-0 bg-white px-3">
                          <img
                            src={Logo}
                            className="img-fluid object-fit-contain"
                            alt="logo"
                          />
                        </div>
                        <p>
                          Welcome to Ogule, your ultimate destination for
                          seamless flight bookings across the United States. At
                          Ogule, we're passionate about transforming your travel
                          dreams into reality. Your hassle-free travel
                          experience begins here, at Ogule...
                          <Link
                            to="/about"
                            className="text-primary pointer ml-1"
                          >
                            Read more
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="blogs_side_bar_sec border-0 custom-card">
                      <div className="blogs_side_bar_sec_about_us p-3 text-center">
                        <h4 className="text-uppercase mb-3 text-roboto fw-600">
                          Social Me
                        </h4>

                        <div className="mt-3">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control form-control-lg"
                              id="email"
                              placeholder="Email Address"
                              name="email"
                            />
                          </div>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-facebook-f fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-google-plus-g fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-twitter fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-instagram fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-linkedin-in fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <div className="d-flex justify-content-center btn-block">
                            <button
                              className="btn custom-btn-primary btn-lg"
                              style={{ fontFamily: "Roboto, sans-serif" }}
                              onClick={this.handleSubscribeModal}
                            >
                              Subscribe
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blogs_side_bar_sec border-0">
                      {/* <div className="blogs_side_bar_sec_about_us p-3">
                        <h4 className="text-uppercase mb-3 text-roboto fw-600">
                          Categories
                        </h4>
                        <ul className="list-group list-group-flush gap-1rem">
                          <li className="d-flex justify-content-between align-items-center border-0 list-shadow">
                            Fashion
                            <span className="badge badge-primary badge-pill">
                              16
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center border-0 list-shadow">
                            Travel
                            <span className="badge badge-primary badge-pill">
                              11
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center border-0 list-shadow">
                            Lifestyle
                            <span className="badge badge-primary badge-pill">
                              16
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center border-0 list-shadow">
                            Photography
                            <span className="badge badge-primary badge-pill">
                              10
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="blogs_side_bar_sec border-0">
                        <div className="blogs_side_bar_sec_about_us p-3">
                          <h4 className="text-uppercase mb-3 text-roboto fw-600">
                            Tags
                          </h4>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                          <button className="btn btn-primary badge-pill m-1">
                            Awecome
                          </button>
                        </div>
                      </div> */}
                      <div className="blogs_side_bar_sec border-0">
                        <div className="blogs_side_bar_sec_about_us p-3">
                          <h4 className="text-uppercase mb-3 text-roboto fw-600">
                            Recent Post
                          </h4>

                          {this.state.bloglist ? (
                            <>
                              {this.state.bloglist?.slice(0, 4)?.map((item) => {
                                return (
                                  <div
                                    className="row sidebar_recent_post list-shadow border-0 mb-3 pointer"
                                    key={item?.id}
                                    onClick={() => {
                                      this.single_blog(item.slug);
                                    }}
                                  >
                                    <div
                                      className="col-5"
                                      style={{
                                        borderRight: "1px dashed #343a40",
                                      }}
                                    >
                                      <img
                                        className="h-100 w-100 rounded-10p"
                                        style={{
                                          borderRadius: "3rem",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                        }}
                                        src={item.image ? item.image : noImg}
                                        alt="blog_img"
                                      />
                                    </div>
                                    <div className="col-7">
                                      <h6 className="text-truncate">
                                        {item?.name}
                                      </h6>
                                      <div className="d-flex gap-12p justify-content-between"></div>
                                      <p className="text-truncate">
                                        {item.short_description}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {Array.from({ length: 4 }).map((_, index) => (
                                <div className="card rounded-10p mb-3 border-0 shadow">
                                  <div className="card-body py-1">
                                    <div className="d-flex gap-1rem">
                                      <Skeleton
                                        variant="text"
                                        width="40%"
                                        height={100}
                                      />
                                      <div className="w-100 d-flex flex-column gap-1rem justify-content-center">
                                        <Skeleton
                                          variant="text"
                                          width="100%"
                                          height={40}
                                        />
                                        <Skeleton
                                          variant="text"
                                          width="100%"
                                          height={20}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            {/* Bell popup modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showNotificationModal}
              onHide={this.handleNotificationModal}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully added in notification list
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            <div className="deals_top_heading_mobile">
              <Link to="/">
                <div className="backarrow">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </Link>
              <div className="journy_atacode">
                <h4>Our Blogs</h4>
              </div>
              <div
                className="notification"
                onClick={this.handleNotificationModal}
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
            </div>
            {this.state.bloglist ? (
              <div className="row deals_box_section">
                <div className="col-12 px-3">
                  <a
                    href="tel:+18883151615"
                    className="btn rounded-1rem card pulse w-100 mb-3"
                    role="button"
                  >
                    <div className="d-flex align-items-center gap-12p w-100 ">
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src="https://tse3.explicit.bing.net/th?id=OIP.Aqn3nZQJW8VFj_uvJkWANgHaFz&pid=Api&P=0&h=180"
                        alt=""
                      />
                      <div className="w-100">
                        <div className="d-flex justify-content-between w-100">
                          <p className="mb-0 text-left">
                            Get upto&nbsp;
                            <span className="text-danger fw-600">30% off</span>
                            &nbsp;on call
                          </p>

                          <p className="mb-0 d-flex align-items-center justify-content-end">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="currentColor"
                              class="bi bi-telephone call_vibration"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                            </svg>
                            &nbsp;Call Us Now
                          </p>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <p className="text-danger fw-600 mb-0">
                            Last hour DEALS!
                          </p>
                          <p className="fw-600 mb-0 font-size-1 blinking">
                            +1 888-315-1615 (US)
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                {this.state.bloglist
                  .slice(0, displayedBlogs)
                  .map((item, index) => (
                    <div
                      className="col-6 mobile_screen_blogs mb-3"
                      key={index}
                      onClick={() => {
                        this.single_blog(item.slug);
                      }}
                    >
                      <div
                        className="card"
                        style={{
                          background: `url("https://api.ogule.com/public/blog/${item.image}")`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "200px",
                        }}
                      >
                        <div
                          className="card-body text-light d-flex flex-column rounded-10p p-2"
                          style={{
                            background:
                              "linear-gradient( 358.7deg, #333333 -39.54%, rgba(0, 0, 0, 0) 66.57%",
                          }}
                        >
                          <img
                            className="img-fluid w-100 h-75"
                            src={item.image ? item.image : noImg}
                            alt="blog_img"
                          />
                          <h4 className="text-truncate">{item?.name}</h4>
                          <p className="card-text">
                            {ReactHtmlParser(
                              item.short_description.substring(0, 30) + "..."
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                {displayedBlogs < this.state.bloglist.length && (
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      className="btn custom-btn-primary mt-3 mb-3"
                      onClick={this.handleShowMore}
                    >
                      Show More
                    </button>
                  </div>
                )}
                <a
                  href="tel:+18883151615"
                  className="btn rounded-1rem text-white btn-lg pulse w-100 mx-3 custom-primary-linear-gradient"
                  role="button"
                >
                  <div className="d-flex align-items-center justify-content-around">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="bi bi-telephone-fill call_vibration"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                      />
                    </svg>
                    <div className="">
                      <p className="fw-600 float-right mb-0 font-size-2">
                        +1 888-315-1615 (US)
                      </p>
                      <p className="mb-0 font-size-1 blinking">
                        Call now for exciting offers!
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            ) : (
              <div className="row deals_box_section">
                <div className="col-12 mobile_screen_blogs">
                  <div className="card border-0 p-2 mb-3">
                    <div className="text-center">
                      <h5>We're finding the best blogs for you.</h5>
                    </div>
                  </div>
                </div>

                {Array.from({ length: 10 }).map((_, index) => (
                  <div className="col-6 mobile_screen_blogs mb-3">
                    <div className="card">
                      <div className="card-body p-2">
                        <Skeleton variant="rect" width="100%" height={115} />
                        <p className="card-text mb-0">
                          <Skeleton variant="text" height={55} />
                        </p>
                        <Skeleton variant="text" width="100%" height={40} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <Header />
          </div>
        )}
      </div>
    );
  }
}

export default Blogs;
