import React, { Component } from "react";
import Header from "./Header";
import ReactHtmlParser from "react-html-parser";
import Skeleton from "@material-ui/lab/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBell,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Logo from "../image/logo.png";
import { Modal } from "react-bootstrap";

class CustomerData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotificationModal: false,
      showSubscribeModal: false,
    };
  }

  componentDidMount() {
    fetch("https://api.ogule.com/api/frontend/bloglist").then(
      (response) => {
        response.json().then((result) => {
          this.setState({ bloglist: result.bloglsit });
        });
      }
    );
  }
  single_blog(item) {
    sessionStorage.setItem("single_blog", item);
    console.warn(this.props.history.push("single_blog"));
  }

  handleNotificationModal = () => {
    this.setState((prevState) => ({
      showNotificationModal: !prevState.showNotificationModal,
    }));
  };
  handleSubscribeModal = () => {
    this.setState((prevState) => ({
      showSubscribeModal: !prevState.showSubscribeModal,
    }));
  };

  render() {
    return (
      <div>
        {window.innerWidth > 768 ? (
          <div>
            <Header />

            {/* Subscribe Modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showSubscribeModal}
              onHide={() => this.handleSubscribeModal()}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully subscribed.
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            <div className="container my-4">
              <div className="row">
                <div className="col-8 d-flex flex-column gap-1rem">
                  {/* Customer Details Card */}
                  <div
                    className="card rounded-10p shadow mb-3"
                    style={{ background: "#e3edff" }}
                  >
                    <div className="card-body row justify-content-between">
                      <div className="col-12">
                        <div className="bg-light rounded-10p w-100 p-2 mb-2">
                          <p className="mb-0 fw-600">Customer 1</p>
                        </div>
                      </div>
                      <div className="col-8">
                        <h5 className="text-info px-2">Billing Details</h5>
                        <div className="p-2 rounded-10p bg-light">
                          <div className="row justify-content-between">
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <p className=" font-size-1 fw-600">
                                  First Name :{" "}
                                </p>
                                <p className="mb-1 text-dark font-size-1 fw-600">
                                  John
                                </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <p className=" font-size-1 fw-600">
                                  Last Name :{" "}
                                </p>
                                <p className="mb-1 text-dark font-size-1 fw-600">
                                  Doe
                                </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <p className=" font-size-1 fw-600">
                                  Contact no. :{" "}
                                </p>
                                <p className="mb-1 text-dark font-size-1 fw-600">
                                  **********
                                </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <p className=" font-size-1 fw-600">Email : </p>
                                <p className="mb-1 text-dark font-size-1 fw-600">
                                  john@gmail.com
                                </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <p className=" font-size-1 fw-600">
                                  Date Of Birth :{" "}
                                </p>
                                <p className="mb-1 text-dark font-size-1 fw-600">
                                  01/01/2000
                                </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <p className=" font-size-1 fw-600">City : </p>
                                <p className="mb-1 text-dark font-size-1 fw-600">
                                  Mumbai
                                </p>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <p className=" font-size-1 fw-600">
                                  Billing Address :{" "}
                                </p>
                                <p className="mb-1 text-dark font-size-1 fw-600">
                                  ****
                                </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-between">
                                <p className=" font-size-1 fw-600">
                                  Pincode :{" "}
                                </p>
                                <p className="mb-1 text-dark font-size-1 fw-600">
                                  ******
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <h5 className="text-info px-2">Card Details</h5>
                        <div className="p-2 rounded-10p bg-light">
                          <div className="d-flex justify-content-between">
                            <p className=" font-size-1 fw-600">
                              Card Holder :{" "}
                            </p>
                            <p className="mb-1 text-dark font-size-1 fw-600">
                              John Doe
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className=" font-size-1 fw-600">
                              Account no. :{" "}
                            </p>
                            <p className="mb-1 text-dark font-size-1 fw-600">
                              **********
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className=" font-size-1 fw-600">Card no. : </p>
                            <p className="mb-1 text-dark font-size-1 fw-600">
                              **********
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className=" font-size-1 fw-600">Card Type : </p>
                            <p className="mb-1 text-dark font-size-1 fw-600">
                              Master Card
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="blogs_side_bar shadow-none d-flex flex-column gap-2rem">
                    <div className="blogs_side_bar_sec border-0">
                      <a
                        href="tel:+18883151615"
                        className="btn rounded-1rem text-white btn-lg pulse w-100"
                        role="button"
                        style={{
                          background:
                            "linear-gradient(45deg, #FF6200, #ffa500, #FF6200)",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            class="bi bi-telephone-fill call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                            />
                          </svg>
                          <div className="">
                            <p className="fw-600 float-right mb-0 font-size-2 w-100">
                              +1 888-315-1615 (US)
                            </p>
                            <p className="mb-0 font-size-1">
                              Call us now to get best offers!
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="blogs_side_bar_sec border-0 custom-card">
                      <div className="blogs_side_bar_sec_about_us p-3 text-center">
                        <h4 className="text-uppercase text-roboto fw-600">
                          About Us
                        </h4>
                        <div className="img_bx d-flex justify-content-center align-items-center border-0 bg-white">
                          <img
                            src={Logo}
                            className="img-fluid object-fit-contain"
                            alt="logo"
                          />
                        </div>
                        <p>
                          Welcome to Ogule, your ultimate destination for
                          seamless flight bookings across the United States. At
                          Ogule, we're passionate about transforming your travel
                          dreams into reality. Your hassle-free travel
                          experience begins here, at Ogule...
                          <Link
                            to="/about"
                            className="text-primary pointer ml-1"
                          >
                            Read more
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="blogs_side_bar_sec border-0 custom-card">
                      <div className="blogs_side_bar_sec_about_us p-3 text-center">
                        <h4 className="text-uppercase mb-3 text-roboto fw-600">
                          Social Me
                        </h4>

                        <div className="mt-3">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control form-control-lg"
                              id="email"
                              placeholder="Email Address"
                              name="email"
                            />
                          </div>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-facebook-f fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-google-plus-g fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-twitter fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-instagram fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <span className="fa-stack fa-sm">
                            <i
                              className="far fa-circle fa-stack-2x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                            <i
                              className="fab fa-linkedin-in fa-stack-1x"
                              style={{ color: "#d2d3d5" }}
                            ></i>
                          </span>
                          <div className="d-flex justify-content-center btn-block">
                            <button
                              className="btn btn-primary btn-lg"
                              style={{ fontFamily: "Roboto, sans-serif" }}
                              onClick={this.handleSubscribeModal}
                            >
                              Subscribe
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blogs_side_bar_sec border-0">
                      <div className="blogs_side_bar_sec border-0">
                        <div className="blogs_side_bar_sec_about_us p-3">
                          <h4 className="text-uppercase mb-3 text-roboto fw-600">
                            Recent Post
                          </h4>

                          {this.state.bloglist ? (
                            <>
                              {this.state.bloglist?.slice(0, 4)?.map((item) => {
                                return (
                                  <div
                                    className="row sidebar_recent_post list-shadow border-0 mb-3 pointer"
                                    key={item?.id}
                                    onClick={() => {
                                      this.single_blog(item.slug);
                                    }}
                                  >
                                    <div
                                      className="col-5"
                                      style={{
                                        borderRight: "1px dashed #343a40",
                                      }}
                                    >
                                      <img
                                        src={
                                          "https://api.ogule.com/public/blog/" +
                                          item?.image
                                        }
                                        className="h-100 w-100 rounded-10p"
                                        style={{
                                          backgroundPosition: "center",
                                          backgroundSize: "cover",
                                        }}
                                        alt="blogs_1"
                                      />
                                    </div>
                                    <div className="col-7">
                                      <h6 className="text-truncate">
                                        {item?.name}
                                      </h6>
                                      <div className="d-flex gap-12p justify-content-between"></div>
                                      <p className="text-truncate">
                                        {item.short_description}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {Array.from({ length: 4 }).map((_, index) => (
                                <div className="card rounded-10p mb-3 border-0 shadow">
                                  <div className="card-body py-1">
                                    <div className="d-flex gap-1rem">
                                      <Skeleton
                                        variant="text"
                                        width="40%"
                                        height={100}
                                      />
                                      <div className="w-100 d-flex flex-column gap-1rem justify-content-center">
                                        <Skeleton
                                          variant="text"
                                          width="100%"
                                          height={40}
                                        />
                                        <Skeleton
                                          variant="text"
                                          width="100%"
                                          height={20}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            {/* Bell popup modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showNotificationModal}
              onHide={this.handleNotificationModal}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully added in notification list
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            <div className="deals_top_heading_mobile">
              <Link to="/">
                <div className="backarrow">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </Link>
              <div className="journy_atacode">
                <h4>Our Blogs</h4>
              </div>
              <div
                className="notification"
                onClick={this.handleNotificationModal}
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
            </div>

            {/* {this.state.bloglist ? ( */}
            <div className="row deals_box_section">
              <div className="col-12 px-3">
                <a
                  href="tel:+18883151615"
                  className="btn rounded-1rem card pulse w-100 mb-3"
                  role="button"
                >
                  <div className="d-flex align-items-center gap-12p w-100 ">
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src="https://tse3.explicit.bing.net/th?id=OIP.Aqn3nZQJW8VFj_uvJkWANgHaFz&pid=Api&P=0&h=180"
                      alt=""
                    />
                    <div className="w-100">
                      <div className="d-flex justify-content-between w-100">
                        <p className="mb-0 text-left">
                          Get upto&nbsp;
                          <span className="text-danger fw-600">30% off</span>
                          &nbsp;on call
                        </p>

                        <p className="mb-0 d-flex align-items-center justify-content-end">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            class="bi bi-telephone call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                          </svg>
                          &nbsp;Call Us Now
                        </p>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <p className="text-danger fw-600 mb-0">
                          Last hour DEALS!
                        </p>
                        <p className="fw-600 mb-0 font-size-1 blinking">
                          +1 888-315-1615 (US)
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="d-flex flex-column gap-1rem px-3 mb-3">
                <div
                  className="card rounded-10p shadow mb-3"
                  style={{ background: "#e3edff" }}
                >
                  <div className="card-body row justify-content-between">
                    <div className="col-12">
                      <div className="bg-light rounded-10p w-100 p-2 mb-2">
                        <p className="mb-0 fw-600">Customer 1</p>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      <h5 className="text-info px-2">Billing Details</h5>
                      <div className="p-2 rounded-10p bg-light">
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">First Name : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            John
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">Last Name : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            Doe
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">Contact no. : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            **********
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">Email : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            john@gmail.com
                          </p>
                        </div>
                        <div className="p-2 rounded-10p bg-light">
                          <div className="d-flex justify-content-between">
                            <p className=" font-size-1 fw-600">
                              Date Of Birth :{" "}
                            </p>
                            <p className="mb-1 text-dark font-size-1 fw-600">
                              01/01/2000
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">
                            Billing Address :{" "}
                          </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            ****
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">City : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            Mumbai
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">Pincode : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            ******
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-0">
                      <h5 className="text-info px-2">Payment Details</h5>
                      <div className="p-2 rounded-10p bg-light">
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">Card Holder : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            John Doe
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">Account no. : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            **********
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">Card no. : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            **********
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">Card Type : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            Master Card
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" font-size-1 fw-600">Ammount : </p>
                          <p className="mb-1 text-dark font-size-1 fw-600">
                            $199
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href="tel:+18883151615"
                className="btn rounded-1rem text-white btn-lg pulse w-100 mx-3"
                role="button"
                style={{
                  background:
                    "linear-gradient(45deg, #FF6200, #ffa500, #FF6200)",
                }}
              >
                <div className="d-flex align-items-center justify-content-around">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="currentColor"
                    class="bi bi-telephone-fill call_vibration"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                    />
                  </svg>
                  <div className="">
                    <p className="fw-600 float-right mb-0 font-size-2">
                      +1 888-315-1615 (US)
                    </p>
                    <p className="mb-0 font-size-1 blinking">
                      Call now for exciting offers!
                    </p>
                  </div>
                </div>
              </a>
            </div>
            {/* ) : (
               <div className="row deals_box_section">
                 {Array.from({ length: 10 }).map((_, index) => (
                   <div className="col-6 mobile_screen_blogs mb-3">
                     <div className="card">
                       <div className="card-body p-2">
                         <Skeleton variant="rect" width="100%" height={115} />
                         <p className="card-text mb-0">
                           <Skeleton variant="text" height={55} />
                         </p>
                         <p className="card-text">
                           <Skeleton variant="text" />
                         </p>
                       </div>
                     </div>
                   </div>
                 ))}
               </div>
             )} */}
            <Header />
          </div>
        )}
      </div>
    );
  }
}

export default CustomerData;
