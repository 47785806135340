import React, {Component} from 'react'
import Header from './Header'
import {Button} from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
class Thanku extends Component{
	constructor(props) {
	  super(props);
	
	  this.state = {};
	}
	continue(){
		console.warn(this.props.history.push('/'))
	}
	render(){
		return(
			<div className="thanku_sec">
				<div className="thanku_sec_icon">
					<FontAwesomeIcon icon={faCheckCircle} />
				</div>
				<div className="thanku_sec_title">
					<h2>Thank You For Booking</h2>
				</div>
				<div className="thanku_sec_btn mt-5">
					<Button label="Continue..." className="p-button-rounded"
									onClick={() => {this.continue()}}
									 />
				</div>
				<Header />
			</div>
		)
	}
}
export default Thanku;