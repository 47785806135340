import React, { Component } from "react";
import Header from "./Header";
import { RadioButton } from "primereact/radiobutton";
import { Fieldset } from "primereact/fieldset";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faBell,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import form from "../image/form.svg";
class Checkout extends Component {
  constructor(props) {
    sessionStorage.removeItem("guest_user");
    sessionStorage.removeItem("booking_login");
    //var login_data = JSON.parse(sessionStorage.getItem('login'));
    super(props);
    this.state = {
      new_card: "",
      //user_id:login_data.id,
      wayOfFlight: sessionStorage.getItem("wayOfFlight"),
      originLocationCode: sessionStorage.getItem("originLocationCode"),
      destinationLocationCode: sessionStorage.getItem("destinationLocationCode"),
      adults: sessionStorage.getItem("adults"),
      departureDate: sessionStorage.getItem("departureDate"),
      returnDate: sessionStorage.getItem("returnDate"),
      children: sessionStorage.getItem("children"),
      infants: sessionStorage.getItem("infants"),
      travelClass: sessionStorage.getItem("travelClass"),
      currencyCode: sessionStorage.getItem("currencyCode"),
      booking_details: sessionStorage.getItem("booking_details"),
      billing_details: {},
      passengers: [],
      booking_steps: "step_one",
      checkout_title: "Billing Information",
      payment_method: "card",
    };
  }
  componentDidMount() {
    for (var i = 0; i < this.state.adults; i++) {
      this.setState((state) => {
        const passengers = state.passengers.concat({
          first_name: "",
          middle_name: "",
          last_name: "",
          date_of_birth: "",
          nationality: "",
          passport_no: "",
          country_region_of_issue: "",
          passport_expiry_date: "",
          passenger_type: "adult",
        });
        return {
          passengers,
        };
      });
    }
    for (var x = 0; x < this.state.children; x++) {
      this.setState((state) => {
        const passengers = state.passengers.concat({
          first_name: "",
          middle_name: "",
          last_name: "",
          date_of_birth: "",
          nationality: "",
          passport_no: "",
          country_region_of_issue: "",
          passport_expiry_date: "",
          passenger_type: "children",
        });
        return {
          passengers,
        };
      });
    }
    for (var y = 0; y < this.state.infants; y++) {
      this.setState((state) => {
        const passengers = state.passengers.concat({
          first_name: "",
          middle_name: "",
          last_name: "",
          date_of_birth: "",
          nationality: "",
          passport_no: "",
          country_region_of_issue: "",
          passport_expiry_date: "",
          passenger_type: "infants",
        });
        return {
          passengers,
        };
      });
    }
    var login_data = JSON.parse(sessionStorage.getItem("login"));
    if (sessionStorage.getItem("login")) {
      fetch("https://api.ogule.com/api/auth/user_card", {
        headers: {
          Authorization: "Bearer " + login_data.access_token,
        },
      }).then((response) => {
        response.json().then((result) => {
          this.setState({ cardslist: result });
        });
      });
    }
    fetch("https://api.ogule.com/api/frontend/get_all_settings").then(
      (response) => {
        response.json().then((result) => {
          this.setState({ get_all_settings: result });
        });
      }
    );
  }
  handlepassengerchange = (idx) => (evt) => {
    const newPassengers = this.state.passengers.map((passenger, sidx) => {
      if (idx[0] !== sidx) return passenger;
      var fname = idx[1];
      return { ...passenger, [fname]: evt.target.value };
    });

    this.setState({ passengers: newPassengers });
  };
  submit() {
    var login_data = JSON.parse(sessionStorage.getItem("login"));
    if (login_data.id) {
      fetch("https://api.ogule.com/api/frontend/booking/add", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + login_data.access_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res) {
            console.warn(this.props.history.push("thanku"));
          } else {
            alert("Username or password is incorrect");
          }
        })
        .catch((error) => {
          console.error(error);
        });
      console.warn(this.state);
    } else {
      fetch("https://api.ogule.com/api/frontend/booking/add", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res) {
            console.warn(this.props.history.push("thanku"));
          } else {
            alert("Username or password is incorrect");
          }
        })
        .catch((error) => {
          console.error(error);
        });
      console.warn(this.state);
    }
  }
  handleChange(field, e) {
    let billing_details = this.state.billing_details;
    billing_details[field] = e.target.value;
    this.setState({ billing_details });
  }
  online_pay() {
    this.setState({ payment_method: "online" });
    fetch("https://api.ogule.com/api/frontend/get_easypay_url", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: "1",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          this.setState({ paynowurl: res.url });
        } else {
          alert("URL NOT SET");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    return (
      <div>
        {window.innerWidth > 768 ? (
          <div>
            <Header />
            <div className="container-fluid py-5">
              <div className="row">
                <div className="col-12">
                  <Fieldset legend="Billing Details">
                    <div className="row">
                      <div className="col-4">
                        <div class="form-group">
                          <label htmlFor="name">
                            Name:{this.state.billing_details.name}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                            value={this.state.billing_details.name}
                            onChange={(event) =>
                              this.setState({
                                billing_details: { name: event.target.value },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div class="form-group">
                          <label htmlFor="email">Email:</label>
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            value={this.state.email}
                            onChange={(event) =>
                              this.setState({
                                billing_details: {
                                  name: this.state.billing_details.name,
                                  email: event.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div class="form-group">
                          <label htmlFor="phone">Phone Number:</label>
                          <input
                            type="text"
                            class="form-control"
                            id="phone"
                            value={this.state.phone}
                            onChange={(event) =>
                              this.setState({
                                billing_details: {
                                  name: this.state.billing_details.name,
                                  email: this.state.billing_details.email,
                                  phone: event.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Fieldset>
                  <Fieldset legend="Traveller Details">
                    {this.state.passengers.map((passenger, key) => (
                      <div className="row">
                        <div className="col">
                          <div class="form-group">
                            <label htmlFor="name">First Name:</label>
                            <input
                              type="text"
                              class="form-control"
                              value={passenger.first_name}
                              onChange={this.handlepassengerchange([
                                key,
                                "first_name",
                              ])}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div class="form-group">
                            <label htmlFor="name">Middle Name:</label>
                            <input
                              type="text"
                              class="form-control"
                              value={passenger.middle_name}
                              onChange={this.handlepassengerchange([
                                key,
                                "middle_name",
                              ])}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div class="form-group">
                            <label htmlFor="name">Last Name:</label>
                            <input
                              type="text"
                              class="form-control"
                              value={passenger.last_name}
                              onChange={this.handlepassengerchange([
                                key,
                                "last_name",
                              ])}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div class="form-group">
                            <label htmlFor="name">Date of Birth:</label>
                            <input
                              type="text"
                              class="form-control"
                              value={passenger.date_of_birth}
                              onChange={this.handlepassengerchange([
                                key,
                                "date_of_birth",
                              ])}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div class="form-group">
                            <label htmlFor="name">Nationality:</label>
                            <input
                              type="text"
                              class="form-control"
                              value={passenger.nationality}
                              onChange={this.handlepassengerchange([
                                key,
                                "nationality",
                              ])}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div class="form-group">
                            <label htmlFor="name">Passport No:</label>
                            <input
                              type="text"
                              class="form-control"
                              value={passenger.passport_no}
                              onChange={this.handlepassengerchange([
                                key,
                                "passport_no",
                              ])}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div class="form-group">
                            <label htmlFor="name">Country Issue:</label>
                            <input
                              type="text"
                              class="form-control"
                              value={passenger.country_region_of_issue}
                              onChange={this.handlepassengerchange([
                                key,
                                "country_region_of_issue",
                              ])}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div class="form-group">
                            <label htmlFor="name">Pass Expiry Date:</label>
                            <input
                              type="text"
                              class="form-control"
                              value={passenger.passport_expiry_date}
                              onChange={this.handlepassengerchange([
                                key,
                                "passport_expiry_date",
                              ])}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Fieldset>
                  {this.state.cardslist ? (
                    <Fieldset legend="Our Card">
                      <>
                        {this.state.cardslist.map((item) => (
                          <div className="chk_card">
                            <RadioButton
                              inputId={item.id}
                              name="user_card_id"
                              value={item.id}
                              onChange={(e) =>
                                this.setState({ user_card_id: e.value })
                              }
                              checked={this.state.user_card_id === item.id}
                            />
                            <label
                              htmlFor={item.id}
                              className="p-radiobutton-label"
                            >
                              {item.card_no}
                            </label>
                          </div>
                        ))}
                      </>
                      <Button
                        label="+ Add New Card"
                        className="p-button-rounded"
                        onClick={() => {
                          this.setState({ new_card: true, user_card_id: "" });
                        }}
                      />
                    </Fieldset>
                  ) : (
                    <></>
                  )}
                  {this.state.new_card ? (
                    <Fieldset legend="Card Details">
                      <div className="row">
                        <div className="p-col-12 p-md-4">
                          <div className="form-group">
                            <label htmlFor="name">Card Name:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              value={this.state.card_name}
                              onChange={(event) =>
                                this.setState({ card_name: event.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                          <div className="form-group">
                            <label htmlFor="name">Card Holder Name:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              value={this.state.name_on_card}
                              onChange={(event) =>
                                this.setState({
                                  name_on_card: event.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                          <div className="form-group">
                            <label htmlFor="name">Card Number:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              value={this.state.card_no}
                              onChange={(event) =>
                                this.setState({ card_no: event.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                          <div className="form-group">
                            <p>Card Expiry Date</p>
                            <InputMask
                              class="form-control"
                              mask="99/99"
                              value={this.state.card_expiry_date}
                              placeholder="MM/YY"
                              slotChar="MM/YY"
                              onChange={(e) =>
                                this.setState({ card_expiry_date: e.value })
                              }
                            ></InputMask>
                          </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                          <div className="form-group">
                            <p>CVV</p>
                            <InputMask
                              class="form-control"
                              mask="999"
                              value={this.state.card_cvv}
                              placeholder="CVV"
                              slotChar="CVV"
                              onChange={(e) =>
                                this.setState({ card_cvv: e.value })
                              }
                            ></InputMask>
                          </div>
                        </div>
                      </div>
                    </Fieldset>
                  ) : (
                    <></>
                  )}
                  <Button
                    label="Submit"
                    className="p-button-raised p-button-rounded mr-1"
                    onClick={() => {
                      this.submit();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="deals_top_heading_mobile">
              <Link to="/flights">
                <div className="backarrow">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </Link>
              <div className="journy_atacode">
                <h4>Billing Details</h4>
              </div>
              <div className="notification">
                <FontAwesomeIcon icon={faBell} />
              </div>
            </div>
            <div className="deals_box_section">
              <p className="checkout_title">{this.state.checkout_title}</p>

              {this.state.booking_steps === "step_one" ? (
                <div className="mobile_checkout_sec">
                  <div className="form-group">
                    <label>Requerd Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      value={this.state.billing_details["name"]}
                      onChange={this.handleChange.bind(this, "name")}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      value={this.state.billing_details["email"]}
                      onChange={this.handleChange.bind(this, "email")}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      placeholder="Phone Number"
                      value={this.state.billing_details["phone"]}
                      onChange={this.handleChange.bind(this, "phone")}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      placeholder="Billing Address"
                      value={this.state.billing_details["address"]}
                      onChange={this.handleChange.bind(this, "address")}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      placeholder="City"
                      value={this.state.billing_details["city"]}
                      onChange={this.handleChange.bind(this, "city")}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="pincode"
                      placeholder="Pin Code"
                      value={this.state.billing_details["pincode"]}
                      onChange={this.handleChange.bind(this, "pincode")}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}

              {this.state.booking_steps === "step_two" ? (
                <div>
                  {this.state.passengers.map((passenger, key) => (
                    <div className="mobile_checkout_sec">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          value={passenger.first_name}
                          onChange={this.handlepassengerchange([
                            key,
                            "first_name",
                          ])}
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Middle Name"
                          value={passenger.middle_name}
                          onChange={this.handlepassengerchange([
                            key,
                            "middle_name",
                          ])}
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Last Name"
                          value={passenger.last_name}
                          onChange={this.handlepassengerchange([
                            key,
                            "last_name",
                          ])}
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Date of Birth"
                          value={passenger.date_of_birth}
                          onChange={this.handlepassengerchange([
                            key,
                            "date_of_birth",
                          ])}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}

              {this.state.booking_steps === "step_three" ? (
                <div>
                  <div className="payment_method_sec">
                    <div className="payment_method">
                      <RadioButton
                        inputId="card"
                        value="card"
                        name="payment_type"
                        onChange={(e) =>
                          this.setState({ payment_method: e.value })
                        }
                        checked={this.state.payment_method === "card"}
                      />
                      <label htmlFor="card" className="p-radiobutton-label">
                        Card
                      </label>
                    </div>
                    {this.state.get_all_settings.easy_payment_status ===
                    "enable" ? (
                      <div className="payment_method">
                        <RadioButton
                          inputId="online"
                          value="online"
                          name="payment_type"
                          onChange={() => this.online_pay()}
                          checked={this.state.payment_method === "online"}
                        />
                        <label htmlFor="online" className="p-radiobutton-label">
                          Online
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {this.state.payment_method === "card" ? (
                    <Button
                      label="+ Add New Card"
                      className="p-button-rounded"
                      onClick={() => {
                        this.setState({ new_card: true, user_card_id: "" });
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {this.state.payment_method === "card" &&
                  this.state.cardslist ? (
                    <div className="mobile_checkout_sec mt-2">
                      {this.state.cardslist.map((item) => (
                        <div className="chk_card">
                          <RadioButton
                            inputId={item.id}
                            name="user_card_id"
                            value={item.id}
                            onChange={(e) =>
                              this.setState({ user_card_id: e.value })
                            }
                            checked={this.state.user_card_id === item.id}
                          />
                          <label
                            htmlFor={item.id}
                            className="p-radiobutton-label"
                          >
                            {item.card_no}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              {this.state.new_card &&
              this.state.booking_steps === "step_three" &&
              this.state.payment_method === "card" ? (
                <div className="mobile_checkout_sec mt-2">
                  <div className="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Card Name"
                      value={this.state.card_name}
                      onChange={(event) =>
                        this.setState({ card_name: event.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Card Holder Name"
                      value={this.state.name_on_card}
                      onChange={(event) =>
                        this.setState({ name_on_card: event.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="card_billing_address"
                      placeholder="Payment Billing Address"
                    />
                  </div>
                  <div className="form-group">
                    <InputMask
                      class="form-control"
                      mask="9999-9999-9999-9999"
                      placeholder="Card Number"
                      slotChar="XXXX/XXXX/XXXX/XXXX"
                      value={this.state.card_no}
                      onChange={(event) =>
                        this.setState({ card_no: event.target.value })
                      }
                    ></InputMask>
                  </div>
                  <div class="d-flex">
                    <div className="form-group px-1">
                      <InputMask
                        class="form-control"
                        mask="99/99"
                        value={this.state.card_expiry_date}
                        placeholder="Expiry Date"
                        slotChar="MM/YY"
                        onChange={(e) =>
                          this.setState({ card_expiry_date: e.value })
                        }
                      ></InputMask>
                    </div>
                    <div className="form-group px-1">
                      <InputMask
                        class="form-control"
                        mask="999"
                        value={this.state.card_cvv}
                        placeholder="CVV"
                        slotChar="CVV"
                        onChange={(e) => this.setState({ card_cvv: e.value })}
                      ></InputMask>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {this.state.payment_method === "online" &&
              this.state.booking_steps === "step_three" ? (
                <div className="pay_online_sec">
                  <a
                    href={this.state.paynowurl}
                    target="_blank"
                    className="pay_online"
                  >
                    Pay Online Now
                  </a>
                </div>
              ) : (
                <></>
              )}
              {this.state.booking_steps === "step_one" ? (
                <div className="step_btn">
                  <button
                    type="button"
                    className="checkout_btn"
                    onClick={() =>
                      this.setState({
                        booking_steps: "step_two",
                        checkout_title: "Travelers Information",
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              ) : (
                <></>
              )}
              {this.state.booking_steps === "step_two" ? (
                <div className="step_btn">
                  <button
                    type="button"
                    className="checkout_btn"
                    onClick={() =>
                      this.setState({
                        booking_steps: "step_one",
                        checkout_title: "Billing Information",
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <button
                    type="button"
                    className="checkout_btn"
                    onClick={() =>
                      this.setState({
                        booking_steps: "step_three",
                        checkout_title: "Payment Information",
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              ) : (
                <></>
              )}
              {this.state.booking_steps === "step_three" ? (
                <div className="step_btn">
                  <button
                    type="button"
                    className="checkout_btn"
                    onClick={() =>
                      this.setState({
                        booking_steps: "step_two",
                        checkout_title: "Travelers Information",
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <button
                    type="button"
                    className="checkout_btn"
                    onClick={() => {
                      this.submit();
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <Header />
          </div>
        )}
      </div>
    );
  }
}
export default Checkout;
