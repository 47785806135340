import React, {Component} from 'react'
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import {format } from 'date-fns'

class OnwayDate extends Component{
	constructor(props) {
	  super(props);
	
	  this.state = {};
	}
	back(){
		console.warn(this.props.history.push(sessionStorage.getItem('search_from')))
	}
	render(){
		return(
			<>
			<InfiniteCalendar onSelect={function(date) {
				sessionStorage.setItem('departureDate', format(date, 'yyyy-MM-dd'))
				sessionStorage.setItem('mobile_departureDate_oneway', format(date, 'MMM dd'))
				sessionStorage.setItem('mobile_departureDate_oneway_day', format(date, 'iii'))
			   }}
			   minDate={new Date()-1}
			   selected={sessionStorage.getItem('departureDate') || new Date()}
			/>
			<div className="footer_btn" onClick={()=> {this.back()}}>
				<p>Submit</p>
			</div>
			 </>
		)
	}

}
export default OnwayDate;