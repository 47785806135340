import React, { Component } from "react";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBell,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

class Privacy_Policy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotificationModal: false,
    };
  }

  handleNotificationModal = () => {
    this.setState((prevState) => ({
      showNotificationModal: !prevState.showNotificationModal,
    }));
  };

  handleResize = () => {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  componentDidMount() {
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }

  componentWillUnmount() {
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }

  render() {
    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Header />
            <div className="container my-4">
              <div className="row">
                <div className="col-12">
                  <div className="blogs_side">
                    <div className="blogs_sec p-3">
                      <h3>Privacy & Cookies Policy</h3>
                      <p>
                        Your privacy and security is primary to us and we aim to
                        abide by every GDPRA security and confidentiality. We
                        appreciate you for believing in the business ideas we
                        have entrusting us with your valuable personal data. We
                        want you to understand how we use your data and your
                        rights regarding that data. This website is owned and
                        operated by OGULE LLC. This Privacy & Cookies Policy
                        explains how we collect, use, share, and process
                        personal data through the OGULE website, the OGULE
                        mobile site, our iPhone, Android and other mobile
                        applications and any other online communications and
                        interfaces. We request to go through the Privacy &
                        Cookies policy given below so that you thoroughly
                        recognize and understand about our practices. By
                        visiting this site, you honor to acknowledge that you
                        have fully read and understood the practices elucidated
                        and detailed in this policy.
                      </p>
                      <br />
                      <h6 className="font-weight-bold">
                        Summary of key issues -
                      </h6>
                      <h6>1. Our Collection of Your Personal Data</h6>
                      <p>
                        We must personally collect recognizable information in
                        order to provide you with our online travel service. We
                        may need to accumulate the following categories of
                        personal data that you provide to us while you are using
                        the site -
                      </p>
                      <dl>
                        <dt>Identifiers</dt>
                        <dd>- Name</dd>
                        <dd>- Address</dd>
                        <dd>- Phone number</dd>
                        <dd>- Email address</dd>
                        <dd>- Age</dd>
                        <dd>- Date of birth</dd>
                        <dd>- Gender</dd>
                        <dd>- Username and password for our site</dd>
                        <dd>
                          - You might choose any other information to provide
                          either through your use of social media handle sign-in
                          features such as Facebook or directly as required to
                          fulfill the requested service.
                        </dd>
                        <dd>- IP address</dd>

                        <dt>Commercial information</dt>
                        <dd>- Credit card number</dd>
                        <dd>- Payment information</dd>

                        <dt>
                          Internet or other electronic network activity
                          information
                        </dt>
                        <dd>- IP address</dd>
                        <dd>- IP address</dd>
                        <dd>- Device information</dd>
                        <dd>- Web log information</dd>
                        <dd>- Preferred searchrelated to specific searches</dd>
                        <dd>- Bids through your interactions with our site</dd>

                        <dt>Inferences drawn from other Personal Data</dt>
                        <dd>
                          - Profile or purchasing habits inferred from Personal
                          Data
                        </dd>
                        <dd>- Device information</dd>
                        <dd>- Web log information</dd>
                        <dd>
                          - Search preferences related to specific searches and
                        </dd>
                        <dd>- Bids through your interactions with our site</dd>

                        <dt>Geolocation data</dt>
                        <dd>- General device location and</dd>
                        <dd>- With consent, specific device location</dd>
                      </dl>
                      <p>
                        Additional to that, we may acquire certain categories of
                        personal datalisted above from sources as well as you,
                        basically more precisely from the following categories
                        of sources
                      </p>
                      <dl>
                        <dd>
                          - Third party applications (such as Facebook and
                          Google) that you use for single sign-in
                        </dd>
                        <dd>
                          - Oursubsidiaries, affiliates, or business partners
                        </dd>
                        <dd>- Third party data providers and</dd>
                        <dd>
                          - Individuals purchasing services on your behalf
                        </dd>
                      </dl>
                      <p>
                        In case you purchase services for your travel companions
                        or any other traveler, you must ensure that you behold
                        the right to provide their personal data to us and that
                        even those travelers have accepted the terms and
                        policies based on which OGULE uses their information.
                        Personal data of other travelers is acquired only on the
                        grounds of providing such personal data to the apt
                        travel supplier to fulfill the requested service, or may
                        be required or permitted by applicable law.
                      </p>
                      <br />
                      <h6>2. Protecting Your Personal Data</h6>
                      <p>
                        Prioritizing the security of your personal data at
                        OGULE, we choose to employ administrative, technical,
                        and physical safeguards that are designated as well as
                        designed to prevent any kind of unauthorized access,
                        maintenance of accurate data, and to ensure that the
                        usage of personal data is being done correctly. If you
                        ever feel that the established interaction with us is no
                        more secure, immediately contact us from the Contact Us
                        section of this Privacy & Cookies Policy.
                      </p>
                      <br />
                      <h6>3. Use of Your Personal Data</h6>
                      <p>
                        We use Personal Data to serve you in a way of building
                        features that will make the services easier to use, and
                        to contact you. This includesbetter customer support,
                        timely notice of new services, a faster website
                        experience, special offers, and more relevant content.
                      </p>

                      <dl>
                        <dt>
                          Our legal basis to use your data, where one is
                          required to be provided by applicable law, is as
                          follows
                        </dt>
                        <dd>- When necessary to perform a transaction</dd>
                        <dd>
                          - When necessary to act in accordance with a legal
                          obligation
                        </dd>
                        <dd>
                          - Where you have provided consent as appropriate under
                          applicable law
                        </dd>
                        <dd>
                          - When necessary for legitimate interests such as
                          marketing that you have not previously objected to
                          receiving.
                        </dd>
                      </dl>
                      <p>
                        Basically, you need to provide the personal data in
                        order to acquire a reservation or otherwise enter into a
                        transaction with us, so that we are able to provide you
                        with travel reservation services and that might be
                        required for our compliance purposes in association with
                        such transactions, except in some varying instances when
                        we indicate that the information is optional and up to
                        your choice. Not receiving your personal data might hold
                        us aback from providing you with some requested
                        information and carrying out transactions.
                      </p>
                      <br />
                      <h6>4. Sharing Your Personal Data</h6>
                      <p>
                        OGULE only shares your personal data on the grounds
                        mentioned in this Privacy & Cookies Policy or as
                        otherwise authorized by you. We also might share your
                        personal data that we accumulate from the categories of
                        recipients mentioned below for our business purposes –{" "}
                      </p>
                      <p>
                        We may share your personal data withhotels, car rental
                        agencies, airlines, and other involved third parties.
                        Please refer to the websites of third party suppliers
                        for their Privacy Policies. We share your information
                        with the companies associated with us -{" "}
                      </p>
                      <dl>
                        <dd>- To provide you with integrated services.</dd>
                        <dd>
                          - To provide you with personalized offers and send to
                          you marketing communications with your consent or as
                          otherwise permitted by applicable law.
                        </dd>
                        <dd>
                          - To enable "single sign-on" that is allowing you to
                          log into a shared account for multiple bookings and
                          the account holding a common log of information.
                        </dd>
                        <dd>- To provide customer support services.</dd>
                        <dd>
                          - To detect, prevent, and investigate fraud and misuse
                          of our services, and other data breaches and illegal
                          activities.
                        </dd>
                        <dd>
                          - To analyze how users use our independent and
                          affiliated platforms, so that we could improvise it
                          for better service.
                        </dd>
                        <dd>- To ensure compliance with applicable law.</dd>
                      </dl>
                      <p>
                        In order to provide you with certain services, we may
                        share your Personal Data with our business partners, or
                        require that you transact directly with a business
                        partner. Our contracts with our business partners that
                        offer these services require them to maintain such
                        Personal Data on grounds of Privacy & Cookies Policy.
                        You might refer to the terms and policies of the
                        business partner on their website.
                      </p>
                      <br />
                      <p>
                        Multiple operations that we perform require us to hire
                        other companies to help us. Such operations include
                        sending and delivering postal mail and email, analyzing
                        data we collect, marketing our services, handling credit
                        card transactions and providing customer service. The
                        companies we are associated with have access to personal
                        data to perform their operations and may not use it for
                        other purposes. OGULE requires these vendors to enter
                        into confidentiality agreements and to agree to act in a
                        manner showing no reluctance to the relevant principles
                        articulated in this Privacy & Cookies Policy.
                      </p>
                      <br />
                      <p>
                        As change is the only constant and that goes for
                        business as well, sobeing a part of this process, OGULE
                        may buy or sell other companies, and Personal Data may
                        be transferred as a part of these transactions.
                        Possibilities also lie in case when OGULE, or
                        substantially all of its assets, may be taken over by
                        another company, either by sale of assets, merger, or
                        otherwise, and personal data might be transferred as a
                        part of such transaction. In cases like such, the
                        company that takes over will be required to abide by the
                        privacy promises in this Privacy and Cookies Policy or
                        it will responsibly obtain your consent to any material
                        changes as to how your personal data needs to be
                        handled.
                      </p>
                      <br />
                      <p>
                        OGULE reserves the right to release personal data in
                        order to comply law or a judicial proceeding, legal
                        processes or court orders served on us. We reserve
                        rights also to release the personal data to apply or
                        enforce Terms and Conditions applicable to the services,
                        in order to protect us or others against inappropriate
                        activities or fraudulent, or to otherwise protect the
                        property or safety of OGULE, our customers, our
                        affiliated companies, or others.
                      </p>
                      <br />
                      <p>
                        We may share internet or some other electronic network
                        activity information about you, and also the general
                        geological data based on IP address, with third party
                        cookie providers we use on our sites which may use this
                        data for purposes other than strictly operational
                        purposes. The aforesaid statements are in accordance
                        with the rights preserved and described well in the
                        section on Our Policy on Cookies and Other Tracking
                        Technologies. In certain jurisdictions, such kind of
                        sharing may be considered a sale of your personal data
                        and you may have the right under the applicable law to
                        opt-out of or object to such sharing.
                      </p>
                      <br />
                      <h6>
                        5. Your Choices Regarding Our Collection and Use of Your
                        Data for Marketing and Other Purposes
                      </h6>
                      <p>
                        As already foresaid and explained that you may choose
                        not to provide us with your personal data; but also
                        doing so may hold us aback from providing you with the
                        varying requested information and carrying out
                        transactions.
                      </p>
                      <br />
                      <p>
                        Your personal data that is acquired to be used for
                        direct marketing is all under your control. If receiving
                        notices of special promotions or savings or any other
                        marketing offers or materials is not preferred by you,
                        you can simply opt out from receiving them by using the
                        unsubscribe hyperlink provided in any communication,
                        updating your choices in your account profile or you
                        might also opt for contacting us where required by law,
                        we take your consent for such direct marketing. We
                        request you to note that we might send you
                        service-related communications (like confirmations of
                        any future travel reservation you make), even if you
                        opt-out of receiving marketing communications from us.
                      </p>
                      <br />
                      <p>
                        You may also have the opportunity of providing your
                        mobile phone number on our site in order to receive
                        certain updates or alerts, which is not reliable to
                        consistency and continuity and may be discontinued at
                        any time. You may also use the settings to enable or
                        unable the mobile push notifications from us.
                      </p>
                      <br />
                      <h6>
                        6. Our Policy on Cookies and Other Tracking Technologies
                      </h6>
                      <p>
                        Cookies and analogous tracking technologies, such as
                        scripts, beacons, and tags, are small bits of code that
                        are usually stored on a user’s computer hard-drive or
                        device that enables a website to customize itself for
                        each user by recognizing information about the user’s
                        visit to the website.
                      </p>
                      <br />
                      <p>
                        The site uses cookies to accumulate your preferences and
                        display content based on what you view to customize your
                        visit, administer the site, analyze trends, track users’
                        movement around the site, gather demographic information
                        about our user base as a whole, and serve targeted
                        advertising. On mobile apps and websites, we may use
                        anonymous and random device IDs or advertiser
                        identifiers in a manner akin to our use of cookies on
                        our websites. Despite these activities include the
                        involvement of understood development of you as a user
                        as we use this information for the purpose described.
                        Provided with the nature of our sites and services as a
                        travel provider, and the limited amount of personal data
                        we obtain about you, this activity in no ways produce
                        legal effects for you and also doesn’t significantly
                        affect you.
                      </p>
                      <br />
                      <p>
                        OGULE cookies: We use our cookies to improvise your
                        web-browsing exploration. For example, we use a cookie
                        to reduce the time it takes for you to submit purchase
                        requests by storing a registered user's email address so
                        that you do not need to enter your email address each
                        time that you log-in. We will also use a cookie to keep
                        track of your search criteria while you are engaging
                        OGULE’s services. OGULE cookies are associated with your
                        personal data. However, no third party may use the
                        information we collect through OGULE cookies for their
                        own purposes.
                      </p>
                      <br />
                      <p>
                        Third Party Cookies: We work with third parties that
                        place cookies on our site to provide their services,
                        including
                      </p>
                      <dl>
                        <dd>
                          - Ad Targeting: We work with third-party advertising
                          companies to serve ads while you are visiting our
                          site. We permit these companies to place and access
                          their own cookies on your computer in the course of
                          serving advertisements on this site. In order to
                          provide advertisements about goods and services of
                          interest to you, these companies may use information
                          obtained through their cookies (which do not include
                          your name, address, email address or telephone number)
                          about your visits to the site and other websites, in
                          combination with non-personally identifiable
                          information about your purchases and interests from
                          other online sites. Other companies' use of their
                          cookies is subject to their own privacy policies and
                          not ours.
                        </dd>
                        <dd>
                          - Data Providers: We also allow data providers to
                          collect web log data from you (including IP address
                          and information about your browser or operating
                          system), when you visit our site, or place or
                          recognize a unique cookie on your browser to enable
                          you to receive customized ads or content. These
                          cookies contain no personally identifiable
                          information. The cookies may reflect demographic or
                          other data, with identifying elements removed, linked
                          to data you voluntarily have submitted to us, e.g.,
                          your email address that we may share with data
                          providers solely in hashed, non-human readable form.
                        </dd>
                        <dd>
                          - Analytics/Measurement: We also use third-party
                          analytics cookies to gain insight into how our
                          visitors use the site and optimize and improve our
                          site. The data we gather includes which web pages you
                          have viewed, which referring/exit pages you have
                          entered and arrived from, which platform type you have
                          used, date and time stamp information, and details
                          such as the number of clicks you make on a given page,
                          your mouse movements and scrolling activity, the
                          search words you use and the text you type while using
                          our site. We also make use of analytics cookies as
                          part of our online advertising campaigns to learn how
                          users interact with our site after they have been
                          shown an online advertisement, which may include
                          advertisements on third-party websites.
                        </dd>
                        <dd>
                          - Remarketing Pixel Tags and Other Technologies: We
                          may share web site usage information with third-party
                          advertising companies for the purpose of managing and
                          targeting advertisements and for market research. For
                          these purposes, we and our third-party advertising
                          companies may place or recognize a cookie on your
                          computer or device or directly in our emails or
                          communications or may place pixel tags (also called
                          clear gifs) on certain web pages. We will then use the
                          information that is collected to serve you relevant
                          advertisements when you are visiting other sites on
                          the Internet. These advertisements may be targeted to
                          specific searches you conducted on OGULE during
                          earlier browsing sessions.
                        </dd>
                        <dd>
                          - Flash Cookies: Our partners, who provide certain
                          features on our site or display advertising based on
                          your browsing activity, also use Local Shared Objects
                          such as Flash Cookies.
                        </dd>
                        <dd>
                          - Controlling Cookies: You have a choice over the use
                          of cookies as described in this Privacy & Cookies
                          Policy. Our site is not currently configured to
                          respond to Do Not Track signals. If you would rather
                          we do not use OGULE cookies when you visit us, please
                          configure your specific browser settings to reject
                          cookies.
                        </dd>
                      </dl>
                      <p>
                        We request you to keep in mind that without cookies you
                        may be unable to have access to specific features on the
                        site that also includes access to your account or
                        profile and certain customized content. Removal of all
                        cookies from your computer also affects your ensuing
                        visits to some websites, including this site, by
                        requiring that, such as you will have to enter your
                        login name when you return to that website.{" "}
                      </p>
                      <br />
                      <h6>Mobile Devices</h6>
                      <p>
                        We and/or our data providers may collect and store a
                        unique identifier matched to your mobile device in order
                        to deliver customized ads or content while you use
                        applications or surf the internet, or to identify you in
                        a unique manner across other devices or browsers. In
                        order to customize these ads or content, we or a data
                        partner may collect demographic or other data, with
                        identifying elements removed, about you received from
                        third parties, as well as data you voluntarily have
                        submitted to us (e.g., your email address) and/or data
                        passively collected from you, such as your device
                        identifier or IP address. For advertising purposes,
                        however, we will share your email address with our data
                        partners solely in hashed, non-human readable form.
                      </p>
                      <br />
                      <h6>
                        7. How You Can Access or Change Your Personal Data and
                        Your Data Subject Rights
                      </h6>
                      <p>
                        OGULE recognizes that you may will to change, access or
                        delete your personal data and you will be able to do
                        that by either contacting us at OGULE or by accessing
                        your profile. We will need to verify your identity
                        before acting on a requestto protect your privacy and
                        security. In most of the cases, we will then reply to
                        your request within 30 days of verification. Your email
                        address and password are required in order to access
                        your profile information if you have created a profile
                        on any of the services available on the site.
                      </p>
                      <br />
                      <p>
                        Depending on the jurisdiction where you reside, you may
                        have specific rights under local legislation to
                      </p>
                      <dl>
                        <dd>- request access to your Personal Data;</dd>
                        <dd>- request rectification of your Personal Data;</dd>
                        <dd>- request erasure of your Personal Data;</dd>
                        <dd>
                          - request restriction of processing of your Personal
                          Data;{" "}
                        </dd>
                        <dd>- request data portability; and </dd>
                        <dd>
                          - object to the processing of your Personal Data
                        </dd>
                      </dl>
                      <p>
                        These also include opting out of certain sharing of your
                        personal data or processing of your personal data for
                        marketing purposes. You may also have the right to
                        request from us the personal data from or about you that
                        we have sold or disclosed for a business purpose within
                        the past 12 months. We request you to visit the "Contact
                        Us" section of this Privacy & Cookies Policy if you have
                        any questions or would like to exercise such rights.
                        Please note that, in order to provide appropriate
                        security to your information, we may require additional
                        data points or information from you in order to verify
                        your identity prior to completing certain requests
                        related to your Personal Data (e.g., requiring the
                        matching of two or three data points provided by you
                        with information maintained by OGULE).
                      </p>
                      <br />
                      <p>
                        We will hold on to your information for as long as your
                        account is active or as needed to provide you services.
                        We will also perpetuate and use your information as
                        necessary to act in accordance with our legal
                        obligations, enforce our agreements, andresolve
                        disputes.
                      </p>
                      <br />
                      <h6>
                        8. Links to Other Websites and Third Party Services
                      </h6>
                      <p>
                        Our site contains links to other services and websites
                        that are neither owned nor controlled by, including
                        links to websites of our advertisers and travel
                        suppliers, partners, and sponsors. The information that
                        are collected by our site are only bound to this Privacy
                        and Cookies Policy. We have no control over the third
                        party websites, and your use of third party websites and
                        features are based on grounds of privacy policies posted
                        on those websites. We aren’t responsible or liable in
                        any ways for the business practices or privacy of any
                        third party website linked to our site or third party
                        social media functionally offered or featured on our
                        site, such as Google Plus, Twitter, Facebook, and
                        YouTube. Your use of third parties’ websites linked to
                        our site is at your own risk and we are not associated
                        in any way, so we highly recommend to go through the
                        privacy policies of any linked third party websites when
                        you leave one of our sites, link into our website or
                        through a sign-in feature, or get benefit from a third
                        party social media functionality or feature available on
                        our site.
                      </p>
                      <h6>9. Changes to the Privacy & Cookies Policy</h6>
                      <p>
                        OGULE may revise this Privacy and Cookie Policy to
                        reflect changes in the law, our personal data collection
                        and use practices, the features of our site, or
                        advancements in technology. If we further indulge into
                        making any such material changes, we will ensure to
                        notify you by email (mentioned email address in your
                        account) or through a prominent notice on the site
                        before the change becomes effective.{" "}
                      </p>
                      <h6>10. How to Contact Us</h6>
                      <p>
                        For any other queries, or to exercise your rights as
                        detailed above, please contact us at –{" "}
                      </p>
                      <br />
                      <p>
                        By postal mail at Ogule LLC, Three Sugar Creek Center
                        Blvd Sugar Land TX 77479, USA.
                      </p>
                      <br />
                      <p>
                        <b>Email –</b> sales@ogule.com
                      </p>
                      <p>
                        <b>Phone -</b> +1 888-315-1615
                        {/* +1 302-585-3548, */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            {/* Bell popup modal */}
            <Modal
              size="sm"
              centered
              show={this.state.showNotificationModal}
              onHide={this.handleNotificationModal}
            >
              <Modal.Header
                closeButton
                className="pb-0 border-0"
              ></Modal.Header>
              <Modal.Body className="pt-0">
                <div className="px-3 text-center">
                  <span className="text-success" style={{ fontSize: "4rem" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <h6 className="mb-5">
                    You have been successfully added in notification list
                  </h6>
                </div>
              </Modal.Body>
            </Modal>

            <div className="deals_top_heading_mobile">
              <Link to="/help">
                <div className="backarrow">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </Link>
              <div className="journy_atacode">
                <h4>Privacy & Policy</h4>
              </div>
              <div
                className="notification"
                onClick={this.handleNotificationModal}
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
            </div>

            <div className="blogs_sec">
              <h3>Privacy & Cookies Policy</h3>
              <p>
                Your privacy and security is primary to us and we aim to abide
                by every GDPRA security and confidentiality. We appreciate you
                for believing in the business ideas we have entrusting us with
                your valuable personal data. We want you to understand how we
                use your data and your rights regarding that data. This website
                is owned and operated by OGULE LLC. This Privacy & Cookies
                Policy explains how we collect, use, share, and process personal
                data through the OGULE website, the OGULE mobile site, our
                iPhone, Android and other mobile applications and any other
                online communications and interfaces. We request to go through
                the Privacy & Cookies policy given below so that you thoroughly
                recognize and understand about our practices. By visiting this
                site, you honor to acknowledge that you have fully read and
                understood the practices elucidated and detailed in this policy.
              </p>
              <br />
              <h6 className="font-weight-bold">Summary of key issues -</h6>
              <h6>1. Our Collection of Your Personal Data</h6>
              <p>
                We must personally collect recognizable information in order to
                provide you with our online travel service. We may need to
                accumulate the following categories of personal data that you
                provide to us while you are using the site -
              </p>
              <dl>
                <dt>Identifiers</dt>
                <dd>- Name</dd>
                <dd>- Address</dd>
                <dd>- Phone number</dd>
                <dd>- Email address</dd>
                <dd>- Age</dd>
                <dd>- Date of birth</dd>
                <dd>- Gender</dd>
                <dd>- Username and password for our site</dd>
                <dd>
                  - You might choose any other information to provide either
                  through your use of social media handle sign-in features such
                  as Facebook or directly as required to fulfill the requested
                  service.
                </dd>
                <dd>- IP address</dd>

                <dt>Commercial information</dt>
                <dd>- Credit card number</dd>
                <dd>- Payment information</dd>

                <dt>
                  Internet or other electronic network activity information
                </dt>
                <dd>- IP address</dd>
                <dd>- IP address</dd>
                <dd>- Device information</dd>
                <dd>- Web log information</dd>
                <dd>- Preferred searchrelated to specific searches</dd>
                <dd>- Bids through your interactions with our site</dd>

                <dt>Inferences drawn from other Personal Data</dt>
                <dd>
                  - Profile or purchasing habits inferred from Personal Data
                </dd>
                <dd>- Device information</dd>
                <dd>- Web log information</dd>
                <dd>- Search preferences related to specific searches and</dd>
                <dd>- Bids through your interactions with our site</dd>

                <dt>Geolocation data</dt>
                <dd>- General device location and</dd>
                <dd>- With consent, specific device location</dd>
              </dl>
              <p>
                Additional to that, we may acquire certain categories of
                personal datalisted above from sources as well as you, basically
                more precisely from the following categories of sources
              </p>
              <dl>
                <dd>
                  - Third party applications (such as Facebook and Google) that
                  you use for single sign-in
                </dd>
                <dd>- Oursubsidiaries, affiliates, or business partners</dd>
                <dd>- Third party data providers and</dd>
                <dd>- Individuals purchasing services on your behalf</dd>
              </dl>
              <p>
                In case you purchase services for your travel companions or any
                other traveler, you must ensure that you behold the right to
                provide their personal data to us and that even those travelers
                have accepted the terms and policies based on which OGULE uses
                their information. Personal data of other travelers is acquired
                only on the grounds of providing such personal data to the apt
                travel supplier to fulfill the requested service, or may be
                required or permitted by applicable law.
              </p>
              <br />
              <h6>2. Protecting Your Personal Data</h6>
              <p>
                Prioritizing the security of your personal data at OGULE, we
                choose to employ administrative, technical, and physical
                safeguards that are designated as well as designed to prevent
                any kind of unauthorized access, maintenance of accurate data,
                and to ensure that the usage of personal data is being done
                correctly. If you ever feel that the established interaction
                with us is no more secure, immediately contact us from the
                Contact Us section of this Privacy & Cookies Policy.
              </p>
              <br />
              <h6>3. Use of Your Personal Data</h6>
              <p>
                We use Personal Data to serve you in a way of building features
                that will make the services easier to use, and to contact you.
                This includesbetter customer support, timely notice of new
                services, a faster website experience, special offers, and more
                relevant content.
              </p>

              <dl>
                <dt>
                  Our legal basis to use your data, where one is required to be
                  provided by applicable law, is as follows
                </dt>
                <dd>- When necessary to perform a transaction</dd>
                <dd>
                  - When necessary to act in accordance with a legal obligation
                </dd>
                <dd>
                  - Where you have provided consent as appropriate under
                  applicable law
                </dd>
                <dd>
                  - When necessary for legitimate interests such as marketing
                  that you have not previously objected to receiving.
                </dd>
              </dl>
              <p>
                Basically, you need to provide the personal data in order to
                acquire a reservation or otherwise enter into a transaction with
                us, so that we are able to provide you with travel reservation
                services and that might be required for our compliance purposes
                in association with such transactions, except in some varying
                instances when we indicate that the information is optional and
                up to your choice. Not receiving your personal data might hold
                us aback from providing you with some requested information and
                carrying out transactions.
              </p>
              <br />
              <h6>4. Sharing Your Personal Data</h6>
              <p>
                OGULE only shares your personal data on the grounds mentioned in
                this Privacy & Cookies Policy or as otherwise authorized by you.
                We also might share your personal data that we accumulate from
                the categories of recipients mentioned below for our business
                purposes –{" "}
              </p>
              <p>
                We may share your personal data withhotels, car rental agencies,
                airlines, and other involved third parties. Please refer to the
                websites of third party suppliers for their Privacy Policies. We
                share your information with the companies associated with us -{" "}
              </p>
              <dl>
                <dd>- To provide you with integrated services.</dd>
                <dd>
                  - To provide you with personalized offers and send to you
                  marketing communications with your consent or as otherwise
                  permitted by applicable law.
                </dd>
                <dd>
                  - To enable "single sign-on" that is allowing you to log into
                  a shared account for multiple bookings and the account holding
                  a common log of information.
                </dd>
                <dd>- To provide customer support services.</dd>
                <dd>
                  - To detect, prevent, and investigate fraud and misuse of our
                  services, and other data breaches and illegal activities.
                </dd>
                <dd>
                  - To analyze how users use our independent and affiliated
                  platforms, so that we could improvise it for better service.
                </dd>
                <dd>- To ensure compliance with applicable law.</dd>
              </dl>
              <p>
                In order to provide you with certain services, we may share your
                Personal Data with our business partners, or require that you
                transact directly with a business partner. Our contracts with
                our business partners that offer these services require them to
                maintain such Personal Data on grounds of Privacy & Cookies
                Policy. You might refer to the terms and policies of the
                business partner on their website.
              </p>
              <br />
              <p>
                Multiple operations that we perform require us to hire other
                companies to help us. Such operations include sending and
                delivering postal mail and email, analyzing data we collect,
                marketing our services, handling credit card transactions and
                providing customer service. The companies we are associated with
                have access to personal data to perform their operations and may
                not use it for other purposes. OGULE requires these vendors to
                enter into confidentiality agreements and to agree to act in a
                manner showing no reluctance to the relevant principles
                articulated in this Privacy & Cookies Policy.
              </p>
              <br />
              <p>
                As change is the only constant and that goes for business as
                well, sobeing a part of this process, OGULE may buy or sell
                other companies, and Personal Data may be transferred as a part
                of these transactions. Possibilities also lie in case when
                OGULE, or substantially all of its assets, may be taken over by
                another company, either by sale of assets, merger, or otherwise,
                and personal data might be transferred as a part of such
                transaction. In cases like such, the company that takes over
                will be required to abide by the privacy promises in this
                Privacy and Cookies Policy or it will responsibly obtain your
                consent to any material changes as to how your personal data
                needs to be handled.
              </p>
              <br />
              <p>
                OGULE reserves the right to release personal data in order to
                comply law or a judicial proceeding, legal processes or court
                orders served on us. We reserve rights also to release the
                personal data to apply or enforce Terms and Conditions
                applicable to the services, in order to protect us or others
                against inappropriate activities or fraudulent, or to otherwise
                protect the property or safety of OGULE, our customers, our
                affiliated companies, or others.
              </p>
              <br />
              <p>
                We may share internet or some other electronic network activity
                information about you, and also the general geological data
                based on IP address, with third party cookie providers we use on
                our sites which may use this data for purposes other than
                strictly operational purposes. The aforesaid statements are in
                accordance with the rights preserved and described well in the
                section on Our Policy on Cookies and Other Tracking
                Technologies. In certain jurisdictions, such kind of sharing may
                be considered a sale of your personal data and you may have the
                right under the applicable law to opt-out of or object to such
                sharing.
              </p>
              <br />
              <h6>
                5. Your Choices Regarding Our Collection and Use of Your Data
                for Marketing and Other Purposes
              </h6>
              <p>
                As already foresaid and explained that you may choose not to
                provide us with your personal data; but also doing so may hold
                us aback from providing you with the varying requested
                information and carrying out transactions.
              </p>
              <br />
              <p>
                Your personal data that is acquired to be used for direct
                marketing is all under your control. If receiving notices of
                special promotions or savings or any other marketing offers or
                materials is not preferred by you, you can simply opt out from
                receiving them by using the unsubscribe hyperlink provided in
                any communication, updating your choices in your account profile
                or you might also opt for contacting us where required by law,
                we take your consent for such direct marketing. We request you
                to note that we might send you service-related communications
                (like confirmations of any future travel reservation you make),
                even if you opt-out of receiving marketing communications from
                us.
              </p>
              <br />
              <p>
                You may also have the opportunity of providing your mobile phone
                number on our site in order to receive certain updates or
                alerts, which is not reliable to consistency and continuity and
                may be discontinued at any time. You may also use the settings
                to enable or unable the mobile push notifications from us.
              </p>
              <br />
              <h6>6. Our Policy on Cookies and Other Tracking Technologies</h6>
              <p>
                Cookies and analogous tracking technologies, such as scripts,
                beacons, and tags, are small bits of code that are usually
                stored on a user’s computer hard-drive or device that enables a
                website to customize itself for each user by recognizing
                information about the user’s visit to the website.
              </p>
              <br />
              <p>
                The site uses cookies to accumulate your preferences and display
                content based on what you view to customize your visit,
                administer the site, analyze trends, track users’ movement
                around the site, gather demographic information about our user
                base as a whole, and serve targeted advertising. On mobile apps
                and websites, we may use anonymous and random device IDs or
                advertiser identifiers in a manner akin to our use of cookies on
                our websites. Despite these activities include the involvement
                of understood development of you as a user as we use this
                information for the purpose described. Provided with the nature
                of our sites and services as a travel provider, and the limited
                amount of personal data we obtain about you, this activity in no
                ways produce legal effects for you and also doesn’t
                significantly affect you.
              </p>
              <br />
              <p>
                OGULE cookies: We use our cookies to improvise your web-browsing
                exploration. For example, we use a cookie to reduce the time it
                takes for you to submit purchase requests by storing a
                registered user's email address so that you do not need to enter
                your email address each time that you log-in. We will also use a
                cookie to keep track of your search criteria while you are
                engaging OGULE’s services. OGULE cookies are associated with
                your personal data. However, no third party may use the
                information we collect through OGULE cookies for their own
                purposes.
              </p>
              <br />
              <p>
                Third Party Cookies: We work with third parties that place
                cookies on our site to provide their services, including
              </p>
              <dl>
                <dd>
                  - Ad Targeting: We work with third-party advertising companies
                  to serve ads while you are visiting our site. We permit these
                  companies to place and access their own cookies on your
                  computer in the course of serving advertisements on this site.
                  In order to provide advertisements about goods and services of
                  interest to you, these companies may use information obtained
                  through their cookies (which do not include your name,
                  address, email address or telephone number) about your visits
                  to the site and other websites, in combination with
                  non-personally identifiable information about your purchases
                  and interests from other online sites. Other companies' use of
                  their cookies is subject to their own privacy policies and not
                  ours.
                </dd>
                <dd>
                  - Data Providers: We also allow data providers to collect web
                  log data from you (including IP address and information about
                  your browser or operating system), when you visit our site, or
                  place or recognize a unique cookie on your browser to enable
                  you to receive customized ads or content. These cookies
                  contain no personally identifiable information. The cookies
                  may reflect demographic or other data, with identifying
                  elements removed, linked to data you voluntarily have
                  submitted to us, e.g., your email address that we may share
                  with data providers solely in hashed, non-human readable form.
                </dd>
                <dd>
                  - Analytics/Measurement: We also use third-party analytics
                  cookies to gain insight into how our visitors use the site and
                  optimize and improve our site. The data we gather includes
                  which web pages you have viewed, which referring/exit pages
                  you have entered and arrived from, which platform type you
                  have used, date and time stamp information, and details such
                  as the number of clicks you make on a given page, your mouse
                  movements and scrolling activity, the search words you use and
                  the text you type while using our site. We also make use of
                  analytics cookies as part of our online advertising campaigns
                  to learn how users interact with our site after they have been
                  shown an online advertisement, which may include
                  advertisements on third-party websites.
                </dd>
                <dd>
                  - Remarketing Pixel Tags and Other Technologies: We may share
                  web site usage information with third-party advertising
                  companies for the purpose of managing and targeting
                  advertisements and for market research. For these purposes, we
                  and our third-party advertising companies may place or
                  recognize a cookie on your computer or device or directly in
                  our emails or communications or may place pixel tags (also
                  called clear gifs) on certain web pages. We will then use the
                  information that is collected to serve you relevant
                  advertisements when you are visiting other sites on the
                  Internet. These advertisements may be targeted to specific
                  searches you conducted on OGULE during earlier browsing
                  sessions.
                </dd>
                <dd>
                  - Flash Cookies: Our partners, who provide certain features on
                  our site or display advertising based on your browsing
                  activity, also use Local Shared Objects such as Flash Cookies.
                </dd>
                <dd>
                  - Controlling Cookies: You have a choice over the use of
                  cookies as described in this Privacy & Cookies Policy. Our
                  site is not currently configured to respond to Do Not Track
                  signals. If you would rather we do not use OGULE cookies when
                  you visit us, please configure your specific browser settings
                  to reject cookies.
                </dd>
              </dl>
              <p>
                We request you to keep in mind that without cookies you may be
                unable to have access to specific features on the site that also
                includes access to your account or profile and certain
                customized content. Removal of all cookies from your computer
                also affects your ensuing visits to some websites, including
                this site, by requiring that, such as you will have to enter
                your login name when you return to that website.{" "}
              </p>
              <br />
              <h6>Mobile Devices</h6>
              <p>
                We and/or our data providers may collect and store a unique
                identifier matched to your mobile device in order to deliver
                customized ads or content while you use applications or surf the
                internet, or to identify you in a unique manner across other
                devices or browsers. In order to customize these ads or content,
                we or a data partner may collect demographic or other data, with
                identifying elements removed, about you received from third
                parties, as well as data you voluntarily have submitted to us
                (e.g., your email address) and/or data passively collected from
                you, such as your device identifier or IP address. For
                advertising purposes, however, we will share your email address
                with our data partners solely in hashed, non-human readable
                form.
              </p>
              <br />
              <h6>
                7. How You Can Access or Change Your Personal Data and Your Data
                Subject Rights
              </h6>
              <p>
                OGULE recognizes that you may will to change, access or delete
                your personal data and you will be able to do that by either
                contacting us at OGULE or by accessing your profile. We will
                need to verify your identity before acting on a requestto
                protect your privacy and security. In most of the cases, we will
                then reply to your request within 30 days of verification. Your
                email address and password are required in order to access your
                profile information if you have created a profile on any of the
                services available on the site.
              </p>
              <br />
              <p>
                Depending on the jurisdiction where you reside, you may have
                specific rights under local legislation to
              </p>
              <dl>
                <dd>- request access to your Personal Data;</dd>
                <dd>- request rectification of your Personal Data;</dd>
                <dd>- request erasure of your Personal Data;</dd>
                <dd>
                  - request restriction of processing of your Personal Data;{" "}
                </dd>
                <dd>- request data portability; and </dd>
                <dd>- object to the processing of your Personal Data</dd>
              </dl>
              <p>
                These also include opting out of certain sharing of your
                personal data or processing of your personal data for marketing
                purposes. You may also have the right to request from us the
                personal data from or about you that we have sold or disclosed
                for a business purpose within the past 12 months. We request you
                to visit the "Contact Us" section of this Privacy & Cookies
                Policy if you have any questions or would like to exercise such
                rights. Please note that, in order to provide appropriate
                security to your information, we may require additional data
                points or information from you in order to verify your identity
                prior to completing certain requests related to your Personal
                Data (e.g., requiring the matching of two or three data points
                provided by you with information maintained by OGULE).
              </p>
              <br />
              <p>
                We will hold on to your information for as long as your account
                is active or as needed to provide you services. We will also
                perpetuate and use your information as necessary to act in
                accordance with our legal obligations, enforce our agreements,
                andresolve disputes.
              </p>
              <br />
              <h6>8. Links to Other Websites and Third Party Services</h6>
              <p>
                Our site contains links to other services and websites that are
                neither owned nor controlled by, including links to websites of
                our advertisers and travel suppliers, partners, and sponsors.
                The information that are collected by our site are only bound to
                this Privacy and Cookies Policy. We have no control over the
                third party websites, and your use of third party websites and
                features are based on grounds of privacy policies posted on
                those websites. We aren’t responsible or liable in any ways for
                the business practices or privacy of any third party website
                linked to our site or third party social media functionally
                offered or featured on our site, such as Google Plus, Twitter,
                Facebook, and YouTube. Your use of third parties’ websites
                linked to our site is at your own risk and we are not associated
                in any way, so we highly recommend to go through the privacy
                policies of any linked third party websites when you leave one
                of our sites, link into our website or through a sign-in
                feature, or get benefit from a third party social media
                functionality or feature available on our site.
              </p>
              <h6>9. Changes to the Privacy & Cookies Policy</h6>
              <p>
                OGULE may revise this Privacy and Cookie Policy to reflect
                changes in the law, our personal data collection and use
                practices, the features of our site, or advancements in
                technology. If we further indulge into making any such material
                changes, we will ensure to notify you by email (mentioned email
                address in your account) or through a prominent notice on the
                site before the change becomes effective.{" "}
              </p>
              <h6>10. How to Contact Us</h6>
              <p>
                For any other queries, or to exercise your rights as detailed
                above, please contact us at –{" "}
              </p>
              <br />
              <p>
                By postal mail at Ogule LLC, Three Sugar Creek Center Blvd Sugar
                Land, TX 77479 USA.
              </p>
              <br />
              <p>
                <b>Email –</b> sales@ogule.com
              </p>
              <p>
                <b>Phone -</b>+1 888-315-1615
                {/* +1 302-585-3548,  */}
              </p>
            </div>
            <Header />
          </div>
        )}
      </div>
    );
  }
}

export default Privacy_Policy;
