import React, { Component } from "react";
import Header from "./Header";
import ReactHtmlParser from "react-html-parser";
class Single_Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    var single_blog = sessionStorage.getItem("single_blog");
    fetch("https://api.ogule.com/api/frontend/blog/" + single_blog).then(
      (response) => {
        response.json().then((result) => {
          this.setState({
            name: result.name,
            image: result.image,
            description: result.description,
          });
        });
      }
    );
    this.mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    this.handleResize();
    this.mobileMediaQuery.addEventListener("change", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  };

  componentWillUnmount() {
    this.mobileMediaQuery.removeEventListener("change", this.handleResize);
  }

  render() {
    return (
      <div>
        {!this.state.isMobile ? (
          <div>
            <Header />
            <div className="container my-4">
              <div className="single_blog">
                <div className="card img-fluid">
                  <img
                    className="card-img-top"
                    src={this.state.image}
                    alt="Card_image"
                  />
                  <div className="card-img-overlay text-center text-white">
                    <h1 className="card-title">{this.state.name}</h1>
                    <p>
                      <i className="fas fa-comment-alt mr-3"> 3</i>
                      <i className="fas fa-share-alt"></i>
                    </p>
                  </div>
                </div>
                <div className="single_blog_content p-5">
                  {ReactHtmlParser(this.state.description)}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="single_blog">
              <div className="card img-fluid">
                <img
                  className="card-img-top"
                  src={this.state.image}
                  alt="Card_image"
                />
                <div className="card-img-overlay text-center text-white">
                  <h1 className="card-title">{this.state.name}</h1>
                  <p>
                    <i className="fas fa-comment-alt mr-3"> 3</i>
                    <i className="fas fa-share-alt"></i>
                  </p>
                </div>
              </div>
              <div
                className="single_blog_content p-3"
                style={{ marginBottom: "8rem" }}
              >
                {ReactHtmlParser(this.state.description)}
              </div>
            </div>
            <Header />
          </div>
        )}
      </div>
    );
  }
}

export default Single_Blog;
