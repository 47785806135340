import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./Header";
import { Modal, ButtonToolbar, Dropdown } from "react-bootstrap";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import logo from "../image/logo-removebg.png";
import plane from "../image/plane.png";
import flight from "../image/flight.png";
import callCenter from "../image/call_center.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlaneDeparture,
  faPlaneArrival,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-elastic-carousel";
import moment from "moment";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

class Home extends Component {
  constructor(props) {
    super(props);
    this.departureDatePickerRef = React.createRef();
    this.datePickerRef = React.createRef();
    this.travellerRef = React.createRef();
    this.toInputRef = React.createRef();
    this.state = {
      setShow: false,
      iatacode_from: sessionStorage.getItem("iatacode_from") || "BHM",
      airport_name_from:
        sessionStorage.getItem("airport_name_from") || "SHUTTLESWORTH INTL",
      country_from: sessionStorage.getItem("country_from") || "US",
      iatacode_to: sessionStorage.getItem("iatacode_to") || "DEN",
      airport_name_to:
        sessionStorage.getItem("airport_name_to") || "DENVER INTERNATIONAL",
      country_to: sessionStorage.getItem("country_to") || "US",

      departuredate: format(new Date(), "yyyy-MM-dd"),
      returndate: format(
        new Date().setDate(new Date().getDate() + 1),
        "yyyy-MM-dd"
      ),

      child: 0,
      adult: 1,
      infants: 0,
      totalCounts: 1,
      t_class: [
        { name: "BASIC ECONOMY" },
        { name: "ECONOMY" },
        { name: "PREMIUM_ECONOMY" },
        { name: "BUSINESS" },
        { name: "FIRST" },
      ],
      t_class_checked: 0,
      t_class_selected: "ECONOMY",
      trip: sessionStorage.getItem("wayOfFlight") || "round_trip",
      currencyCode: "USD",
      selectedCurrency: "USD",
      selectedAirline: "",
      isDirect: false,
    };

    fetch("https://test.api.amadeus.com/v1/security/oauth2/token", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
      }),
      body: "grant_type=client_credentials&client_id=lFD9B6KFg1j3kMKGLzFgadbAKFEzOwVw&client_secret=XmvZgKrjBJAiAMfE", // <-- Post parameters
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.access_token) {
          this.setState({ apitoken: res.access_token });
          //Linklert(this.state.apitoken);
          this.handleGetFlights(res.access_token);
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    fetch("https://api.ogule.com/api/frontend/bloglist").then(
      (response) => {
        response.json().then((result) => {
          this.setState({ bloglist: result.bloglist });
        });
      }
    );
    fetch("https://api.ogule.com/api/frontend/offerpagelist").then(
      (response) => {
        response.json().then((result) => {
          this.setState({ dealslist: result.offerpagelsit });
        });
      }
    );
  }

  componentDidMount() {
    console.log("called---");
  }

  searchfrom(val) {
    console.warn(this.state.apitoken);
    this.setState({ iatacode_from: val });
    if (
      this.state.fromdata &&
      this.state.fromdata.some((f) => f.iataCode === val)
    ) {
      if (this.toInputRef.current) {
        this.toInputRef.current.focus();
      }
    }
    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + this.state.apitoken,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          var data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === res.data[i].iataCode) {
              sessionStorage.setItem("iatacode_from", res.data[i].iataCode);
              sessionStorage.setItem("airport_name_from", res.data[i].name);
              sessionStorage.setItem(
                "country_from",
                res.data[i].address.countryCode
              );
              this.setState({
                iatacode_from: res.data[i].iataCode,
                airport_name_from: res.data[i].name,
                country_from: res.data[i].address.countryCode,
              });
            } else {
            }
          }
          console.warn(res);
          this.setState({
            fromdata: res.data,
          });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleGetFlights = (access_token) => {
    fetch("https://api.ogule.com/api/frontend/get-all-airlines", {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + access_token,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          this.setState({ airlines: res });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  searchto(val) {
    //console.warn(val)
    this.setState({ iatacode_to: val });
    if (
      this.state.todata &&
      this.state.todata.some((f) => f.iataCode === val)
    ) {
      if (this.datePickerRef.current) {
        this.datePickerRef.current.setOpen(true);
      }
    }

    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + this.state.apitoken,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          var data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === res.data[i].iataCode) {
              sessionStorage.setItem("iatacode_to", res.data[i].iataCode);
              sessionStorage.setItem("airport_name_to", res.data[i].name);
              sessionStorage.setItem(
                "country_to",
                res.data[i].address.countryCode
              );
              this.setState({
                iatacode_to: res.data[i].iataCode,
                airport_name_to: res.data[i].name,
                country_to: res.data[i].address.countryCode,
              });
            } else {
            }
          }
          console.warn(res);
          this.setState({
            todata: res.data,
          });
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.adult !== this.state.adult ||
      prevState.child !== this.state.child ||
      prevState.infants !== this.state.infants
    ) {
      this.setState({
        totalCounts: this.state.adult + this.state.child + this.state.infants,
      });
    }
  }

  handledeparturedate = (date) => {
    if (!date) {
      return null;
    }
    sessionStorage.setItem("departureDate", format(date, "yyyy-MM-dd"));
    sessionStorage.setItem("mobile_departureDate_oneway", format(date, "MMM dd"));
    sessionStorage.setItem(
      "mobile_departureDate_oneway_day",
      format(date, "iii")
    );
    this.setState({
      departuredate: date,
    });
    if (this.state.trip === "one_way") {
      this.setState({ setShow: true });
    }
    if (this.departureDatePickerRef.current) {
      this.departureDatePickerRef.current.setOpen(true);
    }
    const returnDate = new Date(date);
    this.handlereturndate(returnDate.setDate(returnDate.getDate() + 1), "true");
  };
  componentDidMount() {
    sessionStorage.setItem("departureDate", format(new Date(), "yyyy-MM-dd"));
    sessionStorage.setItem(
      "returnDate",
      moment(new Date()).add(1,"days").format("YYYY-MM-DD")
      // format(new Date().setDate(new Date().getDate() + 1), "yyyy-MM-dd")
    );
    sessionStorage.setItem(
      "mobile_departureDate_oneway",
      format(new Date(), "MMM dd")
    );
    sessionStorage.setItem(
      "mobile_departureDate_oneway_day",
      format(new Date(), "iii")
    );
    this.setState({
      departuredate: new Date(),
    });
  }

  handlereturndate = (date, autoUpdate) => {
    if (!date) {
      return null;
    }
    sessionStorage.setItem("returnDate", format(date, "yyyy-MM-dd"));
    sessionStorage.setItem("mobile_returnDate_oneway", format(date, "MMM dd"));
    sessionStorage.setItem("mobile_returnDate_oneway_day", format(date, "iii"));
    this.setState({
      returndate: date,
      // setShow: true,
    });
    if (autoUpdate !== "true" && typeof autoUpdate !== "string") {
      // if (this.travellerRef.current) {
      // this.travellerRef.current.setShow(true);
      this.setState({
        setShow: true,
      });
      // }
    }
  };
  handleClose = () => {
    this.setState({ setShow: false, setShow_from: false, setShow_to: false });
  };
  handleShow = () => {
    this.setState({
      setShow: true,
    });
  };
  searchflights() {
    sessionStorage.setItem("originLocationCode", this.state.iatacode_from);
    sessionStorage.setItem("destinationLocationCode", this.state.iatacode_to);
    sessionStorage.setItem("adults", this.state.adult);
    sessionStorage.setItem("children", this.state.child);
    sessionStorage.setItem("infants", this.state.infants);
    sessionStorage.setItem("travelClass", this.state.t_class_selected);
    sessionStorage.setItem("currencyCode", this.state.currencyCode);
    sessionStorage.setItem("wayOfFlight", this.state.trip);
    sessionStorage.setItem("selected_airline", this.state.selectedAirline);
    sessionStorage.setItem("isDirect", this.state.isDirect);
    sessionStorage.setItem("airlinesArray", JSON.stringify(this.state.airlines));
    // this.props.history.push("flights");
    this.props.history.push("flightsupdate");
  }

  handleSelectAirline(value) {
    this.setState({ selectedAirline: value });
  }

  Fromflights() {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push("fromflights"));
  }
  Toflights() {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push("toflights"));
  }
  Onewaydate() {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push("onwayDate"));
  }
  RoundTripdate() {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push("roundTripDate"));
  }

  Singleblog(item) {
    sessionStorage.setItem("single_blog", item);
    console.warn(this.props.history.push("single_blog"));
  }
  from_and_to_exchange() {
    sessionStorage.setItem("iatacode_from", this.state.iatacode_to);
    sessionStorage.setItem("airport_name_from", this.state.airport_name_to);
    sessionStorage.setItem("country_from", this.state.country_to);
    sessionStorage.setItem("iatacode_to", this.state.iatacode_from);
    sessionStorage.setItem("airport_name_to", this.state.airport_name_from);
    sessionStorage.setItem("country_to", this.state.country_from);
    this.setState({
      iatacode_from: this.state.iatacode_to,
      airport_name_from: this.state.airport_name_to,
      country_from: this.state.country_to,
      iatacode_to: this.state.iatacode_from,
      airport_name_to: this.state.airport_name_from,
      country_to: this.state.country_from,
    });
  }
  trip(val) {
    sessionStorage.setItem("trip", val);
    this.setState({ trip: val });
  }
  search_data(val) {
    sessionStorage.setItem("search_from", "/");
    console.warn(this.props.history.push(val));
  }
  handleCurrencyChange = (eventKey) => {
    this.setState({ selectedCurrency: eventKey });
  };

  returnDay = (day) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = daysOfWeek[day];
    return dayName;
  };

  render() {
    const imageUrls = [
      "https://tse1.mm.bing.net/th?id=OIP.AoQBVl7Jm6fnJZA1Qn11kAHaHa&pid=Api&P=0&h=180",
      "https://tse4.mm.bing.net/th?id=OIP.MSFnM7668BrLPjqbaGEQEgHaHa&pid=Api&P=0&h=180",
      "https://tse1.mm.bing.net/th?id=OIP.iLsWFr8SOi1IPXVHaA-M3wHaHa&pid=Api&P=0&h=180",
      "https://tse1.explicit.bing.net/th?id=OIP.ONw40kcU79iQfuayPMR82wHaHa&pid=Api&P=0&h=180",
      "https://tse4.mm.bing.net/th?id=OIP.AJPw72dJDr9_zOAdRDeeYQHaHa&pid=Api&P=0&h=180",
    ];

    return (
      <div>
        {window.innerWidth > 768 ? (
          <div>
            <Modal
              show={this.state.setShow}
              onHide={this.handleClose}
              className="login_popup"
            >
              <Modal.Header closeButton>
                <ButtonToolbar>
                  {this.state.t_class.map((item) => (
                    <div>
                      {this.state.t_class_selected === item.name ? (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded mr-1"
                        />
                      ) : (
                        <Button
                          label={item.name}
                          className="p-button-raised p-button-rounded p-button-secondary mr-1"
                          onClick={() =>
                            this.setState({ t_class_selected: item.name })
                          }
                        />
                      )}
                    </div>
                  ))}
                </ButtonToolbar>
              </Modal.Header>
              <Modal.Body>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Adult (Above 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult - 1 });
                        if (this.state.adult - 1 < this.state.infants) {
                          this.setState({ infants: this.state.adult - 1 });
                        }
                      }}
                      disabled={this.state.adult === 0}
                    />
                    <input
                      type="text"
                      value={this.state.adult}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() => {
                        this.setState({ adult: this.state.adult + 1 });
                      }}
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Child (2 years to 12 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child - 1 })
                      }
                      disabled={this.state.child === 0}
                    />
                    <input
                      type="text"
                      value={this.state.child}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ child: this.state.child + 1 })
                      }
                      disabled={
                        parseInt(this.state.adult) +
                          parseInt(this.state.child) ===
                        9
                      }
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between  mb-3">
                  <div class="p-2">Infants (Below 2 years)</div>
                  <div class="p-2">
                    <Button
                      label="-"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants - 1 })
                      }
                      disabled={this.state.infants === 0}
                    />
                    <input
                      type="text"
                      value={this.state.infants}
                      maxlength="2"
                      className="idval"
                    />
                    <Button
                      label="+"
                      className="p-button-raised"
                      onClick={() =>
                        this.setState({ infants: this.state.infants + 1 })
                      }
                      disabled={this.state.infants === this.state.adult}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  label="Submit"
                  className="p-button-raised p-button-rounded mr-2"
                  onClick={() => this.setState({ setShow: false })}
                />
              </Modal.Footer>
            </Modal>

            <Header />

            <div className="banner_img mx-auto mt-1 rounded-4rem w-85 p-5">
              <div className="row">
                <div className="col-6">
                  <div
                    className="container bg-white p-3"
                    style={{ borderRadius: "1.4rem" }}
                  >
                    <div className="mb-3 d-flex gap-1rem justify-content-between">
                      <div className="d-flex gap-1rem">
                        <div className="d-flex align-items-center gap-12p">
                          <RadioButton
                            value="one_way"
                            name="One Way"
                            onChange={(e) => {
                              this.trip(e.value);
                            }}
                            checked={this.state.trip === "one_way"}
                          />
                          <b>One Way</b>
                        </div>
                        <div className="d-flex align-items-center gap-12p">
                          <RadioButton
                            value="round_trip"
                            name="Round Trip"
                            onChange={(e) => {
                              this.trip(e.value);
                            }}
                            checked={this.state.trip === "round_trip"}
                            className="ml-3"
                          />
                          <b>Round Trip</b>
                        </div>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="direct_flight"
                          checked={this.state.isDirect}
                          onChange={(e) => {
                            if (e.target.checked) {
                              // sessionStorage.setItem("isDirect", true);
                              this.setState({ isDirect: true });
                            } else {
                              // sessionStorage.setItem("isDirect", false);
                              this.setState({ isDirect: false });
                            }
                          }}
                        />
                        <b class="form-check-label" htmlFor="direct_flight">
                          Direct Flight
                        </b>
                      </div>
                    </div>
                    <form>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="from" className="fw-600">
                              From
                            </label>
                            <input
                              className="form-control text-primary rounded-10p font-size-2 mb-2"
                              type="text"
                              autocomplete="off"
                              value={this.state.iatacode_from}
                              id="from"
                              list="fromdata"
                              onChange={(e) => this.searchfrom(e.target.value)}
                            />
                            <p className="text-secondary">
                              {this.state.airport_name_from}&nbsp;
                              {this.state.country_from}
                            </p>
                          </div>

                          <div>
                            {this.state.fromdata ? (
                              <div>
                                <datalist id="fromdata">
                                  {this.state.fromdata.map((item) => (
                                    <option value={item.iataCode}>
                                      {item.name},{item.address.cityName}{" "}
                                      {item.address.countryName}
                                    </option>
                                  ))}
                                </datalist>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="to" className="fw-600">
                              To
                            </label>
                            <input
                              className="form-control text-primary rounded-10p font-size-2 mb-2"
                              type="text"
                              id="to"
                              value={this.state.iatacode_to}
                              list="todata"
                              onChange={(e) => this.searchto(e.target.value)}
                              ref={this.toInputRef}
                            />
                            <p className="text-secondary">
                              {this.state.airport_name_to}&nbsp;
                              {this.state.country_to}
                            </p>
                          </div>
                          <div>
                            {this.state.todata ? (
                              <div>
                                <datalist id="todata">
                                  {this.state.todata.map((item) => (
                                    <option value={item.iataCode}>
                                      {item.name},{item.address.cityName}{" "}
                                      {item.address.countryName}
                                    </option>
                                  ))}
                                </datalist>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {this.state.trip === "round_trip" ? (
                          <div className="col-12">
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="departure" className="fw-600">
                                    Departure
                                  </label>
                                  <DatePicker
                                    selected={
                                      new Date(this.state.departuredate)
                                    }
                                    onChange={this.handledeparturedate}
                                    minDate={new Date()}
                                    dateFormat="d MMM, yy"
                                    ref={this.datePickerRef}
                                  />
                                  <p className="text-secondary">
                                    {this.returnDay(
                                      new Date(
                                        this.state.departuredate
                                      ).getDay()
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="return" className="fw-600">
                                    Return
                                  </label>
                                  <DatePicker
                                    selected={new Date(this.state.returndate)}
                                    onChange={this.handlereturndate}
                                    minDate={new Date(this.state.departuredate)}
                                    dateFormat="d MMM, yy"
                                    ref={this.departureDatePickerRef}
                                  />
                                  <p className="text-secondary">
                                    {this.returnDay(
                                      new Date(this.state.returndate).getDay()
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="departure" className="fw-600">
                                Departure
                              </label>
                              <DatePicker
                                selected={new Date(this.state.departuredate)}
                                onChange={this.handledeparturedate}
                                minDate={new Date()}
                                dateFormat="d MMM, yy"
                              />
                              <p className="text-secondary">
                                {this.returnDay(
                                  new Date(this.state.departuredate).getDay()
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="col-6">
                          <div className="form-group traveller_class">
                            <label
                              htmlFor="travellers_count"
                              className="fw-600"
                            >
                              Travellers
                            </label>
                            <span
                              onClick={() => this.setState({ setShow: true })}
                              ref={this.handlereturndate()}
                            >
                              <div className="text-primary form-control rounded-10p font-size-2 overflow-auto">
                                {this.state.adult +
                                  this.state.child +
                                  this.state.infants}
                                &nbsp;Traveller
                              </div>
                            </span>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label className="fw-600">Flights</label>
                            <select
                              className="text-primary form-control rounded-10p font-size-1"
                              onChange={(e) =>
                                this.handleSelectAirline(e.target.value)
                              }
                              value={this.state.selectedAirline}
                              style={{ height: "3rem" }}
                              disabled={!this.state.airlines}
                            >
                              <option value="">All</option>
                              {!this.state.airlines ? (
                                <option>Loading...</option>
                              ) : (
                                this.state.airlines.map((item) => (
                                  <option
                                    className="overflow-auto"
                                    value={item?.iataCode}
                                  >
                                    {item?.commonName}
                                  </option>
                                ))
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="col-6">
                          <label htmlFor="travellers_count" className="fw-600">
                            Cabin Class
                          </label>
                          <span
                            onClick={() => this.setState({ setShow: true })}
                            ref={this.handlereturndate()}
                          >
                            <div className="text-primary form-control rounded-10p font-size-2 overflow-auto">
                              {this.state.t_class_selected}
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary px-4"
                          style={{ borderRadius: "1.4rem 0 1.4rem 0" }}
                          disabled={!this.state.airlines}
                          onClick={() => {
                            this.searchflights();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-search"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                          </svg>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="col-6">
                  <div className="h-100 d-flex flex-column justify-content-between">
                    <div>
                      <h2
                        className="text-white mb-0"
                        style={{ fontWeight: "900", fontSize: "3.5rem" }}
                      >
                        TRAVEL the <br /> WORLD
                      </h2>
                      <hr
                        className="border-white w-75 mt-0"
                        style={{ borderWidth: "0.5rem" }}
                      />
                      <div className="d-flex gap-1rem">
                        <div className="d-flex align-items-center gap-12p">
                          <img
                            src={flight}
                            style={{
                              height: "2.5rem",
                            }}
                          />
                          <p className="text-white fw-600 h6">
                            SECURE
                            <br />
                            TRAVEL
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-12p">
                          <img src={callCenter} style={{ height: "2.5rem" }} />
                          <p className="text-white fw-600 h6">
                            24X7 CUSTOMER
                            <br />
                            SUPPORT
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <a
                        href="tel:+18883151615"
                        className="btn rounded-1rem text-white btn-lg pulse"
                        role="button"
                        style={{
                          background:
                            "linear-gradient(45deg, #FF6200, #ffa500, #FF6200)",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            class="bi bi-telephone-fill call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                            />
                          </svg>
                          <div className="">
                            <p className="fw-600 float-right mb-0 font-size-2">
                              +1 888-315-1615 (US)
                            </p>
                            <p className="mb-0 font-size-1">
                              Call now for exciting offers!
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container my-4">
              <div className="row align-items-center">
                <div className="col-12 text-center">
                  <h2 className="mb-0">Super Offers</h2>
                </div>
                {/* <div className="col super_offers_nav">
                  <ul className="nav">
                    <li className="nav-item">
                      <Link className="nav-link active" to="/">
                        ALL OFFERS
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        BANK OFFERS
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                        to="/"
                      >
                        MORE
                      </Link>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/">
                          Link 1
                        </Link>
                        <Link className="dropdown-item" to="/">
                          Link 2
                        </Link>
                        <Link className="dropdown-item" to="/">
                          Link 3
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div
                id="super_offers"
                className="carousel slide mb-4"
                data-ride=""
              >
                <div
                  className="d-flex justify-content-end"
                  style={{ padding: "0 5rem" }}
                >
                  <Link to="/deals">
                    <span className="badge badge-pill badge-primary">
                      View All
                    </span>
                  </Link>
                </div>
                <div className="container">
                  {this.state.dealslist ? (
                    <div className="row">
                      <Carousel itemsToShow={3}>
                        {this.state.dealslist.map((item, index) => (
                          <div className="my-3 mx-2">
                            <div
                              className="card border-0 py-2 bg-ticket border-10p"
                              style={{ paddingInline: "2.5rem" }}
                            >
                              <div className="row">
                                <div
                                  className="col-5 pr-3 py-2"
                                  style={{ borderRight: "1px dashed #343a40" }}
                                >
                                  <img
                                    className="img-fluid rounded-10p"
                                    style={{
                                      height: "94px",
                                      width: "100%",
                                    }}
                                    src={imageUrls[index]}
                                    alt="offerpage"
                                  />
                                </div>
                                <div className="col-7 p-2 pl-3">
                                  <h4
                                    className="ml-3 mb-1"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    INTLFLIGHTS
                                  </h4>
                                  <h5 style={{ fontSize: "14px" }}>
                                    {item.name}
                                  </h5>
                                  <p
                                    className="mb-0"
                                    style={{ fontSize: "11px" }}
                                  >
                                    Pick from luxury pool villas, chic
                                    apartments, cosy homestays & cool hostels.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  ) : (
                    <p>Please Wait....</p>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="container mb-5">
              <div
                id="super_offers_two"
                className="carousel slide"
                data-ride=""
              >
                <div className="row px-3 my-1">
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center gap-1rem rounded-10p shadow-none border py-3 px-4">
                      <span
                        className="py-2 px-3 rounded-10p text-white fw-600 d-flex align-items-center"
                        style={{ background: "#21DBB1", width: "128px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#FFC207"
                          class="bi bi-star-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        &nbsp;555 Points
                      </span>
                      <div>
                        <p className="fw-600 mb-0" style={{ fontSize: "16px" }}>
                          Just Login to claim reward points
                        </p>
                        <p className="mb-0 text-secondary">
                          Claim before offer expires
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center gap-1rem rounded-10p shadow-none border py-3 px-4">
                      <span
                        className="py-2 px-3 rounded-10p text-white fw-600 d-flex align-items-center"
                        style={{ background: "#21DBB1", width: "128px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#FFC207"
                          class="bi bi-star-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        &nbsp;555 Points
                      </span>
                      <div>
                        <p className="fw-600 mb-0" style={{ fontSize: "16px" }}>
                          Just Login to claim reward points
                        </p>
                        <p className="mb-0 text-secondary">
                          Claim before offer expires
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row px-3 my-1">
                      <div className="col-sm-6">
                        <div className="super_offers_sec_two d-flex align-items-center">
                          <i
                            className="fas fa-money-check-alt fa-2x"
                            style={{ color: "red" }}
                          ></i>
                          <p>
                            Just login and claim wallet rewards up to 555. Login
                            now before the offer expires!
                          </p>
                          <i
                            className="fas fa-long-arrow-alt-right fa-2x"
                            style={{ color: "#078DFD" }}
                          ></i>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="super_offers_sec_two d-flex align-items-center">
                          <i
                            className="fas fa-money-check-alt fa-2x"
                            style={{ color: "red" }}
                          ></i>
                          <p>
                            Uncover wallet rewards up to 555 effortlessly!.
                            Don't let this opportunity pass you by – your
                            rewards are just a step away!
                          </p>
                          <i
                            className="fas fa-long-arrow-alt-right fa-2x"
                            style={{ color: "#078DFD" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 

                <Link
                  className="carousel-control-prev"
                  href="#super_offers_two"
                  data-slide="prev"
                >
                  <span className="fa-stack fa-sm">
                    <i
                      className="far fa-circle fa-stack-2x"
                      style={{ color: "#d2d3d5" }}
                    ></i>
                    <i
                      className="fas fa-angle-left fa-stack-1x"
                      style={{ color: "#008cff" }}
                    ></i>
                  </span>
                </Link>
                <Link
                  className="carousel-control-next"
                  href="#super_offers_two"
                  data-slide="next"
                >
                  <span className="fa-stack fa-sm">
                    <i
                      className="far fa-circle fa-stack-2x"
                      style={{ color: "#d2d3d5" }}
                    ></i>
                    <i
                      className="fas fa-angle-right fa-stack-1x"
                      style={{ color: "#008cff" }}
                    ></i>
                  </span>
                </Link>
              </div>
            </div> */}
            <div
              className="ogule_card_sec w-75 mx-auto p-5 rounded-4rem"
              style={{
                boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.2)",
                height: "60vh",
              }}
            >
              <div className="container p-4 position-relative">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-between gap-1rem">
                      <p
                        className="font-size-4 text-white mb-3 h1"
                        style={{
                          fontWeight: "700",
                          textShadow: "2px 2px 3px rgba(0,0,0,0.5)",
                        }}
                      >
                        TRAVEL With OGULE Credit Cards
                      </p>

                      <a
                        href="tel:+18883151615"
                        className="btn rounded-1rem bg-blue text-white btn-lg pulse"
                        role="button"
                      >
                        <div className="d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            class="bi bi-telephone-fill call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                            />
                          </svg>
                          <div>
                            <p className="fw-600 float-right mb-0 font-size-2 ">
                              +1 888-315-1615 (US)
                            </p>
                            <p className="mb-0 font-size-1 text-danger fw-600 blinking">
                              Last hour DEALS!
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-6 h-100 py-3 px-4 rounded-1rem"
                    style={{
                      background: "linear-gradient(135deg, #245cc8, #238cbe)",
                    }}
                  >
                    <p
                      className="fw-600 h4 text-white"
                      style={{ textShadow: "2px 2px 3px rgba(0,0,0,0.5)" }}
                    >
                      Travel 1000 miles and get a free Ogule Credits Card
                      signup! Whether it's for work or fun, you can enjoy this
                      special offer. With our card, you get great rewards while
                      you travel. Don't miss out – start your trip now and sign
                      up for free!
                    </p>
                  </div>
                </div>
                {/* <span className="position-absolute drop-shadow up-down-animation">
                  <img src={plane} />
                </span> */}
              </div>
            </div>

            <div className="container p-2 my-5">
              <div className="row align-items-center">
                <div className="col">
                  <h2 className="font-work-sans fs-6">Travel Blogs</h2>
                </div>
                <div
                  className="col text-right mt-1"
                  style={{ marginRight: "5rem" }}
                >
                  <Link to="/blogs">
                    <span className="blog-viewall">View all</span>
                  </Link>
                </div>
              </div>

              <div
                id="blogs"
                className="carousel slide container"
                data-ride="carousel"
              >
                {this.state.bloglist ? (
                  <div className="row">
                    <Carousel itemsToShow={3}>
                      {this.state.bloglist.map((item) => (
                        <div
                          id="blogs"
                          className="carousel slide container p-2"
                          data-ride="carousel"
                        >
                          <div class="cards-list">
                            <div
                              className="blogcard"
                              style={{
                                backgroundImage: `url(https://api.ogule.com/public/blog/${item.image})`,
                              }}
                            >
                              <div className="d-flex flex-column gap-1rem h-100 justify-content-end p-3">
                                <p className="mb-0 text-white h3 text-truncate">
                                  {item?.name.substring(0, 25)}
                                </p>
                                <div
                                  className="d-flex align-items-end justify-content-between"
                                  style={{ gap: "4px" }}
                                >
                                  <p className="text-white h6">
                                    {item.short_description.substring(0, 30) +
                                      "..."}
                                  </p>
                                  <button
                                    type="button"
                                    className="btn btn-sm"
                                    style={{
                                      width: "78px",
                                      height: "32px",
                                      background: "#1502a9",
                                      borderRadius: "40px",
                                      color: "#fff",
                                    }}
                                    onClick={() => {
                                      this.Singleblog(item?.slug);
                                    }}
                                  >
                                    Read
                                  </button>
                                </div>
                              </div>
                              <div className="blogcard-bottom"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <p>Please Wait....</p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ paddingBottom: "10rem" }}>
            <div className="bg-blue p-3 d-flex gap-1rem">
              <img
                // className="bg-light"
                style={{
                  filter: "drop-shadow(0 0 0.1px #fff)",
                  width: "122px",
                }}
                src={logo}
              />
              <Dropdown onSelect={this.handleCurrencyChange}>
                <Dropdown.Toggle
                  className="nav-link btn-light bg-white fw-600 border-0 d-flex align-items-center"
                  id="currency-dropdown"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-coin"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                  </svg>
                  &nbsp;{this.state.selectedCurrency}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="USD">USD</Dropdown.Item>
                  <Dropdown.Item eventKey="EURO">EURO</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="home_bg_screen container mb-2 p-3">
              <div className="d-flex flex-column gap-1rem justify-content-between h-100">
                <div className="d-flex justify-content-end">
                  <a
                    href="tel:+18883151615"
                    className="btn rounded-1rem text-white btn-lg pulse"
                    role="button"
                    style={{
                      background:
                        "linear-gradient(45deg, #FF6200, #ffa500, #FF6200)",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="currentColor"
                        class="bi bi-telephone-fill call_vibration"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                        />
                      </svg>
                      <div className="">
                        <p className="fw-600 float-right mb-0 font-size-2">
                          +1 888-315-1615 (US)
                        </p>
                        <p className="mb-0 font-size-1">
                          Call now for exciting offers!
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                <div>
                  <span
                    className="text-white"
                    style={{
                      fontWeight: "800",
                      fontSize: "1.8rem",
                      borderBottom: "4px solid #dee2e6",
                    }}
                  >
                    TRAVEL the WORLD
                  </span>
                  <div className="d-flex gap-1rem mt-2">
                    <div className="d-flex align-items-center gap-12p">
                      <img
                        src={flight}
                        style={{
                          height: "2rem",
                        }}
                      />
                      <p
                        className="text-white fw-600 mb-0"
                        style={{ fontSize: "12px" }}
                      >
                        SECURE
                        <br />
                        TRAVEL
                      </p>
                    </div>
                    <div className="d-flex align-items-center gap-12p">
                      <img src={callCenter} style={{ height: "2rem" }} />
                      <p
                        className="text-white fw-600 mb-0"
                        style={{ fontSize: "12px" }}
                      >
                        24X7 CUSTOMER
                        <br />
                        SUPPORT
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <Header />
              <Modal
                size="sm"
                centered
                show={this.state.setShow}
                onHide={this.handleClose}
                className="mobile_traveler_popup"
              >
                <Modal.Header closeButton>
                  <ButtonToolbar>
                    {this.state.t_class.map((item, index) => (
                      <div key={index}>
                        {this.state.t_class_selected === item.name ? (
                          <Button
                            label={item.name}
                            className="p-button-raised p-button-rounded mr-1 mb-2"
                          />
                        ) : (
                          <Button
                            label={item.name}
                            className="p-button-raised p-button-rounded p-button-secondary mr-1 mb-2"
                            onClick={() =>
                              this.setState({ t_class_selected: item.name })
                            }
                          />
                        )}
                      </div>
                    ))}
                  </ButtonToolbar>
                </Modal.Header>
                <Modal.Body>
                  <div class="d-flex align-items-center justify-content-between  mb-3">
                    <div class="p-2">Adult</div>
                    <div class="p-2">
                      <Button
                        label="-"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ adult: this.state.adult - 1 })
                        }
                        disabled={this.state.adult === "0" ? "true" : ""}
                      />
                      <input
                        type="text"
                        value={this.state.adult}
                        maxlength="2"
                        className="idval"
                      />
                      <Button
                        label="+"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ adult: this.state.adult + 1 })
                        }
                      />
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between  mb-3">
                    <div class="p-2">Child</div>
                    <div class="p-2">
                      <Button
                        label="-"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ child: this.state.child - 1 })
                        }
                        disabled={this.state.adult === "0" ? "true" : ""}
                      />
                      <input
                        type="text"
                        value={this.state.child}
                        maxlength="2"
                        className="idval"
                      />
                      <Button
                        label="+"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ child: this.state.child + 1 })
                        }
                      />
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between  mb-3">
                    <div class="p-2">Infants</div>
                    <div class="p-2">
                      <Button
                        label="-"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ infants: this.state.infants - 1 })
                        }
                        disabled={this.state.adult === "0" ? "true" : ""}
                      />
                      <input
                        type="text"
                        value={this.state.infants}
                        maxlength="2"
                        className="idval"
                      />
                      <Button
                        label="+"
                        className="p-button-raised"
                        onClick={() =>
                          this.setState({ infants: this.state.infants + 1 })
                        }
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    label="Submit"
                    className="p-button-raised p-button-rounded mr-2"
                    onClick={() => this.setState({ setShow: false })}
                  />
                </Modal.Footer>
              </Modal>
            </div>

            <div
              className="container px-0 mx-0 d-flex flex-column gap-1rem justify-content-between"
              // style={{ marginBottom: "6rem" }}
            >
              <div className="container bg-white p-3">
                <a
                  href="tel:+18883151615"
                  className="btn rounded-1rem card pulse w-100 mb-3"
                  role="button"
                >
                  <div className="d-flex align-items-center gap-12p w-100 ">
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src="https://tse3.explicit.bing.net/th?id=OIP.Aqn3nZQJW8VFj_uvJkWANgHaFz&pid=Api&P=0&h=180"
                      alt=""
                    />
                    <div className="w-100">
                      <div className="d-flex justify-content-between w-100">
                        <p className="mb-0 text-left">
                          Get upto&nbsp;
                          <span className="text-danger fw-600">30% off</span>
                          &nbsp;on call
                        </p>

                        <p className="mb-0 d-flex align-items-center justify-content-end">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            class="bi bi-telephone call_vibration"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                          </svg>
                          &nbsp;Call Us Now
                        </p>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <p className="text-danger fw-600 mb-0">
                          Last hour DEALS!
                        </p>
                        <p className="fw-600 mb-0 font-size-1 blinking">
                          +1 888-315-1615 (US)
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                <div className="d-flex gap-1rem mb-3">
                  <div className="d-flex gap-12p">
                    <RadioButton
                      value="round_trip"
                      name="Round Trip"
                      onChange={(e) => {
                        this.trip(e.value);
                      }}
                      checked={this.state.trip === "round_trip"}
                    />
                    <b>Round Trip</b>
                  </div>
                  <div className="d-flex gap-12p">
                    <RadioButton
                      value="one_way"
                      name="One Way"
                      onChange={(e) => {
                        this.trip(e.value);
                      }}
                      checked={this.state.trip === "one_way"}
                      className="ml-3"
                    />
                    <b>One Way</b>
                  </div>
                </div>

                <form className="d-flex flex-column justify-content-between">
                  <div className="row">
                    <div className="col-12">
                      <div className="row mb-3 mx-1 py-2 border rounded-10p">
                        <div
                          className="col-5 from_sec"
                          onClick={() => {
                            this.Fromflights();
                          }}
                        >
                          {/* <img src={icon7} className="img-fluid" alt="icon7" /> */}
                          <h4 className="h3 text-dark mb-2">
                            {this.state.iatacode_from}
                          </h4>
                          <span
                            className="text-primary"
                            style={{ fontSize: "14px" }}
                          >
                            {this.state.airport_name_from}{" "}
                            {this.state.country_from}
                          </span>
                        </div>
                        <div
                          className="col-2 from_and_to_exchange align-self-center p-1 d-flex align-items-center justify-content-center"
                          onClick={() => {
                            this.from_and_to_exchange();
                          }}
                        >
                          {/* <img
                      src={icon9}
                      className="img-fluid shadow rounded-circle mx-auto d-block"
                      alt="icon5"
                    /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            fill="currentColor"
                            class="bi bi-arrow-left-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                        </div>
                        <div
                          className="col-5 to_sec text-right"
                          onClick={() => {
                            this.Toflights();
                          }}
                        >
                          {/* <img src={icon8} className="img-fluid" alt="icon8" /> */}
                          <h4 className="h3 text-dark mb-2">
                            {this.state.iatacode_to}
                          </h4>
                          <span
                            className="text-primary"
                            style={{ fontSize: "14px" }}
                          >
                            {this.state.airport_name_to} {this.state.country_to}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      {this.state.todata ? (
                        <div>
                          <datalist id="todata">
                            {this.state.todata.map((item) => (
                              <option value={item.iataCode}>
                                {item.name},{item.address.cityName}{" "}
                                {item.address.countryName}
                              </option>
                            ))}
                          </datalist>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.trip === "round_trip" ? (
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group px-2">
                              <label
                                htmlFor="departure"
                                className="fw-600 w-100"
                              >
                                Departure
                              </label>
                              <DatePicker
                                selected={new Date(this.state.departuredate)}
                                onChange={this.handledeparturedate}
                                minDate={new Date()}
                                dateFormat="d MMM, yy"
                              />
                              <p className="text-secondary">Thursday</p>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group px-2">
                              <label htmlFor="return" className="fw-600 w-100">
                                Return
                              </label>
                              <DatePicker
                                selected={new Date(this.state.returndate)}
                                onChange={this.handlereturndate}
                                minDate={this.state.departuredate}
                                dateFormat="d MMM, yy"
                                ref={this.departureDatePickerRef}
                              />
                              <p className="text-secondary">Sunday</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12">
                        <div className="form-group px-2">
                          <label htmlFor="departure" className="fw-600">
                            Departure
                          </label>
                          <DatePicker
                            selected={new Date(this.state.departuredate)}
                            onChange={this.handledeparturedate}
                            dateFormat="d MMM, yy"
                          />
                          <p className="text-secondary">Thursday</p>
                        </div>
                      </div>
                    )}
                    <div className="col-12">
                      <div className="form-group traveller_class">
                        <label htmlFor="travellers_count" className="fw-600">
                          Travellers & Class
                        </label>
                        <Link
                          className="row"
                          onClick={() => this.setState({ setShow: true })}
                        >
                          <div className="col-6">
                            <p className="text-primary font-size-2">
                              {this.state.adult +
                                this.state.child +
                                this.state.infants}
                              &nbsp; Traveller
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="text-primary font-size-2">
                              {this.state.t_class_selected}
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg d-flex align-items-center justify-content-center w-100 rounded-10p"
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                      this.searchflights();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                    &nbsp;Search
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Home;
