import React, { useEffect, useState } from "react";
import Header from "./Header";
import from_to from "../image/icon/from_to.jpg";
import moment from "moment";

const SuccessfullyBooked = () => {
  const [isMobile, setIsMobile] = useState(false);

  const bookingDetails = JSON.parse(sessionStorage.getItem("booking_details"));
  const billInfo = JSON.parse(sessionStorage.getItem("billInfos"));

  function convertTimeFormat(timeString) {
    if (timeString) {
      const hours = timeString?.substring(0, 2);
      const minutes = timeString?.substring(2);
      return `${hours}:${minutes}`;
    }
    return 0;
  }

  function calculateTotalDuration(flightDetails) {
    if (!Array.isArray(flightDetails) || flightDetails.length < 1) {
      return null;
    }
    const firstFlight = flightDetails?.[0].flightInformation?.[0];
    const lastFlight =
      flightDetails[flightDetails?.length - 1].flightInformation?.[0];

    // Format dates and times
    const departureDateTime = moment(
      `${firstFlight?.productDateTime?.[0].dateOfDeparture?.[0]} ${firstFlight.productDateTime?.[0].timeOfDeparture?.[0]}`,
      "DDMMYY HHmm"
    );
    const arrivalDateTime = moment(
      `${lastFlight?.productDateTime?.[0].dateOfArrival?.[0]} ${lastFlight.productDateTime?.[0].timeOfArrival?.[0]}`,
      "DDMMYY HHmm"
    );
    const durationMinutes = arrivalDateTime.diff(departureDateTime, "minutes");

    const durationHours = Math.floor(durationMinutes / 60);
    const durationRemainingMinutes = durationMinutes % 60;

    const formattedDuration = `${durationHours}h ${durationRemainingMinutes}m`;

    return formattedDuration;
  }

  const handleResize = (e) => {
    setIsMobile(e.matches);
  };

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    setIsMobile(mobileMediaQuery.matches);
    mobileMediaQuery.addEventListener("change", handleResize);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  return (
    <div>
      {!isMobile ? (
        <div>
          <Header />
          <div className="container my-4">
            <div className="row">
              <div className="col-12">
                <h4 className="text-white py-2 px-4 text-center w-100 mb-3 vertical-linear-gradient-primary">
                  Congratulations!
                </h4>

                <div className="d-flex flex-column align-items-center">
                  <span
                    className="bg-success text-white rounded-circle mb-3"
                    style={{
                      height: "80px",
                      width: "80px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      fill="currentColor"
                      class="bi bi-check-lg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                    </svg>
                  </span>
                  <h4 className="text-primary mb-0">
                    Your booking is in process will send you E-ticket shortly
                  </h4>
                  <strong className="mb-3">
                    Our team will reach you soon.
                  </strong>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center justify-content-center gap-1rem">
                  <span
                    className="bg-light text-secondary rounded-circle"
                    style={{
                      height: "50px",
                      width: "50px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      class="bi bi-send-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                    </svg>
                  </span>
                  <div>
                    <p className="mb-0">Booking reference</p>
                    <b className="mb-0 text-success">{billInfo?.pnr_no}</b>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="d-flex align-items-center justify-content-center gap-1rem">
                  <span
                    className="bg-light text-secondary rounded-circle"
                    style={{
                      height: "50px",
                      width: "50px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      class="bi bi-envelope-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                    </svg>
                  </span>
                  <div>
                    <p className="mb-0">Confirmation sent to</p>
                    <b className="mb-0 text-info">{billInfo?.billing_email}</b>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="d-flex align-items-center justify-content-center gap-1rem m-auto">
                  <span
                    className="bg-light text-secondary rounded-circle"
                    style={{
                      height: "50px",
                      width: "50px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      class="bi bi-bank2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916zM12.375 6v7h-1.25V6zm-2.5 0v7h-1.25V6zm-2.5 0v7h-1.25V6zm-2.5 0v7h-1.25V6zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2M.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1z" />
                    </svg>
                  </span>
                  <div>
                    <p className="mb-0">Awating payment</p>
                    <b className="mb-0">$ {bookingDetails?.price}</b>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="card shadow px-5 py-2 mb-3">
                  <div className="d-flex align-items-center justify-content-between gap-1rem">
                    <div className="d-flex flex-column gap-1">
                      <img
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "contain",
                          objectPosition: "center",
                        }}
                        src={
                          bookingDetails?.flights?.[0]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.companyId?.[0]
                            ?.customCarriers?.image
                        }
                        alt="logo"
                      />
                      {sessionStorage.getItem("wayOfFlight") ===
                        "round_trip" && (
                        <img
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "contain",
                            objectPosition: "center",
                          }}
                          src={
                            bookingDetails?.flights?.[1]?.flightDetails?.[0]
                              ?.flightInformation?.[0]?.companyId?.[0]
                              ?.customCarriers?.image
                          }
                          alt="logo"
                        />
                      )}
                    </div>

                    <div className="d-flex flex-column gap-1">
                      <div className="d-flex gap-12p align-items-center mb-0 border-dashed px-4 py-1 rounded border-secondary">
                        <div className="d-flex flex-column align-items-end">
                          <b>
                            {
                              bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.location?.[0]
                                ?.locationId
                            }
                          </b>
                          <p className="mb-0">
                            {moment(
                              bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.productDateTime?.[0]
                                ?.dateOfDeparture?.[0],
                              "DDMMYY"
                            ).format("DD/MM/YYYY")}
                          </p>
                          <p className="mb-0">
                            {convertTimeFormat(
                              bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.productDateTime?.[0]
                                ?.timeOfDeparture?.[0]
                            )}
                          </p>
                          {/* <p className="mb-0">
                              Terminal :{" "}
                              jn
                            </p> */}
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <span>
                            {calculateTotalDuration(
                              bookingDetails?.flights?.[0]?.flightDetails
                            )}
                          </span>
                          <img
                            style={{ minWidth: "80", maxWidth: "220px" }}
                            src={from_to}
                            alt=""
                          />
                          <p className="mb-0 text-secondary fs-12p">
                            {bookingDetails?.flights?.[0]?.flightDetails
                              ?.length === 1 ? (
                              "Direct"
                            ) : (
                              <span className="pointer">
                                {bookingDetails?.flights?.[0]?.flightDetails
                                  ?.length - 1}
                                &nbsp;Stop
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="d-flex flex-column align-items-start">
                          <b>
                            {
                              bookingDetails?.flights?.[0]?.flightDetails[
                                bookingDetails?.flights?.[0]?.flightDetails
                                  ?.length - 1
                              ]?.flightInformation?.[0]?.location?.[1]
                                ?.locationId
                            }
                          </b>
                          <p className="mb-0">
                            {moment(
                              bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.productDateTime?.[0]
                                ?.dateOfArrival?.[0],
                              "DDMMYY"
                            ).format("DD/MM/YYYY")}
                          </p>
                          <p className="mb-0">
                            {convertTimeFormat(
                              bookingDetails?.flights?.[0]?.flightDetails[
                                bookingDetails?.flights?.[0]?.flightDetails
                                  ?.length - 1
                              ]?.flightInformation?.[0]?.productDateTime?.[0]
                                ?.timeOfArrival?.[0]
                            )}
                          </p>
                        </div>
                      </div>

                      {sessionStorage.getItem("wayOfFlight") ===
                        "round_trip" && (
                        <div className="d-flex gap-12p align-items-center mb-0 border-dashed px-4 py-1 rounded border-secondary">
                          <div className="d-flex flex-column align-items-end">
                            <b>
                              {
                                bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.location?.[0]
                                  ?.locationId
                              }
                            </b>
                            <p className="mb-0">
                              {moment(
                                bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.dateOfDeparture?.[0],
                                "DDMMYY"
                              ).format("DD/MM/YYYY")}
                            </p>
                            <p className="mb-0">
                              {convertTimeFormat(
                                bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.timeOfDeparture?.[0]
                              )}
                            </p>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <span>
                              {calculateTotalDuration(
                                bookingDetails?.flights?.[1]?.flightDetails
                              )}
                            </span>
                            <img
                              style={{ minWidth: "80", maxWidth: "220px" }}
                              src={from_to}
                              alt=""
                            />
                            <p className="mb-0 text-secondary fs-12p">
                              {bookingDetails?.flights?.[1]?.flightDetails
                                ?.length === 1 ? (
                                "Direct"
                              ) : (
                                <span className="pointer">
                                  {bookingDetails?.flights?.[1]?.flightDetails
                                    ?.length - 1}
                                  &nbsp;Stop
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            <b>
                              {
                                bookingDetails?.flights?.[1]?.flightDetails[
                                  bookingDetails?.flights?.[1]?.flightDetails
                                    ?.length - 1
                                ]?.flightInformation?.[0]?.location?.[1]
                                  ?.locationId
                              }
                            </b>
                            <p className="mb-0">
                              {moment(
                                bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.dateOfArrival?.[0],
                                "DDMMYY"
                              ).format("DD/MM/YYYY")}
                            </p>
                            <p className="mb-0">
                              {convertTimeFormat(
                                bookingDetails?.flights?.[1]?.flightDetails[
                                  bookingDetails?.flights?.[1]?.flightDetails
                                    ?.length - 1
                                ]?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.timeOfArrival?.[0]
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="d-flex flex-column">
                      <span className="d-flex align-items-center mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffa500"
                          class="bi bi-check-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                        </svg>
                        &nbsp;<b>Class</b>&nbsp;:&nbsp;
                        {sessionStorage.getItem("travelClass")}
                      </span>

                      <span className="d-flex align-items-center mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffa500"
                          class="bi bi-airplane-engines-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0" />
                        </svg>
                        &nbsp;<b>Trip type</b>&nbsp;:&nbsp;
                        {sessionStorage.getItem("wayOfFlight") === "one_way"
                          ? "One Way"
                          : "Round Trip"}
                      </span>

                      <span className="d-flex align-items-center mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffa500"
                          class="bi bi-check-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                        </svg>
                        &nbsp;<b>Check-in</b>&nbsp;:&nbsp;Adult:{" "}
                        {sessionStorage.getItem("adults")}, Child:{" "}
                        {sessionStorage.getItem("children")}, Infant:{" "}
                        {sessionStorage.getItem("infants")}
                      </span>
                    </div>
                  </div>
                </div>

                <p
                  className="py-1 w-100 text-center"
                  style={{
                    fontWeight: "600",
                    color: "#ffa500",
                    fontSize: "1rem",
                  }}
                >
                  Some information about baggage or some other important things.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="w-100 bg-white position-relative"
          style={{ minHeight: "100vh", paddingBottom: "4.2rem" }}
        >
          <div>
            <Header />
            <div className="container my-4">
              <div className="row justify-content-center">
                <div className="col-12">
                  <h4 className="text-white py-2 px-4 text-center w-100 mb-3 vertical-linear-gradient-primary">
                    Congratulations!
                  </h4>

                  <div className="d-flex flex-column align-items-center">
                    <span
                      className="bg-success text-white rounded-circle mb-3"
                      style={{
                        height: "80px",
                        width: "80px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="64"
                        height="64"
                        fill="currentColor"
                        class="bi bi-check-lg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                      </svg>
                    </span>
                    <h4 className="text-primary text-center mb-0">
                      Your booking is in process will send you E-ticket shortly
                    </h4>
                    <strong className="mb-3">
                      Our team will reach you soon.
                    </strong>
                  </div>
                </div>
                <div className="m-auto d-flex flex-column gap-3">
                  <div className="d-flex align-items-center gap-1rem">
                    <span
                      className="bg-light text-secondary rounded-circle"
                      style={{
                        height: "50px",
                        width: "50px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-send-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                      </svg>
                    </span>
                    <div>
                      <p className="mb-0">Booking reference</p>
                      <b className="mb-0 text-success">{billInfo?.pnr_no}</b>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-1rem">
                    <span
                      className="bg-light text-secondary rounded-circle"
                      style={{
                        height: "50px",
                        width: "50px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-envelope-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                      </svg>
                    </span>
                    <div>
                      <p className="mb-0">Confirmation sent to</p>
                      <b className="mb-0 text-info">
                        {billInfo?.billing_email}
                      </b>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-1rem">
                    <span
                      className="bg-light text-secondary rounded-circle"
                      style={{
                        height: "50px",
                        width: "50px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-bank2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916zM12.375 6v7h-1.25V6zm-2.5 0v7h-1.25V6zm-2.5 0v7h-1.25V6zm-2.5 0v7h-1.25V6zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2M.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1z" />
                      </svg>
                    </span>
                    <div>
                      <p className="mb-0">Awating payment</p>
                      <b className="mb-0">$ {bookingDetails?.price}</b>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <div className="card shadow px-3 py-1 mb-3">
                    <div className="d-flex flex-column align-items-center justify-content-between gap-1rem">
                      <div className="d-flex align-items-center gap-2 justify-content-evenly w-100">
                        <img
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "contain",
                            objectPosition: "center",
                          }}
                          src={
                            bookingDetails?.flights?.[0]?.flightDetails?.[0]
                              ?.flightInformation?.[0]?.companyId?.[0]
                              ?.customCarriers?.image
                          }
                          alt="logo"
                        />
                        {sessionStorage.getItem("wayOfFlight") ===
                          "round_trip" && (
                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                            src={
                              bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.companyId?.[0]
                                ?.customCarriers?.image
                            }
                            alt="logo"
                          />
                        )}
                      </div>

                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex gap-12p align-items-center mb-0 border-dashed px-4 py-1 rounded border-secondary">
                          <div className="d-flex flex-column align-items-end">
                            <b>
                              {
                                bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.location?.[0]
                                  ?.locationId
                              }
                            </b>
                            <p className="mb-0">
                              {moment(
                                bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.dateOfDeparture?.[0],
                                "DDMMYY"
                              ).format("DD/MM/YYYY")}
                            </p>
                            <p className="mb-0">
                              {convertTimeFormat(
                                bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.timeOfDeparture?.[0]
                              )}
                            </p>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <span>
                              {calculateTotalDuration(
                                bookingDetails?.flights?.[0]?.flightDetails
                              )}
                            </span>
                            <img
                              style={{ width: "100%" }}
                              src={from_to}
                              alt=""
                            />
                            <p className="mb-0 text-secondary fs-12p">
                              {bookingDetails?.flights?.[0]?.flightDetails
                                ?.length === 1 ? (
                                "Direct"
                              ) : (
                                <span className="pointer">
                                  {bookingDetails?.flights?.[0]?.flightDetails
                                    ?.length - 1}
                                  &nbsp;Stop
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            <b>
                              {
                                bookingDetails?.flights?.[0]?.flightDetails[
                                  bookingDetails?.flights?.[0]?.flightDetails
                                    ?.length - 1
                                ]?.flightInformation?.[0]?.location?.[1]
                                  ?.locationId
                              }
                            </b>
                            <p className="mb-0">
                              {moment(
                                bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.dateOfArrival?.[0],
                                "DDMMYY"
                              ).format("DD/MM/YYYY")}
                            </p>
                            <p className="mb-0">
                              {convertTimeFormat(
                                bookingDetails?.flights?.[0]?.flightDetails[
                                  bookingDetails?.flights?.[0]?.flightDetails
                                    ?.length - 1
                                ]?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.timeOfArrival?.[0]
                              )}
                            </p>
                          </div>
                        </div>

                        {sessionStorage.getItem("wayOfFlight") ===
                          "round_trip" && (
                          <div className="d-flex gap-12p align-items-center mb-0 border-dashed px-4 py-1 rounded border-secondary">
                            <div className="d-flex flex-column align-items-end">
                              <b>
                                {
                                  bookingDetails?.flights?.[1]
                                    ?.flightDetails?.[0]?.flightInformation?.[0]
                                    ?.location?.[0]?.locationId
                                }
                              </b>
                              <p className="mb-0">
                                {moment(
                                  bookingDetails?.flights?.[1]
                                    ?.flightDetails?.[0]?.flightInformation?.[0]
                                    ?.productDateTime?.[0]
                                    ?.dateOfDeparture?.[0],
                                  "DDMMYY"
                                ).format("DD/MM/YYYY")}
                              </p>
                              <p className="mb-0">
                                {convertTimeFormat(
                                  bookingDetails?.flights?.[1]
                                    ?.flightDetails?.[0]?.flightInformation?.[0]
                                    ?.productDateTime?.[0]?.timeOfDeparture?.[0]
                                )}
                              </p>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <span>
                                {calculateTotalDuration(
                                  bookingDetails?.flights?.[1]?.flightDetails
                                )}
                              </span>
                              <img
                                style={{ width: "100%" }}
                                src={from_to}
                                alt=""
                              />
                              <p className="mb-0 text-secondary fs-12p">
                                {bookingDetails?.flights?.[1]?.flightDetails
                                  ?.length === 1 ? (
                                  "Direct"
                                ) : (
                                  <span className="pointer">
                                    {bookingDetails?.flights?.[1]?.flightDetails
                                      ?.length - 1}
                                    &nbsp;Stop
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="d-flex flex-column align-items-start">
                              <b>
                                {
                                  bookingDetails?.flights?.[1]?.flightDetails[
                                    bookingDetails?.flights?.[1]?.flightDetails
                                      ?.length - 1
                                  ]?.flightInformation?.[0]?.location?.[1]
                                    ?.locationId
                                }
                              </b>
                              <p className="mb-0">
                                {moment(
                                  bookingDetails?.flights?.[1]
                                    ?.flightDetails?.[0]?.flightInformation?.[0]
                                    ?.productDateTime?.[0]?.dateOfArrival?.[0],
                                  "DDMMYY"
                                ).format("DD/MM/YYYY")}
                              </p>
                              <p className="mb-0">
                                {convertTimeFormat(
                                  bookingDetails?.flights?.[1]?.flightDetails[
                                    bookingDetails?.flights?.[1]?.flightDetails
                                      ?.length - 1
                                  ]?.flightInformation?.[0]
                                    ?.productDateTime?.[0]?.timeOfArrival?.[0]
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="d-flex flex-column">
                        <span className="d-flex align-items-start mb-3">
                          <b
                            className="d-flex align-items-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#ffa500"
                              class="bi bi-check-square-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                            </svg>
                            &nbsp;Class
                          </b>
                          &nbsp;:&nbsp;{sessionStorage.getItem("travelClass")}
                        </span>

                        <span className="d-flex align-items-start mb-3">
                          <b
                            className="d-flex align-items-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#ffa500"
                              class="bi bi-airplane-engines-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0" />
                            </svg>
                            &nbsp;Trip type
                          </b>
                          &nbsp;:&nbsp;
                          {sessionStorage.getItem("wayOfFlight") === "one_way"
                            ? "One Way"
                            : "Round Trip"}
                        </span>

                        <span className="d-flex align-items-start mb-3">
                          <b
                            className="d-flex align-items-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#ffa500"
                              class="bi bi-check-square-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                            </svg>
                            &nbsp;Check-in
                          </b>
                          &nbsp;:&nbsp;Adult:&nbsp;
                          {sessionStorage.getItem("adults")}, Child:{" "}
                          {sessionStorage.getItem("children")}, Infant:{" "}
                          {sessionStorage.getItem("infants")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <p
                    className="py-1 w-100 text-center"
                    style={{
                      fontWeight: "600",
                      color: "#ffa500",
                      fontSize: "1rem",
                    }}
                  >
                    Some information about baggage or some other important
                    things.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessfullyBooked;
