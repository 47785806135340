import React, {Component} from 'react'
import InfiniteCalendar , {
  Calendar,
  withRange,
} from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import {format } from 'date-fns'

class RoundTripDate extends Component{
	constructor(props) {
	  super(props);
	
	  this.state = {};
	}
	back(){
		console.warn(this.props.history.push(sessionStorage.getItem('search_from')))
	}
	onCalendarSelect = (e) => {
        if (e.eventType === 3) {
        	sessionStorage.setItem('departureDate', format(e.start, 'yyyy-MM-dd'))
			sessionStorage.setItem('mobile_departureDate_oneway', format(e.start, 'MMM dd'))
			sessionStorage.setItem('mobile_departureDate_oneway_day', format(e.start, 'iii'))
			sessionStorage.setItem('returnDate', format(e.end, 'yyyy-MM-dd'))
			sessionStorage.setItem('mobile_returnDate_oneway', format(e.end, 'MMM dd'))
			sessionStorage.setItem('mobile_returnDate_oneway_day', format(e.end, 'iii'))
            this.setState({ selectedDates: {
                start: e.start,
                end: e.end,
            } });
        }
    }
	render(){
		return(
			<>
			<InfiniteCalendar
			Component={withRange(Calendar)}  
			  	selected={this.state.selectedDates}
    			onSelect={this.onCalendarSelect}
			  	minDate={new Date()-1}
			/>
			<div className="footer_btn" onClick={()=> {this.back()}}>
				<p>Submit</p>
			</div>
			 </>
		)
	}

}
export default RoundTripDate;