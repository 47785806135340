import React, {Component} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
class Fromflights extends Component{
	constructor(props) {
	  super(props);
	
	  this.state = {};
	  fetch('https://test.api.amadeus.com/v1/security/oauth2/token', {
          method: 'POST',
            headers: new Headers({
		      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
		    }),
			body: "grant_type=client_credentials&client_id=lFD9B6KFg1j3kMKGLzFgadbAKFEzOwVw&client_secret=XmvZgKrjBJAiAMfE" // <-- Post parameters
			})
        .then((response) => response.json())
        .then((res) => {
      if(res.access_token){
      	this.setState({apitoken: res.access_token});
      	//Linklert(this.state.apitoken);
      }
      else{
        alert(JSON.stringify(res));
        }
     }).catch((error) => {
         console.error(error);
        });
	}
	searchfrom(val){
		//console.warn(val)
		this.setState({iatacode_from: val})
		fetch('https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=10&keyword='+val, {
        method: 'GET',
        headers: new Headers({
	      'Authorization': 'Bearer '+this.state.apitoken, 
	    })
		})
        .then((response) => response.json())
        .then((res) => {
      if(res){
      	var data = res.data;
      	for(let i=0; i < data.length; i++){      		
      		if(val===res.data[i].iataCode){      		
      			this.setState({
      				airport_name_from:res.data[i].name,
      				country_from:res.data[i].address.countryCode
      			})
      		}
      		else{      			
      		}
      	}
      	console.warn(res)
      	this.setState({
      		fromdata:res.data
      	})
      }
      else{
        alert(JSON.stringify(res));
        }
     }).catch((error) => {
         console.error(error);
        });
	}
	home(){
		console.warn(this.props.history.push(sessionStorage.getItem('search_from')))
	}
	setfrom(item){
		sessionStorage.setItem('iatacode_from',item.iataCode)
		sessionStorage.setItem('airport_name_from',item.name)
		sessionStorage.setItem('country_from',item.address.countryName)
		console.warn(this.props.history.push(sessionStorage.getItem('search_from')))
	}
	render(){
		return(
			<div>
				<div className="input-group fromflights">
			      <div className="input-group-prepend" onClick={() => {this.home()}}>
			        <span className="input-group-text"><FontAwesomeIcon icon={faArrowLeft} /></span>
			      </div>
			      <input type="text" className="form-control"
			      	autoComplete="off"
				    value={this.state.iatacode_from} 
				    onChange={(e) => this.searchfrom(e.target.value)}
			       />
			    </div>
		       
	            <div className="listdata">
	            	{
	            		this.state.fromdata?
	            		<div>
			            	{
			            		this.state.fromdata.map((item)=>
					            	<div className="iatadata" onClick={() => {this.setfrom(item)}}>
					            		<div class="d-flex justify-content-between">
						            		<div>
							            		<h4>{item.name}</h4>
							            		<h5>{item.name},{item.address.cityName} {item.address.countryName}</h5>
						            		</div>
						            		<div className="align-self-center">
						            			<h5><b>{item.iataCode}</b></h5>
						            		</div>
					            		</div>
					            	</div>
					            )
			            	}
			            </div>
	            	:""
	            	}
	            </div>
			</div>
		)
	}

}
export default Fromflights;