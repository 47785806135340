import React, { useEffect, useState } from "react";
import Header from "../Header";
import Tooltip from "react-tooltip-lite";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import { calculateTotalDuration } from "../GlobalFunction";

const CheckoutNew = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const currentYear = new Date().getFullYear();
  const [loader, setloader] = useState(false);
  const history = useHistory();
  const [firststep, setFirstStep] = useState(true);
  const [secondStep, setSecondStep] = useState(false);
  const [lastStep, setLastStep] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [travellerPricing, setTravellerPricing] = useState([]);

  const originLocationCode = sessionStorage.getItem("originLocationCode");
  const destinationLocationCode = sessionStorage.getItem(
    "destinationLocationCode"
  );
  const adults = sessionStorage.getItem("adults");
  const departureDate = sessionStorage.getItem("departureDate");
  const returnDate = sessionStorage.getItem("returnDate");
  const children = sessionStorage.getItem("children");
  const infants = sessionStorage.getItem("infants");
  const travelClass = sessionStorage.getItem("travelClass");
  const currencyCode = sessionStorage.getItem("currencyCode");
  const wayOfFlight = sessionStorage.getItem("wayOfFlight");
  const bookingDetails = JSON.parse(sessionStorage.getItem("booking_details"));
  const login_data = JSON.parse(sessionStorage.getItem("login"));
  const [travellerDetails, settravellerDetails] = useState([]);
  const [isRefundable, setIsRefundable] = useState("false");
  const [isAddPack, setIsAddPack] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const totalPassengers =
    parseInt(sessionStorage.getItem("adults")) +
    parseInt(sessionStorage.getItem("children")) +
    parseInt(sessionStorage.getItem("infants"));

  useEffect(() => {
    if (bookingDetails && bookingDetails.price) {
      let newPrice = parseFloat(bookingDetails.price);
      if (isRefundable === "true") {
        const additionalCharge = bookingDetails?.price / totalPassengers / 6;
        newPrice += parseFloat((totalPassengers * additionalCharge).toFixed(2));
      } else {
        setTotalPrice(newPrice);
      }
      if (isAddPack) {
        newPrice += parseFloat(19.89);
      } else {
        setTotalPrice(newPrice?.toFixed(2));
      }
      setTotalPrice(newPrice?.toFixed(2));
    }
  }, [isRefundable, bookingDetails, totalPassengers, isAddPack]);

  const travellerData = sessionStorage.getItem("travellerData")
    ? JSON.parse(sessionStorage.getItem("travellerData"))
    : travellerDetails;
  const isAllEmpty =
    travellerDetails?.length === 1 &&
    travellerDetails?.[0] &&
    Object.values(travellerDetails?.[0]).every((value) => value === "");
  // const isDisabled = travellerDetails?.some((details) =>
  //   Object.values(details)?.some((value) => value === "")
  // );
  const isDisabled = travellerDetails?.some((details) =>
    Object.entries(details).some(
      ([key, value]) => key !== "middleName" && value === ""
    )
  );

  const adultsCount = parseInt(sessionStorage.getItem("adults"), 10) || 0;
  const childrenCount = parseInt(sessionStorage.getItem("children"), 10) || 0;
  const infantsCount = parseInt(sessionStorage.getItem("infants"), 10) || 0;

  const passengerLabels = [
    ...Array(adultsCount).fill("Adult"),
    ...Array(childrenCount).fill("Child"),
    ...Array(infantsCount).fill("Infant"),
  ];

  const [cardNumberDigit, setcardNumberDigit] = useState(0);
  const [cardDetails, setcardDetails] = useState({
    name: "",
    holderName: "",
    number: "",
    expDate: "",
    cvv: "",
  });

  function isValidCardNumber(cardNumber) {
    if (!cardNumber) return false;
    const cleanedNumber = cardNumber.replace(/\s/g, "");
    const startsWithCorrectDigit = {
      "American express": cleanedNumber.startsWith("3"),
      Discover: cleanedNumber.startsWith("6"),
      "Master card": cleanedNumber.startsWith("5"),
      Visa: cleanedNumber.startsWith("4"),
    }[cardDetails?.name];

    const validLength =
      cardDetails?.name === "American express"
        ? cleanedNumber.length === 15
        : cleanedNumber.length === 16;

    return startsWithCorrectDigit && validLength && /^\d+$/.test(cleanedNumber);
  }

  const [billingDetails, setbillingDetails] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    countrycode: "",
    address: "",
    state: "",
    city: "",
    zip: "",
  });

  const isBillingDisabled = Object.values(billingDetails).some(
    (value) => value === ""
  );
  // const isBillingDisabled = Object.entries(billingDetails).filter(([key, value]) => key !== 'countrycode').some(([key, value]) => value === "");
  const isCardDisabled = Object.values(cardDetails).some(
    (value) => value === ""
  );
  const [isChecked, setIsChecked] = useState(false);

  const initialFirstStep = () => {
    setFirstStep(true);
    setSecondStep(false);
    setLastStep(false);
  };

  const completeFirstStep = () => {
    setFirstStep(false);
    setSecondStep(true);
    setLastStep(false);
  };

  const completeSecondStep = () => {
    setFirstStep(false);
    setSecondStep(false);
    setLastStep(true);
  };

  // ISO FORMAT
  function convertToISOFormat(dateOfArrival, timeOfArrival) {
    const year = `20${dateOfArrival.substring(4, 6)}`;
    const month = dateOfArrival.substring(2, 4);
    const day = dateOfArrival.substring(0, 2);
    const hours = timeOfArrival.substring(0, 2);
    const minutes = timeOfArrival.substring(2, 4);

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00`;
    return formattedDate;
  }

  useEffect(() => {
    let temp = [];
    for (let index = 0; index < totalPassengers; index++) {
      temp = passengerLabels.map((label) => ({
        firstName: "",
        middleName: "",
        lastName: "",
        date: "",
        gender: "",
        travellerType: label,
      }));
    }
    settravellerDetails(temp);
  }, [totalPassengers]);

  const handleChangeTravellerDetails = (
    e,
    selectedIndex,
    inputType = "input"
  ) => {
    const updatedTravellerDetails = [...travellerDetails];

    if (inputType === "input") {
      const { name, value } = e.target;
      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        [name]: value,
      };
    } else if (inputType === "datepicker") {
      const currentDate = new Date();
      const selectedDate = new Date(e);

      if (selectedDate > currentDate) {
        setErrorMessage(
          "Selected date cannot be greater than the current date."
        );
        return;
      } else {
        setErrorMessage("");
      }

      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        date: selectedDate,
      };
    } else if (inputType === "monthChange") {
      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        currentMonth: e,
      };
    } else if (inputType === "radio") {
      const { name, value } = e.target;
      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        [name.split("-")[0]]: value,
      };
    }

    settravellerDetails(updatedTravellerDetails);
  };

  useEffect(() => {
    if (bookingDetails) {
      const firstItinerary = {
        duration: calculateTotalDuration(
          bookingDetails?.flights?.[0]?.flightDetails
        ),
        segments: bookingDetails?.flights?.[0]?.flightDetails?.map((data) => ({
          departure: {
            iataCode: data?.flightInformation?.[0]?.location?.[0]?.locationId,
            at: convertToISOFormat(
              data?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfDeparture?.[0],
              data?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfDeparture?.[0]
            ),
          },
          arrival: {
            iataCode: data?.flightInformation?.[0]?.location?.[1]?.locationId,
            at: convertToISOFormat(
              data?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0],
              data?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0]
            ),
          },
          carrierCode:
            data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
              ?.airline_code,
          number: data?.flightInformation?.[0]?.flightOrtrainNumber?.[0],
          airline:
            data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
              ?.airline_name,
          airlineImage:
            data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers?.image,
          blacklistedInEU: false,
        })),
      };

      const secondItinerary =
        wayOfFlight !== "one_way" && bookingDetails?.flights?.[1]
          ? {
              duration: calculateTotalDuration(
                bookingDetails?.flights?.[1]?.flightDetails
              ),
              segments: bookingDetails?.flights?.[1]?.flightDetails?.map(
                (data) => ({
                  departure: {
                    iataCode:
                      data?.flightInformation?.[0]?.location?.[0]?.locationId,
                    at: convertToISOFormat(
                      data?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.dateOfDeparture?.[0],
                      data?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.timeOfDeparture?.[0]
                    ),
                  },
                  arrival: {
                    iataCode:
                      data?.flightInformation?.[0]?.location?.[1]?.locationId,
                    at: convertToISOFormat(
                      data?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.dateOfArrival?.[0],
                      data?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.timeOfArrival?.[0]
                    ),
                  },
                  carrierCode:
                    data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.airline_code,
                  number:
                    data?.flightInformation?.[0]?.flightOrtrainNumber?.[0],
                  airline:
                    data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.airline_name,
                  airlineImage:
                    data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.image,
                  blacklistedInEU: false,
                })
              ),
            }
          : null;

      const transformedData = {
        itineraries: [firstItinerary, secondItinerary].filter(Boolean),
        price: {
          currency: "USD",
          total: totalPrice?.toString(),
          base: totalPrice?.toString(),
          fees: [],
          grandTotal: totalPrice?.toString(),
          discount: bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
            ?.recommendation?.recPriceInfo?.[0]?.customPrice?.markUp
            ?.markup_amount
            ? parseFloat(
                bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                  ?.recommendation?.recPriceInfo?.[0]?.customPrice?.markUp
                  ?.markup_amount
              )?.toString()
            : "0",
        },
      };

      setBookingData(transformedData);
    }
  }, [totalPrice]);

  useEffect(() => {
    if (bookingDetails) {
      const data = [
        {
          travelerType:
            bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
              ?.recommendation?.paxFareProduct?.[0]?.paxReference?.[0]
              ?.ptc?.[0],
          price: {
            currency: "USD",
            total:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[0]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[0]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[0]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
            base:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[0]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[0]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[0]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
          },
        },
        {
          travelerType:
            bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
              ?.recommendation?.paxFareProduct?.[1]?.paxReference?.[0]
              ?.ptc?.[0],
          price: {
            currency: "USD",
            total:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[1]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[1]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[1]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
            base:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[1]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[1]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[1]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
          },
        },
        {
          travelerType:
            bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
              ?.recommendation?.paxFareProduct?.[2]?.paxReference?.[0]
              ?.ptc?.[0],
          price: {
            currency: "USD",
            total:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[2]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[2]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[2]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
            base:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[2]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[2]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[2]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
          },
        },
      ];

      if (sessionStorage.getItem("infants") === "0") {
        data.pop();
      }

      if (sessionStorage.getItem("children") === "0") {
        data.splice(1, 1);
      }

      setTravellerPricing(data);

      // setTravellerPricing(travelerPricings);
    }
  }, []);

  const handleResize = (e) => {
    setIsMobile(e.matches);
  };

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    setIsMobile(mobileMediaQuery.matches);
    mobileMediaQuery.addEventListener("change", handleResize);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const handleAddInfo = () => {
    setloader(true);
    const apiUrl = "https://api.ogule.com/api/frontend/add-bill-info";
    let { name, email, phone, address, state, city, zip } = billingDetails;

    let { holderName, number, expDate, cvv } = cardDetails;
    // const filteredTravellerDetails = travellerDetails.map(
    //   ({ gender, ...rest }) => rest
    // );
    const requestBody = {
      billingDetails: {
        name,
        email,
        phone,
        address,
      },
      travellerDetails: travellerDetails,
      cardDetails: {
        name: cardDetails?.name,
        holderName,
        number,
        expDate,
        cvv,
      },
      flightDetails: {
        wayOfFlight,
        originLocationCode,
        destinationLocationCode,
        adults,
        departureDate,
        returnDate,
        children,
        infants,
        travelClass,
        currencyCode,
        other_details: bookingData,
        booking_steps: "step_one",
        checkout_title: "Billing Information",
        payment_method: "card",
        showSubscribeModal: false,
      },
      user_id: login_data ? login_data.user._id : "",
      wayOfFlight,
      originLocationCode,
      destinationLocationCode,
      adults,
      departureDate,
      returnDate,
      children,
      infants,
      travelClass,
      currencyCode,
      isOnline: true,
      travelerPricings: travellerPricing,
      state,
      city,
      zip,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res?.data) {
          setloader(false);
          sessionStorage.setItem("billInfos", JSON.stringify(res?.data));
          sessionStorage.removeItem("booking_login");
          history.push("/booked_successfully");
        } else {
          alert("Some error occured");
          setloader(false);
        }
      })
      .catch((error) => {
        setloader(false);
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Header />
      <div className="pt-3 pb-5 px-responsive">
        <div className="d-flex align-items-center gap-3 mb-3">
          <span
            className="btn custom-btn-primary pointer d-flex align-items-center justify-content-center"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/flights");
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 14"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 7H15M1 7L7 13M1 7L7 1"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="breadcrumbs">
            <button className="breadcrumb-item" onClick={initialFirstStep}>
              <span className="label">Details</span>
              <span className="breadcrumb-after"></span>
            </button>
            <button
              className={`breadcrumb-item ${
                isDisabled ? "breadcrumb-disabled" : ""
              }`}
              disabled={firststep ? isDisabled : ""}
              onClick={completeFirstStep}
            >
              <span className="label">Add ons</span>
              <span className="breadcrumb-after"></span>
            </button>
            <button
              className={`breadcrumb-item ${
                isDisabled ? "breadcrumb-disabled" : ""
              }`}
              disabled={firststep ? isDisabled : ""}
              onClick={completeSecondStep}
            >
              <span className="label">Billing</span>
              <span className="breadcrumb-after"></span>
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            {firststep && (
              <FirstStep
                bookingDetails={bookingDetails}
                wayOfFlight={wayOfFlight}
                travellerDetails={travellerDetails}
                currentYear={currentYear}
                handleChangeTravellerDetails={handleChangeTravellerDetails}
                errorMessage={errorMessage}
              />
            )}
            {secondStep && (
              <SecondStep
                isRefundable={isRefundable}
                setIsRefundable={setIsRefundable}
                totalPrice={totalPrice}
                totalPassengers={totalPassengers}
                bookingDetails={bookingDetails}
                isAddPack={isAddPack}
                setIsAddPack={setIsAddPack}
              />
            )}
            {lastStep && (
              <ThirdStep
                bookingDetails={bookingDetails}
                wayOfFlight={wayOfFlight}
                isAllEmpty={isAllEmpty}
                cardDetails={cardDetails}
                setcardDetails={setcardDetails}
                cardNumberDigit={cardNumberDigit}
                setcardNumberDigit={setcardNumberDigit}
                billingDetails={billingDetails}
                setbillingDetails={setbillingDetails}
                travellerData={travellerData}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                isValidCardNumber={isValidCardNumber}
              />
            )}
          </div>
          <div className="col-lg-4">
            <div className="card p-3 shadow">
              <span
                className="fs-22p"
                style={{
                  fontWeight: 600,
                  color: "#000000",
                }}
              >
                Price details (USD)
              </span>
              <div className="w-100 overflow-auto">
                <table className="table border-0 text-center">
                  <thead>
                    <tr className="text-nowrap">
                      <th className="text-left border-0">Travelers</th>
                      <th className="border-0">Price per person</th>
                      <th className="border-0">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left d-flex text-nowrap border-0">
                        Adult : {sessionStorage.getItem("adults")}&nbsp;
                        <span className="pointer">
                          <Tooltip content="Above 12 years">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              fill="currentColor"
                              className="bi bi-info-circle mb-1 custom-text-primary"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                            </svg>
                          </Tooltip>
                        </span>
                      </td>
                      <td className="border-0 text-nowrap">
                        ${" "}
                        {wayOfFlight === "round_trip"
                          ? parseFloat(bookingDetails?.adult).toFixed(2)
                          : bookingDetails?.flights?.[0]
                              ?.propFlightGrDetail?.[0]?.recommendation
                              ?.paxFareProduct?.[0]?.paxFareDetail?.[0]
                              ?.totalFareAmount?.[0]}
                        &nbsp; x {sessionStorage.getItem("adults")}
                      </td>
                      <td className="border-0 text-nowrap">
                        ${" "}
                        {wayOfFlight === "round_trip"
                          ? sessionStorage.getItem("adults") *
                            parseFloat(bookingDetails?.adult).toFixed(2)
                          : sessionStorage.getItem("adults") *
                            parseFloat(
                              bookingDetails?.flights?.[0]
                                ?.propFlightGrDetail?.[0]?.recommendation
                                ?.paxFareProduct?.[0]?.paxFareDetail?.[0]
                                ?.totalFareAmount?.[0]
                            )?.toFixed(2)}
                      </td>
                    </tr>
                    {sessionStorage.getItem("children") !== "0" && (
                      <tr>
                        <td className="text-left d-flex text-nowrap border-0">
                          Child : {sessionStorage.getItem("children")}&nbsp;
                          <span className="pointer">
                            <Tooltip content="2 years to 12 years">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-info-circle mb-1 custom-text-primary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                              </svg>
                            </Tooltip>
                          </span>
                        </td>
                        <td className="border-0 text-nowrap">
                          ${" "}
                          {wayOfFlight === "round_trip"
                            ? parseFloat(bookingDetails?.child).toFixed(2)
                            : bookingDetails?.flights?.[0]
                                ?.propFlightGrDetail?.[0]?.recommendation
                                ?.paxFareProduct?.[1]?.paxFareDetail?.[0]
                                ?.totalFareAmount?.[0]}
                          &nbsp; x {sessionStorage.getItem("children")}
                        </td>
                        <td className="border-0 text-nowrap">
                          ${" "}
                          {wayOfFlight === "round_trip"
                            ? parseFloat(bookingDetails?.child).toFixed(2)
                            : sessionStorage.getItem("children") *
                              parseFloat(
                                bookingDetails?.flights?.[0]
                                  ?.propFlightGrDetail?.[0]?.recommendation
                                  ?.paxFareProduct?.[1]?.paxFareDetail?.[0]
                                  ?.totalFareAmount?.[0]
                              )?.toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {sessionStorage.getItem("infants") !== "0" && (
                      <tr>
                        <td className="text-left d-flex text-nowrap border-0">
                          Infants : {sessionStorage.getItem("infants")}&nbsp;
                          <span className="pointer">
                            <Tooltip content="Below 2 years">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-info-circle mb-1 custom-text-primary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                              </svg>
                            </Tooltip>
                          </span>
                        </td>
                        <td className="border-0 text-nowrap">$ 0.00</td>
                        <td className="border-0 text-nowrap">
                          $ 0.00
                          {/* {wayOfFlight === "round_trip" 
                          ? sessionStorage.getItem("infants") *
                            (parseFloat(
                              bookingDetails?.flights?.[0]
                                ?.propFlightGrDetail?.[0]?.recommendation
                                ?.paxFareProduct?.[2]?.paxFareDetail?.[0]
                                ?.totalFareAmount?.[0]
                            ) +
                              parseFloat(
                                bookingDetails?.flights?.[1]
                                  ?.propFlightGrDetail?.[0]?.recommendation
                                  ?.paxFareProduct?.[2]?.paxFareDetail?.[0]
                                  ?.totalFareAmount?.[0]
                              ))
                          : sessionStorage.getItem("infants") * parseFloat(
                              bookingDetails?.flights?.[0]
                                ?.propFlightGrDetail?.[0]?.recommendation
                                ?.paxFareProduct?.[2]?.paxFareDetail?.[0]
                                ?.totalFareAmount?.[0]
                            ) } */}
                        </td>
                      </tr>
                    )}
                    {isRefundable === "true" && !firststep && (
                      <tr>
                        <td className="text-left d-flex text-nowrap border-0 fs-14p">
                          Refundable Booking
                        </td>
                        <td className="border-0 text-nowrap">
                          ${" "}
                          {(
                            bookingDetails?.price /
                            totalPassengers /
                            6
                          )?.toFixed(2)}{" "}
                          X {totalPassengers}
                        </td>
                        <td className="border-0 text-nowrap">
                          ${" "}
                          {parseFloat(
                            bookingDetails?.price / totalPassengers / 6
                          )?.toFixed(2) * parseFloat(totalPassengers)}
                        </td>
                      </tr>
                    )}
                    {isAddPack && !firststep && (
                      <tr>
                        <td className="text-left d-flex text-nowrap border-0 fs-14p">
                          Support Package
                        </td>
                        <td className="border-0 text-nowrap">$ 19.89</td>
                        <td className="border-0 text-nowrap">$ 19.89</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {bookingDetails?.markUp !== null && (
                <div className="row px-3 justify-content-end mb-2">
                  <span
                    className="fs-15p"
                    style={{
                      fontWeight: 500,
                      color: "#000000",
                      textTransform: "capitalize",
                    }}
                  >
                    {bookingDetails?.markUp?.markup_type} (
                    {bookingDetails?.markUp?.markup_amount_type === "percentage"
                      ? "%"
                      : "-"}
                    ) : {parseFloat(bookingDetails?.markUp?.markup_amount)}
                  </span>
                </div>
              )}

              <div className="row justify-content-between px-3 mt-auto">
                <div className="fs-20p fw-600">Total Price (USD)</div>
                <div className="fs-20p fw-600">${totalPrice}</div>
              </div>
              <div className="mt-2 px-1">
                <span className="fs-16p fw-800">Bags</span>
              </div>

              <div className="d-flex flex-column mt-2 px-1 gap-1">
                <span className="fs-14p fw-500">Personal items</span>
                <span className="fs-12p fw-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="200 160 200 340"
                    fill="currentColor"
                    width="14"
                    height="16"
                    class="self-center "
                  >
                    <path
                      d="M 302.005 161.623 C 291.656 165.347, 284.177 173.135, 281.198 183.289 C 279.946 187.557, 278.975 189.168, 277.500 189.420 C 251.356 193.899, 241.433 198.585, 226.507 213.500 C 216.503 223.496, 210.922 232.338, 206.306 245.500 L 203.500 253.500 203.223 357.731 L 202.947 461.961 205.495 466.990 C 208.337 472.598, 214.207 477.440, 220.082 479.022 C 222.550 479.687, 257.899 479.945, 322.257 479.769 L 420.658 479.500 425.552 476.474 C 428.586 474.598, 431.598 471.586, 433.474 468.552 L 436.500 463.658 436.764 361.730 C 437.018 263.489, 436.957 259.504, 435.069 251.554 C 431.276 235.585, 423.658 222.525, 411.600 211.321 C 397.513 198.233, 387.268 193.663, 362.500 189.420 C 361.018 189.166, 360.053 187.545, 358.772 183.156 C 356.386 174.980, 350.836 168.271, 342.900 163.969 L 336.500 160.500 321.500 160.253 C 309.724 160.059, 305.534 160.354, 302.005 161.623 M 303.500 173.531 C 297.630 176.653, 292 183.467, 292 187.450 C 292 188.813, 295.379 189, 320 189 C 346.588 189, 348 188.907, 348 187.148 C 348 184.531, 344.171 179.227, 339.661 175.593 L 335.822 172.500 321.161 172.218 C 309.266 171.989, 305.934 172.237, 303.500 173.531 M 275 252 L 275 262 320 262 L 365 262 365 252 L 365 242 320 242 L 275 242 275 252 M 245 370.500 L 245 381 320 381 L 395 381 395 370.500 L 395 360 320 360 L 245 360 245 370.500 M 245 428 L 245 454 320 454 L 395 454 395 428 L 395 402 320 402 L 245 402 245 428"
                      stroke="none"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                  &nbsp; 1 x 5kg included for all passengers
                </span>
                <span className="fs-14p fw-500 ">Carry on baggage</span>
                <span className="fs-12p fw-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    width="14"
                    height="16"
                    class="self-center "
                  >
                    <path
                      d="M 174.859 55.158 C 172.856 56.319, 170.268 58.957, 169.109 61.021 C 167.091 64.610, 167 66.100, 167 95.386 L 167 126 144 126 L 121 126 121 292.500 L 121 459 256 459 L 391 459 391 292.500 L 391 126 368 126 L 345 126 345 95.386 C 345 66.057, 344.911 64.614, 342.882 61.004 C 341.717 58.931, 339.069 56.283, 336.996 55.118 C 333.247 53.011, 332.831 53, 255.864 53.023 C 178.975 53.046, 178.478 53.059, 174.859 55.158 M 200 106 L 200 126 256 126 L 312 126 312 106 L 312 86 256 86 L 200 86 200 106 M 40.608 127.522 C 21.754 132.699, 6.525 148.069, 1.485 167.008 C -0.677 175.134, -0.708 409.752, 1.453 417.869 C 6.054 435.155, 18.512 448.952, 35.623 455.710 C 42.281 458.339, 43.242 458.438, 65.750 458.793 L 89 459.161 89 292.581 L 89 126 67.250 126.089 C 52.396 126.150, 43.949 126.605, 40.608 127.522 M 423 292.581 L 423 459.161 446.250 458.767 C 472.940 458.315, 476.177 457.588, 488.322 449.329 C 499.233 441.908, 507.121 430.742, 510.547 417.869 C 512.708 409.752, 512.677 175.134, 510.515 167.008 C 505.413 147.836, 490.150 132.584, 470.955 127.475 C 467.009 126.425, 459.620 126.020, 444.250 126.012 L 423 126 423 292.581"
                      stroke="none"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                  &nbsp; 0 - Available at additional cost
                </span>
                <span className="fs-14p fw-500">Checked in baggage</span>
                <span className="fs-12p fw-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="250 0 50 750"
                    fill="currentColor"
                    width="14"
                    height="16"
                    className="mb-1"
                  >
                    <path
                      d="M 236 73.876 C 228.608 74.362, 225.692 75.003, 222.857 76.766 C 217.913 79.840, 214.188 84.974, 213.421 89.771 C 213.071 91.960, 212.720 122.944, 212.642 158.625 L 212.500 223.500 174.945 224 L 137.389 224.500 129.945 228.182 C 117.336 234.419, 108.849 244.971, 106.125 257.799 C 105.400 261.214, 105 277.127, 105 302.549 L 105 342 298.500 342 L 492 342 491.944 308.750 C 491.870 265.020, 491.274 257.302, 487.330 248.956 C 483.494 240.839, 477.831 234.466, 470.587 230.113 C 461.214 224.481, 457.413 224.033, 418.834 224.016 L 384.168 224 383.834 156.250 C 383.471 82.566, 383.812 86.752, 377.630 80.165 C 371.102 73.209, 375.043 73.586, 307 73.411 C 273.175 73.324, 241.225 73.533, 236 73.876 M 234 170 L 234 224 298.500 224 L 363 224 363 170 L 363 116 298.500 116 L 234 116 234 170 M 105 460 L 105 557 298.500 557 L 492 557 492 460 L 492 363 298.500 363 L 105 363 105 460 M 105 620.384 L 105 662.768 109.015 670.791 C 113.862 680.475, 121.881 688.409, 131.422 692.963 L 137.836 696.024 298.668 695.762 L 459.500 695.500 466.274 692.291 C 474.769 688.267, 482.720 680.470, 487.057 671.911 C 489.986 666.130, 490.383 664.272, 491.105 653 C 491.546 646.125, 491.927 626.438, 491.953 609.250 L 492 578 298.500 578 L 105 578 105 620.384 M 168.220 710.578 C 158.997 715.174, 153.872 723.646, 153.879 734.286 C 153.884 741.734, 155.769 746.627, 160.773 752.182 C 165.835 757.803, 171.340 760.090, 179.868 760.117 C 187.745 760.142, 192.089 758.617, 197.387 753.965 C 212.364 740.815, 207.683 716.092, 188.965 709.488 C 182.314 707.141, 174.268 707.564, 168.220 710.578 M 406.355 709.447 C 401.808 711.425, 395.173 717.741, 392.842 722.309 C 388.454 730.911, 389.800 743.722, 395.860 751.034 C 397.471 752.977, 401.081 755.815, 403.882 757.339 C 408.162 759.669, 410.171 760.111, 416.469 760.111 C 425.150 760.111, 429.594 758.406, 435 753 C 447.984 740.016, 444.103 717.909, 427.465 710.089 C 422.222 707.624, 411.307 707.292, 406.355 709.447"
                      stroke="none"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                  &nbsp; 0 - Available at additional cost
                </span>
              </div>
              <div className="mt-1">
                <span className="fs-12p">
                  * Additional baggage fees may apply.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-lg custom-btn-primary my-4"
            type="submit"
            disabled={
              firststep
                ? isDisabled || errorMessage
                : lastStep
                ? isBillingDisabled ||
                  isCardDisabled ||
                  !isChecked ||
                  !isValidCardNumber(cardDetails?.number)
                : ""
            }
            onClick={() => {
              if (firststep) {
                setFirstStep(false);
                setSecondStep(true);
                setLastStep(false);
                sessionStorage.setItem(
                  "travellerData",
                  JSON.stringify(travellerDetails)
                );
              } else if (secondStep) {
                setFirstStep(false);
                setSecondStep(false);
                setLastStep(true);
              } else if (lastStep) {
                handleAddInfo();
              }
            }}
          >
            {firststep || secondStep
              ? "Continue to next step"
              : loader
              ? "Loading...."
              : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutNew;
