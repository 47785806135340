import React, { useEffect, useState } from "react";
import trustpilot from "../image/social/trustpilot.png";
import { Link } from "react-router-dom";
import Asta from "../image/NewUI/Asta.png";
import American from "../image/NewUI/American.png";
import Mastercard from "../image/NewUI/Mastercard.png";
import Visa from "../image/NewUI/Visa.png";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  const handleResize = (e) => {
    setIsMobile(e.matches);
  };

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    setIsMobile(mobileMediaQuery.matches);
    mobileMediaQuery.addEventListener("change", handleResize);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  return (
    <div>
      {!isMobile ? (
        <div className="py-5 text-dark" style={{ backgroundColor: "#EBF8FE" }}>
          <div className="container">
            <div className="row mb-3">
              <div className="col-sm-3">
                <h5 className="mb-2 footer-header custom-text-primary">
                  Reach Us
                </h5>
                <div className="list-group">
                  {/* <p><FontAwesomeIcon icon={faPhoneAlt} /> +1 302-585-3548 (US)</p> */}
                  <h5 className="mb-2 footer-title text-dark">Our Address</h5>
                  <div className="list-group">
                    <p>
                      Three Sugar Creek Center Blvd Sugar Land, TX 77479, USA
                    </p>
                    {/* <p>H No 114-B, Radhika Nagar, Kharangajhar Telco Colony, Jamshedpur 831004, Jharkhand INDIA</p> */}
                  </div>
                  <p>
                    <a
                      href="tel:+18883151615"
                      className="btn rounded-1rem btn-primary btn-lg pulse px-4"
                      role="button"
                    >
                      <p className="mb-0 font-size-1">
                        Get upto&nbsp;
                        <span className="text-danger fw-600">30% off</span>
                        &nbsp; on call
                      </p>
                      <p className="fw-600 float-right mb-0 font-size-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          class="bi bi-telephone call_vibration"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                        </svg>
                        &nbsp;+1 888-315-1615 (US)
                      </p>
                    </a>
                  </p>

                  {/* <p><FontAwesomeIcon icon={faPhoneAlt} /> +91 935-013-7900 (IN)</p>									 */}
                </div>
              </div>

              <div className="col-sm-3">
                <h5 className="mb-2 footer-header custom-text-primary">
                  Information
                </h5>
                <div className="list-group">
                  <ul className="footer_li">
                    <Link to="/about" target="_top">
                      <li className="footer-title text-dark">About Us</li>
                    </Link>
                    <Link to="/our_team" target="_top">
                      <li className="footer-title text-dark">Our Team</li>
                    </Link>
                    <Link to="/guest_support" target="_top">
                      <li className="footer-title text-dark">Guest Support</li>
                    </Link>
                    <Link to="/terms_conditions" target="_top">
                      <li className="footer-title text-dark">
                        Terms and Conditions
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>

              <div className="col-sm-3">
                <h5 className="mb-2 footer-header custom-text-primary">
                  Legal
                </h5>
                <div className="list-group">
                  <ul className="footer_li">
                    <Link to="/privacy_policy" target="_top">
                      <li className="footer-title text-dark">
                        Privacy and Cookie policy
                      </li>
                    </Link>
                    <Link to="/terms_conditions#baggage_policy" target="_top">
                      <li className="footer-title text-dark">Baggage Policy</li>
                    </Link>
                    <Link to="/terms_conditions#cancel_refund" target="_top">
                      <li className="footer-title text-dark">Cancel/Refund</li>
                    </Link>
                    <Link to="/terms_conditions#charges" target="_top">
                      <li className="footer-title text-dark">Charges</li>
                    </Link>
                  </ul>
                </div>
              </div>

              <div className="col-sm-3">
                <h5 className="mb-4 footer-header custom-text-primary">
                  Social Media
                </h5>
                <div className="list-group">
                  <ul className="footer_li d-flex flex-wrap gap-1rem">
                    <a
                      href="https://www.facebook.com/ogulellc/?modal=admin_todo_tour"
                      target="_blank"
                    >
                      <svg
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.0476 0C8.52791 0 0 8.52791 0 19.0476C0 29.5673 8.52791 38.0952 19.0476 38.0952C29.5673 38.0952 38.0952 29.5673 38.0952 19.0476C38.0952 8.52791 29.5673 0 19.0476 0ZM21.0317 19.8843V30.2472H16.7441V19.8847H14.6019V16.3136H16.7441V14.1695C16.7441 11.2562 17.9536 9.52381 21.3902 9.52381H24.2512V13.0953H22.4629C21.1251 13.0953 21.0366 13.5944 21.0366 14.5258L21.0317 16.3132H24.2715L23.8924 19.8843H21.0317Z"
                          fill="currentcolor"
                        />
                      </svg>
                    </a>
                    <a
                      href="https://www.instagram.com/ogulellc/"
                      target="_blank"
                    >
                      <svg
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.7156 0C9.19588 0 0.667969 8.52791 0.667969 19.0476C0.667969 29.5673 9.19588 38.0952 19.7156 38.0952C30.2353 38.0952 38.7632 29.5673 38.7632 19.0476C38.7632 8.52791 30.2353 0 19.7156 0ZM15.5279 8.95042C16.6115 8.90111 16.9577 8.88904 19.7167 8.88904H19.7135C22.4733 8.88904 22.8183 8.90111 23.9019 8.95042C24.9834 8.99994 25.722 9.17116 26.3697 9.42238C27.0385 9.68164 27.6036 10.0287 28.1686 10.5938C28.7337 11.1585 29.0808 11.7253 29.3411 12.3934C29.5909 13.0394 29.7623 13.7776 29.8131 14.8591C29.8618 15.9427 29.8745 16.2889 29.8745 19.0479C29.8745 21.8069 29.8618 22.1523 29.8131 23.2359C29.7623 24.3169 29.5909 25.0553 29.3411 25.7015C29.0808 26.3694 28.7337 26.9362 28.1686 27.5009C27.6042 28.066 27.0383 28.4139 26.3703 28.6734C25.724 28.9246 24.9849 29.0958 23.9034 29.1453C22.8198 29.1947 22.4746 29.2067 19.7154 29.2067C16.9567 29.2067 16.6106 29.1947 15.527 29.1453C14.4457 29.0958 13.7073 28.9246 13.061 28.6734C12.3932 28.4139 11.8265 28.066 11.262 27.5009C10.6971 26.9362 10.35 26.3694 10.0904 25.7013C9.83934 25.0554 9.66813 24.3171 9.61839 23.2356C9.56929 22.152 9.55701 21.8069 9.55701 19.0479C9.55701 16.2889 9.56971 15.9425 9.61818 14.8588C9.66685 13.7778 9.83829 13.0394 10.0901 12.3932C10.3505 11.7253 10.6976 11.1585 11.2626 10.5938C11.8273 10.0289 12.3941 9.68186 13.0622 9.42238C13.7082 9.17116 14.4464 8.99994 15.5279 8.95042Z"
                          fill="currentcolor"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18.8055 10.7193C18.9824 10.7191 19.1728 10.7191 19.3782 10.7192L19.7168 10.7193C22.4292 10.7193 22.7507 10.7291 23.8218 10.7777C24.8123 10.823 25.3499 10.9885 25.708 11.1276C26.1821 11.3117 26.52 11.5318 26.8754 11.8874C27.231 12.243 27.4511 12.5816 27.6356 13.0557C27.7747 13.4133 27.9404 13.9509 27.9855 14.9414C28.0341 16.0123 28.0447 16.334 28.0447 19.0451C28.0447 21.7563 28.0341 22.078 27.9855 23.1489C27.9402 24.1394 27.7747 24.6769 27.6356 25.0346C27.4515 25.5087 27.231 25.8463 26.8754 26.2016C26.5198 26.5572 26.1823 26.7773 25.708 26.9614C25.3503 27.1011 24.8123 27.2662 23.8218 27.3115C22.7509 27.3601 22.4292 27.3707 19.7168 27.3707C17.0042 27.3707 16.6827 27.3601 15.6118 27.3115C14.6213 27.2658 14.0837 27.1002 13.7254 26.9612C13.2513 26.7771 12.9127 26.557 12.5571 26.2014C12.2016 25.8458 11.9815 25.5081 11.7969 25.0338C11.6579 24.6761 11.4922 24.1385 11.4471 23.148C11.3984 22.0771 11.3887 21.7554 11.3887 19.0426C11.3887 16.3298 11.3984 16.0098 11.4471 14.9389C11.4924 13.9484 11.6579 13.4108 11.7969 13.0527C11.9811 12.5786 12.2016 12.24 12.5571 11.8844C12.9127 11.5289 13.2513 11.3088 13.7254 11.1242C14.0835 10.9845 14.6213 10.8194 15.6118 10.7739C16.5489 10.7316 16.9121 10.7189 18.8055 10.7168V10.7193ZM25.1403 12.4064C24.4673 12.4064 23.9212 12.9518 23.9212 13.6251C23.9212 14.2981 24.4673 14.8441 25.1403 14.8441C25.8133 14.8441 26.3593 14.2981 26.3593 13.6251C26.3593 12.9521 25.8133 12.406 25.1403 12.406V12.4064ZM14.4997 19.046C14.4997 16.165 16.8355 13.8291 19.7165 13.829C22.5976 13.829 24.9328 16.1649 24.9328 19.046C24.9328 21.9271 22.5978 24.2619 19.7167 24.2619C16.8356 24.2619 14.4997 21.9271 14.4997 19.046Z"
                          fill="currentcolor"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.7164 15.6611C21.5864 15.6611 23.1026 17.1771 23.1026 19.0474C23.1026 20.9175 21.5864 22.4337 19.7164 22.4337C17.8461 22.4337 16.3301 20.9175 16.3301 19.0474C16.3301 17.1771 17.8461 15.6611 19.7164 15.6611V15.6611Z"
                          fill="currentcolor"
                        />
                      </svg>
                    </a>
                    <a href="https://x.com/Ogulellc" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        fill="currentColor"
                        class="bi bi-twitter-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </a>
                    <div className="d-flex gap-1rem w-100 mt-2">
                      <a
                        href="https://www.linkedin.com/in/ogule-llc-890a541a4/"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="39"
                          height="39"
                          fill="none"
                          class="bi bi-linkedin"
                          viewBox="0 0 17 17"
                        >
                          <path
                            d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                            fill="currentcolor"
                          />
                        </svg>
                      </a>
                      <a
                        href="https://www.trustpilot.com/review/ogule.com"
                        target="_blank"
                      >
                        <img
                          src={trustpilot}
                          className="img-fluid rounded "
                          alt="trustpilot"
                          style={{ width: "37px", height: "37px" }}
                        />
                      </a>
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="gap-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={Asta}
                width={100}
                height={60}
                alt=""
                style={{
                  backgroundColor: "transparent",
                  mixBlendMode: "multiply",
                }}
              />
              <img
                src={American}
                width={100}
                height={60}
                alt=""
                style={{
                  backgroundColor: "transparent",
                  mixBlendMode: "multiply",
                }}
              />
              <img
                src={Mastercard}
                width={80}
                height={60}
                alt=""
                style={{
                  backgroundColor: "transparent",
                  mixBlendMode: "multiply",
                }}
              />
              <img
                src={Visa}
                width={80}
                height={60}
                alt=""
                style={{
                  backgroundColor: "transparent",
                  mixBlendMode: "multiply",
                }}
              />
            </div>

            <p className="text-center mt-5">
              Disclaimer: Important Information The content on this flight
              booking site is for general information purposes. We strive for
              accuracy but cannot guarantee up-to-date details. Flight
              schedules, prices, and availability may change. Users are advised
              to verify information directly with airlines. We are not
              responsible for any losses or inconveniences resulting from
              reliance on this site. It's recommended to review terms and
              conditions before making bookings. We do not endorse or guarantee
              third-party services. Users use this site at their own risk. By
              using the site, users agree to these terms.
            </p>
            <p className="text-center mt-3 mb-2">
              Travelling can be complex. Deciding and budget planning for the
              next perfect destination can be difficult at times and perhaps for
              thesame reason OGULE aims at providing holistic guidance to
              customers travel itineraries.
            </p>
            <div className="d-flex justify-content-between mt-5">
              <span className="text-success">
                © 2024 Ogule All rights reserved.
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="py-3 text-dark"
          style={{ backgroundColor: "#EBF8FE", marginBottom: "10%" }}
        >
          <div className="container" style={{ marginTop: location.pathname ==="/dashboard" ? "55%" : location.pathname ==="/help" ? "110%" : ""}}>
            <div className="row mb-2">
              <div className="col-sm-12">
                <h5
                  className="mb-2 footer-header custom-text-primary"
                  style={{ fontSize: "20px" }}
                >
                  Reach Us
                </h5>
                <div className="list-group">
                  <h5 className="mb-2 footer-title text-dark">Our Address</h5>
                  <div className="list-group">
                    <p>
                      Three Sugar Creek Center Blvd Sugar Land, TX 77479, USA
                    </p>
                  </div>
                  <p>
                    <a
                      href="tel:+18883151615"
                      className="btn rounded-1rem btn-primary btn-lg pulse px-4"
                      role="button"
                    >
                      <p className="mb-0 font-size-1">
                        Get upto&nbsp;
                        <span className="text-danger fw-600">30% off</span>
                        &nbsp; on call
                      </p>
                      <p className="fw-600 float-right mb-0 font-size-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          class="bi bi-telephone call_vibration"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                        </svg>
                        &nbsp;+1 888-315-1615 (US)
                      </p>
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-sm-12">
                <h5
                  className="mb-2 footer-header custom-text-primary"
                  style={{ fontSize: "20px" }}
                >
                  Information
                </h5>
                <div className="list-group">
                  <ul className="footer_li">
                    <Link to="/about" target="_top">
                      <li className="footer-title text-dark">About Us</li>
                    </Link>
                    <Link to="/our_team" target="_top">
                      <li className="footer-title text-dark">Our Team</li>
                    </Link>
                    <Link to="/guest_support" target="_top">
                      <li className="footer-title text-dark">Guest Support</li>
                    </Link>
                    <Link to="/terms_conditions" target="_top">
                      <li className="footer-title text-dark">
                        Terms and Conditions
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>

              <div className="col-sm-12">
                <h5
                  className="mb-2 footer-header custom-text-primary"
                  style={{ fontSize: "20px" }}
                >
                  Legal
                </h5>
                <div className="list-group">
                  <ul className="footer_li">
                    <Link to="/privacy_policy" target="_top">
                      <li className="footer-title text-dark">
                        Privacy and Cookie policy
                      </li>
                    </Link>
                    <Link to="/terms_conditions#baggage_policy" target="_top">
                      <li className="footer-title text-dark">Baggage Policy</li>
                    </Link>
                    <Link to="/terms_conditions#cancel_refund" target="_top">
                      <li className="footer-title text-dark">Cancel/Refund</li>
                    </Link>
                    <Link to="/terms_conditions#charges" target="_top">
                      <li className="footer-title text-dark">Charges</li>
                    </Link>
                  </ul>
                </div>
              </div>

              <div className="col-sm-12">
                <h5
                  className="mb-2 footer-header custom-text-primary"
                  style={{ fontSize: "20px" }}
                >
                  Social Media
                </h5>
                <div className="d-flex justify-content-center">
                  <div className="list-group">
                    <ul className="footer_li d-flex flex-wrap gap-1rem">
                      <a
                        href="https://www.facebook.com/ogulellc/?modal=admin_todo_tour"
                        target="_blank"
                      >
                        <svg
                          width="29"
                          height="29"
                          viewBox="0 0 39 39"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.0476 0C8.52791 0 0 8.52791 0 19.0476C0 29.5673 8.52791 38.0952 19.0476 38.0952C29.5673 38.0952 38.0952 29.5673 38.0952 19.0476C38.0952 8.52791 29.5673 0 19.0476 0ZM21.0317 19.8843V30.2472H16.7441V19.8847H14.6019V16.3136H16.7441V14.1695C16.7441 11.2562 17.9536 9.52381 21.3902 9.52381H24.2512V13.0953H22.4629C21.1251 13.0953 21.0366 13.5944 21.0366 14.5258L21.0317 16.3132H24.2715L23.8924 19.8843H21.0317Z"
                            fill="currentcolor"
                          />
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/ogulellc/"
                        target="_blank"
                      >
                        <svg
                          width="29"
                          height="29"
                          viewBox="0 0 39 39"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.7156 0C9.19588 0 0.667969 8.52791 0.667969 19.0476C0.667969 29.5673 9.19588 38.0952 19.7156 38.0952C30.2353 38.0952 38.7632 29.5673 38.7632 19.0476C38.7632 8.52791 30.2353 0 19.7156 0ZM15.5279 8.95042C16.6115 8.90111 16.9577 8.88904 19.7167 8.88904H19.7135C22.4733 8.88904 22.8183 8.90111 23.9019 8.95042C24.9834 8.99994 25.722 9.17116 26.3697 9.42238C27.0385 9.68164 27.6036 10.0287 28.1686 10.5938C28.7337 11.1585 29.0808 11.7253 29.3411 12.3934C29.5909 13.0394 29.7623 13.7776 29.8131 14.8591C29.8618 15.9427 29.8745 16.2889 29.8745 19.0479C29.8745 21.8069 29.8618 22.1523 29.8131 23.2359C29.7623 24.3169 29.5909 25.0553 29.3411 25.7015C29.0808 26.3694 28.7337 26.9362 28.1686 27.5009C27.6042 28.066 27.0383 28.4139 26.3703 28.6734C25.724 28.9246 24.9849 29.0958 23.9034 29.1453C22.8198 29.1947 22.4746 29.2067 19.7154 29.2067C16.9567 29.2067 16.6106 29.1947 15.527 29.1453C14.4457 29.0958 13.7073 28.9246 13.061 28.6734C12.3932 28.4139 11.8265 28.066 11.262 27.5009C10.6971 26.9362 10.35 26.3694 10.0904 25.7013C9.83934 25.0554 9.66813 24.3171 9.61839 23.2356C9.56929 22.152 9.55701 21.8069 9.55701 19.0479C9.55701 16.2889 9.56971 15.9425 9.61818 14.8588C9.66685 13.7778 9.83829 13.0394 10.0901 12.3932C10.3505 11.7253 10.6976 11.1585 11.2626 10.5938C11.8273 10.0289 12.3941 9.68186 13.0622 9.42238C13.7082 9.17116 14.4464 8.99994 15.5279 8.95042Z"
                            fill="currentcolor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.8055 10.7193C18.9824 10.7191 19.1728 10.7191 19.3782 10.7192L19.7168 10.7193C22.4292 10.7193 22.7507 10.7291 23.8218 10.7777C24.8123 10.823 25.3499 10.9885 25.708 11.1276C26.1821 11.3117 26.52 11.5318 26.8754 11.8874C27.231 12.243 27.4511 12.5816 27.6356 13.0557C27.7747 13.4133 27.9404 13.9509 27.9855 14.9414C28.0341 16.0123 28.0447 16.334 28.0447 19.0451C28.0447 21.7563 28.0341 22.078 27.9855 23.1489C27.9402 24.1394 27.7747 24.6769 27.6356 25.0346C27.4515 25.5087 27.231 25.8463 26.8754 26.2016C26.5198 26.5572 26.1823 26.7773 25.708 26.9614C25.3503 27.1011 24.8123 27.2662 23.8218 27.3115C22.7509 27.3601 22.4292 27.3707 19.7168 27.3707C17.0042 27.3707 16.6827 27.3601 15.6118 27.3115C14.6213 27.2658 14.0837 27.1002 13.7254 26.9612C13.2513 26.7771 12.9127 26.557 12.5571 26.2014C12.2016 25.8458 11.9815 25.5081 11.7969 25.0338C11.6579 24.6761 11.4922 24.1385 11.4471 23.148C11.3984 22.0771 11.3887 21.7554 11.3887 19.0426C11.3887 16.3298 11.3984 16.0098 11.4471 14.9389C11.4924 13.9484 11.6579 13.4108 11.7969 13.0527C11.9811 12.5786 12.2016 12.24 12.5571 11.8844C12.9127 11.5289 13.2513 11.3088 13.7254 11.1242C14.0835 10.9845 14.6213 10.8194 15.6118 10.7739C16.5489 10.7316 16.9121 10.7189 18.8055 10.7168V10.7193ZM25.1403 12.4064C24.4673 12.4064 23.9212 12.9518 23.9212 13.6251C23.9212 14.2981 24.4673 14.8441 25.1403 14.8441C25.8133 14.8441 26.3593 14.2981 26.3593 13.6251C26.3593 12.9521 25.8133 12.406 25.1403 12.406V12.4064ZM14.4997 19.046C14.4997 16.165 16.8355 13.8291 19.7165 13.829C22.5976 13.829 24.9328 16.1649 24.9328 19.046C24.9328 21.9271 22.5978 24.2619 19.7167 24.2619C16.8356 24.2619 14.4997 21.9271 14.4997 19.046Z"
                            fill="currentcolor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.7164 15.6611C21.5864 15.6611 23.1026 17.1771 23.1026 19.0474C23.1026 20.9175 21.5864 22.4337 19.7164 22.4337C17.8461 22.4337 16.3301 20.9175 16.3301 19.0474C16.3301 17.1771 17.8461 15.6611 19.7164 15.6611V15.6611Z"
                            fill="currentcolor"
                          />
                        </svg>
                      </a>
                      <a href="https://x.com/Ogulellc" target="_blank">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          class="bi bi-twitter-x"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/ogule-llc-890a541a4/"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29"
                          height="29"
                          fill="none"
                          class="bi bi-linkedin"
                          viewBox="0 0 17 17"
                        >
                          <path
                            d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                            fill="currentcolor"
                          />
                        </svg>
                      </a>
                      <a
                        href="https://www.trustpilot.com/review/ogule.com"
                        target="_blank"
                      >
                        <img
                          src={trustpilot}
                          className="img-fluid rounded "
                          alt="trustpilot"
                          style={{ width: "29px", height: "29px" }}
                        />
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gap-4"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <img
                src={Asta}
                width={60}
                height={40}
                alt=""
                style={{
                  backgroundColor: "transparent",
                  mixBlendMode: "multiply",
                }}
              />
              <img
                src={American}
                width={60}
                height={40}
                alt=""
                style={{
                  backgroundColor: "transparent",
                  mixBlendMode: "multiply",
                }}
              />
              <img
                src={Mastercard}
                width={60}
                height={40}
                alt=""
                style={{
                  backgroundColor: "transparent",
                  mixBlendMode: "multiply",
                }}
              />
              <img
                src={Visa}
                width={60}
                height={40}
                alt=""
                style={{
                  backgroundColor: "transparent",
                  mixBlendMode: "multiply",
                }}
              />
            </div>

            <div
              style={{ borderBottom: "1px solid #6c757d", marginTop: 15 }}
            ></div>
            <p className="text-left mt-2">
              Disclaimer: Important Information The content on this flight
              booking site is for general information purposes. We strive for
              accuracy but cannot guarantee up-to-date details. Flight
              schedules, prices, and availability may change. Users are advised
              to verify information directly with airlines. We are not
              responsible for any losses or inconveniences resulting from
              reliance on this site. It's recommended to review terms and
              conditions before making bookings. We do not endorse or guarantee
              third-party services. Users use this site at their own risk. By
              using the site, users agree to these terms.
            </p>
            <p className="text-left mt-3 mb-3">
              Travelling can be complex. Deciding and budget planning for the
              next perfect destination can be difficult at times and perhaps for
              thesame reason OGULE aims at providing holistic guidance to
              customers travel itineraries.
            </p>
            <div className="d-flex justify-content-center mb-3">
              <span className="text-success">
                © 2024 Ogule All rights reserved.
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
