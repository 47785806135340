import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Header from "./Header";
import logo from "../image/logo-removebg.png";
import Skeleton from "@material-ui/lab/Skeleton";

const Cards = () => {
  const login_data = JSON.parse(sessionStorage.getItem("login"));
  const history = useHistory();
  const [allCards, setAllCards] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setloader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = (e) => {
    setIsMobile(e.matches);
  };

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)");
    setIsMobile(mobileMediaQuery.matches);
    mobileMediaQuery.addEventListener("change", handleResize);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const handleGetAllCards = async () => {
    setloader(true);
    try {
      const response = await fetch(
        "https://api.ogule.com/api/management/get-all-cards",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${login_data.tokens.refresh.token}`
          },
          body: JSON.stringify({
            filters: {
              user_id: login_data.user._id,
            },
          }),
        }
      );

      const res = await response.json();
      if (res.success) {
        setAllCards(res.result);
        setloader(false);
      } else {
        sessionStorage.removeItem("login");
        history.push("/")
      }
    } catch (error) {
      setloader(false);
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    handleGetAllCards();
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const updateCard = (item) => {
    sessionStorage.setItem("user_card_data", JSON.stringify(item));
    history.push("/updatecard");
  };

  return (
    <div>
      {!isMobile ? (
        <div className="profile_top_sec">
          <div className="text-right mr-2">
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                history.push("/addcard");
              }}
            >
              + Add New Card
            </button>
          </div>
          {!loader && allCards?.length > 0 && (
            <div className="row user_card">
              {allCards?.map((item) => {
                return (
                  <div className="col-md-6 mt-2">
                    <div className="cr_card" key={item.id}>
                      <div className="d-flex justify-content-between">
                        <div className="card_type">
                          <h4>{item.card_name}</h4>
                        </div>
                        <div className="card_type">
                          <h5
                            className="edit"
                            onClick={() => {
                              updateCard(item);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                              />
                            </svg>
                          </h5>
                          {/* <h5
                            className="delete"
                            onClick={() => {
                              setShow(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                            </svg>
                          </h5> */}
                        </div>
                      </div>
                      <div className="card_no">
                        <h5>{item.card_no}</h5>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="card_holder">
                          <p>CARD HOLDER</p>
                          <h5>{item.name_on_card}</h5>
                        </div>
                        <div className="card_holder">
                          <p>Expires</p>
                          <h5>{item.card_expiry_date}</h5>
                        </div>
                      </div>
                      <Modal
                        centered
                        size="md"
                        show={show}
                        onHide={handleClose}
                        className="logout_conf_popup"
                      >
                        <Modal.Body className="px-4 py-3 text-center">
                          <h4 className="mb-3">
                            Are You Sure You Want To Delete This Card ?
                          </h4>
                          <Button
                            variant="primary"
                            className="mr-2 px-5 py-2"
                            // onClick={() => {
                            //   this.carddelete(item.id);
                            // }}
                          >
                            YES
                          </Button>
                          <Button
                            variant="primary"
                            className="mr-2 px-5 py-2"
                            onClick={() => setShow(false)}
                          >
                            NO
                          </Button>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {loader && (
            <div className="row user_card mt-2 mb-2">
              <div className="col-md-6">
                <div className="p-2 skeleton_bg">
                  <div className="row">
                    <div className="col-3">
                      <Skeleton variant="circle" width={40} height={40} />
                    </div>
                    <div className="col-9">
                      <h3>
                        <Skeleton variant="text" width="100%" />
                      </h3>
                    </div>
                  </div>
                  <p>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="p-2 skeleton_bg">
                  <div className="row">
                    <div className="col-3">
                      <Skeleton variant="circle" width={40} height={40} />
                    </div>
                    <div className="col-9">
                      <h3>
                        <Skeleton variant="text" width="100%" />
                      </h3>
                    </div>
                  </div>
                  <p>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="p-2 skeleton_bg">
                  <div className="row">
                    <div className="col-3">
                      <Skeleton variant="circle" width={40} height={40} />
                    </div>
                    <div className="col-9">
                      <h3>
                        <Skeleton variant="text" width="100%" />
                      </h3>
                    </div>
                  </div>
                  <p>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="p-2 skeleton_bg">
                  <div className="row">
                    <div className="col-3">
                      <Skeleton variant="circle" width={40} height={40} />
                    </div>
                    <div className="col-9">
                      <h3>
                        <Skeleton variant="text" width="100%" />
                      </h3>
                    </div>
                  </div>
                  <p>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="p-2 skeleton_bg">
                  <div className="row">
                    <div className="col-3">
                      <Skeleton variant="circle" width={40} height={40} />
                    </div>
                    <div className="col-9">
                      <h3>
                        <Skeleton variant="text" width="100%" />
                      </h3>
                    </div>
                  </div>
                  <p>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="p-2 skeleton_bg">
                  <div className="row">
                    <div className="col-3">
                      <Skeleton variant="circle" width={40} height={40} />
                    </div>
                    <div className="col-9">
                      <h3>
                        <Skeleton variant="text" width="100%" />
                      </h3>
                    </div>
                  </div>
                  <p>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                  </p>
                </div>
              </div>
            </div>
          )}
          {!loader && allCards?.length === 0 && (
            <div className="d-flex justify-content-center mt-5">
              No cards available
            </div>
          )}
        </div>
      ) : (
        <div>
          <div
            className="profile_top_sec shadow"
            style={{
              borderBottomLeftRadius: "50px",
              borderBottomRightRadius: "50px",
              height: "24vh",
              background: "linear-gradient(#8CAFF8, #E3EDFF)",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", top: "1rem", left: "1rem" }}>
              <Link to="/dashboard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="#0D2A7D"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </Link>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
              <img
                className="mb-3"
                style={{
                  filter: "drop-shadow(0 0 0.4px #3d3d3d)",
                  width: "140px",
                }}
                src={logo}
                alt=""
              />
              <span
                className="btn btn-lg badge-pill px-4 shadow-sm"
                style={{ background: "#BACFFB", color: "#0D2A7D" }}
                onClick={() => {
                  history.push("/addcard");
                }}
              >
                + Add New Card
              </span>
            </div>
          </div>

          <div className="container-fluid">
            <div
              className="dashboard py-4 bg-transparent shadow-none"
              style={{ top: "24vh" }}
            >
              {allCards?.length > 0 ? (
                <div className="deals_box_section">
                  {allCards?.map((item) => {
                    return (
                      <div className="col-sm-12 mt-2 w-100">
                        <div className="cr_card" key={item.id}>
                          <div className="d-flex justify-content-between">
                            <div className="card_type">
                              <h4>{item.card_name}</h4>
                            </div>
                            <div className="card_type">
                              <h5
                                className="edit"
                                onClick={() => {
                                  updateCard(item);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                              </h5>
                              {/* <h5
                                className="delete"
                                onClick={() => {
                                  setShow(true);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                </svg>
                              </h5> */}
                            </div>
                          </div>
                          <div className="card_no">
                            <h5>{item.card_no}</h5>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="card_holder">
                              <p>CARD HOLDER</p>
                              <h5>{item.name_on_card}</h5>
                            </div>
                            <div className="card_holder">
                              <p>Expires</p>
                              <h5>{item.card_expiry_date}</h5>
                            </div>
                          </div>
                          <Modal
                            centered
                            size="sm"
                            show={show}
                            onHide={handleClose}
                            className="logout_conf_popup"
                          >
                            <Modal.Body className="px-4 py-3 text-center">
                              <h4 className="mb-3">Are You Sure Delete?</h4>
                              <Button
                                variant="primary"
                                className="mr-2 px-5 py-2"
                                // onClick={() => {
                                //   this.carddelete(item.id);
                                // }}
                              >
                                YES
                              </Button>
                              <Button
                                variant="primary"
                                className="mr-2 px-5 py-2"
                                onClick={() => setShow(false)}
                              >
                                NO
                              </Button>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flights_box_section mt-5">
                  <h4 className="data_not_found">Data Not Found</h4>
                </div>
              )}
            </div>
          </div>
          <Header />
        </div>
      )}
    </div>
  );
};

export default Cards;
