import React from "react";
import Tooltip from "react-tooltip-lite";
import {
  calculateTotalDuration,
  convertTimeFormat,
  calculateDuration,
  flightTotalDuration,
} from "../GlobalFunction";
import { useHistory } from "react-router-dom";

const Oneway = ({
  checkCheapestFlightOneWay,
  checkFastestFlightOneWay,
  checkBestFlightOneWay,
  displayedFlights,
  handleShowMore,
  filteredArray,
}) => {

  const history = useHistory();
  const oneWayFlights = filteredArray
    ?.sort((a, b) => a?.totalPrice - b?.totalPrice)
    ?.slice(0, displayedFlights);

  const submit = (item) => {
    sessionStorage.setItem("booking_details", JSON.stringify(item));
    sessionStorage.setItem("booking_login", "yes");
    history.push("checkoutnew");
  };

  return (
    <>
      {oneWayFlights?.map((flight1) => {
        if (filteredArray && filteredArray.length > 0) {
          const sortedFlights = filteredArray
            .sort((a, b) => a?.totalPrice - b?.totalPrice)
            .slice(0, displayedFlights);

          checkCheapestFlightOneWay = sortedFlights.reduce(
            (min, flight) => {
              const totalPrice = parseFloat(flight?.totalPrice);
              const flightDuration = flightTotalDuration(
                flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                flight?.flight1?.flightDetails[
                  flight?.flight1?.flightDetails?.length - 1
                ]?.flightInformation?.[0]?.productDateTime?.[0]
                  ?.timeOfArrival?.[0],
                flight?.flight1?.flightDetails[
                  flight?.flight1?.flightDetails?.length - 1
                ]?.flightInformation?.[0]?.productDateTime?.[0]
                  ?.dateOfArrival?.[0]
              );
              return totalPrice < min.totalPrice
                ? {
                    flight,
                    totalPrice,
                    totalDuration: flightDuration,
                  }
                : min;
            },
            {
              flight: null,
              totalPrice: Infinity,
              totalDuration: Infinity,
            }
          );

          checkFastestFlightOneWay = sortedFlights.reduce(
            (min, flight) => {
              const flightDuration = flightTotalDuration(
                flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                flight?.flight1?.flightDetails[
                  flight?.flight1?.flightDetails?.length - 1
                ]?.flightInformation?.[0]?.productDateTime?.[0]
                  ?.timeOfArrival?.[0],
                flight?.flight1?.flightDetails[
                  flight?.flight1?.flightDetails?.length - 1
                ]?.flightInformation?.[0]?.productDateTime?.[0]
                  ?.dateOfArrival?.[0]
              );
              return flightDuration < min.totalDuration
                ? {
                    flight,
                    totalDuration: flightDuration,
                  }
                : min;
            },
            { flight: null, totalDuration: Infinity }
          );

          checkBestFlightOneWay = sortedFlights.reduce(
            (min, flight) => {
              const totalPrice = parseFloat(flight?.totalPrice);
              const flightDuration = flightTotalDuration(
                flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.productDateTime?.[0]?.timeOfDeparture?.[0],
                flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
                  ?.productDateTime?.[0]?.dateOfDeparture?.[0],
                flight?.flight1?.flightDetails[
                  flight?.flight1?.flightDetails?.length - 1
                ]?.flightInformation?.[0]?.productDateTime?.[0]
                  ?.timeOfArrival?.[0],
                flight?.flight1?.flightDetails[
                  flight?.flight1?.flightDetails?.length - 1
                ]?.flightInformation?.[0]?.productDateTime?.[0]
                  ?.dateOfArrival?.[0]
              );
              const priceWithduration = totalPrice * flightDuration;
              return priceWithduration < min.priceWithduration
                ? { flight, priceWithduration }
                : min;
            },
            {
              flight: null,
              priceWithduration: Infinity,
            }
          );
        }
        const flightPrice = parseFloat(flight1?.totalPrice);
        const flightDuration = flightTotalDuration(
          flight1?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.timeOfDeparture?.[0],
          flight1?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.dateOfDeparture?.[0],
          flight1?.flight1?.flightDetails[
            flight1?.flight1?.flightDetails?.length - 1
          ]?.flightInformation?.[0]?.productDateTime?.[0]?.timeOfArrival?.[0],
          flight1?.flight1?.flightDetails[
            flight1?.flight1?.flightDetails?.length - 1
          ]?.flightInformation?.[0]?.productDateTime?.[0]?.dateOfArrival?.[0]
        );
        const flightPriceWithDuration = flightPrice * flightDuration;
        return (
          <div className="ticket-card position-relative">
            <div className="row">
              <div className="col-9">
                <div className="d-flex justify-content-between gap-2 h-100">
                  <div className="d-flex align-items-center gap-1">
                    <img
                      className="ticket-airline-icon"
                      src={
                        flight1?.flight1?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.companyId?.[0]
                          ?.customCarriers?.image
                      }
                      alt=""
                    />
                    <span className="mb-0 fs-15p text-truncate w-75 text-capitalize pointer">
                      <Tooltip
                        content={
                          flight1?.flight1?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.companyId?.[0]
                            ?.customCarriers?.airline_name
                        }
                      >
                        {
                          flight1?.flight1?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.companyId?.[0]
                            ?.customCarriers?.airline_name
                        }
                      </Tooltip>
                    </span>
                  </div>
                  <div className="d-flex flex-column text-right justify-content-center">
                    <p className="mb-0 font-weight-bold fs-15p">
                      {convertTimeFormat(
                        flight1?.flight1?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.timeOfDeparture?.[0]
                      )}
                    </p>
                    <p className="mb-0 fs-14p">
                      {
                        flight1?.flight1?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.location?.[0]?.locationId
                      }
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 7H18C18.5304 7 19.0391 7.21071 19.4142 7.58579C19.7893 7.96086 20 8.46957 20 9C20 9.53043 19.7893 10.0391 19.4142 10.4142C19.0391 10.7893 18.5304 11 18 11H14L10 18H7L9 11H5L3 13H0L2 9L0 5H3L5 7H9L7 0H10L14 7Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                    <div className="d-flex flex-column align-items-center gap-2">
                      <p className="mb-0 text-secondary fs-12p">
                        {flight1?.flight1?.flightDetails?.length === 1 ? (
                          "Direct"
                        ) : (
                          <span className="pointer">
                            <Tooltip
                              content={
                                flight1?.flight1?.flightDetails &&
                                flight1?.flight1?.flightDetails?.length > 0 &&
                                flight1?.flight1?.flightDetails
                                  .slice(
                                    0,
                                    flight1?.flight1?.flightDetails.length - 1
                                  )
                                  .map(
                                    (item, index) =>
                                      calculateDuration(
                                        item,
                                        item?.flightInformation?.[0]
                                          ?.productDateTime?.[0]
                                          ?.timeOfDeparture?.[0],
                                        item?.flightInformation?.[0]
                                          ?.productDateTime?.[0]
                                          ?.dateOfDeparture?.[0],
                                        item?.flightInformation?.[0]
                                          ?.productDateTime?.[0]
                                          ?.timeOfArrival?.[0],
                                        item?.flightInformation?.[0]
                                          ?.productDateTime?.[0]
                                          ?.dateOfArrival?.[0],
                                        index
                                      ) +
                                      (index < item?.flightDetails?.length - 2
                                        ? ", "
                                        : "")
                                  )
                              }
                            >
                              {flight1?.flight1?.flightDetails?.length - 1}
                              &nbsp;Stop
                            </Tooltip>
                          </span>
                        )}
                      </p>
                      <svg
                        width="100%"
                        height="1"
                        viewBox="0 0 188 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line y1="0.5" x2="188" y2="0.5" stroke="#D9D9D9" />
                      </svg>

                      <p className="mb-0 text-secondary fs-12p">
                        {calculateTotalDuration(
                          flight1?.flight1?.flightDetails
                        )}
                      </p>
                    </div>
                    <svg
                      width="13"
                      height="15"
                      viewBox="0 0 13 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0079 2.2842C12.1783 3.45446 12.8477 5.03403 12.8745 6.68891C12.9013 8.34379 12.2835 9.94423 11.1517 11.1518L11.0079 11.3006L8.00241 14.3053C7.62107 14.6864 7.10912 14.9083 6.57027 14.9261C6.03143 14.9438 5.50598 14.7561 5.10037 14.4009L4.99837 14.3053L1.99221 11.2999C0.796654 10.1043 0.125 8.4828 0.125 6.79203C0.125 5.10127 0.796654 3.47975 1.99221 2.2842C3.18776 1.08865 4.80927 0.416992 6.50004 0.416992C8.1908 0.416992 9.81232 1.08865 11.0079 2.2842ZM6.50004 4.66703C6.22098 4.66703 5.94465 4.722 5.68684 4.82879C5.42902 4.93558 5.19476 5.0921 4.99744 5.28943C4.80011 5.48675 4.64359 5.72101 4.53679 5.97883C4.43 6.23665 4.37504 6.51297 4.37504 6.79203C4.37504 7.07109 4.43 7.34742 4.53679 7.60523C4.64359 7.86305 4.80011 8.09731 4.99744 8.29463C5.19476 8.49196 5.42902 8.64848 5.68684 8.75528C5.94465 8.86207 6.22098 8.91703 6.50004 8.91703C7.06362 8.91703 7.60413 8.69315 8.00264 8.29463C8.40116 7.89612 8.62504 7.35562 8.62504 6.79203C8.62504 6.22845 8.40116 5.68794 8.00264 5.28943C7.60413 4.89091 7.06362 4.66703 6.50004 4.66703Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <p className="mb-0 font-weight-bold fs-15p">
                      {convertTimeFormat(
                        flight1?.flight1?.flightDetails[
                          flight1?.flight1?.flightDetails?.length - 1
                        ]?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.timeOfArrival?.[0]
                      )}
                    </p>
                    <p className="mb-0 fs-14p">
                      {
                        flight1?.flight1?.flightDetails[
                          flight1?.flight1?.flightDetails?.length - 1
                        ]?.flightInformation?.[0]?.location?.[1]?.locationId
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex flex-column justify-content-between gap-1 h-100">
                  <div>
                    <p className="d-flex text-success font-weight-bold mb-0 fs-22p">
                      ${parseFloat(flight1?.totalPrice)?.toFixed(2)} &nbsp;
                      <p className="text-dark fs-14p mt-2">/ person</p>
                    </p>
                    <p className="mb-0 fs-14p">${flight1?.finalPrice?.toFixed(2)} total</p>
                  </div>
                  <span
                    className="ticket-button"
                    onClick={() => {
                      let newItem = {
                        flights: [flight1?.flight1],
                        price: flight1?.finalPrice,
                        adult: flight1?.adultPrice?.toFixed(2),
                        child: flight1?.childPrice?.toFixed(2),
                        markUp: flight1?.markUp,
                      };
                      submit(newItem);
                    }}
                  >
                    Book Now
                  </span>
                </div>
              </div>
            </div>
            {checkCheapestFlightOneWay.totalPrice === flightPrice &&
              checkCheapestFlightOneWay.totalDuration === flightDuration && (
                <span
                  className="text-success px-2 rounded-bottom fw-600"
                  style={{
                    background: "rgba(235, 252, 245, 1)",
                    position: "absolute",
                    top: "0",
                    left: "8px",
                    fontSize: "14px",
                  }}
                >
                  Cheapest
                </span>
              )}
            {checkFastestFlightOneWay.totalDuration === flightDuration && (
              <span
                className=" px-2 rounded-bottom fw-600"
                style={{
                  background: "rgba(255 237 213)",
                  position: "absolute",
                  top: "0",
                  left:
                    checkCheapestFlightOneWay.totalPrice === flightPrice &&
                    checkCheapestFlightOneWay.totalDuration === flightDuration
                      ? "100px"
                      : "8px",
                  color: "rgba(154 52 18)",
                  opacity: 1,
                  fontSize: "14px",
                }}
              >
                Fastest
              </span>
            )}
            {checkBestFlightOneWay.priceWithduration ===
              flightPriceWithDuration && (
              <span
                className="text-primary px-2 rounded-bottom fw-600"
                style={{
                  background: "rgba(219 234 254)",
                  position: "absolute",
                  top: "0",
                  left:
                    checkFastestFlightOneWay.totalDuration === flightDuration &&
                    checkCheapestFlightOneWay.totalPrice === flightPrice &&
                    checkCheapestFlightOneWay.totalDuration === flightDuration
                      ? "180px"
                      : checkFastestFlightOneWay.totalDuration ===
                          flightDuration ||
                        (checkCheapestFlightOneWay.totalPrice === flightPrice &&
                          checkCheapestFlightOneWay.totalDuration ===
                            flightDuration)
                      ? "100px"
                      : "8px",
                  fontSize: "14px",
                }}
              >
                Best
              </span>
            )}
          </div>
        );
      })}
      {displayedFlights < oneWayFlights?.length && (
        <div>
          <button
            className="btn custom-btn-primary mt-3 w-100 mb-3"
            onClick={handleShowMore}
          >
            Show More Results
          </button>
        </div>
      )}
    </>
  );
};

export default Oneway;
